import { Link } from 'react-router-dom'
import ErrorBoundary from 'Components/ErrorBoundary'
import IconUpgrade from 'Images/icons/premium.svg'
import styles from './SidebarUpgrade.module.scss'

function SidebarUpgrade() {
  /*
   *   Render
   */
  return (
    <aside className={styles.container}>
      <div className={styles.inner}>
        <h1 className={styles.title}>
          <IconUpgrade /> Upgrade now!
        </h1>
        <div className={styles.description}>
          Additional posts and views, surge protection, and more.
        </div>
        <Link to="/choose-plan" className={styles.button}>
          Upgrade
        </Link>
      </div>
    </aside>
  )
}

export default function SafeSidebarUpgrade(props) {
  return (
    <ErrorBoundary>
      <SidebarUpgrade {...props} />
    </ErrorBoundary>
  )
}
