import { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { clamp } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import useStorage from 'Hooks/useStorage'
import ControlledInput from 'Components/ControlledInput'
import ResizeIcon from 'Images/icons/resize.svg'
import styles from './Yogi.module.scss'

export default function Yogi({
  children,
  className,
  initialWidth,
  onResize,
  onReady,
  width,
  onRequestWidthChange,
  minWidth = 370,
  breakpoints = [],
}) {
  const { raf } = useTimers()
  const isLoaded = useIsLoaded()
  const [handlesHovered, setHandlesHovered] = useState(false)
  const [showDisplayWidthInput, setShowDisplayWidthInput] = useState(false)
  const [displayWidth, setDisplayWidth] = useState(width)
  const containerRef = useRef(null)
  const dragStartRef = useRef(0)
  const maxWidthStyleRef = useRef(0)
  const reverseRef = useRef(false)
  const containerWidthRef = useRef()
  const displayWidthInputRef = useRef()
  const resizeObserverRef = useRef(new ResizeObserver(handleResize))
  const [{ HIDE_HINT: hideHint }, setStorage] = useStorage('session')

  function setWidth(width) {
    onRequestWidthChange(width)

    raf(() => {
      onRequestWidthChange(null)
    })
  }

  function handleResize(entries) {
    const inlineSize = entries?.[0]?.borderBoxSize?.[0]?.inlineSize
    if (!inlineSize) return
    const newWidth = Math.round(inlineSize)
    setCurrentDisplayWidth(newWidth)
    if (onResize) onResize(newWidth - 50)
  }

  const dragHandler = useCallback((evt) => {
    if (!hideHint) setStorage('HIDE_HINT', true)

    const delta = evt.clientX - dragStartRef.current

    requestAnimationFrame(() => {
      containerRef.current.style.maxWidth = `${
        reverseRef.current
          ? Math.max(minWidth, maxWidthStyleRef.current - delta * 2)
          : Math.max(minWidth, maxWidthStyleRef.current + delta * 2)
      }px`
      setCurrentDisplayWidth(
        reverseRef.current
          ? Math.max(minWidth, maxWidthStyleRef.current - delta * 2)
          : Math.max(minWidth, maxWidthStyleRef.current + delta * 2),
      )
    })
  }, [])

  const stopDrag = useCallback(() => {
    document.body.style.userSelect = ''
    maxWidthStyleRef.current = containerRef.current.offsetWidth
    document.removeEventListener('mouseup', stopDrag)
    document.removeEventListener('mousemove', dragHandler)
    window.removeEventListener('resize', stopDrag)
    setHandlesHovered(false)
  }, [dragHandler])

  const startDrag = useCallback(
    (evt, reverse) => {
      reverseRef.current = reverse
      document.body.style.userSelect = 'none'
      dragStartRef.current = evt.clientX
      document.addEventListener('mousemove', dragHandler, { passive: true })
      document.addEventListener('mouseup', stopDrag)
      window.addEventListener('resize', stopDrag, { passive: true })
      setHandlesHovered(true)
    },
    [dragHandler, stopDrag],
  )

  function setCurrentDisplayWidth(val) {
    if (!containerRef.current) return
    const newWidth = clamp(val - 50, 0, containerRef.current.offsetWidth - 50)
    setDisplayWidth(newWidth)
  }

  function onWidthInputChange(val) {
    setWidth(Math.max(val, 320))
  }

  const Handle = useCallback(
    ({ className, reverse, showHint }) => {
      const [{ HIDE_HINT }] = useStorage('session')
      const [hintIsVisible, setHintIsVisible] = useState(false)

      useEffect(() => {
        setHintIsVisible(!HIDE_HINT)
      }, [HIDE_HINT])

      return (
        <div
          className={classnames(styles.handle, className, {
            [styles.hide_hint]: !hintIsVisible,
          })}
          onMouseOver={() => setHandlesHovered(true)}
          onMouseOut={() => setHandlesHovered(false)}
          onMouseDown={(evt) => startDrag(evt, reverse)}
        >
          <ResizeIcon />
          {showHint && (
            <div className={styles.handle__hint}>
              Drag ↔ to simulate different screen sizes
            </div>
          )}
        </div>
      )
    },
    [startDrag],
  )

  useEffect(() => {
    if (!containerRef.current) return
    containerWidthRef.current = containerRef.current.offsetWidth
    const currentWidth = initialWidth + 50 || containerWidthRef.current
    maxWidthStyleRef.current = currentWidth
    containerRef.current.style.maxWidth = `${currentWidth}px`
    if (onReady) onReady(currentWidth)

    return () => {
      document.removeEventListener('mouseup', stopDrag)
      document.removeEventListener('mousemove', dragHandler)
    }
  }, [containerRef])

  useEffect(() => {
    if (isLoaded && width && containerRef.current) {
      containerWidthRef.current = containerRef.current.offsetWidth
      const currentWidth = parseInt(width) + 50 || containerWidthRef.current
      maxWidthStyleRef.current = currentWidth
      containerRef.current.style.maxWidth = `${currentWidth}px`
    }
  }, [width])

  useEffect(() => {
    resizeObserverRef.current.observe(containerRef.current)

    return () => {
      window.removeEventListener('resize', stopDrag)
      resizeObserverRef.current.disconnect()
    }
  }, [])

  const breakpointMarkers = breakpoints
    .sort((a, b) => b - a)
    .map((bp) => (
      <button
        aria-label={`Jump to ${bp} pixel breakpoint`}
        key={bp}
        className={styles.width_display__marker}
        style={{ width: `${bp}px` }}
        onClick={() => setWidth(bp)}
      />
    ))

  const displayWidthEl = showDisplayWidthInput ? (
    <ControlledInput
      aria-label="Preview width, enter key to update"
      ref={displayWidthInputRef}
      type="number"
      className={styles.width_display__number_input}
      value={displayWidth}
      onChange={onWidthInputChange}
      onBlur={() => setShowDisplayWidthInput(false)}
      updateOn="blur"
      updateOnEnter
    />
  ) : (
    <button
      className={styles.width_display__number}
      aria-label="Preview width"
      onClick={() => {
        setShowDisplayWidthInput(true)

        raf(() => {
          displayWidthInputRef.current.select()
        })
      }}
    >
      {displayWidth}px
    </button>
  )

  return (
    <div
      ref={containerRef}
      className={classnames(styles.container, className, {
        [styles.handles_hovered]: handlesHovered,
      })}
    >
      {children}
      <Handle className={styles.handle_left} reverse={true} />
      <Handle className={styles.handle_right} showHint />
      <div className={styles.width_display}>
        <div className={styles.width_display__arrow} />
        <div className={styles.width_display__arrow} />
        <div className={styles.width_display__number_container}>
          {displayWidthEl}
        </div>
        {breakpointMarkers}
      </div>
    </div>
  )
}

Yogi.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  initialWidth: PropTypes.number,
  onResize: PropTypes.func,
  onReady: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRequestWidthChange: PropTypes.func,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  breakpoints: PropTypes.array,
}
