import { useState, useEffect } from 'react'
import useTimers from 'Hooks/useTimers'
import Layout from 'Components/Layout'
import styles from './PagePlaceholder.module.scss'

export default function PagePlaceholder() {
  const { st } = useTimers()
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    st(() => {
      setShouldRender(true)
    }, 300)
  }, [])

  return shouldRender ? (
    <Layout>
      <div className={styles.container}>
        <div className={styles.placeholder} />
      </div>
    </Layout>
  ) : null
}
