import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { auth } from 'Fire/firebaseInit'
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'
import { validateEmail, validatePassword, redactEmail } from 'Helpers/utils'
import useStorage from 'Hooks/useStorage'
import useTimers from 'Hooks/useTimers'
import Metas from 'Components/Metas'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import Logo from 'Images/logo.svg'
import Google from 'Images/github.svg'
import GitHub from 'Images/google.svg'
import styles from './AuthLogin.module.scss'

const googleProvider = new GoogleAuthProvider()
const gitHubProvider = new GithubAuthProvider()

export default function AuthLogin({ email, setEmail }) {
  const [{ LAST_LOGIN_METHOD }, setStorage] = useStorage()
  const { st } = useTimers()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [showLastLogin, setShowLastLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const navigate = useNavigate()

  /*
   *   Sign up with oAuth
   */
  async function oAuthLogin(authProvider) {
    try {
      setIsLoading(true)
      const {
        providerId,
        user: { email },
      } = await signInWithPopup(auth, authProvider)
      setStorage('LAST_LOGIN_METHOD', {
        email: redactEmail(email),
        provider: providerId.replace('.com', ''),
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)

      if (error.code === 'auth/account-exists-with-different-credential') {
        setErrorMessage('That account uses a password')
      }
    }
  }

  /*
   *   Sign in with email and password
   */
  async function passwordLogin() {
    if (!validateEmail(email)) {
      emailRef.current.focus()
      if (email.length < 1) {
        setErrorMessage('Please enter an email address')
      } else {
        setErrorMessage('Invalid email address')
      }
      return false
    } else if (!validatePassword(password)) {
      passwordRef.current.focus()
      setErrorMessage('Please enter a password')
      return false
    }

    setIsLoading(true)

    try {
      await signInWithEmailAndPassword(auth, email, password)
      setStorage('LAST_LOGIN_METHOD', {
        email: redactEmail(email),
        provider: 'password',
      })
      navigate('/')
    } catch (error) {
      if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/user-not-found'
      ) {
        setIsLoading(false)
        setErrorMessage('Invalid email or password')
      } else if (error.code === 'auth/too-many-requests') {
        setIsLoading(false)
        setErrorMessage(
          'Too many unsuccessful login attempts. Please try again in a few minutes.',
        )
      } else {
        setIsLoading(false)
        if (error.message) setErrorMessage(error.message)
      }
    }
  }

  /*
   *   Focus input on load
   */
  useEffect(() => {
    if (emailRef.current && window.innerWidth > 600) emailRef.current.focus()
    st(() => setShowLastLogin(true), 1000)
  }, [])

  /*
   *   Last login
   */
  const lastLoginEl = LAST_LOGIN_METHOD ? (
    <div
      className={classNames(styles.last_login, {
        [styles.visible]: showLastLogin,
      })}
    >
      Last signed-in with <b>{LAST_LOGIN_METHOD.email}</b> using{' '}
      {LAST_LOGIN_METHOD?.provider === 'password' ? (
        ' a password'
      ) : (
        <b>{LAST_LOGIN_METHOD?.provider}</b>
      )}
    </div>
  ) : null

  const resetButtonEl = (
    <Link
      to="/reset-password"
      tabIndex={3}
      type="button"
      className={styles.forgot_password}
    >
      Forgot your password?
    </Link>
  )

  return (
    <main className={styles.container}>
      <Metas title="Behold login | Sign in to your account" />
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.form}>
          <Form
            className={styles.email}
            isLoading={isLoading}
            errorMessage={errorMessage}
            onSubmit={passwordLogin}
            showArrow={false}
          >
            <h2 className={styles.section_title}>Sign in with email:</h2>

            <FormItem label="Email" inputId="email">
              <ControlledInput
                id="email"
                tabIndex={1}
                ref={emailRef}
                className={styles.input}
                type="email"
                onChange={setEmail}
                value={email}
              />
            </FormItem>

            <FormItem label="Password" inputId="password" note={resetButtonEl}>
              <ControlledInput
                id="password"
                tabIndex={2}
                ref={passwordRef}
                className={styles.input}
                type="password"
                onChange={setPassword}
                value={password}
              />
            </FormItem>
          </Form>
          <div className={styles.oauth}>
            <h2 className={styles.section_title}>Sign in with oAuth:</h2>
            <div className={styles.oauth__buttons}>
              <button
                className={styles.oauth_button}
                onClick={() => oAuthLogin(gitHubProvider)}
              >
                <Google />
                GitHub
              </button>
              <button
                className={styles.oauth_button}
                onClick={() => oAuthLogin(googleProvider)}
              >
                <GitHub /> Google
              </button>
            </div>
          </div>
        </div>
        <footer className={styles.footer}>
          Don’t have an account? <Link to="/register">Sign up</Link>
          <br />
          {lastLoginEl}
        </footer>
      </div>
    </main>
  )
}

AuthLogin.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
}
