import { useEffect, useRef, useCallback } from 'react'

export default function useTimers() {
  const timeoutIds = useRef(new Set())
  const rafIds = useRef(new Set())

  const st = useCallback((callback, duration) => {
    const id = setTimeout(() => {
      callback()
      timeoutIds.current.delete(id)
    }, duration)
    timeoutIds.current.add(id)
    return id
  }, [])

  const raf = useCallback((callback) => {
    const id = requestAnimationFrame(() => {
      callback()
      rafIds.current.delete(id)
    })
    rafIds.current.add(id)
    return id
  }, [])

  const cancelTimer = useCallback((id) => {
    window.cancelAnimationFrame(id)
    window.clearTimeout(id)
    timeoutIds.current.delete(id)
    rafIds.current.delete(id)
  }, [])

  const cancelAllTimers = useCallback(() => {
    timeoutIds.current.forEach((id) => {
      window.clearTimeout(id)
      timeoutIds.current.delete(id)
    })
    rafIds.current.forEach((id) => {
      window.cancelAnimationFrame(id)
      rafIds.current.delete(id)
    })
  }, [])

  useEffect(() => {
    return cancelAllTimers
  }, [cancelAllTimers])

  return {
    st,
    raf,
    cancelTimer,
    cancelAllTimers,
    timeoutIds: timeoutIds.current,
    rafIds: rafIds.current,
  }
}
