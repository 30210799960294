import {
  useState,
  useEffect,
  useReducer,
  createContext,
  useContext,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import useTransition from 'Hooks/useTransition'
import useTimers from 'Hooks/useTimers'
import useHotkey from 'Hooks/useHotkey'
import useOnResize from 'Hooks/useOnResize'
import ErrorBoundary from 'Components/ErrorBoundary'
import styles from './Panels.module.scss'

const PanelContext = createContext()

/*
 *   Panels
 */
export function Panels({
  children,
  panels,
  backgroundColor = 'var(--color-background)',
  onPanelsToggle,
  baseUrl,
  width = 300,
  isDemo = false,
}) {
  const { raf } = useTimers()
  const urlParams = useParams()
  const activePanelsRef = useRef()
  const navigate = useNavigate()

  function reducer(state, payload) {
    switch (payload.action) {
      case 'REGISTER_PANEL':
        return {
          ...state,
          panels: { ...state.panels, [payload.contents.id]: payload.contents },
        }
      case 'SET_OPEN_PANELS': {
        const newSet = new Set(payload.contents)
        activePanelsRef.current = newSet
        return { ...state, activePanels: newSet }
      }
      case 'SET_IS_SCROLLED': {
        return { ...state, isScrolled: payload.contents }
      }
      case 'SET_IS_FULLY_SCROLLED': {
        return { ...state, isFullyScrolled: payload.contents }
      }
      case 'SET_WIDTH': {
        return { ...state, width: payload.contents }
      }
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    panels: {},
    activePanels: new Set(),
    width,
    backgroundColor,
    isFullyScrolled: true,
    baseUrl,
  })

  const currentPanelId = Array.from(state.activePanels)[
    state.activePanels.size - 1
  ]

  const currentPanel = state.panels[currentPanelId]

  useEffect(() => {
    raf(() => {
      const urlPanels = urlParams['*']
        .split('/')
        .filter((panelId) => state.panels[panelId])

      dispatch({ action: 'SET_OPEN_PANELS', contents: urlPanels })
    })
  }, [urlParams, state.panels])

  useEffect(() => {
    dispatch({ action: 'SET_WIDTH', contents: width })
  }, [width])

  useEffect(() => {
    if (onPanelsToggle) {
      onPanelsToggle(!!currentPanel)
    }
  }, [onPanelsToggle, state.activePanels])

  const handleEscape = useCallback(() => {
    if (activePanelsRef.current.size) {
      const newActivePanels = new Set(activePanelsRef.current)
      const id = Array.from(newActivePanels).pop()
      newActivePanels.delete(id)
      navigate(`${state.baseUrl}/${Array.from(newActivePanels).join('/')}`)
    } else {
      if (!isDemo) {
        navigate('/')
      }
    }
  }, [])

  useHotkey('Escape', handleEscape)

  let panelWidth = currentPanel ? `${currentPanel.width}px` : `${width}px`
  if (width === 'FULL') panelWidth = '100%'

  return (
    <PanelContext.Provider value={{ state, dispatch }}>
      <section
        className={styles.container}
        style={{
          width: panelWidth,
        }}
      >
        <div
          className={classNames(styles.base_panel, {
            [styles.hidden]: currentPanel,
          })}
        >
          {children}
        </div>
        {panels}
      </section>
    </PanelContext.Provider>
  )
}

Panels.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  panels: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  backgroundColor: PropTypes.string,
  onPanelsToggle: PropTypes.func,
  baseUrl: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

/*
 *   Panel
 */
export function UnsafePanel({ children, id, width }) {
  const { state, dispatch } = useContext(PanelContext)
  const { show, hide, isVisible, isHidden } = useTransition(250, null, close)
  const { st, raf } = useTimers()
  const [shouldRender, setShouldRender] = useState()
  const containerRef = useRef()
  const innerRef = useRef()
  const resizeRafRef = useRef()

  function isFullyScrolled() {
    const outer = containerRef.current
    const inner = innerRef.current

    return outer?.scrollTop + 100 >= inner?.offsetHeight - outer?.offsetHeight
  }

  const handleScroll = useCallback((evt) => {
    const el = evt?.target || containerRef.current

    if (el?.scrollTop > 0) {
      dispatch({ action: 'SET_IS_SCROLLED', contents: true })
    } else {
      dispatch({ action: 'SET_IS_SCROLLED', contents: false })
    }

    if (isFullyScrolled()) {
      dispatch({ action: 'SET_IS_FULLY_SCROLLED', contents: true })
    } else if (el) {
      dispatch({ action: 'SET_IS_FULLY_SCROLLED', contents: false })
    }
  }, [])

  const handleResize = useCallback((dimension) => {
    if (dimension !== 'height') return
    cancelAnimationFrame(resizeRafRef.current)

    const el = containerRef.current

    resizeRafRef.current = raf(() => {
      if (isFullyScrolled()) {
        dispatch({ action: 'SET_IS_FULLY_SCROLLED', contents: true })
      } else if (el) {
        dispatch({ action: 'SET_IS_FULLY_SCROLLED', contents: false })
      }
    })
  }, [])

  useOnResize(handleResize, 100, containerRef.current)

  function open() {
    setShouldRender(true)
  }

  function close() {
    setShouldRender(false)
  }

  useEffect(() => {
    dispatch({ action: 'REGISTER_PANEL', contents: { id, width } })
  }, [id, width])

  useEffect(() => {
    if (Array.from(state.activePanels).pop() === id) {
      open()
      st(() => {
        raf(show)
      })
    } else {
      hide()
    }
  }, [state])

  useEffect(() => {
    raf(() => handleScroll())
  }, [isVisible])

  return shouldRender ? (
    <div
      id={`panel-${id}`}
      ref={containerRef}
      className={classNames(styles.panel, {
        [styles.bottom_shadow]: !state.isFullyScrolled,
      })}
      onScroll={handleScroll}
      style={{
        zIndex: Array.from(state.activePanels).indexOf(id) + 1,
        backgroundColor: state.backgroundColor,
        transform: isVisible && !isHidden ? '' : 'translateX(100%)',
        opacity: !isVisible || isHidden ? '0' : '1',
        width: `${state.width === 'FULL' ? '100%' : width + 'px'}`,
      }}
    >
      <div ref={innerRef}>{children}</div>
    </div>
  ) : null
}

UnsafePanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export function Panel(props) {
  return (
    <ErrorBoundary>
      <UnsafePanel {...props} />
    </ErrorBoundary>
  )
}

/*
 *   UsePanels
 */
export function usePanels() {
  const { state } = useContext(PanelContext)
  const navigate = useNavigate()

  function openPanel(id) {
    navigate(
      `${state.baseUrl}/${[...Array.from(state.activePanels), id].join('/')}`,
    )
  }

  function closePanel(id) {
    if (state.activePanels.size) {
      const newActivePanels = new Set(state.activePanels)
      newActivePanels.delete(id)
      navigate(`${state.baseUrl}/${Array.from(newActivePanels).join('/')}`)
    }
  }

  function closeAllPanels() {
    navigate(state.baseUrl)
  }

  return { panelState: state, openPanel, closePanel, closeAllPanels }
}
