import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { deleteFeed } from 'Fire/firebaseActions'
import { clearFeedCache } from 'Helpers/instaApi'
import useTimers from 'Hooks/useTimers'
import { useSlice } from 'State'
import MenuItem from './MenuItem'
import SettingsIcon from 'Images/icons/settings.svg'
import EditIcon from 'Images/icons/pencil.svg'
import BroomIcon from 'Images/icons/broom.svg'
import TrashIcon from 'Images/icons/trash.svg'
import styles from './FeedMenu.module.scss'

export default function FeedMenu({
  showSettings = true,
  feedId,
  editTitleHandler,
}) {
  const appState = useSlice('feeds')
  const feed = appState.feeds[feedId]

  const isUpdating =
    feed?.isUpdating &&
    (feed.source === 'HASHTAG' ? feed.hashtags?.length : true)

  return (
    <>
      {showSettings && (
        <MenuItem to={`/feeds/${feedId}`}>
          <SettingsIcon /> Settings
        </MenuItem>
      )}
      <ClearCacheButton isUpdating={isUpdating} feedId={feedId} />
      {editTitleHandler && (
        <MenuItem onClick={editTitleHandler}>
          <EditIcon />
          Edit title
        </MenuItem>
      )}
      <hr />
      <MenuItem
        confirm
        confirmText={'Confirm Delete'}
        onClick={() => deleteFeed(feedId)}
        color="red"
      >
        <TrashIcon /> Delete Feed
      </MenuItem>
    </>
  )
}

FeedMenu.propTypes = {
  showSettings: PropTypes.bool,
  isUpdating: PropTypes.bool,
  feedId: PropTypes.string,
}

function ClearCacheButton({ feedId, isUpdating }) {
  const { st, cancelAllTimers } = useTimers()
  const [status, setStatus] = useState(null)
  const statusRef = useRef(status)

  function handleClick() {
    if (status) return
    setStatus('LOADING')
    clearFeedCache(feedId)
  }

  useEffect(() => {
    if (isUpdating && statusRef.current !== 'LOADING') {
      statusRef.current = 'LOADING'
      setStatus('LOADING')
    }
    if (!isUpdating && statusRef.current === 'LOADING') {
      statusRef.current = 'SUCCESS'
      setStatus('SUCCESS')
    }
  }, [isUpdating, status])

  useEffect(() => {
    if (!status || status === 'LOADING') return
    cancelAllTimers()
    st(() => {
      statusRef.current = null
      setStatus(null)
    }, 1800)
  }, [cancelAllTimers, st, status])

  return (
    <MenuItem
      loading={status === 'LOADING'}
      success={status === 'SUCCESS'}
      error={status === 'ERROR'}
      onClick={handleClick}
    >
      <BroomIcon />
      <div
        className={classNames(styles.loading_button__text, {
          [styles.loading]: status === 'LOADING',
          [styles.success]: status === 'SUCCESS',
        })}
      >
        <div className={styles.loading_button__text_inner}>
          <div className={styles.loading_button__message}>Clear cache</div>
          <div className={styles.loading_button__message} aria-live="assertive">
            {status === 'LOADING' && 'Clearing...'}
          </div>
          <div className={styles.loading_button__message} aria-live="assertive">
            {status === 'SUCCESS' && 'Cache Cleared'}
          </div>
        </div>
      </div>
    </MenuItem>
  )
}

ClearCacheButton.propTypes = {
  feedId: PropTypes.string,
}
