import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { forceLayout } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import SmartButton from 'Components/SmartButton'
import RightArrow from 'Images/icons/arrow-right.svg'
import styles from './Form.module.scss'

export function FormItem({
  children,
  label,
  note,
  className,
  tag = 'div',
  labelColor = null,
  inputId,
}) {
  const Tag = tag
  return (
    <Tag className={classnames(styles.form_item, className)}>
      {label && (
        <div className={styles.form_item__header}>
          <label
            className={styles.label}
            htmlFor={inputId}
            style={{ color: labelColor }}
          >
            {label}
          </label>
          <div className={styles.note}>{note}</div>
        </div>
      )}
      {children}
    </Tag>
  )
}

FormItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  note: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  tag: PropTypes.string,
  labelColor: PropTypes.string,
  inputId: PropTypes.string,
}

export function Form({
  children,
  isLoading,
  submitButtonContent,
  showCancelButton = false,
  showSubmitButton = true,
  submitButtonColor = 'green',
  submitButtonSize = 'large',
  submitIsDisabled = false,
  buttonAlignment = 'left',
  onSubmit,
  onCancel,
  errorMessage,
  className,
  showArrow = false,
  submitText = 'Continue',
}) {
  const { raf, st } = useTimers()
  const [errorIsVisible, setErrorIsVisible] = useState(false)
  const errorElRef = useRef(null)

  /*
   *   Show error
   */
  const showError = useCallback(async () => {
    raf(() => {
      setErrorIsVisible(true)

      raf(() => {
        errorElRef.current.style.height = `${errorElRef.current.scrollHeight}px`
      })

      st(() => {
        errorElRef.current.style.height = `auto`
      }, 400)
    })
  }, [])

  /*
   *   Hide error
   */
  const hideError = useCallback(async () => {
    setErrorIsVisible(false)
    forceLayout()

    raf(() => {
      errorElRef.current.style.height = `0px`
    })
  }, [])

  /*
   *   Submit Form
   */
  function submitForm() {
    if (onSubmit && !submitIsDisabled) onSubmit()
  }

  /*
   *   Cancel
   */
  function cancel() {
    if (onCancel) onCancel()
  }

  /*
   *   Show/hide error on props change
   */
  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage)
    } else {
      hideError()
    }
  }, [errorMessage, showError, hideError])

  return (
    <div className={className}>
      <div
        ref={errorElRef}
        className={classnames(styles.error, {
          [styles.active]: errorMessage,
          [styles.visible]: errorIsVisible,
        })}
      >
        <div className={styles.error__inner} role="alert">
          {errorIsVisible && errorMessage}
        </div>
      </div>
      <form
        className={styles.form}
        onSubmit={(event) => event.preventDefault()}
      >
        {children}
        <div
          className={classnames(styles.footer, {
            [styles.align_right]: buttonAlignment === 'right',
          })}
        >
          {showCancelButton && (
            <SmartButton
              disabled={isLoading}
              className={styles.cancel_button}
              size={submitButtonSize}
              color="grey"
              onClick={cancel}
            >
              Cancel
            </SmartButton>
          )}
          {showSubmitButton && (
            <SmartButton
              disabled={submitIsDisabled}
              type="submit"
              className={styles.submit_button}
              isLoading={isLoading}
              onClick={submitForm}
              color={submitButtonColor}
              size={submitButtonSize}
            >
              {submitButtonContent || (
                <>
                  {submitText} {showArrow && <RightArrow />}
                </>
              )}
            </SmartButton>
          )}
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  isLoading: PropTypes.bool,
  submitButtonContent: PropTypes.element,
  showCancelButton: PropTypes.bool,
  submitButtonColor: PropTypes.string,
  submitButtonSize: PropTypes.string,
  submitIsDisabled: PropTypes.bool,
  buttonAlignment: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  errorMessage: PropTypes.any,
  className: PropTypes.string,
  showArrow: PropTypes.bool,
  submitText: PropTypes.string,
}
