import { adjectives, animals } from 'Constants/words'

function getRandomArrayItem(array) {
  const minCeiled = 0
  const maxFloored = Math.floor(array.length - 1)
  const index = Math.floor(
    Math.random() * (maxFloored - minCeiled + 1) + minCeiled,
  )
  return array[index]
}

export default function generateFunId(existingIds, prefix = '') {
  let id =
    prefix + getRandomArrayItem(adjectives) + '_' + getRandomArrayItem(animals)

  let suffix = 1
  let tempId = id

  while (existingIds.includes(tempId)) {
    tempId = id + suffix
    suffix++
  }

  return tempId
}
