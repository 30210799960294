import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useWindowSize from 'Hooks/useWindowSize'
import Yogi from 'Components/Yogi'
import Widget from 'Components/Widget'
import styles from './FeedWidgetPreview.module.scss'

export default function FeedWidgetPreview({
  feedId,
  posts,
  feedMetadata,
  localWidgetSettings,
  breakpointsRef,
  previewLoadingColors,
  previewWidth,
  onRequestWidthChange,
  demoMode = false,
}) {
  const windowSize = useWindowSize()
  const [yogiIsReady, setYogiIsReady] = useState(false)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null)
  const previewWidthRef = useRef(5000)
  const localWidgetSettingsRef = useRef(localWidgetSettings)
  const currentBreakpointRef = useRef(null)

  function handlePreviewResize(width) {
    previewWidthRef.current = width

    let activeBreakpoint = null

    breakpointsRef.current
      .map((bp) => parseInt(bp[0]))
      .sort((a, b) => b - a)
      .forEach((breakpoint) => {
        if (width <= breakpoint) {
          activeBreakpoint = breakpoint
        }
      })

    if (currentBreakpointRef.current !== activeBreakpoint) {
      currentBreakpointRef.current = activeBreakpoint
      setCurrentBreakpoint(activeBreakpoint)
    }
  }

  useEffect(() => {
    localWidgetSettingsRef.current = localWidgetSettings
    handlePreviewResize(previewWidthRef.current)
  }, [localWidgetSettings])

  return (
    <>
      {windowSize.width > 1000 && (
        <Yogi
          className={styles.resizeable}
          initialWidth={950}
          onResize={handlePreviewResize}
          onReady={() => setYogiIsReady(true)}
          width={previewWidth}
          onRequestWidthChange={onRequestWidthChange}
          breakpoints={Object.keys(localWidgetSettings.breakpoints).filter(
            (bp) => !['default', 'new'].includes(bp),
          )}
        >
          <div
            className={classNames(styles.resizeable__scroll_container, {
              [styles.visible]: yogiIsReady,
            })}
          >
            {yogiIsReady && (
              <Widget
                forceScrollbars={
                  currentBreakpoint
                    ? currentBreakpoint?.bindToScrollPos
                    : localWidgetSettings.breakpoints.default.bindToScrollPos
                }
                posts={posts}
                settings={localWidgetSettings}
                metadata={feedMetadata}
                previewLoadingColors={previewLoadingColors}
              />
            )}
          </div>
        </Yogi>
      )}
      {windowSize.width < 1001 && (
        <div className={classNames(styles.mobile_scroll_container)}>
          <Widget
            posts={posts}
            settings={localWidgetSettings}
            metadata={feedMetadata}
            previewLoadingColors={previewLoadingColors}
          />
        </div>
      )}
      <div
        className={classNames(styles.notices, {
          [styles.visible]: currentBreakpoint,
        })}
      >
        <Link
          to={demoMode ? '/try/breakpoints' : `/feeds/${feedId}/breakpoints`}
          className={classNames(styles.breakpoint_notice, {
            [styles.visible]: currentBreakpoint,
          })}
        >
          <b>{currentBreakpoint ? currentBreakpoint + 'px' : 'Default'}</b>{' '}
          breakpoint applied
        </Link>
      </div>
    </>
  )
}

FeedWidgetPreview.propTypes = {
  feedId: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
  localWidgetSettings: PropTypes.object,
  breakpointsRef: PropTypes.object,
  previewLoadingColors: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  previewWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRequestWidthChange: PropTypes.func,
  demoMode: PropTypes.bool,
}
