import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { pruneObject } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import { usePanels } from 'Components/Panels'
import FeedPanel from 'Components/feed/FeedPanel'
import HelpTooltip from 'Components/HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import ControlledRange from 'Components/ControlledRange'
import ControlledSelect from 'Components/ControlledSelect'
import TransitionWrapper from 'Components/TransitionWrapper'
import PanelIcon from 'Images/icons/responsive.svg'
import PlusIcon from 'Images/icons/plus.svg'
import EditIcon from 'Images/icons/edit.svg'
import TrashIcon from 'Images/icons/trash.svg'
import XIcon from 'Images/icons/x.svg'
import ExpandIcon from 'Images/icons/caret-down.svg'
import CollapseIcon from 'Images/icons/caret-up.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelResponsiveGrid({
  panelId,
  breakpoints,
  localFeedSettings,
  localWidgetSettings,
  updateLocalWidgetSetting,
  setPreviewWidth,
  hasChanges,
  saveChanges,
  resetChanges,
}) {
  const { raf, st } = useTimers()
  const { panelState } = usePanels()
  const scrollToBottomRef = useRef()
  const breakpointsStartOpenRef = useRef(false)
  const breakpointsContainerRef = useRef()

  const isOpen = Array.from(panelState.activePanels).pop() === panelId

  /*
   *   Remove breakpoint
   */
  const removeBreakpoint = useCallback(
    (breakpoint) => {
      updateLocalWidgetSetting(
        'breakpoints',
        pruneObject(localWidgetSettings.breakpoints, breakpoint),
      )
    },
    [localWidgetSettings, updateLocalWidgetSetting],
  )

  /*
   *   Update breakpoint
   */
  const updateBreakpoint = useCallback(
    (breakpoint, key, val) => {
      if (key === 'breakpoint') {
        raf(() => {
          const newBreakpoint = breakpointsContainerRef.current.querySelector(
            `[data-breakpoint-width="${val}"]`,
          )
          if (newBreakpoint) {
            newBreakpoint.scrollIntoView({ block: 'center' })
          }
          setPreviewWidth(val)
        })
        const bpSettings = { ...localWidgetSettings.breakpoints[breakpoint] }
        const prunedBreakpoints = pruneObject(
          localWidgetSettings.breakpoints,
          breakpoint,
        )

        updateLocalWidgetSetting('breakpoints', {
          ...prunedBreakpoints,
          [val]: bpSettings,
        })
        setPreviewWidth(val)
      } else {
        updateLocalWidgetSetting('breakpoints', {
          ...localWidgetSettings.breakpoints,
          [breakpoint]: {
            ...localWidgetSettings.breakpoints[breakpoint],
            [key]: val,
          },
        })
        setPreviewWidth(breakpoint)
      }
    },
    [localWidgetSettings, updateLocalWidgetSetting],
  )

  /*
   *   Add breakpoint
   */
  const addBreakpoint = useCallback(() => {
    updateLocalWidgetSetting('breakpoints', {
      ...localWidgetSettings.breakpoints,
      new: {
        ...localWidgetSettings.breakpoints.default,
        numPosts: localFeedSettings.maxPosts,
      },
    })
    raf(() => {
      if (scrollToBottomRef.current) {
        scrollToBottomRef.current.scrollIntoView(true)
      }
    })
  }, [localWidgetSettings, updateLocalWidgetSetting])

  useEffect(() => {
    if (!isOpen) {
      breakpointsStartOpenRef.current = false
    } else {
      st(() => (breakpointsStartOpenRef.current = true), 100)
    }
  }, [isOpen])
  const breakpointEls = breakpoints
    .sort((a, b) => {
      if (a[0] === 'new') return 1
      if (b[0] === 'new') return -1

      return parseInt(b[0]) - parseInt(a[0])
    })
    .map((bp) => {
      const [breakpoint, bpSettings] = bp

      return (
        <Breakpoint
          key={breakpoint}
          breakpoint={breakpoint}
          settings={bpSettings}
          localFeedSettings={localFeedSettings}
          localWidgetSettings={localWidgetSettings}
          updateBreakpoint={updateBreakpoint}
          removeBreakpoint={removeBreakpoint}
          expanded={breakpointsStartOpenRef.current}
          setPreviewWidth={setPreviewWidth}
        />
      )
    })

  return (
    <FeedPanel
      panelId={panelId}
      width={375}
      icon={<PanelIcon />}
      title="Breakpoints"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={resetChanges}
    >
      {breakpointEls?.length > 0 && (
        <div
          ref={breakpointsContainerRef}
          className={classNames(styles.fieldset, styles.fieldset_invisible)}
        >
          {breakpointEls}
        </div>
      )}
      <div ref={scrollToBottomRef} style={{ height: '1px', width: '100%' }} />
      <footer
        className={classNames(styles.footer, {
          [styles.shadow]: !panelState.isFullyScrolled,
        })}
      >
        <button className={styles.add_breakpoint} onClick={addBreakpoint}>
          <PlusIcon /> Add breakpoint
        </button>
      </footer>
    </FeedPanel>
  )
}

FeedPanelResponsiveGrid.propTypes = {
  panelId: PropTypes.string,
  breakpoints: PropTypes.array,
  localFeedSettings: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  updateLocalWidgetSetting: PropTypes.func,
  setPreviewWidth: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
}

/*
 *   Breakpoint Label
 */
function BreakpointLabel({
  val,
  onUpdate,
  onDelete,
  isExpanded,
  setIsExpanded,
}) {
  const [editing, setEditing] = useState(val === 'new')
  const inputRef = useRef(null)
  const [toggleButtonIsHovered, setToggleButtonIsHovered] = useState(false)

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [editing])

  return editing ? (
    <>
      <div className={styles.label}>Breakpoint width (pixels)</div>
      <div className={styles.breakpoint_edit_container}>
        <ControlledInput
          ref={inputRef}
          type="number"
          min={1}
          value={isNaN(val) ? 0 : val}
          updateOn="button"
          saveButtonText={val === 'new' ? 'Add' : 'Update'}
          onChange={(val) => {
            onUpdate(val)
            setEditing(false)
          }}
        />
        <button
          aria-label="cancel new breakpoint"
          className={styles.breakpoint_edit_cancel}
          onClick={() => (val === 'new' ? onDelete() : setEditing(false))}
        >
          <XIcon />
        </button>
      </div>
    </>
  ) : (
    <div
      className={styles.breakpoint_label}
      onClick={!isExpanded ? () => setIsExpanded(true) : null}
    >
      {val}px{' '}
      <div className={styles.breakpoint_actions}>
        {isExpanded && (
          <TransitionWrapper
            transform="scale(.5)"
            duration={150}
            delay={0.05}
            tag="button"
            className={styles.breakpoint_edit}
            onClick={() => {
              setEditing(true)
              setIsExpanded(true)
            }}
          >
            <EditIcon />
          </TransitionWrapper>
        )}
        {isExpanded && (
          <TransitionWrapper
            transform="scale(.5)"
            duration={150}
            tag="button"
            className={styles.breakpoint_edit}
            onClick={onDelete}
          >
            <TrashIcon />
          </TransitionWrapper>
        )}
        <button
          className={classNames(styles.breakpoint_edit, {
            [styles.hovered]: toggleButtonIsHovered,
          })}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </button>
      </div>
      <button
        className={styles.breakpoint_toggle}
        tabIndex={-1}
        aria-hidden={true}
        onClick={() => setIsExpanded(!isExpanded)}
        onMouseOver={() => setToggleButtonIsHovered(true)}
        onMouseOut={() => setToggleButtonIsHovered(false)}
      />
    </div>
  )
}

BreakpointLabel.propTypes = {
  val: PropTypes.string,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  isExpanded: PropTypes.bool,
}

function Breakpoint({
  breakpoint,
  settings,
  updateBreakpoint,
  removeBreakpoint,
  localFeedSettings,
  localWidgetSettings,
  expanded = false,
  setPreviewWidth,
}) {
  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    if (isExpanded) setPreviewWidth(breakpoint)
  }, [isExpanded])

  /*
   *   Aspect Ratio options
   */
  const aspectRatioOptions = [
    { label: '1:1 - Square', value: '1:1' },
    { label: '9:16 - Reels', value: '9:16' },
    { label: '4:5 - Short Reels', value: '4:5' },
    { label: '3:2 - Landscape', value: '3:2' },
    { label: 'Custom', value: 'CUSTOM' },
  ]
  const aspectRatio = settings.postAspectRatio || [1, 1]
  let aspectRatioString = aspectRatio.join(':')
  if (!'1:1,9:16,4:5,3:2'.includes(aspectRatioString)) {
    aspectRatioString = 'CUSTOM'
  }

  return (
    <TransitionWrapper
      className={styles.breakpoint}
      transform="translateX(-5px)"
      data-breakpoint-width={breakpoint}
    >
      {/* Breakpoint Trigger */}
      <div className={classNames(styles.setting)}>
        <BreakpointLabel
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          val={breakpoint}
          onUpdate={(val) => updateBreakpoint(breakpoint, 'breakpoint', val)}
          onDelete={() => removeBreakpoint(breakpoint)}
        />
      </div>

      {breakpoint !== 'new' && isExpanded && (
        <div
          className={classNames(
            styles.breakpoint__inner,
            styles.fieldset,
            styles.fieldset_invisible,
          )}
        >
          {/* Number of posts */}
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>
              <HelpTooltip triggerText="# Posts to show" width={290}>
                <div className={styles.tooltip}>
                  <h1>Number of Posts</h1>
                  <p>
                    The maximum number of recent posts to show at this
                    breakpoint. Cannot be greater than the primary &quot;Number
                    of posts&quot; setting: <b>{localFeedSettings.maxPosts}</b>
                  </p>
                </div>
              </HelpTooltip>
            </div>
            <ControlledInput
              type="number"
              min={1}
              max={localFeedSettings.maxPosts}
              value={settings.numPosts}
              onChange={(val) => updateBreakpoint(breakpoint, 'numPosts', val)}
            />
          </div>
          {/* Number of columns */}
          <div className={classNames(styles.setting)}>
            <div className={styles.label}># of Columns</div>
            <ControlledInput
              type="number"
              min={1}
              value={settings.numColumns}
              onChange={(val) =>
                updateBreakpoint(breakpoint, 'numColumns', val)
              }
            />
          </div>
          {/* Horizontal spacing */}
          <div className={classNames(styles.setting, styles.setting_half)}>
            <div className={styles.label}>Column Gap</div>
            <ControlledInput
              type="number"
              min={0}
              value={settings.gap.x}
              onChange={(val) =>
                updateBreakpoint(breakpoint, 'gap', {
                  ...localWidgetSettings.breakpoints[breakpoint].gap,
                  x: val,
                })
              }
            />
          </div>
          {/* Vertical spacing */}
          <div className={classNames(styles.setting, styles.setting_half)}>
            <div className={styles.label}>Row Gap</div>
            <ControlledInput
              type="number"
              min={0}
              value={settings.gap.y}
              onChange={(val) =>
                updateBreakpoint(breakpoint, 'gap', {
                  ...localWidgetSettings.breakpoints[breakpoint].gap,
                  y: val,
                })
              }
            />
          </div>
          {/* Aspect Ratio */}
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Post aspect ratio</div>
            <ControlledSelect
              ariaLabel="Post aspect ratio"
              options={aspectRatioOptions}
              value={aspectRatioString}
              onChange={(val) => {
                if (val === 'CUSTOM') val = '16:9'
                updateBreakpoint(
                  breakpoint,
                  'postAspectRatio',
                  val.split(':').map((val) => parseInt(val)),
                )
              }}
            />
          </div>
          {/* Aspect Ratio Width */}
          <TransitionWrapper
            show={aspectRatioString === 'CUSTOM'}
            scale
            height
            className={classNames(styles.setting, styles.setting_half)}
          >
            <div className={styles.label}>Width</div>
            <ControlledInput
              ariaLabel="Aspect Ratio Width"
              type="number"
              min={0}
              max={9999}
              value={aspectRatio[0]}
              onChange={(val) => {
                updateBreakpoint(breakpoint, 'postAspectRatio', [
                  val,
                  aspectRatio[1],
                ])
              }}
            />
          </TransitionWrapper>
          {/* Aspect Ratio Height */}
          <TransitionWrapper
            show={aspectRatioString === 'CUSTOM'}
            scale
            height
            className={classNames(styles.setting, styles.setting_half)}
          >
            <div className={styles.label}>Height</div>
            <ControlledInput
              ariaLabel="Aspect Ratio Height"
              type="number"
              min={0}
              max={9999}
              value={aspectRatio[1]}
              onChange={(val) => {
                updateBreakpoint(breakpoint, 'postAspectRatio', [
                  aspectRatio[0],
                  val,
                ])
              }}
            />
          </TransitionWrapper>
          {/* Border radius */}
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Rounded corners</div>
            <ControlledRange
              type="range"
              min="0"
              max="100"
              suffix={`${settings.borderRadius * 2}%`}
              value={settings.borderRadius * 2}
              onChange={(val) =>
                updateBreakpoint(breakpoint, 'borderRadius', val / 2)
              }
            />
          </div>
        </div>
      )}
    </TransitionWrapper>
  )
}

Breakpoint.propTypes = {
  breakpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: PropTypes.object,
  updateBreakpoint: PropTypes.func,
  removeBreakpoint: PropTypes.func,
  localFeedSettings: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  expanded: PropTypes.bool,
  setPreviewWidth: PropTypes.func,
}
