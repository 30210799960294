import { useEffect, useState, useRef, useMemo } from 'react'
import classnames from 'classnames'
import { copyText, isValidUrl } from 'Helpers/utils'
import { updateUser } from 'Fire/firebaseActions'
import {
  decipherSecret,
  addApiSecret,
  deleteApiSecret,
  addWebhookSecret,
  deleteWebhookSecret,
  setActiveWebhookSecret,
} from 'Helpers/services'
import { useSlice } from 'State'
import Layout from 'Components/Layout'
import PopupMenu from 'Components/PopupMenu'
import MenuItem from 'Components/MenuItem'
import SettingsNav from 'Components/SettingsNav'
import TransitionWrapper from 'Components/TransitionWrapper'
import ImageUploader from 'Components/ImageUploader'
import PageHeader from 'Components/PageHeader'
import Switch from 'Components/Switch'
import ControlledInput from 'Components/ControlledInput'
import Eyecon from 'Images/icons/eye-outline.svg'
import CopyIcon from 'Images/icons/copy-outline.svg'
import TrashIcon from 'Images/icons/trash.svg'
import ActivateIcon from 'Images/icons/refresh.svg'
import XIcon from 'Images/icons/x.svg'
import styles from './DeveloperSettings.module.scss'

function anonymizeSecret(secret, type) {
  if (!secret) return
  if (type === 'api') {
    const [prefix, id, _, checksum] = secret.split('_')
    return `${prefix}_xxxxxxxxxxxxxx_xxxxxxxxxxxxxx_xxxxxxx${checksum.substring(checksum.length - 3)}`
  } else {
    const [prefix, secretValue] = secret.split('_')
    return `${prefix}_xxxxxxxxxxxxxx_xxxxxxxxxxxxxx${secretValue.substring(secretValue.length - 3)}`
  }
}

export default function DeveloperSettings() {
  const appState = useSlice(
    'user',
    'plans',
    'addNotification',
    'removeNotification',
    'updateUser',
  )

  const [apiSecrets, setApiSecrets] = useState([])
  const [apiSecretsAreLoading, setApiSecretsAreLoading] = useState(
    Object.keys(appState.user?.apiSecrets || {}).length > 0,
  )
  const [addingApiSecret, setAddingApiSecret] = useState(false)

  const [webhookSecrets, setWebhookSecrets] = useState([])
  const [webhookSecretsAreLoading, setWebhookSecretsAreLoading] = useState(
    Object.keys(appState.user?.webhookSecrets || {}).length > 0,
  )
  const [addingWebhookSecret, setAddingWebhookSecret] = useState(false)
  const [webhookEndpointError, setWebhookEndpointError] = useState(null)

  function updateDeveloperSettings(updates) {
    updateUser({
      developerSettings: { ...appState.user.developerSettings, ...updates },
    })
  }

  async function addNewApiSecret() {
    try {
      if (apiSecrets.length > 1) {
        appState.addNotification({
          title: 'Max 2 secrets',
          duration: 9,
          text: (
            <>
              You cannot have more than 2 API secrets at a time. Please delete
              one before adding.
            </>
          ),
        })
        return
      }
      setAddingApiSecret(true)
      await addApiSecret()
    } catch (error) {
      setAddingApiSecret(false)
      appState.addNotification({
        duration: 9,
        text: error.message,
      })
    }
  }

  async function addNewWebhookSecret() {
    try {
      if (webhookSecrets.length > 1) {
        appState.addNotification({
          title: 'Max 2 secrets',
          duration: 9,
          text: (
            <>
              You cannot have more than 2 webhook secrets at a time. Please
              delete one before adding.
            </>
          ),
        })
        return
      }
      setAddingWebhookSecret(true)
      await addWebhookSecret()
    } catch (error) {
      setAddingWebhookSecret(false)
      appState.addNotification({
        duration: 9,
        text: error.message,
      })
    }
  }

  function updateSubscribedEvents(event, val) {
    let subs = new Set(
      appState.user.developerSettings?.webhookSubscriptions || [],
    )
    if (subs.has(event)) {
      subs.delete(event)
    } else {
      subs.add(event)
    }
    updateDeveloperSettings({ webhookSubscriptions: Array.from(subs) })
  }

  useEffect(() => {
    const controller = new AbortController()

    async function decipherApiSecrets() {
      try {
        const secrets = appState.user?.apiSecrets || {}

        const decipherers = Object.entries(secrets).map(
          ([id, { created, secret }]) => {
            return new Promise(async (resolve, reject) => {
              try {
                const deciphered = await decipherSecret(
                  secret,
                  controller.signal,
                )
                resolve({ id, secret: deciphered, created })
              } catch (error) {
                reject(error)
              }
            })
          },
        )

        const deciphered = await Promise.all(decipherers)
        const sorted = deciphered.sort((a, b) => {
          return a.created - b.created
        })

        setApiSecrets((prev) => {
          if (prev.length < sorted.length) {
            setAddingApiSecret(false)
          }
          return sorted
        })
        setApiSecretsAreLoading(false)
      } catch (error) {
        setApiSecretsAreLoading(false)
      }
    }

    decipherApiSecrets()

    return () => controller.abort()
  }, [appState.user.apiSecrets])

  useEffect(() => {
    const controller = new AbortController()
    async function deciperWebhookSecrets() {
      const secrets = appState.user?.webhookSecrets || {}

      const decipherers = Object.entries(secrets).map(
        ([id, { created, secret, active }]) => {
          return new Promise(async (resolve, reject) => {
            const deciphered = await decipherSecret(secret, controller.signal)
            resolve({ id, secret: deciphered, created, active })
          })
        },
      )

      const deciphered = await Promise.all(decipherers)
      const sorted = deciphered.sort((a, b) => {
        return a.created - b.created
      })
      setWebhookSecrets((prev) => {
        if (prev.length < sorted.length) {
          setAddingWebhookSecret(false)
        }
        return sorted
      })
      setWebhookSecretsAreLoading(false)
    }
    deciperWebhookSecrets()

    return () => controller.abort()
  }, [appState.user.webhookSecrets])

  const apiSecretEls = useMemo(
    () =>
      apiSecretsAreLoading
        ? Object.keys(appState.user?.apiSecrets || {}).map(() => (
            <div className={styles.secrets__placeholder}></div>
          ))
        : apiSecrets
            .filter((secret) => !!secret)
            .map(({ id, secret, created }) => (
              <Secret
                key={id}
                id={id}
                secret={secret}
                created={created}
                type="api"
              />
            )),
    [apiSecretsAreLoading, apiSecrets],
  )

  const webhookSecretEls = webhookSecretsAreLoading
    ? Object.keys(appState.user?.webhookSecrets || {}).map(() => (
        <div className={styles.secrets__placeholder}></div>
      ))
    : webhookSecrets.map(({ id, secret, created, active }) => (
        <Secret
          key={id}
          id={id}
          secret={secret}
          created={created}
          type="webhook"
          active={active}
        />
      ))

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Developer Settings | Behold">
      <main className={styles.container}>
        <PageHeader title="Developer settings" bottomMargin={false} />

        <SettingsNav />

        <TransitionWrapper>
          <div className={styles.inner}>
            {/* API Secrets */}
            <section className={styles.secrets}>
              <header className={styles.secrets__header}>
                <h1 className={styles.section_label}>API secrets</h1>
                <button
                  disabled={addingApiSecret}
                  className={styles.add_secret_link}
                  onClick={addNewApiSecret}
                >
                  <span>+ Add API secret</span>
                </button>
              </header>
              <div className={styles.secrets__secrets}>
                {apiSecretEls}
                {addingApiSecret && (
                  <div className={styles.secrets__placeholder}></div>
                )}
              </div>
              {!apiSecretsAreLoading &&
                !addingApiSecret &&
                apiSecretEls.length < 1 && (
                  <div className={styles.secrets__empty}>
                    No API secrets added
                  </div>
                )}
            </section>

            {/* Webhook Secrets */}
            <section className={styles.secrets}>
              <header className={styles.secrets__header}>
                <h1 className={styles.section_label}>Webhook secrets</h1>
                <button
                  disabled={addingWebhookSecret}
                  className={styles.add_secret_link}
                  onClick={addNewWebhookSecret}
                >
                  <span>+ Add webhook secret</span>
                </button>
              </header>
              <div className={styles.secrets__secrets}>
                {webhookSecretEls}
                {addingWebhookSecret && (
                  <div className={styles.secrets__placeholder}></div>
                )}
              </div>
              {!webhookSecretsAreLoading &&
                !addingWebhookSecret &&
                webhookSecretEls.length < 1 && (
                  <div className={styles.secrets__empty}>
                    No webhook secrets added
                  </div>
                )}
            </section>

            {/* Webhook endpoint URL */}
            <section className={styles.webhook_endpoint}>
              <header className={styles.webhook_endpoint__header}>
                <h1 className={styles.section_label}>Webhooks endpoint URL</h1>
              </header>
              {webhookEndpointError && (
                <TransitionWrapper height>
                  <div className={styles.error}>
                    {webhookEndpointError}
                    <button
                      className={styles.error__close}
                      onClick={() => setWebhookEndpointError(null)}
                    >
                      <XIcon />
                    </button>
                  </div>
                </TransitionWrapper>
              )}
              <ControlledInput
                className={styles.webhook_input}
                value={appState.user.developerSettings?.webhookEndpoint}
                type="url"
                updateOn="button"
                noBlank={false}
                onChange={(val) => {
                  setWebhookEndpointError(null)

                  if (isValidUrl(val, true) || val.trim() === '') {
                    updateDeveloperSettings({ webhookEndpoint: val })
                  } else {
                    setWebhookEndpointError('Not a valid HTTPS URL')
                  }
                }}
              ></ControlledInput>
            </section>

            {/* Webhook subscribed events */}
            <section className={styles.webhook_endpoint}>
              <header className={styles.webhook_endpoint__header}>
                <h1 className={styles.section_label}>
                  Subscribed webhook events
                </h1>
              </header>
              <div className={classnames(styles.webhook_subs)}>
                <div>
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('source.created', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'source.created',
                    )}
                    after="source.created"
                  />
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('source.updated', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'source.updated',
                    )}
                    after="source.updated"
                  />
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('source.deleted', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'source.deleted',
                    )}
                    after="source.deleted"
                  />
                </div>
                <div>
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('feed.created', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'feed.created',
                    )}
                    after="feed.created"
                  />
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('feed.updated', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'feed.updated',
                    )}
                    after="feed.updated"
                  />
                  <Switch
                    onChange={(val) =>
                      updateSubscribedEvents('feed.deleted', val)
                    }
                    checked={appState.user?.developerSettings?.webhookSubscriptions?.includes(
                      'feed.deleted',
                    )}
                    after="feed.deleted"
                  />
                </div>
              </div>
            </section>

            {/* App Icon */}
            <section className={styles.interstitial_options}>
              <header className={styles.interstitial__header}>
                <h1 className={styles.section_label}>Co-Branding</h1>
              </header>

              <div className={styles.setting}>
                <div className={styles.setting__label}>App icon</div>
                <div className={styles.setting__description}>
                  Ideal dimensions: 90 x 90 or .svg
                </div>
                <ImageUploader />
              </div>

              <div className={styles.setting}>
                <div className={styles.setting__label}>App name</div>
                <ControlledInput
                  className={styles.webhook_input}
                  value={appState.user.developerSettings?.appName}
                  type="text"
                  updateOn="button"
                  noBlank={false}
                  onChange={(val) => {
                    updateDeveloperSettings({ appName: val })
                  }}
                ></ControlledInput>
              </div>

              <div className={styles.setting}>
                <div className={styles.inline}>
                  <Switch
                    ariaLabel="Show auth interstitial"
                    checked={
                      appState.user?.developerSettings?.showAuthInterstitial
                    }
                    onChange={(val) => {
                      updateDeveloperSettings({ showAuthInterstitial: val })
                    }}
                    after={<>Show auth interstitial</>}
                  />
                </div>
              </div>

              {/* <div className={styles.setting}>
                <div className={styles.setting__label}>Color theme</div>
                <div className={styles.inline}>
                  <ControlledSelect
                    value={appState.user.authColorTheme}
                    options={[
                      { label: 'Auto', value: 'auto' },
                      { label: 'Light', value: 'light' },
                      { label: 'Dark', value: 'dark' },
                    ]}
                    onChange={(val) => {
                      updateDeveloperSettings({
                        authColorTheme: val,
                      })
                    }}
                  ></ControlledSelect>
                </div>
              </div> */}
            </section>
          </div>
        </TransitionWrapper>
      </main>
    </Layout>
  )
}

function Secret({ id, secret, created, type, active }) {
  const date = created.toDate().toLocaleDateString()
  const [secretIsVisible, setSecretIsVisible] = useState(false)
  const [forceCloseMenu, setForceCloseMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [activateIsLoading, setActivateIsLoading] = useState(false)

  const copyButtonRef = useRef()

  function copySecret(evt) {
    const bbox = copyButtonRef.current.getBoundingClientRect()
    copyText(evt, secret, {
      x: bbox.x + bbox.width / 2,
      y: bbox.y,
    })
  }

  return (
    <div
      className={classnames(styles.secret, { [styles.is_loading]: isLoading })}
    >
      {isLoading && (
        <div className={styles.secret__loader}>
          <l-zoomies
            size={80}
            color="var(--color-text)"
            speed={1.2}
            stroke={3}
          ></l-zoomies>
        </div>
      )}
      <div className={styles.secret__date}>{date}</div>
      <div className={styles.secret__secret}>
        {secretIsVisible ? (
          <input value={secret} disabled />
        ) : (
          <div className={styles.secret__secret_inner}>
            {anonymizeSecret(secret, type)}
          </div>
        )}
        {active && <div className={styles.secret__active}>Active</div>}
      </div>
      <div className={styles.secret__controls}>
        <button
          disabled={isLoading}
          className={classnames(styles.secret__control, {
            [styles.secret_is_visible]: secretIsVisible,
          })}
          aria-section_label={secretIsVisible ? 'Hide secret' : 'Show secret'}
          onClick={() => setSecretIsVisible(!secretIsVisible)}
        >
          <Eyecon />
        </button>
        <button
          disabled={isLoading}
          ref={copyButtonRef}
          className={styles.secret__control}
          aria-section_label={'Copy secret'}
          onClick={copySecret}
        >
          <CopyIcon />
        </button>

        <PopupMenu
          forceClose={forceCloseMenu}
          onClose={() => setForceCloseMenu(false)}
          offset={5}
          size="small"
          width="150"
        >
          {type === 'webhook' && (
            <MenuItem
              disabled={isLoading || active}
              loading={activateIsLoading}
              confirm
              confirmText={'Confirm'}
              onClick={async () => {
                setActivateIsLoading(true)
                await setActiveWebhookSecret(id)
                setActivateIsLoading(false)
              }}
            >
              <ActivateIcon /> Set active
            </MenuItem>
          )}

          <MenuItem
            disabled={isLoading}
            confirm
            confirmText={'Confirm Delete'}
            onClick={() => {
              setIsLoading(true)
              if (type === 'api') {
                deleteApiSecret(id)
              } else {
                deleteWebhookSecret(id)
              }
            }}
            color="red"
          >
            <TrashIcon /> Delete secret
          </MenuItem>
        </PopupMenu>
      </div>
    </div>
  )
}
