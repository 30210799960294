import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Panel, usePanels } from 'Components/Panels'
import LeftArrow from 'Images/icons/caret-left.svg'
import CheckIcon from 'Images/icons/check.svg'
import XIcon from 'Images/icons/x.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanel({
  panelId,
  icon,
  title,
  width = 300,
  hasChanges,
  saveChanges,
  resetChanges,
  children,
}) {
  const { closePanel, panelState } = usePanels()
  const isOpen = panelState.activePanels.has(panelId)

  function save() {
    if (hasChanges) {
      saveChanges()
    }
  }

  function reset() {
    if (resetChanges) resetChanges()
  }

  useEffect(() => {
    if (!isOpen) reset()
  }, [isOpen])

  return (
    <Panel id={panelId} width={width}>
      <div
        className={classNames(styles.header, {
          [styles.has_changes]: hasChanges,
          [styles.has_shadow]: panelState.isScrolled,
        })}
      >
        <button
          aria-hidden={hasChanges}
          tabIndex={hasChanges ? -1 : 0}
          className={styles.back_button}
          aria-label="go back"
          onClick={() => closePanel(panelId)}
        >
          <div className={styles.back_button__back}>
            <LeftArrow className={styles.back_button__arrow} />
          </div>
          Back
        </button>
        <div className={styles.actions}>
          <button
            aria-hidden={!hasChanges}
            tabIndex={!hasChanges ? -1 : 0}
            onClick={save}
            className={styles.action_button}
          >
            <div className={styles.action_button__inner}>
              <CheckIcon /> Save
            </div>
          </button>
          <button
            aria-hidden={!hasChanges}
            tabIndex={!hasChanges ? -1 : 0}
            onClick={reset}
            className={styles.action_button}
          >
            <div className={styles.action_button__inner}>
              <XIcon /> Cancel
            </div>
          </button>
        </div>
      </div>
      <div className={styles.title}>
        <div className={styles.title__icon}>{icon}</div>
        {title}
      </div>
      <div className={styles.inner}>{children}</div>
    </Panel>
  )
}

FeedPanel.propTypes = {
  panelId: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  width: PropTypes.number,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}
