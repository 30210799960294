import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import resizeImage from 'Helpers/resizeImage'
import { uploadImageToBucket, deleteImageInBucket } from 'Helpers/services'
import blobToDataUrl from 'Helpers/blobToDataUrl'
import { useSlice } from 'State'
import { forceLayout } from 'Helpers/utils'
import announceToScreenReader from 'Helpers/announceToScreenReader'
import useHotkey from 'Hooks/useHotkey'
import useTimers from 'Hooks/useTimers'
import LazyImage from 'Components/LazyImage'
import SmartButton from './SmartButton'
import UploadIcon from 'Images/icons/arrow-up.svg'
import SuccessIcon from 'Images/icons/check.svg'
import TrashIcon from 'Images/icons/trash.svg'
import ReplaceIcon from 'Images/icons/refresh.svg'
import XIcon from 'Images/icons/x.svg'
import styles from './ImageUploader.module.scss'
import TransitionWrapper from './TransitionWrapper'

const allowedFileTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg+xml', 'webp']

export default function ImageUploader() {
  const { st, raf, cancelAllTimers } = useTimers()
  const { user } = useSlice('user')
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [file, setFile] = useState(null)
  const [previewSource, setPreviewSource] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isReplacing, setIsReplacing] = useState(false)
  const [error, setError] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [successIsVisible, setSuccessIsVisible] = useState(false)
  const fileRef = useRef()
  const replaceButtonRef = useRef()
  const fileInputRef = useRef()
  const uploadButtonRef = useRef()

  useHotkey(
    'Escape',
    () => {
      setIsReplacing(false)
    },
    isReplacing,
  )

  function success() {
    cancelAllTimers()
    announceToScreenReader('Successfully uploaded icon')
    setShowSuccess(true)
    forceLayout()

    raf(() => {
      setSuccessIsVisible(true)
      st(() => {
        setSuccessIsVisible(false)
        setTimeout(() => {
          setShowSuccess(false)
        }, 400)
      }, 3000)
    })
  }

  function handleChange(evt) {
    const el = evt.target
    const file = el.files.length > 0 ? el.files[0] : null

    if (
      file &&
      allowedFileTypes.includes(`${file.type.replace('image/', '')}`)
    ) {
      setFile(file)
    }
  }

  function handleDragOver(evt) {
    evt.preventDefault()
    const fileType = Array.from(evt.dataTransfer?.items)?.[0]?.type
    if (allowedFileTypes.includes(`${fileType.replace('image/', '')}`)) {
      setIsDraggingOver(true)
    }
  }

  function handleDragLeave(evt) {
    evt.preventDefault()
    setIsDraggingOver(false)
  }

  function handleDrop(evt) {
    evt.preventDefault()
    if (isLoading) return false
    setIsDraggingOver(false)
    const newFile = Array.from(evt.dataTransfer?.items)?.[0]?.getAsFile()
    if (
      newFile &&
      allowedFileTypes.includes(`${newFile.type.replace('image/', '')}`)
    ) {
      setFile(newFile)
      setError(null)
    } else {
      setError('Supported file types: .png, .jpg, .webp, .svg, .gif')
    }
  }

  function reset() {
    setIsDraggingOver(false)
    setFile(null)
    setIsLoading(false)
    setIsReplacing(false)
  }

  async function upload() {
    const oldPath = user.developerSettings?.icon
      ? user.developerSettings.icon.split('behold.pictures/').pop()
      : null
    try {
      setShowSuccess(false)
      setSuccessIsVisible(true)
      setError(null)
      setIsLoading(true)
      let res = null
      if (oldPath) {
        await deleteImage(oldPath, false, false)
      }
      if (previewSource?.includes('image/svg')) {
        res = await uploadImageToBucket({
          image: file,
          path: `icon-${Date.now()}`,
          bucket: process.env.USER_IMAGES_BUCKET,
          dbField: 'developerSettings.icon',
        })
      } else {
        const blob = await resizeImage(previewSource, 360, 140)
        res = await uploadImageToBucket({
          image: blob,
          path: `icon-${Date.now()}`,
          bucket: process.env.USER_IMAGES_BUCKET,
          maxWidth: 100,
          maxHeight: 100,
          quality: 100,
          dbField: 'developerSettings.icon',
        })
      }
      if (res.status === 'error') {
        console.log(res)
        throw new Error(res.error)
      }
      success()
    } catch (error) {
      reset()
      setError(
        error?.message ||
          'Something went wrong. Contact us for help: support@behold.so',
      )
    }
  }

  async function deleteImage(path = null, updateDb = true, updateUi = true) {
    if (!user.developerSettings?.icon?.length) return
    try {
      if (updateUi) {
        setShowSuccess(false)
        setSuccessIsVisible(false)
        setError(null)
        setIsLoading(true)
      }
      const imagePath =
        path || user.developerSettings.icon.split('behold.pictures/').pop()

      const res = await deleteImageInBucket({
        imagePath,
        bucket: process.env.USER_IMAGES_BUCKET,
        dbField: updateDb ? 'developerSettings.icon' : null,
      })

      if (res.status === 'error') {
        throw new Error(res.error || res.message)
      }

      announceToScreenReader('Successfully removed icon')
    } catch (error) {
      reset()
      setError(
        error?.message ||
          'Something went wrong. Contact us for help: support@behold.so',
      )
    }
  }

  useEffect(() => {
    if (file) {
      setError(null)
      setIsLoading(true)

      if (file.type === 'image/svg+xml' && file.size > 1000000) {
        reset()
        setIsLoading(false)
        setError('Max SVG size 1MB')
        return false
      }
      blobToDataUrl(file).then((dataUrl) => {
        setPreviewSource(dataUrl)
        setIsLoading(false)
      })
      fileRef.current = file
    } else {
      if (fileRef.current) {
        fileRef.current = null
        setPreviewSource(null)
      }
    }
  }, [file])

  useEffect(() => {
    reset()
  }, [user.developerSettings?.icon])

  useEffect(() => {
    if (isLoading) {
      announceToScreenReader('Loading')
    }
  }, [isLoading])

  useEffect(() => {
    if (error) {
      announceToScreenReader(`Error, ${error}`)
    }
  }, [error])

  const loader = (
    <l-squircle
      size={20}
      color="var(--color-text)"
      speed={0.6}
      stroke={2.5}
    ></l-squircle>
  )

  const uploader = (
    <div
      className={classNames(styles.uploader, {
        [styles.is_dragged_over]: isDraggingOver,
      })}
    >
      <div>
        <div class={styles.uploader__button}>
          Choose file
          <input
            ref={fileInputRef}
            onChange={handleChange}
            type="file"
            accept=".png,.jpg,.jpeg,.webp,.svg,.gif"
          />
          <div className={styles.button__focus}></div>
        </div>
        or drag & drop
      </div>
      <div className={styles.accepts}>.png, .jpg, .webp, .svg, .gif</div>
      {isReplacing && (
        <button
          onClick={() => setIsReplacing(false)}
          className={styles.uploader__cancel}
          aria-label="Cancel Replace"
        >
          <XIcon /> Cancel
        </button>
      )}
    </div>
  )

  const preview = (
    <div
      className={classNames(styles.preview, { [styles.is_loading]: isLoading })}
    >
      <div className={styles.preview__image_container}>
        <LazyImage
          className={styles.preview__image}
          src={previewSource}
          alt="Uploaded image preview"
        />
        {isLoading && <div className={styles.loader}>{loader}</div>}
      </div>
      <div className={styles.preview__buttons}>
        <button
          ref={uploadButtonRef}
          disabled={isLoading}
          className={styles.preview__button}
          onClick={upload}
        >
          <UploadIcon /> Upload
        </button>
        <button
          disabled={isLoading}
          className={styles.preview__button}
          onClick={reset}
        >
          <XIcon /> Cancel
        </button>
      </div>
    </div>
  )

  const currentIcon = (
    <div className={styles.current}>
      <div className={styles.current__image_container}>
        <LazyImage
          className={styles.current__image}
          src={user.developerSettings?.icon}
        />
        {showSuccess && (
          <TransitionWrapper
            scale
            className={classNames(styles.success, {
              [styles.is_visible]: successIsVisible,
            })}
          >
            <SuccessIcon />
          </TransitionWrapper>
        )}
        {isLoading && <div className={styles.loader}>{loader}</div>}
      </div>
      <div className={styles.current__actions}>
        <SmartButton
          disabled={isLoading}
          onClick={deleteImage}
          size="medium"
          color="light_terracotta"
          confirm
          confirmColor="var(--color-terracotta)"
        >
          <TrashIcon />
          Remove
        </SmartButton>
        <SmartButton
          ref={replaceButtonRef}
          disabled={isLoading}
          size="medium"
          color="grey"
          onClick={() => setIsReplacing(true)}
        >
          <ReplaceIcon />
          Replace
        </SmartButton>
      </div>
    </div>
  )

  let contents = uploader

  if (user.developerSettings?.icon && !isReplacing) {
    contents = currentIcon
  }

  if (file) {
    contents = preview
  }

  return (
    <div
      className={styles.container}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {error && (
        <TransitionWrapper height>
          <div className={styles.error}>
            {error}
            <button
              className={styles.error__close}
              onClick={() => setError(null)}
            >
              <XIcon />
            </button>
          </div>
        </TransitionWrapper>
      )}
      {contents}
    </div>
  )
}
