import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useStorage from 'Hooks/useStorage'
import Metas from 'Components/Metas'

export default function Layout({ children, metaTitle }) {
  const [{ IS_FIRST_LOGIN }, setStorage] = useStorage()
  const { addNotification } = useSlice('addNotification')
  const { st } = useTimers()

  useEffect(() => {
    if (IS_FIRST_LOGIN) {
      setStorage('IS_FIRST_LOGIN', false)

      st(() => {
        addNotification(
          {
            duration: 7,
            title: 'Welcome!',
            text: 'Your email has been successfully verified',
          },
          'VERIFIED',
        )
      }, 750)
    }
  }, [IS_FIRST_LOGIN, addNotification, setStorage, st])
  return (
    <>
      <Metas title={metaTitle} />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  metaTitle: PropTypes.string,
}
