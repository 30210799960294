import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import VerifyEmail from 'Components/VerifyEmail'
import ResetPassword from 'Components/ResetPassword'
import RecoverEmail from 'Components/RecoverEmail'
import VerifyAndChangeEmail from 'Components/VerifyAndChangeEmail'

export default function EmailActionHandler() {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const action = searchParams.get('mode')
  const code = searchParams.get('oobCode')

  let actionComponent = null

  switch (action) {
    case 'verifyEmail':
      actionComponent = <VerifyEmail actionCode={code} />
      break
    case 'resetPassword':
      actionComponent = <ResetPassword actionCode={code} />
      break
    case 'recoverEmail':
      actionComponent = <RecoverEmail actionCode={code} />
      break
    case 'verifyAndChangeEmail':
      actionComponent = <VerifyAndChangeEmail actionCode={code} />
      break
  }

  /*
   *   Return
   */
  return actionComponent
}
