import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import FeedIcon from 'Images/icons/feed-outline.svg'
import CodeIcon from 'Images/icons/json.svg'
import KlaviyoIcon from 'Images/klaviyo-outline.svg'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedType() {
  const { st } = useTimers()
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)

  function animove(url) {
    setIsLoaded(false)
    st(() => {
      navigate(url)
    }, 200)
  }

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        What kind of output do you want?
      </h1>
      <div
        className={classNames(styles.options, { [styles.loaded]: isLoaded })}
      >
        <div className={styles.options_column}>
          <button
            id="skip-target"
            className={styles.option}
            onClick={() => animove('widget/name')}
          >
            <div className={styles.option__inner}>
              <FeedIcon />
              Widget
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            Embedded JavaScript widget.
            <br /> No coding required.
          </div>
        </div>
        <div className={styles.options_column}>
          <button className={styles.option} onClick={() => animove('api/name')}>
            <div className={styles.option__inner}>
              <CodeIcon className={styles.code_icon} />
              JSON
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            For developers.
            <br />
            I&apos;ll build it myself, thanks.
          </div>
        </div>
        <div className={styles.options_column}>
          <button
            className={styles.option}
            onClick={() => animove('klaviyo/name')}
          >
            <div className={styles.option__inner}>
              <KlaviyoIcon />
              Klaviyo
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            Add Instagram to your
            <br /> Klaviyo email campaigns
          </div>
        </div>
      </div>
    </>
  )
}
