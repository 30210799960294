import { useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import useTimers from 'Hooks/useTimers'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import styles from './ColorPicker.module.scss'

export default function ColorPicker({
  value,
  onChange,
  onFocus,
  onBlur,
  size,
}) {
  const containerRef = useRef()
  const mayUpdate = useRef(true)
  const { st, cancelTimer } = useTimers()
  const finalUpdateTimer = useRef(null)

  function handleFocus() {
    if (onFocus) onFocus()
  }

  function handleBlur(evt) {
    if (!containerRef.current.contains(evt.relatedTarget) && onBlur) {
      onBlur()
    }
  }

  function update(val) {
    if (mayUpdate.current) {
      cancelTimer(finalUpdateTimer.current)
      mayUpdate.current = false
      onChange(val)
      st(() => {
        mayUpdate.current = true
      }, 100)
    } else {
      cancelTimer(finalUpdateTimer.current)
      finalUpdateTimer.current = st(() => {
        onChange(val)
      }, 100)
    }
  }

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, styles[`size_${size}`])}
      onBlur={handleBlur}
    >
      <div className={styles.picker}>
        <HexColorPicker color={value} onChange={update} />
      </div>
      <HexColorInput
        className={styles.input}
        prefixed
        color={value}
        onChange={onChange}
        onFocus={handleFocus}
      />
      <div className={styles.preview} style={{ background: `${value}` }}></div>
    </div>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}
