import { forwardRef } from 'preact/compat'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './ControlledRange.module.scss'

const ControlledRange = forwardRef(
  (
    {
      onChange,
      className = '',
      min = 0,
      max = 100,
      value = '',
      ariaLabel = null,
      step = 1,
      suffix,
      ...rest
    },
    ref,
  ) => {
    /*
     *   Change Handler
     */
    function handleChange(evt) {
      onChange(evt.target.value)
    }

    const handleStyles = {
      left: `${(value / max) * 100}%`,
    }

    const progressStyles = {
      width: `min(calc(${(value / max) * 100}% + 5px), 100%)`,
    }

    return (
      <div className={styles.container}>
        <div className={styles.range_container}>
          <div className={styles.handle_container}>
            <div className={styles.handle} style={handleStyles}></div>
          </div>
          <div className={styles.progress} style={progressStyles}></div>
          <input
            type="range"
            ref={ref}
            className={classnames(styles.input, className)}
            onChange={handleChange}
            value={value}
            aria-label={ariaLabel}
            min={min}
            max={max}
            step={step}
            {...rest}
          />
        </div>
        {suffix && <div className={styles.suffix}>{suffix}</div>}
      </div>
    )
  },
)
export default ControlledRange

ControlledRange.defaultProps = {
  className: '',
  value: '',
  min: 0,
  max: 100,
  step: 1,
  ariaLabel: null,
}

ControlledRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ariaLabel: PropTypes.string,
  step: PropTypes.number,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
