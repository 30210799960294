import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useSlice } from 'State'
import FeedPanel from 'Components/feed/FeedPanel'
import ControlledInput from 'Components/ControlledInput'
import ToggleButton from 'Components/ToggleButton'
import HelpTooltip from 'Components/HelpTooltip'
import TagInput from 'Components/TagInput'
import PanelIcon from 'Images/icons/settings.svg'
import ReelsIcon from 'Images/icons/reels.svg'
import InstagramIcon from 'Images/icons/instagram.svg'
import GalleryIcon from 'Images/icons/gallery-outline.svg'
import VideoIcon from 'Images/icons/play-outline.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

function normalizeWhitelistTags(str) {
  return str.replace(/(((http|https):\/\/)|(\/\/))/, '').replace(/\/(.*)/, '')
}

export default function FeedPanelApiSettings({
  panelId,
  localFeedSettings,
  updateLocalFeedSetting,
  plan,
  hasChanges,
  saveChanges,
  resetChanges,
  isLoading,
}) {
  const { addNotification } = useSlice('addNotification')

  function updateWhitelist(whitelist, newTag) {
    let additionalTags = []
    const numDots = newTag?.match(/\./g)?.length || 0

    if (
      newTag &&
      numDots < 2 &&
      !newTag.includes('www') &&
      !newTag.includes('localhost') &&
      !newTag.includes('127.0.0.1')
    ) {
      additionalTags = [`www.${newTag}`]
    }
    const combined = Array.from(new Set([...whitelist, ...additionalTags]))
    updateLocalFeedSetting('domainWhitelist', combined)
  }

  function updatePostTypeFilter(postType, val) {
    const filterSet = new Set(localFeedSettings.filter.allowedTypes)
    if (val) {
      filterSet.add(postType)
      updateLocalFeedSetting('filter', { allowedTypes: Array.from(filterSet) })
    } else {
      if (filterSet.size > 1) {
        filterSet.delete(postType)
        updateLocalFeedSetting('filter', {
          allowedTypes: Array.from(filterSet),
        })
      } else {
        addNotification(
          {
            text: 'You must allow at least one type of post',
            duration: 20,
          },
          'MIN_ONE_POST_TYPE',
        )
      }
    }
  }

  function updateCaptionIncludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionIncludeText: Array.from(new Set(text)),
    })
  }

  function updateCaptionExcludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionExcludeText: Array.from(new Set(text)),
    })
  }

  let maxPostsPerFeedText = (
    <p>
      On the <b>{plan.title}</b> plan, you can fetch up to{' '}
      <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.{' '}
      <b>
        <Link to="/choose-plan">Upgrade now</Link>
      </b>{' '}
      to increase your limit.
    </p>
  )

  if (plan?.topTier) {
    maxPostsPerFeedText = (
      <p>
        On the <b>{plan.title}</b> plan, you can fetch up to{' '}
        <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.
      </p>
    )
  }

  function maxPostsWarning() {
    addNotification(
      {
        title: 'Post limit reached',
        text: maxPostsPerFeedText,
        duration: 20,
      },
      'MAX_POSTS_EXCEEDED',
    )
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={460}
      icon={<PanelIcon />}
      title="Settings"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={resetChanges}
    >
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={300} triggerText="Number of posts">
              <div className={styles.tooltip}>
                <h1>Number of posts</h1>
                {maxPostsPerFeedText}
              </div>
            </HelpTooltip>
          </div>

          <ControlledInput
            type="number"
            min={1}
            max={plan.maxImagesPerFeed}
            value={localFeedSettings.maxPosts}
            onChange={(val) => updateLocalFeedSetting('maxPosts', val)}
            onMaxExceeded={maxPostsWarning}
            disabled={isLoading}
          />
        </div>

        <div className={styles.setting}>
          <div className={styles.label}>Allowed post types</div>
          <div className={styles.toggle_buttons}>
            <ToggleButton
              ariaLabel="Allowed post types: Images"
              popupLabel={`Images: ${
                localFeedSettings.filter.allowedTypes.includes('IMAGE')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('IMAGE')}
              onChange={(val) => updatePostTypeFilter('IMAGE', val)}
              iconOnly
              disabled={isLoading}
            >
              <InstagramIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Videos"
              popupLabel={`Videos: ${
                localFeedSettings.filter.allowedTypes.includes('VIDEO')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('VIDEO')}
              onChange={(val) => updatePostTypeFilter('VIDEO', val)}
              iconOnly
              disabled={isLoading}
            >
              <VideoIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Reels"
              popupLabel={`Reels: ${
                localFeedSettings.filter.allowedTypes.includes('REEL')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('REEL')}
              onChange={(val) => updatePostTypeFilter('REEL', val)}
              iconOnly
              disabled={isLoading}
            >
              <ReelsIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Albums"
              popupLabel={`Albums: ${
                localFeedSettings.filter.allowedTypes.includes('CAROUSEL_ALBUM')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes(
                'CAROUSEL_ALBUM',
              )}
              onChange={(val) => updatePostTypeFilter('CAROUSEL_ALBUM', val)}
              iconOnly
              disabled={isLoading}
            >
              <GalleryIcon />
            </ToggleButton>
          </div>
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption must include">
              <div className={styles.tooltip}>
                <h1>Caption must include</h1>
                <p>
                  Only posts that contain at least one of the text snippets you
                  add here will be included in your feed. This is case-sensitive
                  and can include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionIncludeText}
            onUpdate={updateCaptionIncludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption may not include">
              <div className={styles.tooltip}>
                <h1>Caption may not include</h1>
                <p>
                  Posts that contain any of the text you add here will be
                  excluded from your feed. This is case-sensitive and can
                  include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionExcludeText}
            onUpdate={updateCaptionExcludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            Domain{' '}
            <HelpTooltip width={350} triggerText="Whitelist">
              <div className={styles.tooltip}>
                <h1>Domain whitelist</h1>
                <p>
                  This feed will only function on domains listed here, or on any
                  domain if left blank.
                </p>
                <p>
                  Subdomains are independent and wildcards are not supported. So{' '}
                  <code>example.com</code> and &nbsp;
                  <code>www.example.com</code> must be entered separately.
                </p>
                <p>
                  <code>localhost</code> is always whitelisted.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.domainWhitelist}
            onUpdate={updateWhitelist}
            normalizer={normalizeWhitelistTags}
          />
        </div>
      </div>

      <div
        className={classNames(styles.description, styles.description_narrow)}
      >
        {maxPostsPerFeedText}
        <p>
          More information about using API feeds can be found in{' '}
          <a target="_blank" href="https://behold.so/docs/api" rel="noreferrer">
            our docs.
          </a>
        </p>
      </div>
    </FeedPanel>
  )
}

FeedPanelApiSettings.propTypes = {
  panelId: PropTypes.string,
  localFeedSettings: PropTypes.object,
  updateLocalFeedSetting: PropTypes.func,
  plan: PropTypes.object,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
}
