import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import throttle from 'lodash.throttle'
import useTimers from 'Hooks/useTimers'

export default function useDragScroll(
  el,
  startCallback,
  dragCallback,
  endCallBack,
  delay = 100,
) {
  const [didDrag, setDidDrag] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const { raf, st } = useTimers()
  const startPos = useRef(0)
  const isReady = useRef(true)

  const throttledCallback = useMemo(
    () => throttle(dragCallback, delay),
    [dragCallback, delay],
  )

  const start = useCallback(
    (x) => {
      setDidDrag(false)
      startPos.current = x
      setIsDragging(true)
      if (startCallback) startCallback(x, el)
    },
    [el, startCallback],
  )

  const handleDrag = useCallback(
    (evt) => {
      if (!isReady.current) return
      let x = evt.clientX
      if (evt.touches) {
        x = evt.touches[0].clientX
      }

      if (!isDragging) return
      if (Math.abs(x - startPos.current) > 5) setDidDrag(true)
      raf(() => throttledCallback(x - startPos.current))
    },
    [raf, throttledCallback, isDragging],
  )

  const handleMouseDown = useCallback(
    (evt) => {
      if (!isReady.current) return
      isReady.current = false
      start(evt.clientX)
      st(() => (isReady.current = true), 100)
    },
    [start, st],
  )

  const handleTouchStart = useCallback(
    (evt) => {
      if (!isReady.current) return
      isReady.current = false
      start(evt.touches[0].clientX)
      st(() => (isReady.current = true), 120)
    },
    [st, start],
  )

  const handleMouseUp = useCallback(
    (evt) => {
      setIsDragging(false)
      if (endCallBack) endCallBack(evt.clientX, el)
    },
    [endCallBack, el],
  )

  useEffect(() => {
    el?.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousemove', handleDrag)
    el?.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleDrag)

    return () => {
      el?.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleDrag)
      el?.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleDrag)
    }
  }, [el, handleMouseDown, handleTouchStart, handleMouseUp, handleDrag])

  return didDrag
}
