import { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './LazyImage.module.scss'

/*
 *   Lazy image
 */
export default function LazyImage({
  src,
  backgroundColor,
  onLoad,
  className = '',
  width,
  height,
  transparentAfterLoad = false,
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [inView, setInView] = useState(false)
  const containerRef = useRef(null)
  const timeoutRef = useRef(null)
  const observer = useRef(
    new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        setInView(true)
        observer.disconnect()
      }
    })
  )

  useLayoutEffect(() => {
    observer.current.observe(containerRef.current)

    return () => {
      observer.current.disconnect()
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <div
      ref={containerRef}
      style={{
        backgroundColor:
          isLoaded && transparentAfterLoad ? null : backgroundColor,
      }}
      className={classnames(styles.container, className, {
        [styles.loaded]: isLoaded,
      })}
    >
      {inView && (
        <img
          width={width}
          height={height}
          src={src}
          onLoad={() => {
            timeoutRef.current = setTimeout(() => {
              setIsLoaded(true)
              if (onLoad) onLoad()
            }, 50)
          }}
          style={{
            opacity: isLoaded ? 1 : 0,
            transition: 'all .6s var(--ease)',
          }}
        />
      )}
    </div>
  )
}

LazyImage.propTypes = {
  src: PropTypes.string,
  backgroundColor: PropTypes.string,
  onLoad: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  transparentAfterLoad: PropTypes.bool,
}
