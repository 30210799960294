import { Helmet } from 'react-helmet'
import { useScript } from 'Hooks/useScript'
import PropTypes from 'prop-types'

const isProd = process.env.ENVIRONMENT === 'PRODUCTION'

export default function Metas({ title, includeScripts = true }) {
  useScript({
    id: 'hotjar',
    active: includeScripts && isProd,
    contents: `
    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: 3006265, hjsv: 6 }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    `,
  })
  useScript({
    id: 'tidio',
    src: '//code.tidio.co/vgcgzjrwnjz1aq2ix9qzwaxhoajqi0pc.js',
    attributes: { async: true },
    active: includeScripts,
  })
  useScript({
    id: 'hide_tidio',
    active: includeScripts,
    contents: `
    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady)
      window.tidioChatApi.on('setStatus', onTidioStatusUpdate)
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      document.addEventListener('tidioChat-setStatus', onTidioStatusUpdate)
    }
  
    function onTidioChatApiReady(evt) {
      window.tidioChatApi.hide()
    }

    function onTidioStatusUpdate(evt){
      window.beholdTidioOnlineStatus = evt === 'online' || evt.data === 'online' ? 'online' : 'offline'
    }
    `,
  })

  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
        },
        { name: 'color-scheme', content: 'light' },
        { name: 'theme-color', content: '#f4f4f0' },
      ]}
    >
      <link rel="icon" href={'/favicon-32.png'} sizes="32x32" />
      <link rel="icon" href={'/favicon-128.png'} sizes="128x128" />
      <link rel="icon" href={'/favicon-192.png'} sizes="192x192" />
      <link rel="shortcut icon" href={'/favicon-196.png'} sizes="196x196" />
      <link rel="apple-touch-icon" href="/favicon-152.png" sizes="152x152" />
      <link rel="apple-touch-icon" href="/favicon-167.png" sizes="167x167" />
      <link rel="apple-touch-icon" href="/favicon-180.png" sizes="180x180" />
    </Helmet>
  )
}

Metas.propTypes = {
  title: PropTypes.string,
  includeScripts: PropTypes.bool,
}
