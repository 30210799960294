import PropTypes from 'prop-types'
import classnames from 'classnames'
import HelpTooltip from 'Components/HelpTooltip'
import styles from './ToggleButton.module.scss'

export default function ToggleButton({
  className,
  onChange,
  disabled,
  checked,
  ariaLabel,
  children,
  popupLabel = null,
  iconOnly = false,
  size = 'medium',
}) {
  function onClick() {
    onChange(!checked)
  }

  const button = (
    <button
      className={classnames(styles.container, styles[size], className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.icon_only]: iconOnly,
      })}
      aria-label={ariaLabel}
      aria-pressed={checked}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )

  const contents = popupLabel ? (
    <HelpTooltip
      className={styles.tooltip_trigger}
      showIcon={false}
      delay={0}
      openOnClick={false}
      xAlign="center"
      width={95}
      tabIndex={-1}
      triggerText={button}
    >
      <div className={styles.tooltip}>{popupLabel}</div>
    </HelpTooltip>
  ) : (
    button
  )

  /*
   *   Return
   */
  return contents
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  popupLabel: PropTypes.string,
  iconOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}
