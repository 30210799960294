function makeRows(feedString, breakpointName) {
  const paddingBase = `post.padding.${breakpointName}`
  return `<div style="display:flex;width: 100%;font-size:0px;column-gap:{{bp.gap.x}}px;">
        {% for post in ${feedString}.posts %}
          {% if forloop.counter <= bp.numPosts %}
            <div style="display:inline-block;width:{{100|divide:bp.numColumns}}%; padding: 0px 0px {{${paddingBase}.bottom}}px 0px;">
              <div style="display: block; position: relative; width: 100%; height: 0px; max-height:0px; padding-bottom: 100%; border-radius:{{bp.borderRadius}}%; background: rgba({{post.colorPalette.dominant}}) url('{{post.sizes.medium.mediaUrl}}') center center no-repeat; background-size: cover;">
              {% if bp.onPostClick = 'doNothing' %}&nbsp;{% else %}
                <a href="{% if bp.onPostClick = "linkToProfile" %}https://www.instagram.com/{{${feedString}.username}}{% elif bp.onPostClick = "customLink" %}{{bp.customLinkURL}}{% else %}{{post.permalink}}{% endif %}" target="_blank" style="display:block;height: 0px; padding-bottom:100%;width:100%;text-decoration: none;">&nbsp;</a>
              {% endif %}
              </div>
            </div>
            {% if forloop.counter|divisibleby:bp.numColumns %}
              {% if forloop.counter < bp.numPosts %}
              </div><div style="display:flex;width: 100%;font-size:0px;column-gap:{{bp.gap.x}}px;">
              {% endif %}
            {% endif %}
          {% endif %}
        {% endfor %}
        </div>`
}

function makeTable(posts, settings, breakpointName, feedString) {
  const breakpointString = `${feedString}.widgetSettings.breakpoints.${breakpointName}`

  posts = posts
    .filter((p, i) => i < settings.numPosts)
    .map((p, i) => ({ ...p, index: i }))
  const containerClasses =
    breakpointName === 'DESKTOP'
      ? `class="{% if ${feedString}.widgetSettings.mobileStylesLinked is not True %}desktop-only{% endif %}"`
      : 'class="mobile-only"'

  const rows = makeRows(feedString, breakpointName)

  const styleString = `width: 100%;`

  return `{% spaceless %}
  {% with bp=${breakpointString} %}
    <div ${containerClasses}>
      <div style="${styleString}">
          ${rows}
      </div>
    </div>
  {% endwith %}
{% endspaceless %}`
}

export default function generateKlaviyoCode(feed, posts) {
  if (!feed || !posts?.length) return null

  const feedName = feed.widgetSettings.klaviyoWebFeedId
  const feedString = `feeds.${feedName}`

  const desktop = `${makeTable(
    posts,
    feed.widgetSettings.breakpoints.DESKTOP,
    'DESKTOP',
    feedString,
  )}`
  const mobile = `{% if ${feedString}.widgetSettings.mobileStylesLinked is not True %}${makeTable(
    posts,
    feed.widgetSettings.breakpoints.MOBILE,
    'MOBILE',
    feedString,
  )}{% endif %}`

  return `{% if false %}<img src="https://static.behold.so/klaviyo-widget-placeholder.png?v=1" height="300" width="600"/><div style="display:none">{% endif %}${desktop}${mobile}{% if false %}</div>{% endif %}`
}
