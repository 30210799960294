import PropTypes from 'prop-types'
import FeedPanel from 'Components/feed/FeedPanel'
import HelpTooltip from 'Components/HelpTooltip'
import TagInput from 'Components/TagInput'
import PanelIcon from 'Images/icons/key-outline.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

function normalizeWhitelistTags(str) {
  return str.replace(/(((http|https):\/\/)|(\/\/))/, '').replace(/\/(.*)/, '')
}

export default function FeedPanelWidgetSettingsAdvanced({
  panelId,
  localFeedSettings,
  updateLocalFeedSetting,
  hasChanges,
  saveChanges,
  resetChanges,
}) {
  function updateWhitelist(whitelist, newTag) {
    let additionalTags = []
    const numDots = newTag?.match(/\./g)?.length || 0

    if (
      newTag &&
      numDots < 2 &&
      !newTag.includes('www') &&
      !newTag.includes('localhost') &&
      !newTag.includes('127.0.0.1')
    ) {
      additionalTags = [`www.${newTag}`]
    }
    const combined = Array.from(new Set([...whitelist, ...additionalTags]))
    updateLocalFeedSetting('domainWhitelist', combined)
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={350}
      icon={<PanelIcon />}
      title="Advanced settings"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={resetChanges}
    >
      {/* Whitelist */}
      <div className={styles.setting}>
        <div className={styles.label}>
          Domain{' '}
          <HelpTooltip width={350} triggerText="Whitelist">
            <div className={styles.tooltip}>
              <h1>Domain Whitelist</h1>
              <p>
                This feed will only function on domains listed here, or on any
                domain if left blank.
              </p>
              <p>
                Subdomains are independent. So <code>example.com</code> and
                &nbsp;
                <code>www.example.com</code> must be entered separately.
              </p>
              <p>
                <code>localhost</code> is always whitelisted.
              </p>
            </div>
          </HelpTooltip>
        </div>
        <TagInput
          tags={localFeedSettings.domainWhitelist}
          onUpdate={updateWhitelist}
          normalizer={normalizeWhitelistTags}
        />
      </div>
    </FeedPanel>
  )
}

FeedPanelWidgetSettingsAdvanced.propTypes = {
  panelId: PropTypes.string,
  localFeedSettings: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  updateLocalFeedSetting: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
}
