import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { hexToHSL, HSLToHex, isAnyOf } from 'Helpers/utils'
import defaultWidgetSettings from 'Constants/defaultWidgetSettings'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import useTimers from 'Hooks/useTimers'
import FeedPanel from 'Components/feed/FeedPanel'
import TransitionWrapper from 'Components/TransitionWrapper'
import HelpTooltip from 'Components/HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import ControlledSelect from 'Components/ControlledSelect'
import ColorPicker from 'Components/ColorPicker'
import OptionBox from 'Components/OptionBox'
import BlockSelect from 'Components/BlockSelect'
import Switch from 'Components/Switch'
import ToggleButton from 'Components/ToggleButton'
import ControlledRange from 'Components/ControlledRange'
import TagInput from 'Components/TagInput'
import PanelIcon from 'Images/icons/palette.svg'
import PremiumIcon from 'Images/icons/premium-solid.svg'
import BushwickIcon from 'Images/widget-bushwick.svg'
import TribecaIcon from 'Images/widget-tribeca.svg'
import ReelsIcon from 'Images/icons/reels.svg'
import InstagramIcon from 'Images/icons/instagram.svg'
import GalleryIcon from 'Images/icons/gallery-outline.svg'
import VideoIcon from 'Images/icons/play-outline.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelWidgetSettingsGalleryWall({
  panelId,
  feed,
  plan,
  localFeedSettings,
  updateLocalFeedSetting,
  localWidgetSettings,
  setLocalWidgetSettings,
  updateLocalWidgetSetting,
  setPreviewLoadingColors,
  hasChanges,
  saveChanges,
  resetChanges,
  demoMode = false,
  isLoading,
}) {
  const { addNotification } = useSlice('addNotification')

  localWidgetSettings = {
    ...defaultWidgetSettings.galleryWall,
    ...localWidgetSettings,
  }
  const windowSize = useWindowSize()
  const { raf, st, cancelAllTimers } = useTimers()
  const loadingColorToneRef = useRef()
  const loadingColorRef = useRef(localWidgetSettings.loadingColor)

  function updatePostTypeFilter(postType, val) {
    const filterSet = new Set(localFeedSettings.filter.allowedTypes)
    if (val) {
      filterSet.add(postType)
      updateLocalFeedSetting('filter', { allowedTypes: Array.from(filterSet) })
    } else {
      if (filterSet.size > 1) {
        filterSet.delete(postType)
        updateLocalFeedSetting('filter', {
          allowedTypes: Array.from(filterSet),
        })
      } else {
        addNotification(
          {
            text: 'You must allow at least one type of post',
            duration: 20,
          },
          'MIN_ONE_POST_TYPE',
        )
      }
    }
  }

  function updateCaptionIncludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionIncludeText: Array.from(new Set(text)),
    })
  }

  function updateCaptionExcludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionExcludeText: Array.from(new Set(text)),
    })
  }

  function updateGalleryWallLayout(val) {
    switch (val) {
      case 'bushwick':
        updateLocalFeedSetting('maxPosts', 6)

        setLocalWidgetSettings({
          ...localWidgetSettings,
          breakpoints: {
            ...localWidgetSettings.breakpoints,
            default: {
              ...localWidgetSettings.breakpoints.default,
              galleryWallNamedLayout: 'bushwick',
              galleryWallLayout: [
                '0,20,24,24',
                '24,0,44,44',
                '68,8,21,21',
                '18,44,28,28',
                '46,44,22,22',
                '68,29,32,32',
              ],
            },
          },
        })

        break
      case 'tribeca':
        updateLocalFeedSetting('maxPosts', Math.min(14, plan.maxImagesPerFeed))
        setLocalWidgetSettings({
          ...localWidgetSettings,
          breakpoints: {
            ...localWidgetSettings.breakpoints,
            default: {
              ...localWidgetSettings.breakpoints.default,
              galleryWallNamedLayout: 'tribeca',
              galleryWallLayout: [
                '0,13,7,7',
                '7,9,11,11',
                '18,5,15,15',
                '33,0,20,20',
                '53,3,17,17',
                '70,5,15,15',
                '85,9,11,11',
                '4,20,11,11',
                '15,20,15,15',
                '30,20,17,17',
                '47,20,20,20',
                '67,20,15,15',
                '82,20,11,11',
                '93,20,7,7',
              ],
            },
          },
        })
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (
      loadingColorRef.current !== 'tone' &&
      localWidgetSettings.loadingColor === 'tone'
    ) {
      raf(() => {
        if (loadingColorToneRef.current) {
          loadingColorToneRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        }
      })
    }
    loadingColorRef.current = localWidgetSettings.loadingColor
  }, [localWidgetSettings])

  /*
   *   Alignment options
   */
  const alignmentOptions = [
    { label: 'Left', value: `left` },
    { label: 'Center', value: `center` },
    { label: 'Right', value: `right` },
  ]

  /*
   *   Click behavior options
   */
  const clickBehavior = [
    { label: 'Link to post', value: `linkToPost` },
    { label: 'Link to profile', value: `linkToProfile` },
    { label: 'Custom link', value: `customLink` },
    { label: 'Open popup gallery', value: `openPopupGallery` },
    { label: 'Do nothing', value: `doNothing` },
  ]

  /*
   *   Popup theme
   */
  const popupColorTheme = [
    { label: 'Auto', value: `auto` },
    { label: 'Light', value: `light` },
    { label: 'Dark', value: `dark` },
  ]

  /*
   *   Hover contents options
   */
  const hoverContentsOptions = [
    { label: 'Icon', value: `showIcon` },
    { label: 'Caption', value: `showCaption` },
    { label: 'None', value: `none` },
  ]

  /*
   *   Hover contents options
   */
  const hoverOverlayColorOptions = [
    { label: 'Default', value: `default` },
    { label: 'Auto', value: `auto` },
    { label: 'Custom Color', value: `custom` },
  ]

  /*
   *   Hover animation options
   */
  const hoverEffectOptions = [
    { label: 'Fade', value: `fade` },
    { label: 'Zoom fade', value: `zoomFade` },
    { label: 'Blur', value: `blur` },
    { label: 'Zoom blur', value: `zoomBlur` },
    { label: 'To greyscale', value: `toGreyscale` },
    { label: 'Zoom to greyscale', value: `zoomToGreyscale` },
    { label: 'From greyscale', value: `fromGreyscale` },
    { label: 'Zoom from greyscale', value: `zoomFromGreyscale` },
    { label: 'None', value: `none` },
  ]

  return (
    <FeedPanel
      panelId={panelId}
      width={350}
      icon={<PanelIcon />}
      title="Customize"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={resetChanges}
    >
      <h3 className={styles.section_title}>Gallery wall design</h3>
      <div className={styles.setting}>
        <BlockSelect
          options={[
            { value: 'bushwick', title: <BushwickIcon /> },
            {
              value: 'tribeca',
              title: <TribecaIcon />,
              badge:
                plan.maxImagesPerFeed < 15 ? (
                  <>
                    <PremiumIcon /> Paid plans
                  </>
                ) : null,
            },
          ]}
          value={
            localWidgetSettings.breakpoints.default.galleryWallNamedLayout ||
            'bushwick'
          }
          onChange={updateGalleryWallLayout}
          name="Gallery Wall Design"
          numColumns={2}
          size="medium"
        />
        {!demoMode &&
          localWidgetSettings.breakpoints.default.galleryWallLayout.length >
            feed.widgetSettings.breakpoints.default.galleryWallLayout
              .length && (
            <TransitionWrapper
              scale
              height
              className={styles.load_posts_notice__wrapper}
            >
              <div className={styles.load_posts_notice}>
                <div
                  className={classNames(
                    styles.description,
                    styles.description_narrower,
                  )}
                >
                  <button onClick={saveChanges}>Save</button> to load additional
                  posts.
                </div>
              </div>
            </TransitionWrapper>
          )}
      </div>

      <h3 className={styles.section_title}>Filter</h3>
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>Allowed post types</div>
          <div className={styles.toggle_buttons}>
            <ToggleButton
              ariaLabel="Allowed post types: Images"
              popupLabel={`Images: ${
                localFeedSettings.filter.allowedTypes.includes('IMAGE')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('IMAGE')}
              onChange={(val) => updatePostTypeFilter('IMAGE', val)}
              iconOnly
            >
              <InstagramIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Videos"
              popupLabel={`Videos: ${
                localFeedSettings.filter.allowedTypes.includes('VIDEO')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('VIDEO')}
              onChange={(val) => updatePostTypeFilter('VIDEO', val)}
              iconOnly
            >
              <VideoIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Reels"
              popupLabel={`Reels: ${
                localFeedSettings.filter.allowedTypes.includes('REEL')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('REEL')}
              onChange={(val) => updatePostTypeFilter('REEL', val)}
              iconOnly
            >
              <ReelsIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Albums"
              popupLabel={`Albums: ${
                localFeedSettings.filter.allowedTypes.includes('CAROUSEL_ALBUM')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes(
                'CAROUSEL_ALBUM',
              )}
              onChange={(val) => updatePostTypeFilter('CAROUSEL_ALBUM', val)}
              iconOnly
            >
              <GalleryIcon />
            </ToggleButton>
          </div>
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption must include">
              <div className={styles.tooltip}>
                <h1>Caption must include</h1>
                <p>
                  Only posts that contain at least one of the text snippets you
                  add here will be included in your feed. This is case-sensitive
                  and can include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionIncludeText}
            onUpdate={updateCaptionIncludeText}
            size="small"
            allowSpaces
          />
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption may not include">
              <div className={styles.tooltip}>
                <h1>Caption may not include</h1>
                <p>
                  Posts that contain any of the text you add here will be
                  excluded from your feed. This is case-sensitive and can
                  include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionExcludeText}
            onUpdate={updateCaptionExcludeText}
            size="small"
            allowSpaces
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Layout</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Max width */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label} htmlFor="max-width">
            <HelpTooltip width={275} triggerText="Max width">
              <div className={styles.tooltip}>
                <h1>Maximum width</h1>
                <p>
                  Sets a maximum pixel width for your feed. When this option is
                  disabled your feed will fit the width of its container.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <div className={styles.switch_input}>
            <ControlledInput
              id="max-width"
              className={styles.switch_input__input}
              type="number"
              min={0}
              value={localWidgetSettings.maxWidth}
              disabled={!localWidgetSettings.constrainWidth}
              onChange={(val) => updateLocalWidgetSetting('maxWidth', val)}
            />
            <Switch
              ariaLabel="constrain width"
              className={styles.switch_input__switch}
              checked={!!localWidgetSettings.constrainWidth}
              onChange={(val) => {
                updateLocalWidgetSetting('constrainWidth', val)
              }}
            />
          </div>
        </div>

        {/* Alignment */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Alignment</div>
          <ControlledSelect
            disabled={!localWidgetSettings.constrainWidth}
            ariaLabel="Alignment"
            options={alignmentOptions}
            value={localWidgetSettings.alignment}
            onChange={(val) => updateLocalWidgetSetting('alignment', val)}
          />
        </div>
        {/* Gap */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Gap</div>
          <ControlledInput
            ariaLabel="Gap"
            type="number"
            min={0}
            value={localWidgetSettings.breakpoints.default.gap.x}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...localWidgetSettings.breakpoints.default,
                  gap: {
                    x: val,
                    y: val,
                  },
                },
              })
            }
          />
        </div>
      </div>
      <h3 className={styles.section_title}>Appearance</h3>
      <div className={styles.fieldset}>
        {/* Border radius */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Rounded corners</div>
          <ControlledRange
            ariaLabel="Rounded corners"
            type="range"
            min="0"
            max="100"
            suffix={`${
              localWidgetSettings.breakpoints.default.borderRadius * 2
            }%`}
            value={localWidgetSettings.breakpoints.default.borderRadius * 2}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...localWidgetSettings.breakpoints.default,
                  borderRadius: val / 2,
                },
              })
            }
          />
        </div>
      </div>
      <div className={styles.fieldset}>
        {/* Loading Color */}
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={300} triggerText="Placeholder colors">
              <div className={styles.tooltip}>
                <h1>Placeholder colors</h1>
                <p>
                  This determines the colors that show while your posts are
                  loading. These colors are extracted from the images
                  themselves.
                </p>
              </div>
            </HelpTooltip>
          </div>

          {(!feed.newImagesAreProcessing ||
            localWidgetSettings.loadingColor === 'tone') && (
            <OptionBox
              options={[
                {
                  value: 'vibrant',
                  label: 'Vibrant',
                },
                {
                  value: 'vibrantLight',
                  label: 'Vibrant light',
                },
                {
                  value: 'vibrantDark',
                  label: 'Vibrant dark',
                },
                {
                  value: 'muted',
                  label: 'Muted',
                },
                {
                  value: 'mutedLight',
                  label: 'Muted light',
                },
                {
                  value: 'mutedDark',
                  label: 'Muted dark',
                },
                {
                  value: 'dominant',
                  label: 'Dominant',
                },
                {
                  value: 'tone',
                  label: 'Custom tone',
                },
                { value: 'transparent', label: 'None' },
              ]}
              value={localWidgetSettings.loadingColor}
              onChange={(val) => updateLocalWidgetSetting('loadingColor', val)}
              onOptionIn={(val) => {
                cancelAllTimers()
                setPreviewLoadingColors(val)
              }}
              onOptionOut={() => st(() => setPreviewLoadingColors(null), 50)}
              onClose={() => setPreviewLoadingColors(null)}
              numColumns={windowSize.width > 600 ? 3 : 2}
              width={windowSize.width > 600 ? 500 : 300}
              yAlign="top"
            />
          )}
          {feed.newImagesAreProcessing &&
            localWidgetSettings.loadingColor !== 'tone' && (
              <div className={styles.setting_loader}>
                <l-squircle
                  stroke="3"
                  size={20}
                  color="var(--color-text-light)"
                ></l-squircle>
                <div className={styles.setting_loader__text}>
                  Extracting colors...
                </div>
              </div>
            )}
        </div>
        <TransitionWrapper
          show={localWidgetSettings.loadingColor === 'tone'}
          height
          scale
          className={styles.setting}
        >
          <div ref={loadingColorToneRef} className={styles.label}>
            Placeholder custom tone
          </div>
          <ColorPicker
            onChange={(val) => {
              updateLocalWidgetSetting('loadingColorTone', hexToHSL(val))
              setPreviewLoadingColors('tone')
            }}
            onFocus={() => {
              setPreviewLoadingColors('tone')
            }}
            onBlur={() => setPreviewLoadingColors(null)}
            value={HSLToHex(localWidgetSettings.loadingColorTone)}
          />
        </TransitionWrapper>
      </div>

      {/* On hover */}
      <h3 className={styles.section_title}>Hover</h3>
      <div className={classNames(styles.fieldset)}>
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Hover contents</div>
          <ControlledSelect
            ariaLabel="Hover contents"
            options={hoverContentsOptions}
            value={localWidgetSettings.onHover}
            onChange={(val) => updateLocalWidgetSetting('onHover', val)}
          />
        </div>

        {/* Hover effect */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Hover effect</div>
          <ControlledSelect
            ariaLabel="Hover effect"
            options={hoverEffectOptions}
            value={localWidgetSettings.hoverEffect}
            onChange={(val) => updateLocalWidgetSetting('hoverEffect', val)}
          />
        </div>

        {/* Hover overlay color */}
        <TransitionWrapper
          show={
            isAnyOf(localWidgetSettings.hoverEffect, [
              'fade',
              'zoomFade',
              'fromGreyscale',
              'zoomFromGreyscale',
            ]) || localWidgetSettings.onHover === 'showCaption'
          }
          scale
          height
          className={classNames(styles.setting)}
        >
          <div className={styles.label}>Hover overlay color</div>
          <ControlledSelect
            ariaLabel="Hover overlay color"
            options={hoverOverlayColorOptions}
            value={localWidgetSettings.hoverOverlayColor}
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayColor', val)
            }
          />
        </TransitionWrapper>

        {/* Hover overlay custom color */}
        <TransitionWrapper
          show={
            localWidgetSettings.hoverOverlayColor === 'custom' &&
            (isAnyOf(localWidgetSettings.hoverEffect, ['fade', 'zoomFade']) ||
              localWidgetSettings.onHover === 'showCaption')
          }
          className={classNames(styles.setting)}
          height
          scale
        >
          <div className={styles.label}>Hover overlay custom color</div>
          <ColorPicker
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayCustomColor', hexToHSL(val))
            }
            value={HSLToHex(localWidgetSettings.hoverOverlayCustomColor)}
          />
        </TransitionWrapper>

        {/* Hover overlay opacity */}
        <TransitionWrapper
          show={
            isAnyOf(localWidgetSettings.hoverOverlayColor, [
              'auto',
              'custom',
            ]) &&
            (isAnyOf(localWidgetSettings.hoverEffect, ['fade', 'zoomFade']) ||
              localWidgetSettings.onHover === 'showCaption')
          }
          className={classNames(styles.setting)}
          height
          scale
        >
          <div className={styles.label}>Hover overlay opacity</div>
          <ControlledRange
            ariaLabel="Hover overlay opacity"
            type="range"
            min="0"
            max="100"
            suffix={`${localWidgetSettings.hoverOverlayOpacity}%`}
            value={localWidgetSettings.hoverOverlayOpacity}
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayOpacity', val)
            }
          />
        </TransitionWrapper>
      </div>

      <h3 className={styles.section_title}>Video</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Autoplay videos */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Autoplay videos"
            checked={!!localWidgetSettings.autoplayVideos}
            onChange={(val) => updateLocalWidgetSetting('autoplayVideos', val)}
            before={<b>Autoplay videos</b>}
          />
        </div>
        {/* Video Previews */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Play videos on hover"
            checked={!!localWidgetSettings.previewVideosOnHover}
            onChange={(val) =>
              updateLocalWidgetSetting('previewVideosOnHover', val)
            }
            before={<b>Play videos on hover</b>}
            disabled={localWidgetSettings.autoplayVideos}
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Behavior</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Click behavior */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>On click</div>
          <ControlledSelect
            ariaLabel="On Click"
            options={clickBehavior}
            value={localWidgetSettings.onPostClick}
            onChange={(val) => updateLocalWidgetSetting('onPostClick', val)}
          />
        </div>

        {/* Popup color theme */}
        <TransitionWrapper
          show={localWidgetSettings?.onPostClick === 'openPopupGallery'}
          scale
          height
          className={classNames(styles.setting)}
        >
          <div className={styles.label}>Popup color theme</div>
          <ControlledSelect
            ariaLabel="Popup color theme"
            options={popupColorTheme}
            value={localWidgetSettings.popupColorTheme}
            onChange={(val) => updateLocalWidgetSetting('popupColorTheme', val)}
          />
        </TransitionWrapper>

        {/* Custom link URL */}
        <TransitionWrapper
          show={localWidgetSettings?.onPostClick === 'customLink'}
          scale
          height
          className={classNames(styles.setting)}
        >
          <div className={styles.label}>Custom link URL</div>
          <ControlledInput
            ariaLabel="Custom link URL"
            value={localWidgetSettings.customLinkURL}
            onChange={(val) => updateLocalWidgetSetting('customLinkURL', val)}
          />
        </TransitionWrapper>

        {/* Link Target */}
        <TransitionWrapper
          show={
            !'openPopupGallery,doNothing'.includes(
              localWidgetSettings.onPostClick,
            )
          }
          scale
          height
          className={classNames(styles.setting, styles.setting_switch)}
        >
          <Switch
            ariaLabel="Open links in a new tab"
            checked={!!localWidgetSettings.linkTarget}
            onChange={(val) => {
              const value = val ? '_blank' : ''
              updateLocalWidgetSetting('linkTarget', value)
            }}
            before={<b>Open links in a new tab</b>}
          />
        </TransitionWrapper>
      </div>
    </FeedPanel>
  )
}

FeedPanelWidgetSettingsGalleryWall.propTypes = {
  feedId: PropTypes.string,
  panelId: PropTypes.string,
  feed: PropTypes.object,
  localFeedSettings: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  updateLocalWidgetSetting: PropTypes.func,
  updateLocalFeedSetting: PropTypes.func,
  setPreviewLoadingColors: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
  plan: PropTypes.object,
}
