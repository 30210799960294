import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { auth } from 'Fire/firebaseInit'
import { isPopulated } from 'Helpers/utils'
import { useSlice } from 'State'
import Layout from 'Components/Layout'
import FeedCard from 'Components/FeedCard'
import PageHeader from 'Components/PageHeader'
import TransitionWrapper from 'Components/TransitionWrapper'
import PlusIcon from 'Images/icons/plus.svg'
import ConnectIcon from 'Images/icons/connect.svg'
import styles from './Feeds.module.scss'

export default function Feeds() {
  const appState = useSlice(
    'feeds',
    'user',
    'authorizedAccounts',
    'plans',
    'newFeedIsLoading',
    'addNotification',
  )
  const feeds = appState?.feeds
  const user = appState?.user
  const sources = appState?.authorizedAccounts

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? appState?.plans?.[planId] : null

  function showVerifyPrompt() {
    appState.addNotification(
      {
        text: <>A verified email is required for that action</>,
        duration: 5,
      },
      'VERIFIED_EMAIL_REQUIRED',
    )
  }

  /*
   *   Loading el
   */
  const loadingEL = (
    <div className={styles.loading_feed}>
      <l-squircle
        stroke="3"
        size={30}
        color="var(--color-text-light)"
      ></l-squircle>
    </div>
  )

  /*
   *   Feed els
   */
  const feedEls = isPopulated(feeds)
    ? Object.values(feeds)
        .filter((f) => !!f.created && sources?.[f.sourceId])
        .sort((a, b) => {
          return a.created.seconds
            .toString()
            .localeCompare(b.created.seconds.toString())
        })
        .map((f, i, arr) => {
          const sourceId = f.sourceId
          const source = sources[sourceId]
          return (
            <FeedCard
              key={f.id}
              feed={f}
              plan={activePlan}
              reauth={source?.reauthorize}
              reasonForReauth={source?.reasonForReauth}
              first={i === 0}
              last={i >= arr.length - 1}
            />
          )
        })
    : null

  const addFeedEl = auth.currentUser.emailVerified ? (
    <Link className={classnames(styles.add_feed)} to="/add-feed">
      <div className={styles.add_feed__inner}>
        <PlusIcon /> Add feed
      </div>
    </Link>
  ) : (
    <button className={classnames(styles.add_feed)} onClick={showVerifyPrompt}>
      <div className={styles.add_feed__inner}>
        <PlusIcon /> Add feed
      </div>
    </button>
  )

  const welcomeMessageEl = (
    <Link to="/add-source" className={styles.empty_message}>
      <div className={styles.empty_message__inner}>
        <ConnectIcon />
        <div>
          Welcome! To get started let&apos;s <u>connect a source</u>
        </div>
      </div>
    </Link>
  )

  const addFeedMessageEl = (
    <Link
      to="/add-feed"
      className={classnames(styles.empty_message, styles.empty_message__feeds)}
    >
      <div className={styles.empty_message__inner}>
        Congratulations! You&apos;ve successfully connected a source. Now
        let&apos;s{' '}
        <TransitionWrapper scale className={styles.empty_message__button}>
          <div className={styles.empty_message__button_inner}>Add a feed</div>
        </TransitionWrapper>
      </div>
    </Link>
  )

  const feedsWrapperEl = (
    <TransitionWrapper className={classnames(styles.feeds)}>
      {feedEls}
      {appState.newFeedIsLoading && loadingEL}
      {!appState.newFeedIsLoading && isPopulated(sources) && addFeedEl}
    </TransitionWrapper>
  )

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Feeds | Behold">
      <main className={classnames(styles.container)}>
        <PageHeader
          title="Feeds"
          buttonText={
            <>
              <PlusIcon /> Add feed
            </>
          }
          buttonTo={auth.currentUser.emailVerified ? '/add-feed' : null}
          buttonAction={
            auth.currentUser.emailVerified ? null : showVerifyPrompt
          }
        />

        {isPopulated(sources) && isPopulated(feeds) && feedsWrapperEl}
        {(!sources || !feeds) && (
          <div className={classnames(styles.feeds_loading)}>
            <div className={styles.feeds_loading__loader} />
            <div className={styles.feeds_loading__loader} />
          </div>
        )}

        {!appState.newFeedIsLoading && isPopulated(sources, false)
          ? welcomeMessageEl
          : null}

        <TransitionWrapper
          show={
            !appState.newFeedIsLoading &&
            isPopulated(sources) &&
            isPopulated(feeds, false)
          }
          transform="scale(.97)"
        >
          {addFeedMessageEl}
        </TransitionWrapper>
      </main>
    </Layout>
  )
}
