const sharedSettings = {
  customLinkURL: '',
  popupColorTheme: 'auto',
  loadingColor: 'dominant',
  loadingColorTone: '225,73,57',
  hoverOverlayColor: 'default',
  hoverOverlayCustomColor: '0,0,0',
  hoverOverlayOpacity: 65,
  alignment: 'center',
  onPostClick: 'linkToPost',
  autoplayVideos: false,
}

const defaultWidgetSettings = {
  grid: {
    ...sharedSettings,
  },
  galleryWall: {
    ...sharedSettings,
  },
  klaviyo: {
    mobileStylesLinked: false,
  },
  elasticCarousel: {
    ...sharedSettings,
    iconColor: '#4a4a4a',
    textColor: '#4a4a4a',
    buttonColor: '#f4f4f4',
    buttonHoverColor: '#ececec',
    buttonIconColor: '#4a4a4a',
    buttonIconHoverColor: '#2e2e2e',
  },
}

export default defaultWidgetSettings
