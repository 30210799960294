import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { checkActionCode, applyActionCode } from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import Logo from 'Images/logo.svg'
import styles from './VerifyAndChangeEmail.module.scss'

export default function VerifyAndChangeEmail({ actionCode }) {
  const [error, setError] = useState(null)
  const [emailIsUpdated, setEmailIsUpdated] = useState(false)
  const [newEmail, setNewEmail] = useState(null)

  /*
   *   Use Effect
   */
  useEffect(() => {
    if (emailIsUpdated) return

    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        const updatedEmail = info?.data?.email
        setNewEmail(updatedEmail)

        // Revert to the old email.
        return applyActionCode(auth, actionCode)
      })
      .then(() => {
        setEmailIsUpdated(true)
      })
      .catch((error) => {
        switch (error.code) {
          case 'appAuth/expired-action-code':
            setError('This email update link has expired')
            break
          default:
            setError('Invalid email update link')
        }
      })
  }, []) //eslint-disable-line

  /*
   *   Return
   */
  return (
    <main className={classnames(styles.container)}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {error && (
          <div className={styles.error}>
            {error}{' '}
            <p>
              <a href="/account" className={styles.button}>
                Go back
              </a>
            </p>
          </div>
        )}

        {emailIsUpdated && !error && (
          <div className={styles.message}>
            <p>Success! Your email has been changed to {newEmail}.</p>
            <p>
              <a href="/account" className={styles.button}>
                Log in
              </a>
            </p>
          </div>
        )}
        {!emailIsUpdated && !error && (
          <div className={styles.loading}>
            <l-squircle
              stroke="4"
              size={35}
              color="var(--color-text-light)"
              speed=".65"
            ></l-squircle>
          </div>
        )}
      </div>
    </main>
  )
}

VerifyAndChangeEmail.propTypes = {
  actionCode: PropTypes.string,
}
