export const adjectives = [
  'important',
  'large',
  'popular',
  'able',
  'basic',
  'difficult',
  'historical',
  'useful',
  'additional',
  'emotional',
  'healthy',
  'financial',
  'federal',
  'strong',
  'significant',
  'successful',
  'electrical',
  'intelligent',
  'interesting',
  'happy',
  'responsible',
  'helpful',
  'recent',
  'wonderful',
  'impossible',
  'serious',
  'huge',
  'rare',
  'technical',
  'typical',
  'competitive',
  'immediate',
  'educational',
  'global',
  'relevant',
  'accurate',
  'capable',
  'dramatic',
  'efficient',
  'powerful',
  'hungry',
  'practical',
  'severe',
  'suitable',
  'numerous',
  'sufficient',
  'unusual',
  'consistent',
  'cultural',
  'existing',
  'famous',
  'pure',
  'obvious',
  'careful',
  'acceptable',
  'distinct',
  'logical',
  'reasonable',
  'strict',
  'automatic',
  'civil',
  'massive',
  'southern',
  'unfair',
  'visible',
  'exciting',
  'friendly',
  'lucky',
  'realistic',
  'unlikely',
  'curious',
  'impressive',
  'informal',
  'pleasant',
  'sudden',
  'wooden',
  'confident',
  'decent',
  'embarrassed',
  'guilty',
  'remarkable',
  'substantial',
  'suspicious',
  'tall',
  'tiny',
  'great',
  'best',
  'certain',
  'little',
  'common',
  'next',
  'simple',
  'big',
  'possible',
  'particular',
  'major',
  'least',
  'natural',
  'physical',
  'short',
  'professional',
  'international',
  'special',
  'cold',
  'necessary',
  'creative',
  'glad',
  'proper',
  'complex',
  'effective',
  'regular',
  'fast',
  'independent',
  'original',
  'active',
  'safe',
  'quick',
  'direct',
  'excellent',
  'unique',
  'classic',
  'private',
  'familiar',
  'official',
  'perfect',
  'bright',
  'broad',
  'comfortable',
  'rich',
  'warm',
  'heavy',
  'valuable',
  'correct',
  'slow',
  'clean',
  'fresh',
  'normal',
  'secret',
  'tough',
  'deep',
  'secure',
  'extreme',
  'formal',
  'vast',
  'smooth',
  'double',
  'frequent',
  'constant',
  'minor',
  'amazing',
  'busy',
]
export const animals = [
  'aardvark',
  'albatross',
  'alligator',
  'alpaca',
  'ant',
  'anteater',
  'antelope',
  'ape',
  'armadillo',
  'baboon',
  'badger',
  'barracuda',
  'bat',
  'bear',
  'beaver',
  'bee',
  'bison',
  'boar',
  'buffalo',
  'butterfly',
  'camel',
  'capybara',
  'caribou',
  'cassowary',
  'caterpillar',
  'cattle',
  'chamois',
  'cheetah',
  'chicken',
  'chimpanzee',
  'chinchilla',
  'chough',
  'clam',
  'cobra',
  'cockroach',
  'cod',
  'cormorant',
  'coyote',
  'crab',
  'crane',
  'crocodile',
  'crow',
  'curlew',
  'deer',
  'dinosaur',
  'dog',
  'dogfish',
  'dolphin',
  'dotterel',
  'dove',
  'dragonfly',
  'duck',
  'dugong',
  'dunlin',
  'eagle',
  'echidna',
  'eel',
  'eland',
  'elephant',
  'elk',
  'emu',
  'falcon',
  'ferret',
  'finch',
  'fish',
  'flamingo',
  'fly',
  'fox',
  'frog',
  'gaur',
  'gazelle',
  'gerbil',
  'giraffe',
  'gnat',
  'gnu',
  'goat',
  'goldfinch',
  'goldfish',
  'goose',
  'gorilla',
  'goshawk',
  'grasshopper',
  'grouse',
  'guanaco',
  'gull',
  'hamster',
  'hare',
  'hawk',
  'hedgehog',
  'heron',
  'herring',
  'hippopotamus',
  'hornet',
  'horse',
  'human',
  'hummingbird',
  'hyena',
  'ibex',
  'ibis',
  'jackal',
  'jaguar',
  'jay',
  'jellyfish',
  'kangaroo',
  'kingfisher',
  'koala',
  'kookabura',
  'kouprey',
  'kudu',
  'lapwing',
  'lark',
  'lemur',
  'leopard',
  'lion',
  'llama',
  'lobster',
  'locust',
  'loris',
  'louse',
  'lyrebird',
  'magpie',
  'mallard',
  'manatee',
  'mandrill',
  'mantis',
  'marten',
  'meerkat',
  'mink',
  'mole',
  'mongoose',
  'monkey',
  'moose',
  'mosquito',
  'mouse',
  'mule',
  'narwhal',
  'newt',
  'nightingale',
  'octopus',
  'okapi',
  'opossum',
  'oryx',
  'ostrich',
  'otter',
  'owl',
  'oyster',
  'panther',
  'parrot',
  'partridge',
  'peafowl',
  'pelican',
  'penguin',
  'pheasant',
  'pig',
  'pigeon',
  'pony',
  'porcupine',
  'porpoise',
  'quail',
  'quetzal',
  'rabbit',
  'raccoon',
  'raven',
  'reindeer',
  'rhinoceros',
  'rook',
  'salamander',
  'salmon',
  'sandpiper',
  'sardine',
  'seahorse',
  'seal',
  'shark',
  'sheep',
  'shrew',
  'snail',
  'sparrow',
  'spoonbill',
  'squid',
  'squirrel',
  'starling',
  'stingray',
  'stork',
  'swan',
  'tapir',
  'tarsier',
  'termite',
  'tiger',
  'toad',
  'trout',
  'turkey',
  'turtle',
  'viper',
  'vulture',
  'wallaby',
  'walrus',
  'wasp',
  'weasel',
  'whale',
  'wildcat',
  'wolf',
  'wolverine',
  'wombat',
  'wren',
  'yak',
  'zebra',
]
