import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { zoomies, squircle, dotPulse, quantum } from 'ldrs'
import App from 'Components/App'
import './assets/scss/base.scss'

zoomies.register()
squircle.register()
dotPulse.register()
quantum.register()

/*
 *   Add class to body when keyboard nav is being used
 *   Used to conditionally add focus highlights and other keyboard-friendly indicators
 */
document.addEventListener('keydown', (evt) => {
  if (evt.key === 'Tab') {
    document.body.classList.add('keys')
  }
})

document.addEventListener('mousedown', (e) => {
  if (e.clientX === 0 && e.clientY === 0) return
  document.body.classList.remove('keys')
})

document.addEventListener('mousemove', () => {
  document.body.classList.remove('keys')
})

if (process.env.ENVIRONMENT === 'PRODUCTION') {
  Sentry.init({
    dsn: 'https://09d05dbe531f41d9b0a2a10c8852e5bb@o1429463.ingest.sentry.io/6780093',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
)
