import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import {
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import LeftArrow from 'Images/icons/caret-left.svg'
import Logo from 'Images/logo.svg'
import styles from './ResetPassword.module.scss'

/*
 *   Reset Password
 */
async function verifyCodeAndGetEmail(code) {
  try {
    return verifyPasswordResetCode(auth, code)
  } catch (error) {
    console.log(error)
  }
}

export default function ResetPassword({ actionCode }) {
  const [error, setError] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordIsReset, setPasswordIsReset] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const emailRef = useRef()
  const navigate = useNavigate()

  /*
   *   Update password and log in
   */
  async function updatePassword() {
    if (password.length < 6) {
      setError('Password must be at least 6 characters')
      return false
    }

    setIsLoading(true)
    setError(false)

    try {
      await confirmPasswordReset(auth, actionCode, password)
      setPasswordIsReset(true)
    } catch (error) {
      console.log(error)
    }
  }

  /*
   *   Sign in and load dashboard
   */
  async function signInAndGoToDashboard() {
    await signInWithEmailAndPassword(auth, emailRef.current, password)
    navigate('/')
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    if (isVerified || passwordIsReset) return
    verifyCodeAndGetEmail(actionCode)
      .then((email) => {
        setIsVerified(true)
        emailRef.current = email
      })
      .catch((error) => {
        switch (error.code) {
          case 'appAuth/expired-action-code':
            setError('This password reset link has expired')
            break
          default:
            setError('Invalid password reset link')
        }
      })
  }, []) //eslint-disable-line

  /*
   *   Return
   */
  return (
    <main className={classnames(styles.container)}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {(passwordIsReset || !isVerified) && error && (
          <div className={styles.error}>{error}</div>
        )}

        {!passwordIsReset && isVerified && (
          <>
            <div className={styles.form}>
              <Form
                className={styles.email}
                isLoading={isLoading}
                errorMessage={error}
                onSubmit={updatePassword}
                submitButtonContent={<>Reset password</>}
              >
                <h2 className={styles.section_title}>
                  Enter your new password:
                </h2>
                <FormItem label="Password">
                  <ControlledInput
                    tabIndex={1}
                    className={styles.input}
                    type="password"
                    onChange={setPassword}
                    value={password}
                  />
                </FormItem>
              </Form>
            </div>
          </>
        )}

        {passwordIsReset && (
          <div className={styles.message}>
            <p>Success! Your password has been reset.</p>
            <p>
              <button
                onClick={signInAndGoToDashboard}
                className={styles.button}
              >
                Go to Dashboard
              </button>
            </p>
          </div>
        )}

        {!auth.currentUser && !passwordIsReset && (isVerified || error) && (
          <footer className={styles.footer}>
            <Link to="/sign-in">
              <LeftArrow /> Back to login
            </Link>
          </footer>
        )}
      </div>
    </main>
  )
}

ResetPassword.propTypes = {
  actionCode: PropTypes.string,
}
