import { useState, useRef, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Popover from 'Components/Popover'
import styles from './PopupMenu.module.scss'
import DotsIcon from 'Images/icons/dots.svg'

/*
 *   Trigger Button
 */
export default function TriggerButton({
  children,
  className,
  width,
  size = 'medium',
  color = 'grey',
  onOpen,
  onClose,
  fixed = false,
  icon = <DotsIcon />,
  xAlign = 'right',
  yAlign = 'top',
  containerId = 'root',
  forceClose,
  offset = 5,
  unstyled = false,
  ariaLabel = 'Open menu',
  untabbable = false,
}) {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState()
  const triggerRef = useRef(null)

  /*
   *   Open Menu
   */
  function open() {
    setIsOpen(true)
    if (onOpen) onOpen()
  }

  /*
   *   Close menu
   */
  const close = useCallback(() => {
    setIsOpen(false)
    if (onClose) onClose()
  }, [onClose])

  /*
   *   Toggle menu
   */
  function toggle(evt) {
    evt.preventDefault()

    if (isOpen) {
      close()
    } else {
      open()
    }
  }

  useEffect(() => {
    close()
  }, [location])

  const containerClasses = classnames(styles.trigger, className, {
    [styles.small]: size === 'small',
    [styles.white]: color === 'white',
    [styles.unstyled]: unstyled,
  })

  /*
   *   Render
   */
  return (
    <>
      <button
        aria-label={ariaLabel}
        aria-haspopup="menu"
        ref={triggerRef}
        className={containerClasses}
        onClick={toggle}
        {...(untabbable ? { tabIndex: -1 } : {})}
      >
        {icon}
      </button>
      {isOpen && (
        <Popover
          fixed={fixed}
          onRequestClose={close}
          anchorRef={triggerRef}
          width={width}
          yAlign={yAlign}
          xAlign={xAlign}
          offset={offset}
          containerId={containerId}
          forceClose={forceClose}
          focusOnInit={false}
          role="menu"
        >
          <div className={styles.popup}>{children}</div>
        </Popover>
      )}
    </>
  )
}

TriggerButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
  yAlign: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  xAlign: PropTypes.string,
  containerId: PropTypes.string,
  forceClose: PropTypes.any,
  offset: PropTypes.number,
  unstyled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  untabbable: PropTypes.bool,
}
