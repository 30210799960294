export default async function blobToDataUrl(blobOrFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result)
      },
      false,
    )

    if (blobOrFile) {
      reader.readAsDataURL(blobOrFile)
    } else {
      reject()
    }
  })
}
