import { useState, useEffect } from 'react'
import {
  Link,
  useNavigate,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom'
import classNames from 'classnames'
import { useSlice } from 'State'
import useHotkey from 'Hooks/useHotkey'
import useTimers from 'Hooks/useTimers'
import Metas from 'Components/Metas'
import TransitionWrapper from 'Components/TransitionWrapper'
import HashIcon from 'Images/icons/hash.svg'
import CaretLeftIcon from 'Images/icons/caret-left.svg'
import XIcon from 'Images/icons/x.svg'
import AccountIcon from 'Images/icons/user.svg'
import BasicSourceIcon from 'Images/icons/source-outline.svg'
import AdvancedSourceIcon from 'Images/icons/advanced.svg'
import FeedIcon from 'Images/icons/feed-outline.svg'
import CodeIcon from 'Images/icons/json.svg'
import KlaviyoIcon from 'Images/klaviyo-outline.svg'
import styles from './AddFeed.module.scss'

export default function AddFeed() {
  const appState = useSlice('addFeedIsLoading')
  const location = useLocation()
  const navigate = useNavigate()
  const { raf } = useTimers()
  const [isVisible, setIsVisible] = useState(false)

  /*
   *   Go back one step
   */
  function goBack() {
    function getPathEndingWith(word) {
      return location.pathname.split('/').reduce((acc, curr) => {
        if (acc.includes(word) || curr.trim().length < 1) return acc
        return `${acc}/${curr}`
      }, '')
    }

    switch (location.pathname.split('/').pop()) {
      case 'source':
        navigate('/add-feed')
        break
      case 'type':
        navigate(getPathEndingWith('source'))
        break
      case 'name':
        navigate(getPathEndingWith('type'))
        break
      default:
        navigate('/feeds')
        break
    }
  }

  useHotkey('Escape', () => navigate('/'))

  useEffect(() => {
    raf(() => {
      setIsVisible(true)
    })
  }, [])

  return (
    <>
      <Metas title="Add a feed | Behold" />
      <main className={styles.container}>
        <div
          className={classNames(styles.nav, {
            [styles.loaded]: isVisible && !appState.addFeedIsLoading,
          })}
        >
          {!location.pathname.includes('hashtags') && (
            <button
              onClick={goBack}
              className={classNames(styles.nav__button, styles.back_button)}
            >
              <CaretLeftIcon /> Back{' '}
            </button>
          )}
          <Link
            aria-label="Back to feeds"
            to="/"
            className={classNames(styles.nav__button, styles.close_button)}
          >
            <div className={styles.hotkey}>Esc</div>
            <XIcon />
          </Link>
        </div>
        <Outlet />
        {!appState.addFeedIsLoading && <Recipe />}
        {appState.addFeedIsLoading && (
          <div className={styles.loader}>
            <l-squircle
              stroke="3"
              size={30}
              color="var(--color-text-light)"
            ></l-squircle>
          </div>
        )}
      </main>
    </>
  )
}

/*
 *   Recipe
 */
function Recipe() {
  const appState = useSlice('addFeedIsLoading', 'authorizedAccounts')
  const { sourceType, source: sourceId, type, ids } = useParams()

  const igAccounts = Object.values(appState.authorizedAccounts).reduce(
    (acc, curr) => {
      if (curr.type.includes('instagramAdvanced')) {
        if (curr.id || curr.accessToken) {
          Object.values(curr.proInstagramAccounts).forEach((proAccount) => {
            acc[`${curr.id}_${proAccount.instagramUserId}`] = {
              ...proAccount,
              accessToken: curr.accessToken,
              parentSourceId: curr.id,
              expiresOn: curr.expiresOn ?? null,
              type: curr.type,
            }
          })
        }
        return acc
      } else {
        return { ...acc, ['_' + curr.instagramUserId]: curr }
      }
    },
    {},
  )

  const source = igAccounts[sourceId]

  return sourceType && !ids ? (
    <TransitionWrapper className={styles.recipe} scale>
      <div className={styles.recipe__inner}>
        You are making a
        <TransitionWrapper className={styles.ingredient} scale>
          <div className={styles.ingredient__inner}>
            {sourceType === 'user' ? (
              <>
                <AccountIcon /> User feed
              </>
            ) : (
              <>
                <HashIcon /> Hashtag feed
              </>
            )}
          </div>
        </TransitionWrapper>
        {source && (
          <>
            from the{' '}
            {source.type.includes('instagramAdvanced') ? 'advanced' : 'basic'}{' '}
            source
            <TransitionWrapper className={styles.ingredient} scale>
              <div className={styles.ingredient__inner}>
                {source.type.includes('instagramAdvanced') ? (
                  <AdvancedSourceIcon />
                ) : (
                  <BasicSourceIcon />
                )}{' '}
                @{source.username}
              </div>
            </TransitionWrapper>
          </>
        )}
        {type && (
          <>
            that outputs {type === 'widget' ? 'a' : ''}
            <TransitionWrapper className={styles.ingredient} scale>
              <div className={styles.ingredient__inner}>
                {type === 'widget' && (
                  <>
                    <FeedIcon /> Widget
                  </>
                )}
                {type === 'api' && (
                  <>
                    <CodeIcon /> JSON
                  </>
                )}
                {type === 'klaviyo' && (
                  <>
                    <KlaviyoIcon /> Klaviyo
                  </>
                )}
              </div>
            </TransitionWrapper>
          </>
        )}
      </div>
    </TransitionWrapper>
  ) : null
}
