import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { checkActionCode, applyActionCode } from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import { setUserEmail } from 'Helpers/services'
import Logo from 'Images/logo.svg'
import styles from './RecoverEmail.module.scss'

export default function RecoverEmail({ actionCode }) {
  const [error, setError] = useState(null)
  const [emailIsRestored, setEmailIsRestored] = useState(false)
  const [restoredEmail, setRestoredEmail] = useState(null)

  /*
   *   Use Effect
   */
  useEffect(() => {
    if (emailIsRestored) return

    checkActionCode(auth, actionCode)
      .then(async (info) => {
        // Get the restored email address.
        const recoveredEmail = info?.data?.email
        setRestoredEmail(recoveredEmail)

        // Revert to the old email.
        await applyActionCode(auth, actionCode)
        return setUserEmail(recoveredEmail, true)
      })
      .then(() => {
        setEmailIsRestored(true)
      })
      .catch((error) => {
        switch (error.code) {
          case 'appAuth/expired-action-code':
            setError('This email recovery link has expired')
            break
          default:
            setError('Invalid email recovery link')
        }
      })
  }, []) //eslint-disable-line

  /*
   *   Return
   */
  return (
    <main className={classnames(styles.container)}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {error && (
          <div className={styles.error}>
            {error}{' '}
            <p>
              <a href="/account" className={styles.button}>
                Go back
              </a>
            </p>
          </div>
        )}

        {emailIsRestored && !error && (
          <div className={styles.message}>
            <p>Success! Your email has been restored to {restoredEmail}.</p>
            <p>
              <a href="/account" className={styles.button}>
                Log in
              </a>
            </p>
          </div>
        )}
        {!emailIsRestored && !error && (
          <div className={styles.loading}>
            <l-squircle
              stroke="4"
              size={35}
              color="var(--color-text-light)"
              speed=".65"
            ></l-squircle>
          </div>
        )}
      </div>
    </main>
  )
}

RecoverEmail.propTypes = {
  actionCode: PropTypes.string,
}
