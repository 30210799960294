import { forwardRef } from 'preact/compat'
import { Link } from 'react-router-dom'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    offset: 85,
  })
}

const SmartLink = forwardRef((props, ref) => {
  if (
    props.to &&
    (props.to.startsWith('http') || props.to.startsWith('mailto:'))
  ) {
    return (
      <a ref={ref} {...props} target="_blank" rel="noreferrer" href={props.to}>
        {props.children}
      </a>
    )
  }
  return (
    <Link ref={ref} {...props} to={props.to}>
      {props.children}
    </Link>
  )
})

SmartLink.defaultProps = {
  to: '/',
}

export default SmartLink
