import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Popover from 'Components/Popover'
import styles from './HelpTooltip.module.scss'

const closeFuncs = new Set()

export default function HelpTooltip({
  children,
  triggerText,
  forceClose,
  className = '',
  width = 200,
  persistent = false,
  showIcon = true,
  yAlign = 'top',
  xAlign = 'right',
  delay = 200,
  openOnClick = true,
  openOnHover = true,
  onClick = null,
  tabIndex = null,
  containerId = 'root',
  disabled = false,
}) {
  const triggerRef = useRef(null)
  const toRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const isOpenRef = useRef(false)

  function open() {
    if (isOpenRef.current || disabled) return
    clearTimeout(toRef.current)
    Array.from(closeFuncs).forEach((func) => func())
    isOpenRef.current = true
    setIsOpen(true)
  }

  const close = useCallback(() => {
    if (!isOpenRef.current) return
    isOpenRef.current = false
    setIsOpen(false)
  }, [])

  function handleHover() {
    if (!openOnHover) return
    clearTimeout(toRef.current)
    toRef.current = setTimeout(open, delay)
  }

  function handleLeave() {
    if (persistent) return
    clearTimeout(toRef.current)
    close()
  }

  useEffect(() => close, [forceClose])

  useEffect(() => {
    closeFuncs.add(close)
  }, [close])

  useEffect(() => {
    clearTimeout(toRef.current)
  }, [isOpen])

  useEffect(
    () => () => {
      clearTimeout(toRef.current)
      closeFuncs.delete(close)
    },
    [],
  )

  const Tag = openOnClick ? 'button' : 'div'

  return (
    <>
      <Tag
        ref={triggerRef}
        onClick={(evt) => {
          if (onClick) onClick()
          if (!openOnClick || onClick) return
          evt.preventDefault()
          open()
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        className={classnames(styles.container, className, {
          [styles.open]: isOpen,
          [styles.disabled]: disabled,
        })}
        tabIndex={tabIndex}
      >
        {triggerText}
        {showIcon && (
          <div className={styles.icon} aria-label="tooltip">
            ?
          </div>
        )}
      </Tag>
      {isOpen && (
        <Popover
          anchorRef={triggerRef}
          onRequestClose={close}
          yAlign={yAlign}
          xAlign={xAlign}
          yOffset={10}
          width={width}
          trapFocus={true}
          role="alert"
          containerId={containerId}
        >
          {children}
        </Popover>
      )}
    </>
  )
}

HelpTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  triggerText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  forceClose: PropTypes.any,
  className: PropTypes.string,
  xAlign: PropTypes.string,
  yAlign: PropTypes.string,
  width: PropTypes.number,
  persistent: PropTypes.bool,
  showIcon: PropTypes.bool,
  delay: PropTypes.number,
  openOnClick: PropTypes.bool,
  onClick: PropTypes.func,
  tabIndex: PropTypes.any,
}
