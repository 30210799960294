import { forwardRef } from 'preact/compat'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DownCaret from 'Images/icons/caret-down.svg'
import styles from './ControlledSelect.module.scss'

const ControlledSelect = forwardRef(function ControlledSelect(
  { options, value, onChange, ariaLabel, disabled = false, size = 'medium' },
  ref,
) {
  const optionEls = options.map((o) => (
    <option key={`${o.value}-${o.label}`} value={o.value} disabled={o.disabled}>
      {o.label}
    </option>
  ))

  return (
    <label
      className={classNames(styles.container, styles[`size_${size}`], {
        [styles.disabled]: disabled,
      })}
      aria-label={ariaLabel}
    >
      <select
        ref={ref}
        disabled={disabled}
        className={styles.select}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
      >
        {optionEls}
      </select>
      <div className={styles.caret}>
        <DownCaret />
      </div>
    </label>
  )
})

ControlledSelect.defaultProps = {
  options: [{ label: '', value: null }],
  ariaLabel: null,
}

ControlledSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ControlledSelect
