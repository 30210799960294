import { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { forceLayout } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import ErrorBoundary from 'Components/ErrorBoundary'

function TransitionWrapper({
  children,
  className,
  show = true,
  scale = false,
  transform = '',
  height = false,
  opacity = true,
  tag = 'div',
  delay = 0,
  duration = 350,
  animateInitialRender = true,
  ...props
}) {
  const isInitialRender = useRef(true)
  const [shouldRender, setShouldRender] = useState(
    show && !animateInitialRender,
  )
  const { raf, st, cancelAllTimers } = useTimers()
  const Tag = tag
  const containerRef = useRef()
  const stylesRef = useRef(
    animateInitialRender || !isInitialRender.current
      ? {
          opacity: 0,
          ...(height
            ? { height: '0px', marginTop: '0px', overflow: 'hidden' }
            : {}),
        }
      : {},
  )
  const [styles, setStyles] = useState(stylesRef.current)

  const updateStyles = useCallback((newStyles, overwrite = false) => {
    if (overwrite) {
      stylesRef.current = newStyles
    } else {
      stylesRef.current = { ...stylesRef.current, ...newStyles }
    }
    setStyles(stylesRef.current)
  }, [])

  const animateIn = useCallback(() => {
    cancelAllTimers()

    let transformVal = transform ? `${transform}` : ''
    if (!transform && scale) transformVal = `scale(.9)`

    updateStyles(
      {
        opacity: opacity || height ? 0 : 1,
        transform: transformVal,
        height: height ? '0px' : '',
        overflow: height ? 'hidden' : '',
        marginTop: height ? '0px' : '',
      },
      true,
    )

    setShouldRender(true)

    forceLayout()

    if (height) {
      raf(() => {
        updateStyles({
          height: containerRef.current
            ? `${containerRef.current.scrollHeight}px`
            : '',
          marginTop: '',
          transition: `all ${duration}ms var(--ease)`,
        })

        st(
          () => {
            updateStyles(
              {
                opacity: 1,
                transition: `all ${duration}ms var(--ease)`,
                transitionDelay: `${delay}s`,
                transform: '',
                height: containerRef.current
                  ? `${containerRef.current.scrollHeight}px`
                  : '',
                willChange: 'opacity, transform, height',
              },
              true,
            )

            st(() => {
              updateStyles({
                height: '',
                overflow: '',
              })
            }, duration)
          },
          duration * 0.5 + delay,
        )
      })
    } else {
      raf(() => {
        setStyles({
          opacity: 1,
          transition: `all ${duration}ms var(--ease)`,
          transitionDelay: `${delay}s`,
          transform: '',
          willChange: 'opacity, transform',
        })
      })
    }
  }, [])

  const animateOut = useCallback(() => {
    if (!containerRef.current) return
    cancelAllTimers()

    let transformVal = transform ? `${transform}` : ''
    if (!transform && scale) transformVal = `scale(.9)`

    updateStyles(
      {
        opacity: `${opacity || height ? 0 : 1}`,
        transition: `all ${duration * 0.75}ms var(--ease)`,
        transform: transformVal,
        height:
          height && containerRef.current
            ? `${containerRef.current.offsetHeight}px`
            : '',
        overflow: height ? 'hidden' : '',
        willChange: height
          ? 'opacity, transform, height'
          : 'opacity, transform',
      },
      true,
    )

    if (height) {
      st(() => {
        if (!containerRef.current) return
        updateStyles(
          {
            opacity: 0,
            height: `0px`,
            marginTop: `0px`,
            overflow: `hidden`,
            transition: `all ${duration * 0.75}ms var(--ease)`,
            willChange: `opacity, transform, height`,
          },
          true,
        )

        st(() => {
          setShouldRender(false)
        }, duration * 0.75)
      }, duration * 0.5)
    } else {
      setShouldRender(false)
    }
  }, [])

  useEffect(() => {
    if (show && (animateInitialRender || !isInitialRender.current)) {
      animateIn()
    } else if (!isInitialRender.current) {
      animateOut()
    }
    isInitialRender.current = false
  }, [show])

  return shouldRender ? (
    <Tag ref={containerRef} style={styles} className={className} {...props}>
      {children}
    </Tag>
  ) : null
}

TransitionWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  show: PropTypes.bool,
  scale: PropTypes.bool,
  height: PropTypes.bool,
  transform: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
}

export default function SafeTransitionWrapper(props) {
  return (
    <ErrorBoundary>
      <TransitionWrapper {...props} />
    </ErrorBoundary>
  )
}
