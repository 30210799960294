import classNames from 'classnames'
import PropTypes from 'prop-types'
import UpgradeIcon from 'Images/upgrade-graphic.svg'
import styles from './UpgradeDowngradeIcon.module.scss'

export default function UpgradeDowngradeIcon({
  type = 'upgrade',
  animating = false,
}) {
  return (
    <div
      className={classNames(styles.container, styles[type], {
        [styles.animating]: animating,
      })}
    >
      <UpgradeIcon aria-hidden className={styles.icon} />
    </div>
  )
}

UpgradeDowngradeIcon.propTypes = {
  type: PropTypes.string,
  animating: PropTypes.bool,
}
