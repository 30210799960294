import { useRef } from 'react'
import PropTypes from 'prop-types'
import { v1 as uuid } from 'uuid'
import classnames from 'classnames'
import styles from './Switch.module.scss'

export default function Switch({
  className,
  onChange,
  before,
  after,
  disabled,
  checked,
  isLoading,
  loadingText,
  ariaLabel,
  toggle = false,
  values = ['on', 'off'],
}) {
  const uid = useRef(uuid())

  function onClick() {
    onChange(!checked)
  }

  // On/off
  const switchEl = (
    <div
      className={classnames(styles.container, className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
    >
      <button
        className={styles.button}
        aria-label={ariaLabel}
        aria-pressed={checked}
        disabled={isLoading || disabled}
        onClick={onClick}
      />
      {before && <div className={styles.before}>{before}</div>}
      <div className={styles.graphic} />
      {after && <div className={styles.after}>{after}</div>}
    </div>
  )

  // Toggle between options
  const toggleEl = (
    <div
      className={classnames(styles.container, styles.toggle, className, {
        [styles.checked]: checked,
      })}
      aria-label={ariaLabel}
    >
      {before && (
        <label
          className={classnames(styles.before, { [styles.current]: !checked })}
          htmlFor={`${uid.current}_1`}
        >
          {before}
        </label>
      )}
      <input
        id={`${uid.current}_1`}
        className={styles.input}
        type="radio"
        name={ariaLabel}
        value={values[0]}
        disabled={isLoading || disabled}
        checked={!checked}
        onChange={onClick}
      />
      <div className={styles.graphic} onClick={onClick} />
      {after && (
        <label
          className={classnames(styles.after, { [styles.current]: checked })}
          htmlFor={`${uid.current}_2`}
        >
          {after}
        </label>
      )}
      <input
        id={`${uid.current}_2`}
        className={styles.input}
        type="radio"
        name={ariaLabel}
        value={values[1]}
        disabled={isLoading || disabled}
        checked={checked}
        onChange={onClick}
      />
    </div>
  )

  const loadingEl = (
    <div className={styles.loading_container}>
      <l-squircle size={24}></l-squircle>
      {loadingText}
    </div>
  )

  /*
   *   Return
   */
  return isLoading ? loadingEl : toggle ? toggleEl : switchEl
}

Switch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  before: PropTypes.element,
  after: PropTypes.element,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  toggle: PropTypes.bool,
  values: PropTypes.array,
}
