import { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import { auth } from 'Fire/firebaseInit'
import {
  cancelSubscriptionSchedule,
  setDefaultPaymentMethod,
  deletePaymentMethod,
} from 'Helpers/services'
import { capitalize, isPopulated } from 'Helpers/utils'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import useFeatures from 'Hooks/useFeatures'
import Layout from 'Components/Layout'
import SmartButton from 'Components/SmartButton'
import PopupMenu from 'Components/PopupMenu'
import MenuItem from 'Components/MenuItem'
import HelpTooltip from 'Components/HelpTooltip'
import AddNewCardPopup from 'Components/AddNewCardPopup'
import ManageLoginsPopup from 'Components/ManageLoginsPopup'
import ChangeBillingEmailPopup from 'Components/ChangeBillingEmailPopup'
import ChangePasswordPopup from 'Components/ChangePasswordPopup'
import DeleteCurrentUserPopup from 'Components/DeleteCurrentUserPopup'
import TransitionWrapper from 'Components/TransitionWrapper'
import PageHeader from 'Components/PageHeader'
import SettingsNav from 'Components/SettingsNav'
import AddCardIcon from 'Images/icons/add-card.svg'
import TrashIcon from 'Images/icons/trash.svg'
import CardIcon from 'Images/icons/card.svg'
import PencilIcon from 'Images/icons/pencil.svg'
import ExternalLinkIcon from 'Images/icons/external-link.svg'
import GoogleLogo from 'Images/google.svg'
import GithubLogo from 'Images/github.svg'
import EmailIcon from 'Images/icons/email-login.svg'
import styles from './Account.module.scss'

const displayPlans = ['Free', 'Personal', 'Business', 'Enterprise']

export default function Account() {
  const windowSize = useWindowSize()
  const appState = useSlice(
    'user',
    'plans',
    'addNotification',
    'removeNotification',
    'updateUser',
  )
  const features = useFeatures()
  const {
    email,
    billingEmail,
    plan,
    stripeCustomerId,
    paymentMethods,
    payments,
    defaultPaymentMethod,
    stripeSubscriptionId,
    stripeSubscriptionInterval,
    stripeNextBill,
    stripeBalance,
    markedForDowngrade,
    planAfterDowngrade,
    periodAfterDowngrade,
  } = appState.user
  const { price: currPriceObj, title: currentPlanTitle } =
    appState.plans?.[plan] || {}
  const [showNewCardPopup, setShowNewCardPopup] = useState(false)
  const [showManageLoginsPopup, setShowManageLoginsPopup] = useState(false)
  const [showUpdateBillingEmailPopup, setShowUpdateBillingEmailPopup] =
    useState(false)
  const [showUpdatePasswordPopup, setShowUpdatePasswordPopup] = useState(false)
  const [showDeleteUserPopup, setShowDeleteUserPopup] = useState(false)
  const [cancellingDowngrade, setCancellingDowngrade] = useState(false)
  const [showAllReceipts, setShowAllReceipts] = useState(false)
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState([])
  const addCardButtonRef = useRef(null)
  const manageLoginsButtonRef = useRef(null)
  const changeBillingEmailButtonRef = useRef(null)
  const changePasswordButtonRef = useRef(null)
  const deleteCurrentUserButtonRef = useRef(null)

  function getNextPlan() {
    const currentIndex = displayPlans.indexOf(plan)
    if (currentIndex === -1) return 'Business'
    const nextIndex =
      currentIndex + 1 >= displayPlans.length
        ? displayPlans.length - 1
        : currentIndex + 1
    return displayPlans[nextIndex]
  }

  async function deleteCard({ cardId, brand, lastFour }) {
    await deletePaymentMethod(cardId)

    appState.addNotification({
      duration: 3.5,
      text: `Deleted ${capitalize(brand)} ending ${lastFour}`,
    })
  }

  function onUpdateLogins() {
    appState.addNotification({
      duration: 3.5,
      text: `Login methods updated`,
    })
    setShowManageLoginsPopup(false)
  }

  function onUpdateBillingEmail() {
    appState.addNotification({
      duration: 3.5,
      text: `Billing email updated`,
    })
    setShowUpdateBillingEmailPopup(false)
  }

  function onUpdatePassword() {
    appState.addNotification({
      duration: 3.5,
      text: `Password updated`,
    })
    setShowUpdatePasswordPopup(false)
  }

  // There must always be a default payment method
  useEffect(() => {
    if (isPopulated(paymentMethods) && !defaultPaymentMethod) {
      try {
        setDefaultPaymentMethod(
          stripeCustomerId,
          Object.values(paymentMethods)[0].id,
        )
      } catch (error) {
        console.log(error)
      }
    }
  }, [paymentMethods, defaultPaymentMethod, stripeCustomerId])

  /*
   *   Set Default button
   */
  function SetDefaultButton({ pmId }) {
    const [status, setStatus] = useState()
    const timeoutRef = useRef()
    const timeoutRef2 = useRef()
    let timeoutFinished = false
    let succeeded = false

    async function handleClick() {
      if (status) return
      setStatus('LOADING')

      timeoutRef.current = setTimeout(() => {
        timeoutFinished = true
        if (succeeded) {
          setStatus('SUCCESS')
        }
      }, 750)

      try {
        await setDefaultPaymentMethod(stripeCustomerId, pmId)
        succeeded = true
        if (timeoutFinished) {
          setStatus('SUCCESS')
        }
        appState.addNotification(
          {
            duration: 10,
            text: `Your ${capitalize(paymentMethods[pmId].brand)} ending ${
              paymentMethods[pmId].lastFour
            } will now be used for all payments`,
          },
          'DEFAULT_CARD',
        )

        appState.updateUser({ defaultPaymentMethod: pmId })
      } catch (err) {
        console.log(err)
        setStatus('ERROR')
      }
    }

    useEffect(() => {
      if (!status || status === 'LOADING') return
      clearTimeout(timeoutRef2.current)
      timeoutRef2.current = setTimeout(() => {
        setStatus(null)
      }, 1800)

      return () => {
        clearTimeout(timeoutRef2.current)
      }
    }, [status])

    useEffect(() => clearTimeout(timeoutRef.current), [])

    return (
      <MenuItem
        disabled={
          pmId === defaultPaymentMethod || loadingPaymentMethods.includes(pmId)
        }
        loading={status === 'LOADING'}
        success={status === 'SUCCESS'}
        error={status === 'ERROR'}
        onClick={handleClick}
      >
        <CardIcon />
        Set as Default
      </MenuItem>
    )
  }

  const paymentMethodEls = paymentMethods
    ? Object.values(paymentMethods).map(
        ({ id, name, lastFour, expMonth, expYear, brand }) => {
          return (
            <div
              key={id}
              className={classnames(
                styles.payment_method,
                styles[`brand_${brand}`],
              )}
            >
              <div className={styles.payment_method__logo}></div>
              <div className={styles.payment_method__text}>
                <div className={styles.payment_method__name}>
                  <div className={styles.payment_method__name_text}>{name}</div>
                  {id === defaultPaymentMethod && (
                    <div className={styles.payment_method__default}>
                      Default
                    </div>
                  )}
                </div>
                <div className={styles.payment_method__details}>
                  XXXX-{lastFour} &ensp; Exp. {expMonth}/{expYear}
                </div>
              </div>
              <div className={styles.payment_method__right}>
                <PopupMenu
                  className={styles.payment_method__menu}
                  containerId={windowSize.width > 600 ? 'content' : 'root'}
                  size="large"
                  width={180}
                >
                  <SetDefaultButton pmId={id} />
                  <MenuItem
                    confirm={true}
                    confirmText="Confirm"
                    disabled={loadingPaymentMethods.includes(id)}
                    onClick={() => {
                      if (
                        currPriceObj?.month?.amount > 0 &&
                        paymentMethodEls?.length < 2
                      ) {
                        appState.addNotification({
                          duration: 10,
                          title: 'At least one saved card is required',
                          text: (
                            <>
                              <button onClick={() => setShowNewCardPopup(true)}>
                                Add another card
                              </button>{' '}
                              before deleting this one{' '}
                            </>
                          ),
                        })
                        return
                      }
                      if (id === defaultPaymentMethod && plan !== 'Free') {
                        appState.addNotification({
                          duration: 10,
                          title: 'You cannot delete your default card',
                          text: 'Set a new default payment method before deleting this one',
                        })
                        return
                      }
                      setLoadingPaymentMethods([...loadingPaymentMethods, id])
                      deleteCard({
                        cardId: id,
                        brand,
                        lastFour,
                      })
                    }}
                  >
                    <TrashIcon /> Delete
                  </MenuItem>
                </PopupMenu>
              </div>
              {loadingPaymentMethods.includes(id) && (
                <div className={styles.payment_method__loader}>
                  <l-zoomies
                    size={80}
                    color="var(--color-text)"
                    speed={1.2}
                    stroke={3}
                  ></l-zoomies>
                </div>
              )}
            </div>
          )
        },
      )
    : null

  const currentPrice =
    plan === 'Free' ? 0 : currPriceObj?.[stripeSubscriptionInterval]?.amount

  const nextPayment = stripeNextBill
    ? new Date(stripeNextBill).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '-'

  const planIsUpdating = appState.user?.planIsUpdating

  let status = 'Active'
  if (markedForDowngrade) {
    status = (
      <HelpTooltip width={270} triggerText="Downgrading">
        <div className={styles.tooltip_text}>
          <h1>Marked for downgrade</h1>
          Your account will downgrade to the{' '}
          <code>
            {planAfterDowngrade}{' '}
            {planAfterDowngrade === 'Free'
              ? ''
              : capitalize(
                  periodAfterDowngrade === 'year' ? 'Annual' : 'Monthly',
                )}
          </code>{' '}
          plan at the end of your current billing cycle: {nextPayment}
        </div>
      </HelpTooltip>
    )
  }
  if (planIsUpdating) {
    status = 'Updating...'
  }

  /*
   *   Payment receipt els
   */
  const paymentEls = isPopulated(payments)
    ? payments
        .sort((a, b) =>
          b.created.toString().localeCompare(a.created.toString()),
        )
        .filter((payment, i) => (showAllReceipts ? true : i < 6))
        .map(
          ({
            id,
            created,
            brand,
            lastFour,
            amount,
            amountRefunded,
            receiptUrl,
          }) => {
            const formattedDate = new Date(created * 1000).toLocaleDateString(
              'en-US',
              { dateStyle: 'short' },
            )

            let refundLabel = null

            if (amountRefunded) {
              if (amountRefunded < amount) {
                refundLabel = 'Partial Refund'
              } else {
                refundLabel = 'Refunded'
              }
            }

            return (
              <div
                key={id}
                className={classnames(styles.receipt, {
                  [styles.refunded]: !!amountRefunded,
                })}
              >
                <div className={styles.receipt__left}>
                  <div className={styles.receipt__date}>{formattedDate}</div>
                  <div className={styles.receipt__card}>
                    {capitalize(brand)} XXXX-{lastFour}
                  </div>
                  {refundLabel && (
                    <div className={styles.receipt__refund_label}>
                      {refundLabel}
                    </div>
                  )}
                </div>
                <div className={styles.receipt__right}>
                  <div className={styles.receipt__amount}>
                    ${(amount / 100).toFixed(2)}
                  </div>
                  {amountRefunded && (
                    <div className={styles.receipt__refund}>
                      ${((amount - amountRefunded) / 100).toFixed(2)}
                    </div>
                  )}
                  {receiptUrl && (
                    <a
                      href={receiptUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.receipt__button}
                    >
                      <ExternalLinkIcon /> <span>Receipt</span>
                    </a>
                  )}
                </div>
              </div>
            )
          },
        )
    : null

  /*
   *   Credit
   */
  const creditText =
    stripeBalance < 0 ? `$${Math.abs(stripeBalance / 100).toFixed(2)}` : null

  /*
   *   Auth emails
   */
  const loginProvidersByEmail = auth?.currentUser?.providerData.reduce(
    (acc, curr) => {
      if (!acc?.[curr?.email]) {
        acc[curr.email] = []
      }
      acc[curr.email].push(curr.providerId)
      return acc
    },
    {},
  )

  const loginProviderEls = Object.entries(loginProvidersByEmail)
    .sort((a, b) => {
      if (a[1].includes('password') && !b[1].includes('password')) return -1
      if (b[1].includes('password') && !a[1].includes('password')) return 1
      return 0
    })
    .map(([email, providerIds]) => {
      return (
        <div className={styles.login_provider} key={email}>
          {(providerIds.length > 1 ||
            Object.entries(loginProvidersByEmail).length > 1) &&
            providerIds.includes('password') && (
              <HelpTooltip
                className={classnames(
                  styles.login_provider__icon,
                  styles.login_provider__icon_email,
                )}
                width="140"
                showIcon={false}
                xAlign="center"
                delay={0}
                triggerText={<EmailIcon />}
              >
                <div className={styles.tooltip_small}>Email & Password</div>
              </HelpTooltip>
            )}
          {providerIds.includes('github.com') && (
            <HelpTooltip
              className={styles.login_provider__icon}
              width="110"
              showIcon={false}
              xAlign="center"
              delay={0}
              triggerText={<GithubLogo />}
            >
              <div className={styles.tooltip_small}>GitHub OAuth</div>
            </HelpTooltip>
          )}
          {providerIds.includes('google.com') && (
            <HelpTooltip
              className={styles.login_provider__icon}
              width="110"
              showIcon={false}
              xAlign="center"
              delay={0}
              triggerText={<GoogleLogo />}
            >
              <div className={styles.tooltip_small}>Google OAuth</div>
            </HelpTooltip>
          )}
          <div className={styles.login_provider__email}>{email}</div>
          {Object.entries(loginProvidersByEmail).length < 2 ? (
            <button
              ref={manageLoginsButtonRef}
              aria-label="Manage logins"
              onClick={() => setShowManageLoginsPopup(true)}
              className={styles.edit_button}
            >
              <PencilIcon />
            </button>
          ) : null}
        </div>
      )
    })

  const hasPassword = auth.currentUser.providerData.reduce((acc, curr) => {
    if (curr.providerId === 'password') return true
    return acc
  }, false)

  let accountEmailLabel = 'Account email'

  if (loginProviderEls.length > 1) {
    accountEmailLabel = (
      <>
        <HelpTooltip width={300} triggerText="Logins">
          <div className={styles.tooltip_text}>
            <h1>Multiple logins</h1>
            <p>You have multiple login emails configured for this account. </p>

            <p>
              Your primary account email is <em>{auth.currentUser?.email}</em>
            </p>

            <p>All account notifications will be sent to that address.</p>
          </div>
        </HelpTooltip>
        <button
          ref={manageLoginsButtonRef}
          aria-label="Manage logins"
          onClick={() => setShowManageLoginsPopup(true)}
          className={styles.edit_button}
        >
          <PencilIcon />
        </button>
      </>
    )
  }

  if (
    loginProviderEls.length < 2 &&
    !Object.keys(loginProvidersByEmail).includes(auth.currentUser.email)
  ) {
    accountEmailLabel = (
      <>
        <HelpTooltip width={300} triggerText="Logins">
          <div className={styles.tooltip_text}>
            <h1>Mismatched emails</h1>
            <p>
              Your primary account email is <em>{auth.currentUser?.email}</em>.
              All account notifications will be sent there.
            </p>
            <p>
              The account you use to log in is{' '}
              <em>{Object.keys(loginProvidersByEmail)[0]}</em>
            </p>
            <p>
              This mismatch is fine, but if you&apos;re confused or need
              support, just send us an email:{' '}
              <a href="mailto:support@behold.so">support@behold.so</a>
            </p>
          </div>
        </HelpTooltip>
      </>
    )
  }

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Account | Behold">
      <main className={styles.container}>
        <PageHeader
          title="Account settings"
          bottomMargin={!features.has('api')}
        />

        <SettingsNav />

        <TransitionWrapper>
          <div className={styles.inner}>
            {/* Basic info */}
            <section className={styles.info}>
              <div>
                <h2 className={styles.label}>{accountEmailLabel}</h2>
                <div className={styles.login_providers}>{loginProviderEls}</div>
              </div>
              <div>
                <h2 className={styles.label}>Billing email</h2>
                <div className={styles.text}>
                  {billingEmail || email}
                  <button
                    ref={changeBillingEmailButtonRef}
                    aria-label="Change billing email"
                    onClick={() => setShowUpdateBillingEmailPopup(true)}
                    className={styles.edit_button}
                  >
                    <PencilIcon />
                  </button>
                </div>
              </div>
              <div>
                <h2 className={styles.label}>Password</h2>
                <div className={styles.text}>
                  <div
                    className={classnames({
                      [styles.text__password]: hasPassword,
                    })}
                  >
                    {hasPassword ? '************' : '—'}
                  </div>{' '}
                  {hasPassword && (
                    <button
                      ref={changePasswordButtonRef}
                      aria-label="Change password"
                      onClick={() => setShowUpdatePasswordPopup(true)}
                      className={styles.edit_button}
                    >
                      <PencilIcon />
                    </button>
                  )}
                </div>
              </div>
            </section>

            {/* Plan */}
            <section className={styles.plan} id="plan">
              <h1 className={styles.label}>Current plan</h1>
              {!plan && (
                <div className={styles.plan_loader}>
                  <div></div>
                </div>
              )}
              {plan && (
                <div className={styles.plan__details}>
                  <div className={styles.plan__detail}>
                    <div className={styles.label}>Plan</div>
                    <div className={styles.plan__text}>
                      {planIsUpdating ? (
                        <l-dot-pulse
                          color="var(--color-text-light)"
                          speed={1}
                          size={30}
                        ></l-dot-pulse>
                      ) : (
                        currentPlanTitle
                      )}
                    </div>
                  </div>
                  <div className={styles.plan__detail}>
                    <div className={styles.label}>Price</div>
                    <div className={styles.plan__text}>
                      {planIsUpdating ? (
                        '-'
                      ) : (
                        <>
                          {' '}
                          ${currentPrice}
                          {stripeSubscriptionInterval &&
                            ` / ${stripeSubscriptionInterval}`}
                        </>
                      )}
                    </div>
                  </div>
                  {!markedForDowngrade && (
                    <div className={styles.plan__detail}>
                      <div className={styles.label}>Next payment</div>
                      <div className={styles.plan__text}>
                        {planIsUpdating ? '-' : nextPayment}
                      </div>
                    </div>
                  )}
                  {markedForDowngrade && (
                    <div className={styles.plan__detail}>
                      <div className={styles.label}>Downgrade on</div>
                      <div className={styles.plan__text}>
                        {planIsUpdating ? '-' : nextPayment}
                      </div>
                    </div>
                  )}
                  <div className={styles.plan__detail}>
                    <div className={styles.label}>Status</div>
                    <div className={styles.plan__text}>{status}</div>
                  </div>
                  {plan && !markedForDowngrade && (
                    <SmartButton
                      to={`/choose-plan/${getNextPlan()}`}
                      color={plan === 'Free' ? 'dark_green' : 'grey'}
                      className={styles.plan__button}
                    >
                      {plan === 'Free' ? 'Upgrade' : 'Change'}
                    </SmartButton>
                  )}
                  {plan && markedForDowngrade && stripeSubscriptionId && (
                    <SmartButton
                      isLoading={cancellingDowngrade}
                      onClick={async () => {
                        setCancellingDowngrade(true)
                        await cancelSubscriptionSchedule(stripeSubscriptionId)
                        setCancellingDowngrade(false)
                      }}
                      color="algae"
                      className={styles.plan__button}
                    >
                      Cancel downgrade
                    </SmartButton>
                  )}
                </div>
              )}
            </section>

            {/* Credit */}
            {creditText && (
              <TransitionWrapper tag="section" className={styles.credit}>
                <h1 className={styles.label}>
                  <HelpTooltip width={280} triggerText="Account Credit">
                    <div className={styles.tooltip_text}>
                      <h1>Account credit</h1>
                      Payments will be drawn from your account credit first
                      before your card is charged the remainder.
                    </div>
                  </HelpTooltip>
                </h1>
                <div className={styles.credit__text}>{creditText}</div>
              </TransitionWrapper>
            )}

            {/* Payment Methods */}
            <section className={styles.payment}>
              <header className={styles.payment__header}>
                <h1 className={styles.label}>Saved cards</h1>
                {isPopulated(paymentMethodEls) && (
                  <button
                    ref={addCardButtonRef}
                    className={styles.add_card_link}
                    onClick={() => setShowNewCardPopup(true)}
                  >
                    <span>+ Add card</span>
                  </button>
                )}
              </header>
              {paymentMethodEls}
              {isPopulated(paymentMethodEls, false) && (
                <div className={styles.card}>
                  <button
                    ref={addCardButtonRef}
                    className={styles.add_card_button}
                    onClick={() => setShowNewCardPopup(true)}
                  >
                    <AddCardIcon /> Add a card
                  </button>
                </div>
              )}
            </section>

            {/* Payment Receipts */}
            {paymentEls && (
              <section className={styles.receipts}>
                <header className={styles.receipts__header}>
                  <h1 className={styles.label}>Payment history</h1>
                </header>
                {paymentEls}
                {payments.length > 6 && (
                  <footer className={styles.receipts__footer}>
                    <button
                      className={styles.receipts__show_all}
                      onClick={() => setShowAllReceipts(!showAllReceipts)}
                    >
                      {showAllReceipts
                        ? 'Show fewer payments'
                        : `Show all ${payments.length} payments`}
                    </button>
                  </footer>
                )}
              </section>
            )}

            <section className={styles.delete}>
              <h1 className={styles.label}>Danger zone</h1>
              <div className={styles.delete__inner}>
                <button
                  ref={deleteCurrentUserButtonRef}
                  onClick={() => setShowDeleteUserPopup(true)}
                  className={styles.delete__button}
                >
                  Delete account
                </button>
              </div>
            </section>
          </div>
        </TransitionWrapper>
      </main>

      {showNewCardPopup && (
        <AddNewCardPopup
          stripeId={stripeCustomerId}
          onRequestClose={() => setShowNewCardPopup(false)}
          closeFocusRef={addCardButtonRef}
        />
      )}
      {showManageLoginsPopup && (
        <ManageLoginsPopup
          onSuccess={onUpdateLogins}
          onRequestClose={() => setShowManageLoginsPopup(false)}
          closeFocusRef={manageLoginsButtonRef}
        />
      )}
      {showUpdateBillingEmailPopup && (
        <ChangeBillingEmailPopup
          onSuccess={onUpdateBillingEmail}
          onRequestClose={() => setShowUpdateBillingEmailPopup(false)}
          closeFocusRef={changeBillingEmailButtonRef}
        />
      )}
      {showUpdatePasswordPopup && (
        <ChangePasswordPopup
          onSuccess={onUpdatePassword}
          onRequestClose={() => setShowUpdatePasswordPopup(false)}
          closeFocusRef={changePasswordButtonRef}
        />
      )}
      {showDeleteUserPopup && (
        <DeleteCurrentUserPopup
          onRequestClose={() => setShowDeleteUserPopup(false)}
          closeFocusRef={deleteCurrentUserButtonRef}
        />
      )}
    </Layout>
  )
}
