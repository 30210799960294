import { useState, useCallback } from 'react'
import useTimers from 'Hooks/useTimers'
import { forceLayout } from 'Helpers/utils'

export default function useTransition(
  durationOut,
  onOpen,
  onClose,
  onBeforeClose,
) {
  const [isActive, setIsActive] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const { st, raf, cancelAllTimers } = useTimers()

  const show = useCallback(() => {
    if (isActive && isVisible) return

    cancelAllTimers()
    setIsActive(true)
    setIsHidden(false)
    setIsVisible(false)

    forceLayout()

    raf(() => setIsVisible(true))

    if (onOpen) raf(onOpen)
  }, [cancelAllTimers, isActive, isVisible, onOpen, raf])

  const hide = useCallback(() => {
    cancelAllTimers()
    setIsHidden(true)
    if (onBeforeClose) onBeforeClose()

    st(() => {
      setIsActive(false)
      setIsVisible(false)
      setIsHidden(false)
      if (onClose) onClose()
    }, durationOut)
  }, [cancelAllTimers, durationOut, onClose, st])

  return { show, hide, isActive, isVisible, isHidden }
}
