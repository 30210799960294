import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { createPortal } from 'preact/compat'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSlice } from 'State'
import XIcon from 'Images/icons/x.svg'
import styles from './Bulletin.module.scss'

function Message({ id, title, text, duration = false }) {
  const { removeNotification } = useSlice('removeNotification')
  const [isLoaded, setIsLoaded] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const toRef = useRef(null)
  const containerRef = useRef(null)

  const close = useCallback(() => {
    setIsHidden(true)
    setTimeout(() => {
      removeNotification(id)
    }, 400)
  }, [id, removeNotification])

  useEffect(() => {
    setIsLoaded(true)
    if (!toRef.current && duration) {
      toRef.current = setTimeout(close, duration * 1000)
    }

    return () => clearTimeout(toRef.current)
  }, [close, duration])

  /*
   *   Return
   */
  return (
    <aside
      ref={containerRef}
      style={{
        height:
          isLoaded && !isHidden
            ? containerRef.current.scrollHeight + 'px'
            : null,
      }}
      className={classnames(styles.message, {
        [styles.visible]: isLoaded,
        [styles.hidden]: isHidden,
      })}
    >
      <div className={styles.inner}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {text && <div className={styles.text}>{text}</div>}
        <button className={styles.close} onClick={close}>
          <XIcon className={styles.close__x} />
          {duration && (
            <svg
              className={styles.progress}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 26 26"
              enableBackground="new 0 0 26 26"
            >
              <path
                style={{ animationDuration: duration + 's' }}
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M25,13c0,6.6-5.4,12-12,12S1,19.6,1,13S6.4,1,13,1C19.6,1,24.9,6.4,25,13"
              />
            </svg>
          )}
        </button>
      </div>
    </aside>
  )
}

Message.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  duration: PropTypes.number,
}

export default function Bulletin() {
  const { notifications } = useSlice('notifications')

  const notificationEls = useMemo(
    () =>
      Array.from(notifications.values()).map((item) => (
        <Message
          key={item.id}
          id={item.id}
          title={item.title}
          text={item.text}
          duration={item.duration}
        />
      )),
    [notifications]
  )

  /*
   *   Return
   */
  return createPortal(
    <div className={classnames(styles.container)}>
      <div role="alert">{notificationEls}</div>
    </div>,
    document.body
  )
}
