import { useState, useCallback, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { applyActionCode } from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useStorage from 'Hooks/useStorage'
import TransitionWrapper from 'Components/TransitionWrapper'
import VerifyEmailIcon from 'Images/icons/email-verified-solid.svg'
import Logo from 'Images/logo.svg'
import styles from './VerifyEmail.module.scss'

export default function VerifyEmail() {
  const appState = useSlice('user', 'updateUser')
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [{ SELECTED_PLAN }, setStorage] = useStorage()
  const [isLoaded, setIsLoaded] = useState(null)
  const [error, setError] = useState(null)
  const [successful, setSuccessful] = useState(false)
  const [selectedPlan, selectedInterval] = SELECTED_PLAN
    ? SELECTED_PLAN.split('-')
    : [null, null]
  const navigate = useNavigate()
  const { raf } = useTimers()

  /*
   *   Verify email
   */
  const verifyEmail = useCallback(
    (oobCode) => {
      // Try to apply the email verification code.
      applyActionCode(auth, oobCode)
        .then(async () => {
          // Logged in
          if (auth.currentUser) {
            setStorage('IS_FIRST_LOGIN', true)
            await auth.currentUser.getIdToken(true)
            await auth.currentUser.reload()

            if (SELECTED_PLAN) {
              setStorage('SELECTED_PLAN', null)
              location.href = `/choose-plan/${selectedPlan}-${selectedInterval}/confirm`
            } else {
              navigate('/')
            }
          } else {
            setStorage('IS_FIRST_LOGIN', true)
            setSuccessful(true)
          }
        })
        .catch(function (error) {
          switch (error.code) {
            case 'auth/invalid-action-code':
              setError(
                'Invalid verification link. It may have expired, or was already used.',
              )
              break
          }
        })
    },
    [SELECTED_PLAN, appState, selectedInterval, selectedPlan, setStorage],
  )

  /*
   *   UseEffect
   */
  useEffect(() => {
    if (
      searchParams.get('mode') === 'verifyEmail' &&
      searchParams.has('oobCode') &&
      !auth?.currentUser?.emailVerified
    ) {
      verifyEmail(searchParams.get('oobCode'))
    } else {
      navigate('/')
    }

    raf(() => setIsLoaded(true))
  }, [])

  /*
   *   Return
   */
  return (
    <main
      className={classnames(styles.container, { [styles.loaded]: isLoaded })}
    >
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>

        {error && <div className={styles.error}>{error}</div>}

        {successful && (
          <TransitionWrapper scale className={styles.message}>
            <p>
              <VerifyEmailIcon />
            </p>
            <h2 className={styles.message__title}>Success! Email verified.</h2>
            <Link to="/" className={styles.message__button}>
              Log in
            </Link>
          </TransitionWrapper>
        )}

        {searchParams.has('mode') && !error && !successful && (
          <div className={styles.loading}>
            <l-squircle
              stroke="4"
              size={35}
              color="var(--color-text-light)"
              speed=".65"
            ></l-squircle>
          </div>
        )}
      </div>
    </main>
  )
}
