import { useState, useEffect } from 'react'
import { forwardRef } from 'preact/compat'
import PropTypes from 'prop-types'
import { forceLayout } from 'Helpers/utils'
import useTimers from 'Hooks/useTimers'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './SmartButton.module.scss'

const SmartButton = forwardRef(function SmartButton(
  {
    to,
    children,
    onClick,
    color = 'algae',
    size = 'large',
    fullWidth,
    confirm,
    confirmText,
    confirmColor = 'var(--color-blue)',
    isLoading = false,
    className,
    disabled,
    type = 'button',
  },
  ref,
) {
  const [isConfirming, setConfirming] = useState(false)
  const [confirmIsVisible, setConfirmIsVisible] = useState(false)
  const { st, raf } = useTimers()

  /*
   *   Handle click
   */
  function handleClick(evt) {
    if (!onClick) return
    evt.preventDefault()

    if (confirm && !isConfirming) {
      setConfirming(true)
      forceLayout()
      st(() => {
        window.addEventListener('click', handleWindowClick)
        raf(() => setConfirmIsVisible(true))
      }, 10)
    } else {
      onClick()
    }
  }

  /*
   *   Handle WIndow click
   */
  function handleWindowClick() {
    window.removeEventListener('click', handleWindowClick)
    setConfirmIsVisible(false)
    setConfirming(false)
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, []) // eslint-disable-line

  const containerClasses = classnames(
    styles.container,
    styles[`color_${color}`],
    styles[`size_${size}`],
    className,
    {
      [styles.confirming]: isConfirming,
      [styles.full_width]: fullWidth,
      [styles.loading]: isLoading,
    },
  )

  const confirmClasses = classnames(styles.confirm, {
    [styles.visible]: confirmIsVisible,
  })

  let Tag = to ? Link : 'button'
  if (to && to.startsWith('mailto:')) {
    Tag = 'a'
  }
  const href =
    to && (to.startsWith('mailto:') || to.startsWith('http')) ? to : null

  let loaderColor = 'var(--color-text-light)'

  if (color === 'green' || color === 'dark') loaderColor = '#fff'
  if (color === 'light_terracotta') loaderColor = 'var(--color-terracotta)'
  if (color === 'light_blue') loaderColor = 'var(--color-blue)'

  return (
    <Tag
      ref={ref}
      to={to}
      href={href}
      type={type}
      className={containerClasses}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
      {isConfirming && (
        <div
          className={confirmClasses}
          style={{ backgroundColor: confirmColor }}
        >
          <div className={styles.confirm__inner}>
            {confirmText || 'Confirm'}
          </div>
        </div>
      )}

      <div className={styles.loader} aria-live="assertive">
        {isLoading && (
          <>
            loading
            <l-dot-pulse color={loaderColor} speed={1} size={30}></l-dot-pulse>
          </>
        )}
      </div>
    </Tag>
  )
})

SmartButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  confirm: PropTypes.bool,
  confirmText: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default SmartButton
