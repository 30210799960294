let root = document
let containerEl = null
let tabbableEls = new Set()
let excludedEls = new Set()
let mutationObserver = new MutationObserver(handleMutation)
let trapped = false

function getTabbableEls(container) {
  const treeWalker = document.createTreeWalker(
    container,
    NodeFilter.SHOW_ELEMENT,
    {
      acceptNode: function (node) {
        return node.tabIndex >= 0
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_SKIP
      },
    },
  )
  const tabbable = []
  let currNode = null
  while ((currNode = treeWalker.nextNode())) {
    let include = true

    if (include) {
      tabbable.push(currNode)
    }
  }
  return tabbable
}

function checkVisibility(el) {
  return new Promise((resolve) => {
    const ob = new IntersectionObserver((entries) => {
      resolve(entries[0].isIntersecting)
      ob.disconnect()
    })
    ob.observe(el)
  })
}

function isTabbable(el) {
  return new Promise((resolve) => {
    if (el.disabled) {
      resolve(false)
      return
    }
    checkVisibility(el).then((isVisible) => {
      if (!isVisible) {
        resolve(false)
      } else {
        resolve(
          [...excludedEls].every((excludedEl) => !excludedEl.contains(el)),
        )
      }
    })
  })
}

async function getCurrentTabbable() {
  const promiseArray = await Promise.all(
    [...tabbableEls].map(async (el) => {
      const isTababble = await isTabbable(el)
      return isTababble ? el : false
    }),
  )
  return promiseArray.filter((item) => !!item)
}

async function advance() {
  const currentTabbable = await getCurrentTabbable()
  let next = currentTabbable.indexOf(root.activeElement) + 1
  if (next > currentTabbable.length - 1 || next < 0) {
    next = 0
  }

  currentTabbable[next]?.focus()
}

async function retreat() {
  const currentTabbable = await getCurrentTabbable()
  let next = currentTabbable.indexOf(root.activeElement) - 1
  if (next < 0) {
    next = currentTabbable.length - 1
  }

  currentTabbable[next]?.focus()
}

function handleMutation(records) {
  const shouldUpdate = records.some((record) => {
    if (record.type === 'attributes') return true

    return [...record.addedNodes, ...record.removedNodes].some(
      (node) => getTabbableEls(node).length > 0,
    )
  })

  if (shouldUpdate) {
    updateTabbableEls({ include: [containerEl], merge: true })
  }
}

function handleKeydown(evt) {
  const keyList = ['Tab']

  if (
    keyList.includes(evt.code) &&
    !evt.ctrlKey &&
    !evt.altKey &&
    !evt.metaKey
  ) {
    evt.preventDefault()

    if (evt.code === 'Tab' && evt.shiftKey) {
      retreat()
    } else {
      advance()
    }
  }
}

export function updateTabbableEls({
  include = [],
  exclude = [],
  merge = false,
}) {
  if (merge) {
    include.forEach((el) => {
      excludedEls.delete(el)
      getTabbableEls(el).forEach((el) => {
        if (!tabbableEls.has(el)) {
          tabbableEls.add(el)
        }
      })
    })
    exclude.forEach((el) => excludedEls.add(el))
  } else {
    tabbableEls = new Set(include.flatMap((el) => getTabbableEls(el)))
    excludedEls = new Set(exclude)
  }
}

export async function trapFocus({ el, exclude = [], initialFocusIndex = 0 }) {
  containerEl = el
  if (isShadowRoot(el)) {
    root = el
  } else {
    root = document
  }
  trapped = true
  updateTabbableEls({ include: [el], exclude })
  document.addEventListener('keydown', handleKeydown)
  mutationObserver.observe(el, {
    subtree: true,
    attributes: true,
    attributeFilter: ['disabled'],
    childList: true,
  })

  const currentTabbable = await getCurrentTabbable()

  currentTabbable[initialFocusIndex]?.focus()
}

export function releaseFocus(newFocusEl) {
  if (!trapped) return
  trapped = false
  newFocusEl = newFocusEl ?? document.body
  document.removeEventListener('keydown', handleKeydown)
  mutationObserver.disconnect()
  tabbableEls.clear()
  excludedEls.clear()
  containerEl = null
  if (newFocusEl) newFocusEl.focus()
}

const isShadowRoot = (el) => el instanceof ShadowRoot
