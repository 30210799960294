import PropTypes from 'prop-types'
import { useState } from 'react'
import AuthLogin from 'Components/AuthLogin'
import AuthSignUp from 'Components/AuthSignUp'
import AuthResetPassword from 'Components/AuthResetPassword'
import ErrorBoundary from 'Components/ErrorBoundary'

function Auth({ form }) {
  const [email, setEmail] = useState('')

  switch (form) {
    case 'log-in':
      return <AuthLogin email={email} setEmail={setEmail} />
    case 'sign-up':
      return <AuthSignUp email={email} setEmail={setEmail} />
    case 'reset-password':
      return <AuthResetPassword email={email} setEmail={setEmail} />
  }
}

Auth.propTypes = {
  form: PropTypes.string,
}

export default function SafeAuth(props) {
  return (
    <ErrorBoundary>
      <Auth {...props} />
    </ErrorBoundary>
  )
}
