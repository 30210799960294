import { useState, useRef, useEffect } from 'react'

import { initStripe } from 'Helpers/stripe'

export default function useStripe() {
  const [stripeIsReady, setStripeIsReady] = useState(false)
  const stripeRef = useRef(null)

  /*
   *   initialize Strip
   */
  useEffect(() => {
    async function initialize() {
      stripeRef.current = await initStripe()
      setStripeIsReady(true)
    }
    initialize()
  }, [])

  return { stripe: stripeRef.current, stripeIsReady }
}
