import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useWindowSize from 'Hooks/useWindowSize'
import Popover from 'Components/Popover'
import CaretIcon from 'Images/icons/caret-down.svg'
import styles from './OptionBox.module.scss'

export default function OptionBox({
  options,
  onChange,
  value,
  onOptionIn,
  onOptionOut,
  onOpen,
  onClose,
  ariaLabel,
  numColumns = 2,
  width = 400,
  xAlign = 'center',
  yAlign = 'top',
  size = 'medium',
  optionSize = 'medium',
}) {
  const windowSize = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef()
  const optionsIndex = options.reduce((acc, curr) => {
    return { ...acc, [curr.value]: curr }
  }, {})

  function handleButtonClick(val) {
    onChange(val)
    close()
    triggerRef.current?.focus()
  }

  function open() {
    setIsOpen(true)
    if (onOpen) onOpen()
  }

  function close() {
    setIsOpen(false)
    if (onClose) onClose()
  }

  const optionEls = options.map(
    ({ value: optionVal, label, disabled, selectedLabel }) => {
      return (
        <button
          key={optionVal}
          className={classNames(styles.option, styles[optionSize], {
            [styles.current]: optionVal === value,
          })}
          disabled={disabled}
          aria-pressed={false}
          aria-label={selectedLabel || null}
          onMouseOver={() => {
            if (disabled) return
            if (onOptionIn) onOptionIn(optionVal)
          }}
          onMouseOut={() => {
            if (disabled) return
            if (onOptionOut) onOptionOut(optionVal)
          }}
          onFocus={() => {
            if (disabled) return
            if (onOptionIn && document.body.classList.contains('keys'))
              onOptionIn(optionVal)
          }}
          onBlur={() => {
            if (disabled) return
            if (onOptionOut) onOptionOut(optionVal)
          }}
          onClick={() => {
            if (disabled) return
            handleButtonClick(optionVal)
          }}
        >
          {label}
        </button>
      )
    },
  )

  return (
    <div
      className={classNames(styles.container, styles[`size_${size}`], {
        [styles.open]: isOpen,
      })}
    >
      <button
        ref={triggerRef}
        onClick={open}
        aria-label={ariaLabel}
        aria-haspopup="menu"
        className={styles.button}
      >
        <div className={styles.button__text}>
          {optionsIndex[value].selectedLabel || optionsIndex[value].label}
        </div>
        <div className={styles.caret}>
          <CaretIcon />
        </div>
      </button>
      {isOpen && (
        <Popover
          fixed={false}
          anchorRef={triggerRef}
          onRequestClose={close}
          width={width}
          yAlign={yAlign}
          xAlign={xAlign}
          offset={10}
          role="menu"
          trapFocus
          mask={windowSize.width < 601}
        >
          <div
            className={styles.popover}
            style={{ '--num-columns': `${numColumns}` }}
          >
            {optionEls}
          </div>
        </Popover>
      )}
    </div>
  )
}

OptionBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.string,
  onOptionHover: PropTypes.func,
  ariaLabel: PropTypes.string,
  numColumns: PropTypes.number,
  width: PropTypes.number,
  xAlign: PropTypes.string,
  yAlign: PropTypes.string,
  size: PropTypes.string,
}
