import { pruneArray } from 'Helpers/utils'
import { useSlice } from 'State'

export default function useFeatures() {
  const { user, plans } = useSlice('user', 'plans')
  const planData = plans?.[user.plan] ?? {}
  const features = planData.features ?? []
  const overrides = user.planOverrides
  const additionalFeatures = overrides.additionalFeatures ?? []

  let featuresArray = pruneArray(
    [...features, ...additionalFeatures],
    overrides.removedFeatures,
  )

  return {
    has: (...args) => args.every((arg) => featuresArray.includes(arg)),
    lacks: (...args) => args.some((arg) => !featuresArray.includes(arg)),
    list: featuresArray,
  }
}
