import { useEffect, useState } from 'react'
import classnames from 'classnames'
import useTimers from 'Hooks/useTimers'
import ErrorBoundary from 'Components/ErrorBoundary'
import Logo from 'Images/mark.svg'
import styles from './LoadingScreen.module.scss'

function LoadingScreen() {
  const { st, raf } = useTimers()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDelayed, setIsDelayed] = useState(false)

  useEffect(() => {
    raf(() => {
      setIsLoaded(true)
    })

    st(() => {
      setIsDelayed(true)
    }, 2000)
  }, [])

  return (
    <div
      className={classnames(styles.container, {
        [styles.active]: isLoaded,
        [styles.delayed]: isDelayed,
      })}
    >
      <div className={styles.inner}>
        <Logo />
        <div className={styles.loader}>
          {isDelayed && (
            <l-dot-pulse
              color="var(--color-text)"
              speed={1}
              size={30}
            ></l-dot-pulse>
          )}
        </div>
      </div>
    </div>
  )
}

export default function SafeLoadingScreen(props) {
  return (
    <ErrorBoundary>
      <LoadingScreen {...props} />
    </ErrorBoundary>
  )
}
