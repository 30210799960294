import { useEffect, useState, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import {
  getChargePreview,
  cancelSubscriptionSchedule,
  setCustomerPlan,
} from 'Helpers/services'
import { processUpgradeFromFree, updateUser } from 'Fire/firebaseActions'
import useTrapFocus from 'Hooks/useTrapFocus'
import useStripe from 'Hooks/useStripe'
import { capitalize, forceLayout, isPopulated } from 'Helpers/utils'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import Layout from 'Components/Layout'
import SmartButton from 'Components/SmartButton'
import AddNewCardPopup from 'Components/AddNewCardPopup'
import PlanDetails from 'Components/PlanDetails'
import ControlledSelect from 'Components/ControlledSelect'
import { Form, FormItem } from 'Components/Form'
import TransitionWrapper from 'Components/TransitionWrapper'
import PageHeader from 'Components/PageHeader'
import LiveChatButton from 'Components/LiveChatButton'
import UpgradeDowngradeIcon from 'Components/UpgradeDowngradeIcon.js'
import AddCardIcon from 'Images/icons/add-card.svg'
import styles from './ConfirmPlan.module.scss'

export default function ConfirmPlan() {
  const { raf, st } = useTimers()
  const appState = useSlice('user', 'updateUser', 'plans', 'feeds')
  const navigate = useNavigate()
  const { stripe } = useStripe()
  const { planAndPeriod } = useParams()
  const plans = appState.plans
  const {
    plan: currentPlan,
    stripeCustomerId,
    paymentMethods,
    stripeSubscriptionId,
    stripeNextBill,
    stripeSubscriptionInterval: currentInterval,
    stripeBalance,
    defaultPaymentMethod: currentPM,
    priceAfterDowngrade,
    planAfterDowngrade,
    periodAfterDowngrade,
  } = appState.user
  const [proratedCost, setProratedCost] = useState(null)
  const [proratedCostFrozen, setProratedCostFrozen] = useState(null)
  const [totalDue, setTotalDue] = useState(null)
  const [totalDueFrozen, setTotalDueFrozen] = useState(null)
  const [appliedCredit, setAppliedCredit] = useState(null)
  const paymentMethodsRef = useRef([])
  const successRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [showNewCardPopup, setShowNewCardPopup] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [priceIsLoading, setPriceIsLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(
    planAndPeriod ? planAndPeriod.split('-')[0] : 'Business',
  )
  const [selectedPeriod, setSelectedPeriod] = useState(
    planAndPeriod ? planAndPeriod.split('-')[1] : 'year',
  )
  const [selectedPM, setSelectedPM] = useState(currentPM)
  const currentPlanObj = currentPlan ? plans?.[currentPlan] : null
  const selectedPlanObj = selectedPlan ? plans?.[selectedPlan] : null
  const displayPlans = ['Free', 'Personal', 'Business', 'Enterprise']
  const [accountActionFrozen, setAccountActionFrozen] = useState(null)
  const [cancellingDowngrade, setCancellingDowngrade] = useState(false)
  const cardSelectElRef = useRef()

  useTrapFocus(successRef.current, isLoading || showSuccessMessage)

  /*
   *   Card select optons
   */
  const cardOptions = isPopulated(paymentMethods)
    ? [...Object.values(paymentMethods), { id: 'NEW' }].map(
        ({ id, lastFour, expMonth, expYear, brand }) =>
          id === 'NEW'
            ? { value: id, label: 'Add new card' }
            : {
                label: `${capitalize(
                  brand,
                )} XXXX-${lastFour} — exp. ${expMonth}/${expYear
                  .toString()
                  .slice(2, 4)}`,
                value: id,
              },
      )
    : []

  /*
   *   Handle new card popup close
   */
  function handleNewCardPopupClose() {
    setShowNewCardPopup(false)
    raf(() => {
      setSelectedPM(currentPM)
    })
  }

  /*
   *   Success!
   */
  function handleSuccess() {
    setShowSuccessMessage(true)

    if (accountAction === 'UPGRADE_FROM_FREE') {
      processUpgradeFromFree(appState.feeds)
    }
  }

  /*
   *   Error!
   */
  function handleError(error) {
    if (error.payment_intent) {
      navigate('/retry-payment')
    }
    st(() => {
      setIsLoading(false)
      setShowSuccessMessage(false)
      setErrorMessage(error.message || error)
    }, 500)
  }

  /*
   *   Calculate total due
   */
  function updateTotalDue() {
    let totalDue = selectedPlanObj?.price[selectedPeriod].amount
    if (proratedCost || proratedCostFrozen) {
      totalDue = proratedCostFrozen ? proratedCostFrozen : proratedCost
    }
    let appliedCredit = 0
    if (stripeBalance && stripeBalance < 0) {
      appliedCredit = Math.min(Math.abs(stripeBalance / 100), totalDue)
    }
    if (appliedCredit > 0) {
      totalDue = totalDue - appliedCredit
    }

    totalDue = totalDue ? parseFloat(totalDue.toFixed(2)) : 0

    setAppliedCredit(appliedCredit)
    setTotalDue(totalDue)
  }

  /*
   *   Handle submit
   */
  async function handleSubmit() {
    try {
      setProratedCostFrozen(proratedCost)
      setTotalDueFrozen(totalDue)
      setAccountActionFrozen(accountAction)
      setErrorMessage(null)

      updateUser({ planIsUpdating: true })

      const transactionResult = await setCustomerPlan(
        stripeCustomerId,
        selectedPM,
        selectedPlanObj?.price?.[selectedPeriod]?.stripeId,
        selectedPlan,
        selectedPeriod,
      )

      const { latest_invoice } = transactionResult || {}

      switch (latest_invoice?.payment_intent?.status) {
        case 'requires_action':
          const paymentIntent = await stripe.confirmCardPayment(
            latest_invoice?.payment_intent?.client_secret,
            {
              payment_method: selectedPM,
            },
          )
          if (paymentIntent.error) {
            handleError(paymentIntent.error)
          } else if (paymentIntent.status === 'succeeded') {
            handleSuccess()
          }
          break
        case 'requires_payment_method':
        default:
          handleSuccess()
      }
    } catch (error) {
      updateUser({ planIsUpdating: false })
      handleError(
        'An error has occurred. You were not charged. Please try again.',
      )
    }
  }

  /*
   *   Determine selected account action
   *   Upgrade from free, upgrade, downgrade or no change
   */
  const currentAnnualCost = currentPlanObj?.price?.year?.amount
  const newAnnualCost = selectedPlanObj?.price?.year?.amount
  const currentIntervalCost = currentPlanObj?.price?.[currentInterval]?.amount
  const newPeriodCost = selectedPlanObj?.price?.[selectedPeriod]?.amount
  const newPriceId = selectedPlanObj?.price?.[selectedPeriod]?.stripeId

  let accountAction = 'UPGRADE_FROM_FREE'

  if (currentPlan !== 'Free' && newAnnualCost > currentAnnualCost) {
    accountAction = 'UPGRADE'
  }

  if (newAnnualCost < currentAnnualCost) {
    accountAction = 'DOWNGRADE'
  }

  if (selectedPlan === currentPlan) {
    if (newPeriodCost > currentIntervalCost) accountAction = 'UPGRADE'
    if (newPeriodCost < currentIntervalCost) accountAction = 'DOWNGRADE'
  }

  if (
    (selectedPlan === currentPlan && selectedPeriod === currentInterval) ||
    (selectedPlan === currentPlan && selectedPlan === 'Free') ||
    newPriceId === priceAfterDowngrade ||
    (selectedPlan === 'Free' && planAfterDowngrade === 'Free')
  ) {
    accountAction = 'NO_CHANGE'
  }

  useEffect(updateTotalDue, [
    proratedCost,
    proratedCostFrozen,
    stripeBalance,
    selectedPlan,
    selectedPeriod,
  ])

  /*
   *   Calculate prorated cost due now
   */
  useEffect(() => {
    const controller = new AbortController()

    async function getProratedCharge() {
      try {
        setPriceIsLoading(true)
        const res = await getChargePreview(
          stripeCustomerId,
          newPriceId,
          controller.signal,
        )
        if (res) {
          setProratedCost(res.total / 100)
          setPriceIsLoading(false)
        } else {
          throw new error('No charge preview returned')
        }
      } catch (error) {
        setProratedCost(null)
        setPriceIsLoading(false)
      }
    }
    if (stripeSubscriptionId) {
      getProratedCharge()
    }

    return () => controller.abort()
  }, [
    selectedPlan,
    selectedPeriod,
    accountAction,
    stripeCustomerId,
    stripeSubscriptionId,
    selectedPlan,
    newPriceId,
  ])

  /*
   *   No selected payment method? Set to default
   */
  useEffect(() => {
    if (!selectedPM && paymentMethods && currentPM) {
      setSelectedPM(currentPM)
    }
  }, [currentPM, selectedPM, paymentMethods])

  useEffect(() => {
    if (!paymentMethods) return
    setSelectedPM(currentPM)
    paymentMethodsRef.current = Object.values(paymentMethods)
  }, [currentPM, paymentMethods])

  /*
   *   Open add new card popup when new option is selected
   */
  useEffect(() => {
    if (selectedPM === 'NEW' && !showNewCardPopup) {
      setShowNewCardPopup(true)
      setSelectedPM(currentPM)
    }
  }, [selectedPM, showNewCardPopup, currentPM])

  /*
   *   Set loading when account action is frozen
   *   Allows time for initial styles to apply
   */
  useEffect(() => {
    if (accountActionFrozen) {
      forceLayout()
      raf(() => setIsLoading(true))
    }
  }, [accountActionFrozen])

  /*
   *   Plans
   */
  const planOptions = plans
    ? displayPlans.map((plan) => ({
        label: plan === currentPlan ? `${plan} (Current)` : plan,
        value: plan,
      }))
    : []

  const intervalOptions = [
    { label: 'Annual', value: 'year' },
    { label: 'Monthly', value: 'month' },
  ].map((interval) => ({
    label:
      interval.value === currentInterval && selectedPlan === currentPlan
        ? `${interval.label} (Current)`
        : interval.label,
    value: interval.value,
  }))

  /*
   *   Info loader
   */
  const infoLoader = (
    <div className={styles.info_loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )

  /*
   *   Discount text
   */
  const discount =
    selectedPlanObj?.price.month.amount * 12 -
    selectedPlanObj?.price.year.amount

  /*
   *   Discount Note
   */
  const discountNote = (
    <TransitionWrapper
      show={selectedPeriod === 'year' && discount > 0}
      scale
      className={classnames(styles.price_note, styles.price_note_yellow)}
    >
      Saving ${discount}
    </TransitionWrapper>
  )

  /*
   *   Next payment date
   */
  const nextPaymentDate = stripeNextBill
    ? new Date(stripeNextBill).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '-'

  /*
   *   Monthly Price
   */
  const monthlyPrice =
    selectedPeriod === 'year'
      ? selectedPlanObj?.price.year.amount / 12
      : selectedPlanObj?.price.month.amount

  /*
   *   Selected period text
   */
  let selectedPeriodText = selectedPeriod === 'year' ? ' Annual' : ' Monthly'
  if (selectedPlan === 'Free') selectedPeriodText = ''

  /*
   *   Success messaging
   */
  let successTitleText = (
    <>
      Success! You are now on
      <br /> the{' '}
      <em>
        {selectedPlan}
        {selectedPeriodText}
      </em>{' '}
      plan.
    </>
  )

  let successBodyText =
    totalDue >= 0 ? (
      <>
        Your {capitalize(paymentMethods?.[selectedPM]?.brand)} ending{' '}
        <strong>{paymentMethods?.[selectedPM]?.lastFour}</strong> will be
        charged <strong>${totalDueFrozen}</strong>. You should receive an email
        receipt soon.
      </>
    ) : (
      <>
        Your account will be issued a credit of{' '}
        <strong>${Math.abs(totalDueFrozen)}</strong>.
      </>
    )

  const mainContentEl = (
    <div className={styles.inner}>
      {!priceAfterDowngrade && (
        <div className={styles.sidebar}>
          <PlanDetails planId={selectedPlan} planObj={selectedPlanObj} />

          <LiveChatButton size="small" />
        </div>
      )}

      {selectedPlanObj && !priceAfterDowngrade && (
        <TransitionWrapper>
          <Form
            className={styles.info}
            onSubmit={handleSubmit}
            submitIsDisabled={
              accountAction === 'NO_CHANGE' ||
              (!selectedPM && selectedPlan !== 'Free') ||
              selectedPM === 'NEW'
            }
            isLoading={isLoading}
            errorMessage={errorMessage}
            submitButtonContent={
              accountAction === 'DOWNGRADE' ? <>Downgrade</> : <>Pay Now</>
            }
          >
            <div className={styles.info__price_plan}>
              <FormItem className={styles.info__plan} label="Selected Plan">
                {selectedPlan && (
                  <ControlledSelect
                    ariaLabel="Plan"
                    disabled={isLoading}
                    options={planOptions}
                    value={selectedPlan}
                    onChange={setSelectedPlan}
                  />
                )}
              </FormItem>
              <FormItem className={styles.info__period} label="Billing Period">
                {selectedPeriod && (
                  <ControlledSelect
                    ariaLabel="Billing Period"
                    disabled={isLoading}
                    options={intervalOptions}
                    value={selectedPeriod}
                    onChange={setSelectedPeriod}
                  />
                )}
              </FormItem>
              <FormItem className={styles.info__price} label="Price">
                ${monthlyPrice}
                <div className={styles.info__price_period}> / Mo</div>
              </FormItem>

              {isPopulated(cardOptions) &&
                !selectedPM &&
                selectedPlan !== 'Free' && (
                  <FormItem
                    className={styles.info__payment_method}
                    label="Payment Method"
                  >
                    <l-squircle
                      speed={0.75}
                      size={18}
                      stroke={2.5}
                      color="var(--color-text-light)"
                    ></l-squircle>
                  </FormItem>
                )}

              {isPopulated(cardOptions) &&
                selectedPM &&
                selectedPlan !== 'Free' && (
                  <FormItem
                    className={styles.info__payment_method}
                    label="Payment Method"
                  >
                    <ControlledSelect
                      ref={cardSelectElRef}
                      ariaLabel="Price"
                      disabled={isLoading}
                      options={cardOptions}
                      value={selectedPM || ''}
                      onChange={setSelectedPM}
                    />
                  </FormItem>
                )}

              {cardOptions.length < 1 && (
                <FormItem
                  className={styles.info__payment_method}
                  label="Payment Method"
                >
                  <SmartButton
                    ref={cardSelectElRef}
                    disabled={isLoading}
                    size="large"
                    onClick={() => setShowNewCardPopup(true)}
                  >
                    <AddCardIcon /> Add a card
                  </SmartButton>
                </FormItem>
              )}
            </div>
            {accountAction !== 'DOWNGRADE' && accountAction !== 'NO_CHANGE' && (
              <FormItem className={styles.info__total}>
                <div className={styles.info__total_title}>Due now:</div>
                <div className={styles.info__total_amount}>
                  {!priceIsLoading && (
                    <TransitionWrapper scale>${totalDue}</TransitionWrapper>
                  )}

                  {priceIsLoading && (
                    <div className={styles.price_loader}>
                      <l-squircle
                        speed={0.75}
                        size={18}
                        stroke={2.5}
                        color="var(--color-text-light)"
                      ></l-squircle>
                    </div>
                  )}
                </div>
                <div className={styles.price_notes}>
                  {!priceIsLoading && appliedCredit > 0 && (
                    <TransitionWrapper scale className={styles.price_note}>
                      ${appliedCredit} credit applied
                    </TransitionWrapper>
                  )}

                  {!priceIsLoading &&
                    proratedCost !== null &&
                    proratedCost > 0 && (
                      <TransitionWrapper scale className={styles.price_note}>
                        Prorated
                      </TransitionWrapper>
                    )}

                  {!priceIsLoading &&
                    proratedCost !== null &&
                    proratedCost < 0 && (
                      <TransitionWrapper scale className={styles.price_note}>
                        Account will be credited
                      </TransitionWrapper>
                    )}
                  {!priceIsLoading && discountNote}
                </div>
              </FormItem>
            )}
            {accountAction === 'NO_CHANGE' && (
              <FormItem tag="div" className={styles.info__note}>
                <div className={styles.info__note_inner}>
                  This is your current plan
                </div>
              </FormItem>
            )}
            {accountAction === 'DOWNGRADE' && (
              <FormItem tag="div" className={styles.info__note}>
                <div className={styles.info__note_inner}>
                  Your plan will downgrade to{' '}
                  <strong>
                    {selectedPlan}
                    {selectedPeriodText}
                  </strong>
                  . Your account will be issued a credit of
                  {priceIsLoading && (
                    <div className={styles.inline_price_loader}>
                      <l-squircle
                        speed={0.75}
                        size={16}
                        stroke={2.5}
                        color="var(--color-text-light)"
                      ></l-squircle>
                    </div>
                  )}
                  {!priceIsLoading && <b> ${Math.abs(proratedCost)}</b>}
                </div>
              </FormItem>
            )}
          </Form>
        </TransitionWrapper>
      )}

      {!selectedPlanObj && infoLoader}

      {/* Cancel Downgrade */}
      {selectedPlanObj && priceAfterDowngrade && (
        <div className={classnames(styles.cancel_downgrade)}>
          <div className={styles.cancel_downgrade__text}>
            <h2 className={styles.cancel_downgrade__title}>
              Downgrade Scheduled
            </h2>
            <div className={styles.cancel_downgrade__copy}>
              Your plan is scheduled to downgrade to{' '}
              <strong>
                {planAfterDowngrade}{' '}
                {periodAfterDowngrade === 'year' ? 'Annual' : 'Monthly'}
              </strong>{' '}
              after your current plan expires on {nextPaymentDate}. Would you
              like to cancel this pending downgrade?
            </div>
          </div>
          <SmartButton
            className={styles.cancel_downgrade__button}
            isLoading={cancellingDowngrade}
            onClick={async () => {
              setCancellingDowngrade(true)
              await cancelSubscriptionSchedule(stripeSubscriptionId)
              setCancellingDowngrade(false)
            }}
            color={'algae'}
          >
            Cancel downgrade
          </SmartButton>
        </div>
      )}
    </div>
  )

  const retryContentEl = (
    <div className={styles.payment_failed}>
      Your last payment failed to proccess.
      <Link to="/retry-payment">Click here to retry</Link>
    </div>
  )

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Confirm plan | Behold">
      <main className={styles.container}>
        <PageHeader
          title="Confirm plan"
          breadCrumbs={[
            { text: 'Account', link: '/account' },
            { text: 'Pick a plan', link: '/choose-plan' },
            { text: 'Confirm' },
          ]}
        />

        {appState.user.failedInvoice ? retryContentEl : mainContentEl}

        {/* Loading & Success */}
        <div
          ref={successRef}
          aria-hidden={isLoading || showSuccessMessage ? false : true}
          className={classnames(styles.success, {
            [styles.visible]: isLoading,
            [styles.successful]: showSuccessMessage,
            [styles.downgrade]: accountActionFrozen === 'DOWNGRADE',
            [styles.upgrade]: accountActionFrozen !== 'DOWNGRADE',
          })}
        >
          <div
            className={styles.success__inner}
            tabIndex={isLoading || showSuccessMessage ? 0 : -1}
          >
            <div className={styles.success__icon}>
              <UpgradeDowngradeIcon
                type={accountActionFrozen?.toLowerCase()}
                animating={!showSuccessMessage && isLoading}
              />
            </div>
            <h2 className={styles.success__processing} role="alert">
              {accountActionFrozen === 'DOWNGRADE' &&
                isLoading &&
                'Downgrading'}
              {accountActionFrozen !== 'DOWNGRADE' && isLoading && 'Upgrading'}
            </h2>
            <div className={styles.success__content}>
              <h2 className={styles.success__title} aria-live="assertive">
                {showSuccessMessage && successTitleText}
              </h2>
              <div className={styles.success__text} aria-live="polite">
                {showSuccessMessage && successBodyText}
              </div>
              <SmartButton
                className={styles.success__button}
                to="/account"
                size="large"
              >
                Done
              </SmartButton>
            </div>
          </div>
        </div>
      </main>
      {showNewCardPopup && (
        <AddNewCardPopup
          stripeId={stripeCustomerId}
          onRequestClose={handleNewCardPopupClose}
          onOpen={() => setSelectedPM(currentPM)}
          closeFocusRef={cardSelectElRef}
        />
      )}
    </Layout>
  )
}
