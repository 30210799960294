import { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { hexToHSL, HSLToHex, isAnyOf } from 'Helpers/utils'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import useTimers from 'Hooks/useTimers'
import defaultWidgetSettings from 'Constants/defaultWidgetSettings'
import FeedPanel from 'Components/feed/FeedPanel'
import TransitionWrapper from 'Components/TransitionWrapper'
import HelpTooltip from 'Components/HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import ControlledSelect from 'Components/ControlledSelect'
import ColorPicker from 'Components/ColorPicker'
import OptionBox from 'Components/OptionBox'
import Switch from 'Components/Switch'
import ToggleButton from 'Components/ToggleButton'
import ControlledRange from 'Components/ControlledRange'
import TagInput from 'Components/TagInput'
import PanelIcon from 'Images/icons/palette.svg'
import ReelsIcon from 'Images/icons/reels.svg'
import InstagramIcon from 'Images/icons/instagram.svg'
import GalleryIcon from 'Images/icons/gallery-outline.svg'
import VideoIcon from 'Images/icons/play-outline.svg'
import TopLeftIcon from 'Images/icons/controls-tl.svg'
import TopCenterIcon from 'Images/icons/controls-tc.svg'
import TopRightIcon from 'Images/icons/controls-tr.svg'
import TopSplitIcon from 'Images/icons/controls-ts.svg'
import SidesIcon from 'Images/icons/controls-sides.svg'
import BottomLeftIcon from 'Images/icons/controls-bl.svg'
import BottomCenterIcon from 'Images/icons/controls-bc.svg'
import BottomRightIcon from 'Images/icons/controls-br.svg'
import BottomSplitIcon from 'Images/icons/controls-bs.svg'
import TopLeftLabelIcon from 'Images/icons/label-tl.svg'
import TopCenterLabelIcon from 'Images/icons/label-tc.svg'
import TopRightLabelIcon from 'Images/icons/label-tr.svg'
import BottomLeftLabelIcon from 'Images/icons/label-bl.svg'
import BottomCenterLabelIcon from 'Images/icons/label-bc.svg'
import BottomRightLabelIcon from 'Images/icons/label-br.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelWidgetSettingsElasticCarousel({
  panelId,
  feed,
  localFeedSettings,
  updateLocalFeedSetting,
  localWidgetSettings,
  updateLocalWidgetSetting,
  setPreviewLoadingColors,
  hasChanges,
  saveChanges,
  resetChanges,
  plan,
  demoMode = false,
  isLoading,
}) {
  localWidgetSettings = {
    ...defaultWidgetSettings.grid,
    ...localWidgetSettings,
  }
  const { addNotification } = useSlice('addNotification')
  const windowSize = useWindowSize()
  const { st, cancelAllTimers } = useTimers()
  const loadingColorToneRef = useRef()
  const loadingColorRef = useRef(localWidgetSettings.loadingColor)
  const maxPostsRef = useRef(localFeedSettings.maxPosts)

  let maxPostsPerFeedText = (
    <p>
      On the <b>{plan.title}</b> plan, you can set this up to{' '}
      <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.{' '}
      <b>
        <Link to="/choose-plan">Upgrade now</Link>
      </b>{' '}
      to increase your limit.
    </p>
  )

  if (plan?.topTier) {
    maxPostsPerFeedText = (
      <p>
        On the <b>{plan.title}</b> plan, you can set this up to{' '}
        <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.
      </p>
    )
  }

  function save() {
    maxPostsRef.current = localFeedSettings.maxPosts
    saveChanges()
  }

  function maxPostsWarning() {
    addNotification(
      {
        title: 'Post limit reached',
        text: maxPostsPerFeedText,
        duration: 20,
      },
      'MAX_POSTS_EXCEEDED',
    )
  }

  function updatePostTypeFilter(postType, val) {
    const filterSet = new Set(localFeedSettings.filter.allowedTypes)
    if (val) {
      filterSet.add(postType)
      updateLocalFeedSetting('filter', { allowedTypes: Array.from(filterSet) })
    } else {
      if (filterSet.size > 1) {
        filterSet.delete(postType)
        updateLocalFeedSetting('filter', {
          allowedTypes: Array.from(filterSet),
        })
      } else {
        addNotification(
          {
            text: 'You must allow at least one type of post',
            duration: 20,
          },
          'MIN_ONE_POST_TYPE',
        )
      }
    }
  }

  function updateCaptionIncludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionIncludeText: Array.from(new Set(text)),
    })
  }

  function updateCaptionExcludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionExcludeText: Array.from(new Set(text)),
    })
  }

  useEffect(() => {
    if (
      loadingColorRef.current !== 'tone' &&
      localWidgetSettings.loadingColor === 'tone'
    ) {
      st(() => {
        if (loadingColorToneRef.current) {
          loadingColorToneRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        }
      }, 10)
    }
    loadingColorRef.current = localWidgetSettings.loadingColor
  }, [localWidgetSettings])

  /*
   *   Aspect Ratio options
   */
  const aspectRatioOptions = [
    { label: 'Original', value: 'ORIGINAL' },
    { label: '1:1 - Square', value: '1:1' },
    { label: '9:16 - Reels', value: '9:16' },
    { label: '4:5 - Short Reels', value: '4:5' },
    { label: '3:2 - Landscape', value: '3:2' },
    { label: 'Custom', value: 'CUSTOM' },
  ]
  const aspectRatio = localWidgetSettings.breakpoints.default
    .postAspectRatio || [1, 1]
  let aspectRatioString = aspectRatio.join(':')
  if (!'1:1,9:16,4:5,3:2'.includes(aspectRatioString)) {
    aspectRatioString = 'CUSTOM'
  }

  /*
   *   Click behavior options
   */
  const clickBehavior = [
    { label: 'Link to post', value: `linkToPost` },
    { label: 'Link to profile', value: `linkToProfile` },
    { label: 'Custom link', value: `customLink` },
    { label: 'Open popup gallery', value: `openPopupGallery` },
    { label: 'Do nothing', value: `doNothing` },
  ]

  /*
   *   Popup theme
   */
  const popupColorTheme = [
    { label: 'Auto', value: `auto` },
    { label: 'Light', value: `light` },
    { label: 'Dark', value: `dark` },
  ]

  /*
   *   Hover contents options
   */
  const hoverContentsOptions = [
    { label: 'Icon', value: `showIcon` },
    { label: 'Caption', value: `showCaption` },
    { label: 'None', value: `none` },
  ]

  /*
   *   Hover contents options
   */
  const hoverOverlayColorOptions = [
    { label: 'Default', value: `default` },
    { label: 'Auto', value: `auto` },
    { label: 'Custom Color', value: `custom` },
  ]

  /*
   *   Hover animation options
   */
  const hoverEffectOptions = [
    { label: 'Fade', value: `fade` },
    { label: 'Zoom fade', value: `zoomFade` },
    { label: 'Blur', value: `blur` },
    { label: 'Zoom blur', value: `zoomBlur` },
    { label: 'To greyscale', value: `toGreyscale` },
    { label: 'Zoom to greyscale', value: `zoomToGreyscale` },
    { label: 'From greyscale', value: `fromGreyscale` },
    { label: 'Zoom from greyscale', value: `zoomFromGreyscale` },
    { label: 'None', value: `none` },
  ]

  const defBreakpoint = localWidgetSettings.breakpoints.default

  return (
    <FeedPanel
      panelId={panelId}
      width={350}
      icon={<PanelIcon />}
      title="Customize"
      hasChanges={hasChanges}
      saveChanges={save}
      resetChanges={resetChanges}
    >
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>Number of posts</div>
          <ControlledInput
            type="number"
            min={1}
            max={plan.maxImagesPerFeed}
            value={localFeedSettings.maxPosts}
            onChange={(val) => {
              updateLocalFeedSetting('maxPosts', val)
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  numPosts: val,
                },
              })
            }}
            onMaxExceeded={maxPostsWarning}
            ariaLabel="Number of posts"
            disabled={isLoading}
          />
          <TransitionWrapper
            show={localFeedSettings.maxPosts > maxPostsRef.current && !demoMode}
            scale
            height
          >
            <div className={styles.load_posts_notice}>
              <div
                className={classNames(
                  styles.description,
                  styles.description_darker,
                  styles.description_narrower,
                )}
              >
                <button onClick={save}>Save</button> to load additional posts.
              </div>
            </div>
          </TransitionWrapper>
        </div>
      </div>

      <h3 className={styles.section_title}>Filter</h3>
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>Allowed post types</div>
          <div className={styles.toggle_buttons}>
            <ToggleButton
              ariaLabel="Allowed post types: Images"
              popupLabel={`Images: ${
                localFeedSettings.filter.allowedTypes.includes('IMAGE')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('IMAGE')}
              onChange={(val) => updatePostTypeFilter('IMAGE', val)}
              iconOnly
              disabled={isLoading}
            >
              <InstagramIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Videos"
              popupLabel={`Videos: ${
                localFeedSettings.filter.allowedTypes.includes('VIDEO')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('VIDEO')}
              onChange={(val) => updatePostTypeFilter('VIDEO', val)}
              iconOnly
              disabled={isLoading}
            >
              <VideoIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Reels"
              popupLabel={`Reels: ${
                localFeedSettings.filter.allowedTypes.includes('REEL')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('REEL')}
              onChange={(val) => updatePostTypeFilter('REEL', val)}
              iconOnly
              disabled={isLoading}
            >
              <ReelsIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Albums"
              popupLabel={`Albums: ${
                localFeedSettings.filter.allowedTypes.includes('CAROUSEL_ALBUM')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes(
                'CAROUSEL_ALBUM',
              )}
              onChange={(val) => updatePostTypeFilter('CAROUSEL_ALBUM', val)}
              iconOnly
              disabled={isLoading}
            >
              <GalleryIcon />
            </ToggleButton>
          </div>
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption must include">
              <div className={styles.tooltip}>
                <h1>Caption must include</h1>
                <p>
                  Only posts that contain at least one of the text snippets you
                  add here will be included in your feed. This is case-sensitive
                  and can include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionIncludeText}
            onUpdate={updateCaptionIncludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption may not include">
              <div className={styles.tooltip}>
                <h1>Caption may not include</h1>
                <p>
                  Posts that contain any of the text you add here will be
                  excluded from your feed. This is case-sensitive and can
                  include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionExcludeText}
            onUpdate={updateCaptionExcludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Layout & Appearance</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Max width */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label} htmlFor="max-width">
            <HelpTooltip width={275} triggerText="Max width">
              <div className={styles.tooltip}>
                <h1>Maximum width</h1>
                <p>
                  Sets a maximum pixel width for your feed. When this option is
                  disabled your feed will fit the width of its container.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <div className={styles.switch_input}>
            <ControlledInput
              id="max-width"
              className={styles.switch_input__input}
              type="number"
              min={0}
              value={localWidgetSettings.maxWidth}
              disabled={!localWidgetSettings.constrainWidth}
              onChange={(val) => updateLocalWidgetSetting('maxWidth', val)}
              ariaLabel="Maximum width"
            />
            <Switch
              ariaLabel="constrain width"
              className={styles.switch_input__switch}
              checked={!!localWidgetSettings.constrainWidth}
              onChange={(val) => {
                updateLocalWidgetSetting('constrainWidth', val)
              }}
            />
          </div>
        </div>

        {/* Height */}
        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Height</div>
          <ControlledInput
            ariaLabel="Height"
            type="number"
            min={0}
            value={defBreakpoint.postHeight}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  postHeight: val,
                },
              })
            }
          />
        </div>

        {/* Horizontal spacing */}
        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Gap</div>
          <ControlledInput
            ariaLabel="Gap"
            type="number"
            min={0}
            value={defBreakpoint.gap.x}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  gap: {
                    x: val,
                    y: val,
                  },
                },
              })
            }
          />
        </div>

        {/* Aspect Ratio */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Post aspect ratio</div>
          <ControlledSelect
            ariaLabel="Post aspect ratio"
            options={aspectRatioOptions}
            value={
              defBreakpoint.forcePostAspectRatio
                ? aspectRatioString
                : 'ORIGINAL'
            }
            onChange={(val) => {
              if (val === 'ORIGINAL') {
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    forcePostAspectRatio: false,
                  },
                })
              } else {
                if (val === 'CUSTOM') val = '16:9'
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    forcePostAspectRatio: true,
                    postAspectRatio: val.split(':').map((val) => parseInt(val)),
                  },
                })
              }
            }}
          />
        </div>

        {/* Aspect Ratio Width */}
        <TransitionWrapper
          show={
            aspectRatioString === 'CUSTOM' && defBreakpoint.forcePostAspectRatio
          }
          scale
          height
          className={classNames(styles.setting, styles.setting_half)}
        >
          <div className={styles.label}>Width</div>
          <ControlledInput
            ariaLabel="Aspect Ratio Width"
            type="number"
            min={0}
            max={9999}
            value={aspectRatio[0]}
            onChange={(val) => {
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  postAspectRatio: [val, aspectRatio[1]],
                },
              })
            }}
          />
        </TransitionWrapper>

        {/* Aspect Ratio Height */}
        <TransitionWrapper
          show={
            aspectRatioString === 'CUSTOM' && defBreakpoint.forcePostAspectRatio
          }
          scale
          height
          className={classNames(styles.setting, styles.setting_half)}
        >
          <div className={styles.label}>Height</div>
          <ControlledInput
            ariaLabel="Aspect Ratio Height"
            type="number"
            min={0}
            max={9999}
            value={aspectRatio[1]}
            onChange={(val) => {
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  postAspectRatio: [aspectRatio[0], val],
                },
              })
            }}
          />
        </TransitionWrapper>

        {/* Loading Color */}
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={300} triggerText="Placeholder colors">
              <div className={styles.tooltip}>
                <h1>Placeholder colors</h1>
                <p>
                  This determines the colors that show while your posts are
                  loading. These colors are extracted from the images
                  themselves.
                </p>
              </div>
            </HelpTooltip>
          </div>

          {(!feed.newImagesAreProcessing ||
            localWidgetSettings.loadingColor === 'tone') && (
            <OptionBox
              options={[
                {
                  value: 'vibrant',
                  label: 'Vibrant',
                },
                {
                  value: 'vibrantLight',
                  label: 'Vibrant light',
                },
                {
                  value: 'vibrantDark',
                  label: 'Vibrant dark',
                },
                {
                  value: 'muted',
                  label: 'Muted',
                },
                {
                  value: 'mutedLight',
                  label: 'Muted light',
                },
                {
                  value: 'mutedDark',
                  label: 'Muted dark',
                },
                {
                  value: 'dominant',
                  label: 'Dominant',
                },
                {
                  value: 'tone',
                  label: 'Custom tone',
                },
                { value: 'transparent', label: 'None' },
              ]}
              value={localWidgetSettings.loadingColor}
              onChange={(val) => updateLocalWidgetSetting('loadingColor', val)}
              onOptionIn={(val) => {
                cancelAllTimers()
                setPreviewLoadingColors(val)
              }}
              onOptionOut={() => st(() => setPreviewLoadingColors(null), 50)}
              onClose={() => setPreviewLoadingColors(null)}
              numColumns={windowSize.width > 600 ? 3 : 2}
              width={windowSize.width > 600 ? 500 : 300}
              yAlign="top"
              ariaLabel="Placeholder colors"
            />
          )}
          {feed.newImagesAreProcessing &&
            localWidgetSettings.loadingColor !== 'tone' && (
              <div className={styles.setting_loader}>
                <l-squircle
                  stroke="3"
                  size={20}
                  color="var(--color-text-light)"
                ></l-squircle>
                <div className={styles.setting_loader__text}>
                  Extracting colors...
                </div>
              </div>
            )}
        </div>
        <TransitionWrapper
          show={localWidgetSettings.loadingColor === 'tone'}
          height
          scale
          className={styles.setting}
        >
          <div ref={loadingColorToneRef} className={styles.label}>
            Placeholder custom tone
          </div>
          <ColorPicker
            ariaLabel="Placeholder custom tone"
            onChange={(val) => {
              updateLocalWidgetSetting('loadingColorTone', hexToHSL(val))
              setPreviewLoadingColors('tone')
            }}
            onFocus={() => {
              setPreviewLoadingColors('tone')
            }}
            onBlur={() => setPreviewLoadingColors(null)}
            value={HSLToHex(localWidgetSettings.loadingColorTone)}
          />
        </TransitionWrapper>

        {/* Border radius */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Rounded corners</div>
          <ControlledRange
            ariaLabel="Rounded corners"
            type="range"
            min="0"
            max="100"
            suffix={`${defBreakpoint.borderRadius * 2}%`}
            value={defBreakpoint.borderRadius * 2}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  borderRadius: val / 2,
                },
              })
            }
          />
        </div>
        {/* Apply border radius to container */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Apply border radius to container"
            checked={defBreakpoint.applyBorderRadiusToContainer}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  applyBorderRadiusToContainer: val,
                },
              })
            }
            before={<b>Container rounded corners</b>}
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Controls & Label</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Show Controls */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Show controls"
            checked={defBreakpoint.showControls}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  showControls: val,
                },
              })
            }
            before={<b>Show controls</b>}
          />
        </div>

        {/* Show username/hashtag */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Show label"
            checked={defBreakpoint.showLabel}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  showLabel: val,
                },
              })
            }
            before={<b>Show label</b>}
          />
        </div>

        <TransitionWrapper
          show={defBreakpoint.showControls}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={styles.setting}>
            <div className={styles.label}>Controls layout</div>
            <OptionBox
              options={[
                {
                  value: 'top:left',
                  label: <TopLeftIcon />,
                  selectedLabel: 'Top left',
                },
                {
                  value: 'top:center',
                  label: <TopCenterIcon />,
                  selectedLabel: 'Top center',
                },
                {
                  value: 'top:right',
                  label: <TopRightIcon />,
                  selectedLabel: 'Top right',
                },
                {
                  value: 'bottom:left',
                  label: <BottomLeftIcon />,
                  selectedLabel: 'Bottom left',
                },
                {
                  value: 'bottom:center',
                  label: <BottomCenterIcon />,
                  selectedLabel: 'Bottom center',
                },
                {
                  value: 'bottom:right',
                  label: <BottomRightIcon />,
                  selectedLabel: 'Bottom right',
                },
                {
                  value: 'top:split',
                  label: <TopSplitIcon />,
                  selectedLabel: 'Top split',
                },
                {
                  value: 'bottom:split',
                  label: <BottomSplitIcon />,
                  selectedLabel: 'Bottom split',
                },
                {
                  value: 'sides',
                  label: <SidesIcon />,
                  selectedLabel: 'Sides',
                },
              ]}
              value={
                defBreakpoint.controlsPosition === 'sides'
                  ? 'sides'
                  : `${defBreakpoint.controlsPosition}:${defBreakpoint.controlsJustification}`
              }
              onChange={(val) => {
                let [position, justification] = val.split(':')
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    controlsPosition: position,
                    controlsJustification: justification ?? null,
                    ...(defBreakpoint.labelPosition === position &&
                    'center,split'.includes(justification)
                      ? { labelJustification: 'center' }
                      : {}),
                    ...(defBreakpoint.labelPosition === position &&
                    justification === 'left' &&
                    'left,center'.includes(defBreakpoint.labelJustification)
                      ? { labelJustification: 'right' }
                      : {}),
                    ...(defBreakpoint.labelPosition === position &&
                    justification === 'right' &&
                    'right,center'.includes(defBreakpoint.labelJustification)
                      ? { labelJustification: 'left' }
                      : {}),
                  },
                })
              }}
              numColumns={windowSize.width > 600 ? 3 : 2}
              width={windowSize.width > 600 ? 500 : 300}
              yAlign="top"
              optionSize="large"
              ariaLabel="Controls layout"
            />
          </div>
        </TransitionWrapper>

        {/* Controls border radius */}
        <TransitionWrapper
          show={defBreakpoint.showControls}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Controls rounded corners</div>
            <ControlledRange
              ariaLabel="Controls rounded corners"
              type="range"
              min="0"
              max="100"
              suffix={`${defBreakpoint.buttonBorderRadius * 2}%`}
              value={defBreakpoint.buttonBorderRadius * 2}
              onChange={(val) =>
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    buttonBorderRadius: val / 2,
                  },
                })
              }
            />
          </div>
        </TransitionWrapper>

        <TransitionWrapper
          show={defBreakpoint.showLabel}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={styles.setting}>
            <div className={styles.label}>Label position</div>
            <OptionBox
              options={[
                {
                  value: 'top:left',
                  label: <TopLeftLabelIcon />,
                  selectedLabel: 'Top left',
                  disabled:
                    defBreakpoint.showControls &&
                    'left,center,split'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'top',
                },
                {
                  value: 'top:center',
                  label: <TopCenterLabelIcon />,
                  selectedLabel: 'Top center',
                  disabled:
                    defBreakpoint.showControls &&
                    'left,right'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'top',
                },
                {
                  value: 'top:right',
                  label: <TopRightLabelIcon />,
                  selectedLabel: 'Top right',
                  disabled:
                    defBreakpoint.showControls &&
                    'right,center,split'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'top',
                },
                {
                  value: 'bottom:left',
                  label: <BottomLeftLabelIcon />,
                  selectedLabel: 'Bottom left',
                  disabled:
                    defBreakpoint.showControls &&
                    'left,center,split'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'bottom',
                },
                {
                  value: 'bottom:center',
                  label: <BottomCenterLabelIcon />,
                  selectedLabel: 'Bottom center',
                  disabled:
                    defBreakpoint.showControls &&
                    'left,right'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'bottom',
                },
                {
                  value: 'bottom:right',
                  label: <BottomRightLabelIcon />,
                  selectedLabel: 'Bottom right',
                  disabled:
                    defBreakpoint.showControls &&
                    'right,center,split'.includes(
                      defBreakpoint.controlsJustification,
                    ) &&
                    defBreakpoint.controlsPosition === 'bottom',
                },
              ]}
              value={`${defBreakpoint.labelPosition}:${defBreakpoint.labelJustification}`}
              onChange={(val) => {
                let [position, justification] = val.split(':')
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    labelPosition: position,
                    labelJustification: justification,
                  },
                })
              }}
              numColumns={windowSize.width > 600 ? 3 : 2}
              width={windowSize.width > 600 ? 500 : 300}
              yAlign="top"
              optionSize="large"
              ariaLabel="Controls layout"
            />
          </div>
        </TransitionWrapper>

        <TransitionWrapper
          show={defBreakpoint.showLabel}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Custom label text</div>
            <ControlledInput
              ariaLabel="Custom label text"
              value={localWidgetSettings.label}
              onChange={(val) => updateLocalWidgetSetting('label', val)}
            />
          </div>
        </TransitionWrapper>

        <TransitionWrapper
          show={defBreakpoint.showLabel}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Custom label link</div>
            <ControlledInput
              ariaLabel="Custom label link URL"
              value={localWidgetSettings.labelLink}
              onChange={(val) => updateLocalWidgetSetting('labelLink', val)}
            />
          </div>
        </TransitionWrapper>

        {/* Controls margin */}
        <TransitionWrapper
          show={defBreakpoint.showLabel || defBreakpoint.showControls}
          height
          scale
          className={styles.transition_wrapper}
        >
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Controls & label margin</div>
            <ControlledInput
              ariaLabel="Controls & label margin"
              type="number"
              min={0}
              max={250}
              value={defBreakpoint.controlsMargin}
              onChange={(val) =>
                updateLocalWidgetSetting('breakpoints', {
                  ...localWidgetSettings.breakpoints,
                  default: {
                    ...defBreakpoint,
                    controlsMargin: val,
                  },
                })
              }
            />
          </div>
        </TransitionWrapper>
      </div>

      <h3 className={styles.section_title}>Colors</h3>
      <div className={classNames(styles.fieldset)}>
        <TransitionWrapper
          show={defBreakpoint.showLabel}
          scale
          className={classNames(styles.setting, styles.setting_half)}
        >
          <div className={styles.label}>Label icon</div>
          <ColorPicker
            ariaLabel="Icon color"
            onChange={(val) => updateLocalWidgetSetting('iconColor', val)}
            value={localWidgetSettings.iconColor}
          />
        </TransitionWrapper>

        <TransitionWrapper
          show={defBreakpoint.showLabel}
          scale
          className={classNames(styles.setting, styles.setting_half)}
        >
          <div className={styles.label}>Label text</div>
          <ColorPicker
            ariaLabel="Text color"
            onChange={(val) => updateLocalWidgetSetting('textColor', val)}
            value={localWidgetSettings.textColor}
          />
        </TransitionWrapper>

        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Button</div>
          <ColorPicker
            ariaLabel="Button color"
            onChange={(val) => updateLocalWidgetSetting('buttonColor', val)}
            value={localWidgetSettings.buttonColor}
          />
        </div>

        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Button hover</div>
          <ColorPicker
            ariaLabel="Button hover color"
            onChange={(val) =>
              updateLocalWidgetSetting('buttonHoverColor', val)
            }
            value={localWidgetSettings.buttonHoverColor}
          />
        </div>

        <div
          show={defBreakpoint.showControls}
          height
          scale
          className={classNames(styles.transition_wrapper, styles.setting_half)}
        >
          <div className={styles.label}>Button icon</div>
          <ColorPicker
            ariaLabel="Button icon color"
            onChange={(val) => updateLocalWidgetSetting('buttonIconColor', val)}
            value={localWidgetSettings.buttonIconColor}
          />
        </div>

        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Btn icon hover</div>
          <ColorPicker
            ariaLabel="Button icon hover color"
            onChange={(val) =>
              updateLocalWidgetSetting('buttonIconHoverColor', val)
            }
            value={localWidgetSettings.buttonIconHoverColor}
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Hover</h3>
      {/* On hover */}
      <div className={classNames(styles.fieldset)}>
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Hover contents</div>
          <ControlledSelect
            ariaLabel="Hover contents"
            options={hoverContentsOptions}
            value={localWidgetSettings.onHover}
            onChange={(val) => updateLocalWidgetSetting('onHover', val)}
          />
        </div>

        {/* Hover effect */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>Hover effect</div>
          <ControlledSelect
            ariaLabel="Hover effect"
            options={hoverEffectOptions}
            value={localWidgetSettings.hoverEffect}
            onChange={(val) => updateLocalWidgetSetting('hoverEffect', val)}
          />
        </div>

        {/* Hover overlay color */}
        <TransitionWrapper
          show={
            isAnyOf(localWidgetSettings.hoverEffect, [
              'fade',
              'zoomFade',
              'fromGreyscale',
              'zoomFromGreyscale',
            ]) || localWidgetSettings.onHover === 'showCaption'
          }
          scale
          height
          className={classNames(styles.setting)}
        >
          <div className={styles.label}>Hover overlay color</div>
          <ControlledSelect
            ariaLabel="Hover overlay color"
            options={hoverOverlayColorOptions}
            value={localWidgetSettings.hoverOverlayColor}
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayColor', val)
            }
          />
        </TransitionWrapper>

        {/* Hover overlay custom color */}
        <TransitionWrapper
          show={
            localWidgetSettings.hoverOverlayColor === 'custom' &&
            (isAnyOf(localWidgetSettings.hoverEffect, [
              'fade',
              'zoomFade',
              'fromGreyscale',
              'zoomFromGreyscale',
            ]) ||
              localWidgetSettings.onHover === 'showCaption')
          }
          className={classNames(styles.setting)}
          height
          scale
        >
          <div className={styles.label}>Hover overlay custom color</div>
          <ColorPicker
            ariaLabel="Hover overlay custom color"
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayCustomColor', hexToHSL(val))
            }
            value={HSLToHex(localWidgetSettings.hoverOverlayCustomColor)}
          />
        </TransitionWrapper>

        {/* Hover overlay opacity */}
        <TransitionWrapper
          show={
            isAnyOf(localWidgetSettings.hoverOverlayColor, [
              'auto',
              'custom',
            ]) &&
            (isAnyOf(localWidgetSettings.hoverEffect, ['fade', 'zoomFade']) ||
              localWidgetSettings.onHover === 'showCaption')
          }
          className={classNames(styles.setting)}
          height
          scale
        >
          <div className={styles.label}>Hover overlay opacity</div>
          <ControlledRange
            ariaLabel="Hover overlay opacity"
            type="range"
            min="0"
            max="100"
            suffix={`${localWidgetSettings.hoverOverlayOpacity}%`}
            value={localWidgetSettings.hoverOverlayOpacity}
            onChange={(val) =>
              updateLocalWidgetSetting('hoverOverlayOpacity', val)
            }
          />
        </TransitionWrapper>
      </div>

      <h3 className={styles.section_title}>Videos</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Autoplay videos */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Autoplay videos"
            checked={!!localWidgetSettings.autoplayVideos}
            onChange={(val) => updateLocalWidgetSetting('autoplayVideos', val)}
            before={<b>Autoplay videos</b>}
          />
        </div>
        {/* Video Previews */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Play videos on hover"
            checked={!!localWidgetSettings.previewVideosOnHover}
            onChange={(val) =>
              updateLocalWidgetSetting('previewVideosOnHover', val)
            }
            before={<b>Play videos on hover</b>}
            disabled={localWidgetSettings.autoplayVideos}
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Behavior</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Click behavior */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>On click</div>
          <ControlledSelect
            ariaLabel="On Click"
            options={clickBehavior}
            value={localWidgetSettings.onPostClick}
            onChange={(val) => updateLocalWidgetSetting('onPostClick', val)}
          />
        </div>

        {/* Popup color theme */}
        <TransitionWrapper
          show={localWidgetSettings?.onPostClick === 'openPopupGallery'}
          scale
          height
          className={classNames(styles.transition_wrapper)}
        >
          <div className={styles.setting}>
            <div className={styles.label}>Popup color theme</div>
            <ControlledSelect
              ariaLabel="Popup color theme"
              options={popupColorTheme}
              value={localWidgetSettings.popupColorTheme}
              onChange={(val) =>
                updateLocalWidgetSetting('popupColorTheme', val)
              }
            />
          </div>
        </TransitionWrapper>

        {/* Custom link URL */}
        <TransitionWrapper
          show={localWidgetSettings?.onPostClick === 'customLink'}
          scale
          height
          className={classNames(styles.transition_wrapper)}
        >
          <div className={styles.setting}>
            <div className={styles.label}>Custom link URL</div>
            <ControlledInput
              ariaLabel="Custom link URL"
              value={localWidgetSettings.customLinkURL}
              onChange={(val) => updateLocalWidgetSetting('customLinkURL', val)}
            />
          </div>
        </TransitionWrapper>

        {/* Link Target */}
        <TransitionWrapper
          show={
            !'openPopupGallery,doNothing'.includes(
              localWidgetSettings.onPostClick,
            )
          }
          scale
          height
          className={classNames(styles.transition_wrapper_switch)}
        >
          <div className={classNames(styles.setting, styles.setting_switch)}>
            <Switch
              ariaLabel="Open links in a new tab"
              checked={!!localWidgetSettings.linkTarget}
              onChange={(val) => {
                const value = val ? '_blank' : ''
                updateLocalWidgetSetting('linkTarget', value)
              }}
              before={<b>Open links in a new tab</b>}
            />
          </div>
        </TransitionWrapper>

        {/* Snap to slide */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Snap to slide"
            checked={defBreakpoint.snapToSlide}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  snapToSlide: val,
                },
              })
            }
            before={
              <HelpTooltip width={275} triggerText={<b>Snap to slide</b>}>
                <div className={styles.tooltip}>
                  <h1>Snap to slide</h1>
                  <p>
                    The carousel will always come to rest with the left edge of
                    a slide perfectly aligned with the left side of the widget.
                  </p>
                </div>
              </HelpTooltip>
            }
          />
        </div>

        {/* Bind to scroll */}
        <div className={classNames(styles.setting, styles.setting_switch)}>
          <Switch
            ariaLabel="Bind to scroll position"
            checked={defBreakpoint.bindToScrollPos}
            onChange={(val) =>
              updateLocalWidgetSetting('breakpoints', {
                ...localWidgetSettings.breakpoints,
                default: {
                  ...defBreakpoint,
                  bindToScrollPos: val,
                },
              })
            }
            before={
              <HelpTooltip
                width={275}
                triggerText={<b>Bind to scroll position</b>}
              >
                <div className={styles.tooltip}>
                  <h1>Bind to scroll position</h1>
                  <p>
                    Bind carousel progress to the widget&apos;s position on the
                    page as a user scrolls.
                  </p>
                </div>
              </HelpTooltip>
            }
          />
        </div>
      </div>
    </FeedPanel>
  )
}

FeedPanelWidgetSettingsElasticCarousel.propTypes = {
  panelId: PropTypes.string,
  feed: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  updateLocalWidgetSetting: PropTypes.func,
  updateLocalFeedSetting: PropTypes.func,
  setPreviewLoadingColors: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
  plan: PropTypes.object,
  demoMode: PropTypes.bool,
}
