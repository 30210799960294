import PropTypes from 'prop-types'
import { useEffect } from 'react'

export function useScript({
  src,
  contents,
  id,
  attributes = {},
  active = true,
}) {
  useEffect(() => {
    const existingScript = document.getElementById(`useScript_${id}`)

    if (!existingScript && active) {
      const scriptEl = document.createElement('script')
      scriptEl.id = `useScript_${id}`
      scriptEl.type = 'text/javascript'
      if (src) {
        scriptEl.src = src
      }
      if (contents) {
        scriptEl.innerHTML = contents
      }
      Object.entries(attributes).forEach(([key, val]) => {
        scriptEl.setAttribute(key, val)
      })

      document.getElementsByTagName('head')[0].appendChild(scriptEl)
    }

    if (!active && existingScript) {
      existingScript.remove()
    }
  }, [src, contents, id, active])
}

useScript.propTypes = {
  src: PropTypes.string,
  contents: PropTypes.string,
  id: PropTypes.string,
}
