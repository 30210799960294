import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { forceLayout } from 'Helpers/utils'
import CheckIcon from 'Images/icons/check.svg'
import styles from './MenuItem.module.scss'

export default function MenuItem({
  children,
  onClick,
  to,
  href,
  confirm,
  confirmText,
  className,
  loading,
  success,
  error,
  disabled,
  current,
  color = 'green-grey',
}) {
  const [confirming, setConfirming] = useState(false)
  const [confirmIsVisible, setConfirmIsVisible] = useState(false)
  const buttonRef = useRef(null)
  const stRef = useRef(null)
  const rafRef = useRef(null)

  /*
   *   Handle click
   */
  function handleClick() {
    if (onClick && confirm && !confirming) {
      setConfirming(true)
      forceLayout()
      stRef.current = setTimeout(() => {
        window.addEventListener('click', handleWindowClick)
        rafRef.current = requestAnimationFrame(() => setConfirmIsVisible(true))
      }, 10)
    } else {
      if (onClick) onClick()
    }

    if (href) {
      location.href = href
    }
  }

  /*
   *   Handle Window click
   */
  function handleWindowClick() {
    window.removeEventListener('click', handleWindowClick)
    setConfirmIsVisible(false)
    setConfirming(false)
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    return () => {
      window.cancelAnimationFrame(rafRef.current)
      window.clearTimeout(stRef.current)
      window.removeEventListener('click', handleWindowClick)
    }
  }, []) // eslint-disable-line

  const containerClasses = classnames(
    styles.container,
    styles[color],
    className,
    {
      [styles.confirming]: confirming,
      [styles.loading]: loading,
      [styles.success]: success,
      [styles.error]: error,
      [styles.disabled]: disabled,
      [styles.current]: current,
    },
  )

  let Tag = to ? Link : 'button'
  if (href) Tag = 'a'

  return (
    <Tag
      ref={buttonRef}
      className={containerClasses}
      onClick={handleClick}
      disabled={disabled}
      to={to}
      href={href}
    >
      {children}
      <div
        aria-live="assertive"
        className={classnames(styles.confirm, {
          [styles.visible]: confirmIsVisible,
        })}
      >
        {confirmIsVisible && (confirmText || 'Confirm')}
      </div>
      <div className={styles.loading_el} aria-live="assertive">
        {loading && 'loading'}
        <l-squircle
          speed={0.75}
          size={15}
          stroke={2}
          color="var(--color-text)"
        ></l-squircle>
      </div>
      <div className={styles.success_el}>
        <CheckIcon />
      </div>
    </Tag>
  )
}

MenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  confirm: PropTypes.bool,
  confirmText: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  current: PropTypes.bool,
  color: PropTypes.string,
}
