import { useRef } from 'react'
import PropTypes from 'prop-types'
import Prism from 'prismjs'
import classnames from 'classnames'
import { copyText } from 'Helpers/utils'
import CopyIcon from 'Images/icons/copy.svg'
import styles from './Lit.module.scss'

export default function Lit({
  children,
  className,
  copy = false,
  highlight = true,
}) {
  const buttonRef = useRef()
  return typeof children === 'string' ? (
    <div className={classnames(styles.container, { [styles.has_copy]: copy })}>
      <pre className={classnames(styles.pre, className, 'lit')} tabIndex={-1}>
        <code
          tabIndex={-1}
          className={styles.code}
          dangerouslySetInnerHTML={{
            __html: highlight
              ? Prism.highlight(
                  children,
                  Prism.languages.javascript,
                  'javascript'
                )
              : children,
          }}
        />
      </pre>
      {copy && (
        <button
          aria-label="click to copy"
          ref={buttonRef}
          className={styles.copy}
          onClick={(evt) => {
            const bbox = buttonRef.current.getBoundingClientRect()
            copyText(evt, children, {
              x: bbox.x + bbox.width / 2,
              y: bbox.y - 5,
            })
          }}
        >
          Copy <CopyIcon />
        </button>
      )}
    </div>
  ) : null
}

Lit.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  copy: PropTypes.bool,
  highlight: PropTypes.bool,
}
