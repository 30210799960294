import { auth } from 'Fire/firebaseInit'
import { makeFetchUrl } from 'Helpers/utils'
import blobToDataUrl from 'Helpers/blobToDataUrl'

export async function getInsights(username, sourceId) {
  const token = await auth.currentUser.getIdToken()
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/insights`, {
      username,
      sourceId,
    }),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  return res.json()
}

export async function findHashtag(sourceId, instagramUserId, hashtag) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/hashtags/search`, {
      sourceId,
      instagramUserId,
      hashtag,
    }),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

export async function deleteCurrentUser() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/users/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

export async function decipherSecret(secret, signal) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/decipher`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ secret }),
      signal,
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function addApiSecret() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/api/add`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function deleteApiSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/api/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function addWebhookSecret() {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/webhooks/add`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function deleteWebhookSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/secrets/webhooks/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

export async function setActiveWebhookSecret(secretId) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(
      `${process.env.SERVICES_URL}/private/secrets/webhooks/activate`,
    ),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secretId,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json.secret
}

/**
 *   @param {{image: Blob, uploadPath: string, bucket: string, maxWidth?: number, maxHeight?: number, quality?: number, dbPath?:string, dbField?:string}} upload
 */
export async function uploadImageToBucket(upload) {
  const dataUrl = await blobToDataUrl(upload.image)
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/images/upload`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        base64Source: dataUrl,
        ...upload,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {{imagePath: string, bucket: string, dbPath?:string, dbField?:string}} deletion
 */
export async function deleteImageInBucket({
  imagePath,
  bucket,
  dbPath = '',
  dbField = null,
}) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/images/delete`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imagePath,
        bucket,
        dbPath,
        dbField,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {string} email
 *   @param {getUidFromEmail} boolean
 */
export async function setUserEmail(email, getUidFromEmail = false) {
  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(`${process.env.SERVICES_URL}/private/users/setEmail`),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        getUidFromEmail,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.message)
  }

  return json
}

/**
 *   @param {string} email
 */
export async function sendVerificationEmail(email) {
  const lastSent = sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL') || 0
  sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', Date.now())

  const token = await auth.currentUser.getIdToken(true)
  const res = await fetch(
    makeFetchUrl(
      `${process.env.SERVICES_URL}/private/users/sendVerificationEmail`,
    ),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    },
  )

  const json = await res.json()

  if (!res.ok) {
    sessionStorage.setItem('BEHOLD_LAST_VERIFICATION_EMAIL', lastSent)
    console.log(json.message)
  }

  return json
}

/**
 *   @param {string} stripeCustomerId
 *   @param {string} newPriceId
 *   @param {AbortSignal} signal
 */
export async function getChargePreview(stripeCustomerId, newPriceId, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/getChargePreview`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: stripeCustomerId,
          newPriceId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} subscriptionId
 *   @param {AbortSignal} signal
 */
export async function cancelSubscriptionSchedule(subscriptionId, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/cancelSubscriptionSchedule`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscriptionId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} customerId
 *   @param {AbortSignal} signal
 */
export async function createSetupIntent(customerId, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/createSetupIntent`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} customerId
 *   @param {string} email
 *   @param {AbortSignal} signal
 */
export async function setBillingEmail(customerId, email, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/setBillingEmail`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          email,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} customerId
 *   @param {string} defaultPaymentMethodId
 *   @param {AbortSignal} signal
 */
export async function setDefaultPaymentMethod(
  customerId,
  defaultPaymentMethodId,
  signal,
) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/setDefaultPaymentMethod`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId: defaultPaymentMethodId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} paymentMethodId
 *   @param {AbortSignal} signal
 */
export async function deletePaymentMethod(paymentMethodId, signal) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/deletePaymentMethod`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} customerId
 *   @param {string} paymentMethodId
 *   @param {string} newPriceId
 *   @param {string} newPlanId
 *   @param {string} newInterval
 *   @param {AbortSignal} signal
 */
export async function setCustomerPlan(
  customerId,
  paymentMethodId,
  newPriceId,
  newPlanId,
  newInterval,
  signal,
) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/setCustomerPlan`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
          newPriceId,
          newPlanId,
          newInterval,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}

/**
 *   @param {string} customerId
 *   @param {string} paymentMethodId
 *   @param {string} invoiceId
 *   @param {AbortSignal} signal
 */
export async function retryFailedPayment(
  customerId,
  paymentMethodId,
  invoiceId,
  signal,
) {
  try {
    const token = await auth.currentUser.getIdToken(true)
    const res = await fetch(
      makeFetchUrl(
        `${process.env.SERVICES_URL}/private/stripe/retryFailedPayment`,
      ),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
          invoiceId,
        }),
        signal,
      },
    )

    if (!res.ok) {
      throw new Error(json.message)
    }

    const json = await res.json()
    return json
  } catch (error) {
    if (!signal?.aborted) {
      throw new Error(error.message)
    }
  }
}
