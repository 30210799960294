export default function announceToScreenReader(text) {
  const containerEl = document.createElement('div')
  containerEl.innerHTML = text
  containerEl.setAttribute('role', 'alert')
  containerEl.style.cssText = `
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  `

  requestAnimationFrame(() => {
    document.body.appendChild(containerEl)
    setTimeout(() => {
      containerEl.remove()
    }, 500)
  })
}
