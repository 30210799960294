import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import HashIcon from 'Images/icons/hash.svg'
import UserIcon from 'Images/icons/user.svg'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedSourceType() {
  const { st } = useTimers()
  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)

  function animove(url) {
    setIsLoaded(false)
    st(() => {
      navigate(url)
    }, 200)
  }

  return (
    <>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        What kind of feed?
      </h1>

      <div
        className={classNames(styles.options, { [styles.loaded]: isLoaded })}
      >
        <div className={styles.options_column}>
          <button
            id="skip-target"
            className={styles.option}
            onClick={() => animove('user/source')}
          >
            <div className={styles.option__inner}>
              <UserIcon />
              User
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            Posts from a single
            <br />
            Instagram account
          </div>
        </div>
        <div className={styles.options_column}>
          <button
            className={styles.option}
            onClick={() => animove('hashtag/source')}
          >
            <div className={styles.option__inner}>
              <HashIcon />
              Hashtag
              <CaretRightIcon className={styles.caret} />
            </div>
          </button>
          <div className={styles.option_footer}>
            Public posts from one
            <br />
            or more hashtags
          </div>
        </div>
      </div>
    </>
  )
}
