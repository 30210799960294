import { useState, useEffect, useRef, useCallback } from 'react'
import { createPortal } from 'preact/compat'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useHotkey from 'Hooks/useHotkey'
import useTrapFocus from 'Hooks/useTrapFocus'
import useTimers from 'Hooks/useTimers'
import XIcon from 'Images/icons/x.svg'
import styles from './Popup.module.scss'

/*
 *  Popup
 */
export default function Popup({
  onRequestClose,
  onOpen,
  forceClose = false,
  className,
  children,
  showCloseButton = true,
  closeOnMaskClick = true,
  closeFocusRef,
  trapFocus = true,
}) {
  const [isVisible, setIsVisible] = useState(null)
  const [isHidden, setIsHidden] = useState(null)
  const { raf, st } = useTimers()
  const containerRef = useRef()
  const closeRef = useRef()

  if (trapFocus) {
    useTrapFocus(containerRef.current, isVisible && !isHidden)
  }

  const close = useCallback(() => {
    setIsHidden(true)

    if (closeFocusRef?.current) {
      closeFocusRef.current.focus()
    }

    st(() => {
      setIsVisible(false)
      setIsHidden(false)
      onRequestClose()
    }, 300)
  }, [onRequestClose, st])

  useHotkey('Escape', close)

  useEffect(() => {
    st(() => {
      raf(() => {
        setIsVisible(true)
        if (onOpen) onOpen()
      })
    }, 10)
  }, [onOpen, st, raf])

  useEffect(() => {
    if (forceClose) close()
  }, [close, forceClose])

  return createPortal(
    <div className={classnames(styles.outer, { [styles.hidden]: isHidden })}>
      <div
        className={classnames(styles.mask, {
          [styles.visible]: isVisible,
          [styles.hidden]: isHidden,
        })}
        onClick={() => {
          if (closeOnMaskClick) {
            close()
          }
        }}
      ></div>
      <aside
        ref={containerRef}
        className={classnames(styles.container, className, {
          [styles.visible]: isVisible,
          [styles.hidden]: isHidden,
        })}
      >
        {children}
        {showCloseButton && (
          <button
            ref={closeRef}
            onClick={close}
            className={classnames(styles.close_button, {
              [styles.visible]: isVisible,
              [styles.hidden]: isHidden,
            })}
          >
            <XIcon />
          </button>
        )}
      </aside>
    </div>,
    document.body,
  )
}

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onRequestClose: PropTypes.func,
  onOpen: PropTypes.object,
  forceClose: PropTypes.bool,
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
  closeOnMaskClick: PropTypes.bool,
  closeFocusRef: PropTypes.object,
  trapFocus: PropTypes.bool,
}
