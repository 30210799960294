import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useTimers from 'Hooks/useTimers'
import NotificationIcon from 'Images/icons/notification-fill.svg'
import styles from './Notice.module.scss'

export default function Notice({
  title,
  children,
  button,
  animateIn = true,
  compact = false,
}) {
  const [loaded, setLoaded] = useState(0)
  const { st, raf } = useTimers()
  const [height, setHeight] = useState(0)
  const containerRef = useRef(null)

  useEffect(() => {
    st(
      () =>
        raf(() => {
          setHeight(containerRef.current.scrollHeight + 'px')
          setLoaded(true)
          st(() => {
            setHeight('auto')
          }, 600)
        }),
      200,
    )
  }, [st, raf])

  return (
    <aside
      style={{
        height: animateIn ? height : null,
      }}
      className={classnames(styles.container, {
        [styles.is_loaded]: loaded,
        [styles.is_animated]: animateIn,
        [styles.is_compact]: compact,
      })}
    >
      <div ref={containerRef} className={styles.inner}>
        <div className={styles.text}>
          {title && (
            <h1 className={styles.title}>
              <NotificationIcon />
              {title}
            </h1>
          )}
          {children && <div className={styles.subtitle}>{children}</div>}
        </div>
        {button}
      </div>
    </aside>
  )
}

Notice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  title: PropTypes.string,
  button: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  animateIn: PropTypes.bool,
}
