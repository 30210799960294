import Layout from 'Components/Layout'
import TransitionWrapper from 'Components/TransitionWrapper'
import PageHeader from 'Components/PageHeader'
import styles from './DeveloperSettingsNotSupported.module.scss'

export default function DeveloperSettingsNotSupported() {
  return (
    <Layout metaTitle="Developer Settings | Behold">
      <main className={styles.container}>
        <PageHeader title="Developer settings" />

        <TransitionWrapper>
          <div className={styles.inner}>
            Our admin API is not yet released. Contact{' '}
            <a href="mailto:support@behold.so">support@behold.so</a> if you have
            questions!
          </div>
        </TransitionWrapper>
      </main>
    </Layout>
  )
}
