import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSlice } from 'State'
import { setBillingEmail } from 'Helpers/services'
import { updateUser as updateUserInFirestore } from 'Fire/firebaseActions'
import Popup from 'Components/Popup'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import styles from './ChangeBillingEmailPopup.module.scss'

export default function ChangeBillingEmailPopup({
  onRequestClose,
  onSuccess,
  closeFocusRef,
}) {
  const appState = useSlice('user', 'addNotification', 'updateUser')
  const [forceClose, setForceClose] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [newBillingEmail, setNewBillingEmail] = useState(
    appState.user.billingEmail || appState.user.email,
  )
  const emailInputRef = useRef()

  /*
   *   Submit
   */
  async function submit() {
    setIsLoading(true)

    try {
      await setBillingEmail(appState.user.stripeCustomerId, newBillingEmail)
      await updateUserInFirestore({ billingEmail: newBillingEmail })

      appState.updateUser({ billingEmail: newBillingEmail })

      setIsLoading(false)
      onSuccess()
    } catch (error) {
      setIsLoading(false)
      if (error.message) setErrorMessage(error.message)
    }
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    emailInputRef.current.select()
  }, [])

  return (
    <Popup
      onRequestClose={onRequestClose}
      forceClose={forceClose}
      showCloseButton={false}
      closeFocusRef={closeFocusRef}
    >
      <div className={classnames(styles.container)}>
        <header className={styles.header}>
          <h1 className={styles.title}>Update billing email</h1>
        </header>
        <div className={styles.intro}>
          Receipts and billing-related emails will be sent to this email
          address.
        </div>
        <Form
          className={styles.form}
          submitText="Submit"
          showArrow={false}
          onSubmit={submit}
          submitIsDisabled={!newBillingEmail || !newBillingEmail.length}
          buttonAlignment="right"
          showCancelButton
          onCancel={() => setForceClose(true)}
          isLoading={isLoading}
          errorMessage={errorMessage}
        >
          <FormItem inputId="billing-email">
            <ControlledInput
              id="billing-email"
              ref={emailInputRef}
              value={newBillingEmail}
              type="email"
              onChange={setNewBillingEmail}
              disabled={isLoading}
            />
          </FormItem>
        </Form>
      </div>
    </Popup>
  )
}

ChangeBillingEmailPopup.propTypes = {
  onRequestClose: PropTypes.func,
  onSuccess: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
