import { useEffect, useCallback } from 'react'

const hotkeys = {}

function handleKeydown(evt) {
  if (Object.keys(hotkeys).length < 1) return
  const latestHandler = hotkeys[evt.key]
    ? Array.from(hotkeys[evt.key]).pop()
    : null
  if (latestHandler) {
    latestHandler()
  }
}

document.addEventListener('keydown', handleKeydown)

export default function useHotkey(hotkey, handler, isActive = true) {
  const unset = useCallback(() => {
    hotkeys?.[hotkey]?.delete(handler)
  }, [hotkey, handler])

  useEffect(() => {
    if (isActive) {
      if (hotkeys?.[hotkey]) {
        hotkeys?.[hotkey]?.add(handler)
      } else {
        hotkeys[hotkey] = new Set([handler])
      }
    } else {
      hotkeys?.[hotkey]?.delete(handler)
    }

    return () => {
      hotkeys?.[hotkey]?.delete(handler)
    }
  }, [hotkey, handler, isActive])

  return unset
}
