import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSlice } from 'State'
import useAuthUrl from 'Hooks/useAuthUrl'
import Popup from 'Components/Popup'
import CopyText from 'Components/CopyText'
import { removeSearchParam } from 'Helpers/utils'
import CaretRightIcon from 'Images/icons/caret-right.svg'
import styles from './ReauthPopup.module.scss'

export default function ReauthPopup({
  sourceId,
  onRequestClose,
  closeFocusRef,
  redirectTo = 'feeds',
}) {
  const { authorizedAccounts } = useSlice('authorizedAccounts')
  const source = authorizedAccounts[sourceId]
  const {
    basicAuthUrl,
    advancedAuthUrlBusinessPortfolio,
    advancedAuthUrlFacebookUser,
  } = useAuthUrl({
    isReauth: true,
    afterAuthRedirect: redirectTo,
  })
  const isAdvancedSource = source.type.includes('Advanced')
  let authLink = basicAuthUrl

  if (isAdvancedSource && source.accountType === 'SYSTEM_USER') {
    authLink = advancedAuthUrlBusinessPortfolio
  }

  if (isAdvancedSource && source.accountType === 'FACEBOOK') {
    authLink = advancedAuthUrlFacebookUser
  }

  return (
    <Popup onRequestClose={onRequestClose} closeFocusRef={closeFocusRef}>
      <div className={classnames(styles.container)}>
        <header className={styles.header}>
          <h1 className={styles.title}>
            Reauthorize {source.username && '@'}
            {source.label}
          </h1>
        </header>
        <div className={styles.content}>
          <div className={styles.intro}>
            This is your unique auth link. <br />
            Visit this URL to connect an Instagram account.
          </div>
          <div className={styles.auth_link}>
            <CopyText
              className={styles.copy_text}
              value={removeSearchParam(authLink, 'next')}
            />
            <a href={authLink} className={styles.button}>
              Open link <CaretRightIcon />
            </a>
          </div>

          <div className={styles.hint}>
            <b>Tip:</b> You can send this link to other people. They don&apos;t
            need to be logged-in to Behold to use it.
          </div>
        </div>
      </div>
    </Popup>
  )
}

ReauthPopup.propTypes = {
  onRequestClose: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
