import { useState, useEffect, useMemo, useCallback } from 'react'
import classNames from 'classnames'
import { isPopulated } from 'Helpers/utils'
import useWindowSize from 'Hooks/useWindowSize'
import Video from 'Components/Video'
import TransitionWrapper from 'Components/TransitionWrapper'
import MobileIcon from 'Images/icons/mobile.svg'
import DesktopIcon from 'Images/icons/desktop.svg'
import styles from './FeedKlaviyoPreview.module.scss'

export default function FeedKlaviyoPreview({
  feed,
  posts,
  localWidgetSettings,
  showingMobileStyles,
  setShowingMobileStyles,
  previewLoadingColors,
}) {
  const windowSize = useWindowSize()
  const [currentPreviewTab, setCurrentPreviewTab] = useState('DESKTOP')

  useEffect(() => {
    setCurrentPreviewTab(showingMobileStyles ? 'MOBILE' : 'DESKTOP')
  }, [showingMobileStyles])

  const setPreviewTab = useCallback(
    function setPreviewTab(val) {
      setCurrentPreviewTab(val)
      if (!localWidgetSettings.mobileStylesLinked) {
        setShowingMobileStyles(val === 'MOBILE')
      }
    },
    [localWidgetSettings, setShowingMobileStyles],
  )

  const settingsBase = useMemo(() => {
    let sb =
      currentPreviewTab === 'DESKTOP'
        ? localWidgetSettings.breakpoints.DESKTOP
        : localWidgetSettings.breakpoints.MOBILE

    if (localWidgetSettings.mobileStylesLinked) {
      sb = localWidgetSettings.breakpoints.DESKTOP
    }
    return sb
  }, [currentPreviewTab, localWidgetSettings])

  const imageEls = isPopulated(posts)
    ? posts
        .filter((p, i) => {
          let bp = currentPreviewTab
          if (localWidgetSettings.mobileStylesLinked) {
            bp = 'DESKTOP'
          }
          return i < localWidgetSettings.breakpoints[bp].numPosts
        })
        .map(
          ({
            id,
            mediaUrl,
            mediaUrlIsVideo,
            mediaType,
            thumbnailUrl,
            colorPalette,
            sizes,
            permalink,
          }) => {
            colorPalette = colorPalette || {}
            const loadingColor =
              previewLoadingColors || settingsBase.loadingColor
            let bgColor = `rgba(${colorPalette[loadingColor]})`

            if (loadingColor === 'tone') {
              bgColor = `rgba(${settingsBase.loadingColorTone})`
            }

            if (loadingColor === 'transparent') {
              bgColor = 'transparent'
            }

            const postStyles = {
              borderRadius: `${settingsBase.borderRadius}%`,
              backgroundColor: bgColor,
            }

            let link = permalink
            if (settingsBase.onPostClick === 'linkToProfile') {
              link = `https://www.instagram.com/${feed.username}`
            }
            if (settingsBase.onPostClick === 'customLink') {
              link = settingsBase.customLinkURL
            }

            const PostEl = ({ children }) => {
              return settingsBase.onPostClick === 'doNothing' ? (
                <div
                  className={classNames(styles.post, {
                    [styles.is_previewing_loading_colors]: previewLoadingColors,
                  })}
                  style={postStyles}
                >
                  {children}
                </div>
              ) : (
                <a
                  className={classNames(styles.post, {
                    [styles.is_previewing_loading_colors]: previewLoadingColors,
                  })}
                  style={postStyles}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {children}
                </a>
              )
            }

            return (mediaType === 'VIDEO' && !thumbnailUrl) ||
              mediaUrlIsVideo ? (
              <TransitionWrapper>
                <PostEl>
                  <Video
                    key={id}
                    className={styles.video}
                    src={mediaUrl}
                    currentTime={1}
                  />
                </PostEl>
              </TransitionWrapper>
            ) : (
              <TransitionWrapper>
                <PostEl>
                  <img
                    key={id}
                    className={styles.photo}
                    src={
                      mediaType === 'VIDEO'
                        ? sizes?.medium?.mediaUrl ?? thumbnailUrl
                        : sizes?.medium?.mediaUrl ?? mediaUrl
                    }
                    width={sizes?.full?.width}
                    height={sizes?.full?.height}
                    backgroundColor={
                      colorPalette?.dominant
                        ? `rgb(${colorPalette?.dominant})`
                        : 'var(--color-green-grey-dark)'
                    }
                  />
                </PostEl>
              </TransitionWrapper>
            )
          },
        )
    : null

  const previewWidth = currentPreviewTab === 'DESKTOP' ? '600px' : '360px'

  const containerStyles = {
    gridTemplateColumns: `repeat(${settingsBase.numColumns}, 1fr)`,
    gap: `${settingsBase.gap.y}px ${settingsBase.gap.x}px`,
  }

  return (
    <div
      className={classNames(styles.preview)}
      style={{ '--preview-width': previewWidth }}
    >
      {windowSize.width > 700 && (
        <div className={styles.preview__tabs}>
          <button
            aria-label="Preview Desktop"
            onClick={() => setPreviewTab('DESKTOP')}
            className={classNames(styles.preview__tab, {
              [styles.current]: currentPreviewTab === 'DESKTOP',
            })}
          >
            <DesktopIcon />
          </button>
          <button
            aria-label="Preview Mobile"
            onClick={() => setPreviewTab('MOBILE')}
            className={classNames(styles.preview__tab, {
              [styles.current]: currentPreviewTab === 'MOBILE',
            })}
          >
            <MobileIcon />
          </button>
        </div>
      )}
      <div className={styles.preview__posts}>
        <div className={styles.preview__posts_inner} style={containerStyles}>
          {imageEls}
        </div>
      </div>
    </div>
  )
}
