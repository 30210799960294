/* eslint-disable no-undef */
import { useEffect } from 'react'
import { onIdTokenChanged } from 'firebase/auth'
import { auth } from 'Fire/firebaseInit'
import { useSlice } from 'State'
import { pruneObject } from 'Helpers/utils'
import {
  setUID,
  subscribeToVersions,
  subscribeToAnnouncement,
  subscribeToUser,
  subscribeToSources,
  subscribeToProtectedUserInfo,
  subscribeToPaymentMethods,
  subscribeToPayments,
  getMonthlyUserUsage,
  subscribeToFeeds,
  getPlans,
  getFeedUsage,
} from 'Fire/firebaseActions'

const unsubscribes = []
let refreshedAuthToken = false

async function refreshAuthToken() {
  if (refreshedAuthToken) return
  refreshedAuthToken = true
  try {
    await auth.currentUser.getIdToken(true)
    await auth.currentUser.reload()
  } catch (error) {
    console.log(error)
  }
}

export function cancelAllListeners() {
  unsubscribes.forEach((unsub) => unsub())
}

export default function FirebaseConnect() {
  const appState = useSlice(
    'setAppVersion',
    'setAnnouncement',
    'setAppIsReady',
    'updateUser',
    'setAuthorizedAccounts',
    'setPlans',
    'setFeeds',
    'setFeedPosts',
    'setFeedUsage',
    'setNewFeedIsLoading',
    'resetAppState',
  )

  /*
   *   Subscribe to data sources
   */
  async function subscribeToData(authUser) {
    const results = await Promise.allSettled([
      // Subscribe to app version
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToVersions((data) => {
            try {
              if (!data) return
              appState.setAppVersion(data.webAppVersion)
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to announcements
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToAnnouncement((data) => {
            try {
              if (!data) return
              appState.setAnnouncement(data)
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to user
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToUser(authUser.uid, (data) => {
            try {
              if (!data) return
              appState.updateUser(
                pruneObject(data, ['id', 'email', 'photo', 'name']),
              )
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to protected user data
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToProtectedUserInfo(authUser.uid, (data) => {
            try {
              appState.updateUser(data)
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to user payment methods
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToPaymentMethods(authUser.uid, (data) => {
            try {
              appState.updateUser({ paymentMethods: data })
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to user payment receipts
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToPayments(authUser.uid, (data) => {
            try {
              appState.updateUser({ payments: data })
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to authorized accounts
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToSources(authUser.uid, (data) => {
            try {
              appState.setAuthorizedAccounts(data)
              if (!resolved) {
                resolved = true
                resolve()
              }
            } catch (error) {
              reject(error)

              if (error.code === 'permission-denied') {
                refreshAuthToken()
              }
            }
          }),
        )
      }),
      // Subscribe to feeds
      new Promise((resolve, reject) => {
        let resolved = false
        unsubscribes.push(
          subscribeToFeeds(
            authUser.uid,
            (data) => {
              try {
                appState.setFeeds(data)
                appState.setNewFeedIsLoading(false)
                if (!resolved) {
                  resolved = true
                  resolve()
                }
              } catch (error) {
                reject(error)

                if (error.code === 'permission-denied') {
                  refreshAuthToken()
                }
              }
            },
            ({ feedId, posts }) => {
              appState.setFeedPosts({
                feedId,
                posts,
              })
            },
            unsubscribes,
          ),
        )
      }),
    ])

    return results
  }

  /*
   *   Get one-time data
   */
  function getData(authUser) {
    return Promise.allSettled([
      // Get total monthly usage for user
      new Promise((resolve) => {
        let resolved = false
        try {
          getMonthlyUserUsage(authUser.uid, (data) => {
            appState.updateUser({ usageByMonth: data })

            if (!resolved) {
              resolved = true
              resolve()
            }
          })
        } catch (error) {
          if (error.code === 'permission-denied') {
            refreshAuthToken()
          }
        }
      }),

      // Get per-feed usage
      new Promise((resolve) => {
        let resolved = false
        try {
          getFeedUsage(authUser.uid, (data) => {
            appState.setFeedUsage(data)
            if (!resolved) {
              resolved = true
              resolve()
            }
          })
        } catch (error) {
          if (error.code === 'permission-denied') {
            refreshAuthToken()
          }
        }
      }),

      // Get plans
      new Promise((resolve) => {
        let resolved = false
        try {
          getPlans((snapshot) => {
            const plans = {}

            snapshot.forEach((doc) => {
              plans[doc.id] = doc.data()
            })

            appState.setPlans(plans)

            if (!resolved) {
              resolved = true
              resolve()
            }
          })
        } catch (error) {
          if (error.code === 'permission-denied') {
            refreshAuthToken()
          }
        }
      }),
    ])
  }

  /*
   *   Listen for login / signOut
   */
  useEffect(() => {
    /*
     *   Listen for user login
     */
    onIdTokenChanged(
      auth,
      async (authUser) => {
        unsubscribes.forEach((unsub) => unsub())

        // There is a logged in user
        if (authUser && typeof authUser.uid !== 'undefined') {
          const { uid, email, displayName, photoURL } = authUser
          appState.updateUser({
            id: uid,
            email,
            name: displayName,
            photo: photoURL,
          })

          setUID(authUser.uid)
          getData(authUser)
          await subscribeToData(authUser)
          appState.setAppIsReady(true)

          // No logged-in user
        } else {
          appState.resetAppState()
          appState.setAppIsReady(true)
        }
      },
      (error) => {
        console.log(error)
      },
    )
  }, []) // eslint-disable-line

  return null
}
