import { useEffect, useCallback, useMemo, useRef } from 'react'
import throttle from 'lodash.throttle'

export default function useOnResize(func, delay = 50, el = null) {
  const sizeRef = useRef(null)
  const onResize = useCallback(
    (entries) => {
      const dimensions = entries?.[0]?.contentBoxSize?.[0]

      if (!dimensions) return

      if (!sizeRef.current) {
        sizeRef.current = {
          height: dimensions.blockSize,
          width: dimensions.inlineSize,
        }
      } else {
        // Height change
        if (sizeRef.current.height !== dimensions.blockSize) {
          sizeRef.current = {
            height: dimensions.blockSize,
            width: dimensions.inlineSize,
          }
          func('height', dimensions.blockSize)
        }

        // Width change
        if (sizeRef.current.width !== dimensions.inlineSize) {
          sizeRef.current = {
            height: dimensions.blockSize,
            width: dimensions.inlineSize,
          }
          func('width', dimensions.inlineSize)
        }
      }
    },
    [func],
  )

  const onWindowResize = useCallback(() => {
    const dimensions = {
      blockSize: window.innerHeight,
      inlineSize: window.innerWidth,
    }

    if (!dimensions) return

    if (!sizeRef.current) {
      sizeRef.current = {
        height: dimensions.blockSize,
        width: dimensions.inlineSize,
      }
    } else {
      // Height change
      if (sizeRef.current.height !== dimensions.blockSize) {
        sizeRef.current = {
          height: dimensions.blockSize,
          width: dimensions.inlineSize,
        }
        func('height', dimensions.blockSize)
      }

      // Width change
      if (sizeRef.current.width !== dimensions.inlineSize) {
        sizeRef.current = {
          height: dimensions.blockSize,
          width: dimensions.inlineSize,
        }
        func('width', dimensions.inlineSize)
      }
    }
  }, [func])

  const throttledCallback = useMemo(
    () => throttle(onResize, delay),
    [onResize, delay],
  )
  const handleResize = useCallback(throttledCallback, [throttledCallback])
  const ro = useRef(new ResizeObserver(handleResize))

  const throttledWindowCallback = useMemo(
    () => throttle(onWindowResize, delay),
    [onWindowResize, delay],
  )
  const handleWindowResize = useCallback(throttledWindowCallback, [
    throttledWindowCallback,
  ])

  useEffect(() => {
    window.removeEventListener('resize', handleWindowResize)
    ro.current.disconnect()

    if (el === window) {
      window.addEventListener('resize', handleWindowResize)
    } else if (!!el) {
      if (el) ro.current.observe(el)
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      ro.current.disconnect()
    }
  }, [el, handleWindowResize])

  return () => {
    window.removeEventListener('resize', handleResize)
    ro.current.disconnect()
  }
}
