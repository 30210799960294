import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { addFeed } from 'Fire/firebaseActions'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import useIsLoaded from 'Hooks/useIsLoaded'
import useHotkey from 'Hooks/useHotkey'
import ControlledInput from 'Components/ControlledInput'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedName() {
  const appState = useSlice(
    'feeds',
    'addFeedIsLoading',
    'setAddFeedIsLoading',
    'setNewFeedIsLoading',
    'authorizedAccounts',
  )
  const navigate = useNavigate()
  const { sourceType, source: sourceId, type } = useParams()
  const { raf } = useTimers()
  const [isLoaded, setIsLoaded] = useIsLoaded(true)
  const [name, setName] = useState('')
  const inputRef = useRef(null)
  useHotkey('Enter', submit)

  const igAccounts = Object.values(appState.authorizedAccounts).reduce(
    (acc, curr) => {
      if (curr.type.includes('instagramAdvanced')) {
        Object.values(curr.proInstagramAccounts).forEach((proAccount) => {
          acc[`${curr.id}_${proAccount.id}`] = {
            ...proAccount,
            accessToken: curr.accessToken,
            expiresOn: curr.expiresOn ?? null,
            type: curr.type,
            active: curr.active,
          }
        })
        return acc
      } else {
        return { ...acc, ['_' + curr.id]: curr }
      }
    },
    {},
  )

  const source = igAccounts[sourceId]

  /*
   *   Submit
   */
  async function submit() {
    if (!source) return
    appState.setNewFeedIsLoading(true)
    appState.setAddFeedIsLoading(true)
    setIsLoaded(false)
    const newFeedId = await addFeed(
      {
        username: source.username,
        instagramUserId: source.instagramUserId,
        source: source,
        accessToken: source.accessToken,
        expiresOn: source.expiresOn,
        title: name,
        type: 'widget,klaviyo'.includes(type) ? 'WIDGET' : 'API',
        subtype: type === 'klaviyo' ? 'KLAVIYO' : null,
        contentSource: sourceType === 'hashtag' ? 'HASHTAG' : 'USER',
        followersCount: source.followersCount ?? 0,
        followsCount: source.followsCount ?? 0,
        mediaCount: source.mediaCount,
        profilePictureUrl: source.profilePictureUrl,
        website: source.website,
        ...(sourceType === 'hashtag' && { hashtags: [] }),
      },
      appState.feeds,
    )
    if (sourceType === 'hashtag') {
      navigate(
        `/add-feed/${newFeedId}_${source.instagramUserId}_${source.parentSourceId}/hashtags`,
      )
    } else {
      appState.setAddFeedIsLoading(false)
      navigate(`/feeds/${newFeedId}`)
    }
  }

  useEffect(() => {
    let label = 'JSON'
    if (type === 'widget') {
      label = 'widget'
    }
    if (type === 'klaviyo') {
      label = 'Klaviyo'
    }
    setName(`${source?.label ?? 'New'} ${label}`)
    if (isLoaded) {
      raf(() => {
        inputRef.current.select()
      })
    }
  }, [isLoaded])

  return appState.addFeedIsLoading ? null : (
    <div className={classNames(styles.name, { [styles.loaded]: isLoaded })}>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Pick a name for your feed
      </h1>
      <div
        className={classNames(styles.subtitle, { [styles.loaded]: isLoaded })}
      >
        Used to label your feed in the Behold admin.
        <br /> You can change this later.
      </div>
      <div className={styles.name__form}>
        <ControlledInput
          ref={inputRef}
          value={name}
          onChange={(val) => {
            setName(val)
          }}
          className={styles.input}
          placeholder={`@${source?.username || 'example'} ${
            type === 'widget' ? 'widget' : 'API'
          }`}
          size="large"
        />
        <button
          id="skip-target"
          className={styles.submit_button}
          onClick={submit}
        >
          Create Feed
        </button>
      </div>
    </div>
  )
}
