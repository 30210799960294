import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

export default function Video({ onCanPlay, className, src, currentTime }) {
  const ref = useRef()

  useEffect(() => {
    if (currentTime && ref.current) ref.current.currentTime = currentTime
  }, [currentTime])

  return (
    <video ref={ref} className={className} onCanPlay={onCanPlay} src={src} />
  )
}

Video.propTypes = {
  onCanPlay: PropTypes.func,
  className: PropTypes.string,
  src: PropTypes.string,
  currentTime: PropTypes.number,
}
