import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TransitionWrapper from 'Components/TransitionWrapper'
import ChatIcon from 'Images/icons/chat.svg'
import CaretRight from 'Images/icons/caret-right.svg'
import styles from './LiveChatButton.module.scss'

export default function LiveChatButton({
  size = 'medium',
  hideWhenOffline = true,
}) {
  const [chatIsLive, setChatIsLive] = useState(false)

  function toggleChat(evt) {
    setChatIsLive(evt === 'online' || evt.data === 'online' ? true : false)
  }

  function openChat() {
    window.tidioChatApi.show()
    window.tidioChatApi.open()
  }

  useEffect(() => {
    if (window.tidioChatApi) {
      window.tidioChatApi.on('setStatus', toggleChat)
      toggleChat(window.beholdTidioOnlineStatus || 'offline')
    } else {
      document.addEventListener('tidioChat-setStatus', toggleChat)
    }
  }, [])

  const onlineMessage = size === 'small' ? 'Live chat' : 'Online'
  const offlineMessage = size === 'small' ? 'Chat offline' : 'Offline'

  return hideWhenOffline && !chatIsLive ? null : (
    <TransitionWrapper>
      <button
        className={classNames(styles.live_chat, {
          [styles.live_chat__online]: chatIsLive,
        })}
        onClick={chatIsLive ? openChat : null}
      >
        <div className={styles.live_chat__indicator} />
        <div className={styles.live_chat__status}>
          <ChatIcon /> {chatIsLive ? onlineMessage : offlineMessage}
        </div>
        {chatIsLive && (
          <div className={styles.live_chat__cta}>
            {size === 'medium' && <>Start chat</>} <CaretRight />
          </div>
        )}
      </button>
    </TransitionWrapper>
  )
}

LiveChatButton.propTypes = {
  size: PropTypes.string,
  hideWhenOffline: PropTypes.bool,
}
