import { useState, useRef, useEffect, useCallback } from 'react'
import { forwardRef } from 'preact/compat'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Eyecon from 'Images/icons/eye.svg'
import styles from './ControlledInput.module.scss'

const ControlledInput = forwardRef(function ControlledInput(
  {
    onChange,
    onFocus,
    onBlur,
    onMaxExceeded,
    className = '',
    value = '',
    min,
    max,
    type = 'text',
    noBlank = true,
    ariaLabel = null,
    updateOn = 'change',
    updateOnEnter = false,
    saveButtonText = 'Save',
    forceClear = null,
    size = 'medium',
    unstyled,
    ...rest
  },
  ref,
) {
  const [currentVal, setCurrentVal] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const forceClearRef = useRef(forceClear)
  const Tag = type === 'textarea' ? 'textarea' : 'input'

  /*
   *   Submit change
   */
  const submit = useCallback(
    (val) => {
      let newVal = type === 'number' ? parseInt(val) : val

      if (type === 'number' && typeof min !== 'undefined') {
        newVal = Math.max(newVal, min)
      }
      if (type === 'number' && typeof max !== 'undefined') {
        newVal = Math.min(newVal, max)
        if (val > max && onMaxExceeded) onMaxExceeded(val, max)
      }
      onChange(newVal)
    },
    [type, noBlank, min, max, onChange],
  )

  /*
   *   Change Handler
   */
  const handleChange = useCallback(
    (evt) => {
      if (updateOn === 'change') {
        submit(type === 'number' ? evt.target.valueAsNumber : evt.target.value)
      } else {
        setCurrentVal(
          type === 'number' ? evt.target.valueAsNumber : evt.target.value,
        )
      }
    },
    [submit, updateOn],
  )

  /*
   *   Keydown handler
   */
  const handleKeydown = useCallback(
    (evt) => {
      if (evt.key === 'Enter' && updateOnEnter) {
        let newVal = type === 'number' ? parseInt(currentVal) : currentVal

        if (type === 'number' && isNaN(newVal) && noBlank) {
          newVal = 0
        }
        submit(newVal)
      }
    },
    [currentVal, updateOnEnter, submit],
  )

  /*
   *   Handle focus
   */
  const handleFocus = useCallback(() => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }, [onFocus])

  /*
   *   Handle Blur
   */
  const handleBlur = useCallback(
    (evt) => {
      setIsFocused(false)
      let newVal =
        type === 'number' ? evt.target.valueAsNumber : evt.target.value

      if (type === 'number' && isNaN(newVal) && noBlank) {
        newVal = 0
      }

      if (updateOn !== 'button') {
        submit(newVal)
      }

      if (onBlur) onBlur()
    },
    [submit, updateOn, onBlur],
  )

  /*
   *   Toggle show password
   */
  function toggleShow() {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (forceClear !== forceClearRef.current) {
      forceClearRef.current = forceClear
      setCurrentVal('')
    }
  }, [forceClear])

  return updateOn === 'button' ? (
    <form
      onSubmit={(evt) => {
        evt.preventDefault()
        submit(currentVal)
      }}
      className={classnames(
        styles.container,
        styles[`size_${size}`],
        className,
        {
          [styles.focused]: isFocused,
        },
      )}
    >
      <Tag
        ref={ref}
        className={classnames(styles.input, styles[`size_${size}`])}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
        value={updateOn === 'change' ? String(value) : String(currentVal)}
        aria-label={ariaLabel}
        type={type === 'textarea' ? null : type}
        min={min}
        max={onMaxExceeded ? null : max}
        {...rest}
      />
      <button
        type="button"
        className={styles.button}
        onClick={() => submit(currentVal)}
        disabled={
          type === 'number'
            ? currentVal === value || isNaN(currentVal)
            : currentVal === value || (noBlank && currentVal === '')
        }
      >
        {saveButtonText}
      </button>
    </form>
  ) : type === 'password' ? (
    <div
      className={classnames(
        styles.container,
        className,
        styles[`size_${size}`],
        {
          [styles.focused]: isFocused,
          [styles.revealed]: showPassword,
          [styles.unstyled]: unstyled,
        },
      )}
    >
      <Tag
        ref={ref}
        className={classnames(styles.input, styles[`size_${size}`], className)}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
        value={updateOn === 'change' ? String(value) : String(currentVal)}
        type={showPassword ? 'text' : type}
        aria-label={ariaLabel}
        {...rest}
      />
      <button
        type="button"
        title={showPassword ? 'Hide password' : 'Show password'}
        aria-label={showPassword ? 'Hide password' : 'Show password'}
        className={classnames(styles.reveal, { [styles.hide]: showPassword })}
        onClick={toggleShow}
      >
        <Eyecon />
      </button>
    </div>
  ) : (
    <Tag
      ref={ref}
      className={classnames(styles.input, styles[`size_${size}`], className, {
        [styles.unstyled]: unstyled,
      })}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeydown}
      value={updateOn === 'change' ? String(value) : String(currentVal)}
      aria-label={ariaLabel}
      type={type === 'textarea' ? null : type}
      min={min}
      max={onMaxExceeded ? null : max}
      {...rest}
    />
  )
})
export default ControlledInput

ControlledInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMaxExceeded: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noBlank: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  updateOn: PropTypes.string,
  saveButtonText: PropTypes.string,
  forceClear: PropTypes.any,
  size: PropTypes.string,
  unstyled: PropTypes.bool,
}
