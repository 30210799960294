import Metas from 'Components/Metas'
import Logo from 'Images/logo.svg'
import styles from './DeleteData.module.scss'

export default function DeleteData() {
  return (
    <main className={styles.container}>
      <Metas title="Data removed | Behold" />
      <Logo />
      <h1 className={styles.title}>Data Removal Successful</h1>
      <h2 className={styles.subtitle}>
        Your data removal request has been processed. There was no stored user
        data associated with this account.
      </h2>
    </main>
  )
}
