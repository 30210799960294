import PropTypes from 'prop-types'
import generateKlaviyoCode from 'Helpers/generateKlaviyoCode'
import FeedPanel from 'Components/feed/FeedPanel'
import Lit from 'Components/Lit'
import CopyText from 'Components/CopyText'
import CodeIcon from 'Images/icons/code.svg'
import UpgradeIcon from 'Images/icons/premium-solid.svg'
import styles from 'Components/feed/FeedPanel.module.scss'
import useFeatures from 'Hooks/useFeatures'

const sampleCode = `<table class="not-real-code" style="display:none;">
<tbody>
  <tr>
    <td><div style="border:1px solid papayawhip;"></div></td>
    <td><div style="border:1px solid papayawhip;"></div></td>
    <td><div style="border:1px solid papayawhip;"></div></td>
  </tr>
  <tr>
    <td><div style="border:1px solid papayawhip;"></div></td>
    <td><div style="border:1px solid papayawhip;"></div></td>
    <td><div style="border:1px solid papayawhip;"></div></td>
  </tr>
</tbody>
</table>`

export default function FeedPanelKlaviyoEmbedCode({ feed, posts, panelId }) {
  const features = useFeatures()

  return (
    <FeedPanel
      panelId={panelId}
      width={500}
      icon={<CodeIcon />}
      title="Add to Klaviyo"
    >
      <div className={styles.description}>
        <p>
          Adding this code to an email template requires a couple simple updates
          in your Klaviyo admin.
        </p>
        <p>
          See our docs for{' '}
          <a
            href="https://behold.so/docs/klaviyo"
            target="_blank"
            rel="noreferrer"
          >
            step by step instructions
          </a>
          , and keep this tab open, you&apos;ll need the <b>Feed name</b>,{' '}
          <b>Feed URL</b> and <b>Embed code</b> shown below.
        </p>
      </div>
      {features.has('widgetType:klaviyo') && (
        <div className={styles.setting}>
          <div className={styles.label}>Feed name</div>
          <CopyText value={feed.widgetSettings.klaviyoWebFeedId} />
        </div>
      )}
      {features.has('widgetType:klaviyo') && (
        <div className={styles.setting}>
          <div className={styles.label}>Feed URL</div>
          <CopyText value={`${process.env.FEEDS_URL}/${feed.id}`} />
        </div>
      )}
      <div className={styles.setting}>
        <div className={styles.label}>Embed code</div>
        {features.has('widgetType:klaviyo') ? (
          <Lit language="html" className={styles.klaviyo_code_lit} copy>
            {generateKlaviyoCode(feed, posts)}
          </Lit>
        ) : (
          <div className={styles.klaviyo_code_upgrade}>
            <div className={styles.klaviyo_code_upgrade__inner}>
              <p>
                Upgrade to a <b>Business</b> plan or greater to access your
                embed code.
              </p>
              <a
                href="/choose-plan/Business"
                className={styles.klaviyo_code_upgrade__button}
              >
                <UpgradeIcon /> Upgrade Now
              </a>
            </div>
            <Lit
              language="html"
              className={styles.klaviyo_code_upgrade__lit}
              copy={false}
            >
              {sampleCode}
            </Lit>
          </div>
        )}
      </div>
    </FeedPanel>
  )
}

FeedPanelKlaviyoEmbedCode.propTypes = {
  feedId: PropTypes.string,
  panelId: PropTypes.string,
}
