import { forceLayout } from 'Helpers/utils'
let notes = []

export function clickNote(evt, note, opts) {
  const x = opts && opts.x ? opts.x : evt.clientX
  const y = opts && opts.y ? opts.y : evt.clientY
  const viewWidth = window.innerWidth
  const container = document.createElement('div')
  container.setAttribute('role', 'alert')
  const delay = opts && opts.duration ? opts.duration : 2000
  let timeOut = null
  let position = 'top'

  container.innerHTML = note

  if (x < viewWidth * 0.2) {
    position = 'right'
  } else if (x > viewWidth * 0.8) {
    position = 'left'
  }

  if (opts.forcePosition) {
    position = opts.forcePosition
  }

  container.style.cssText = `
    position: fixed;
    z-index: 1000;
    left: ${x}px;
    top: ${y}px;
    max-width: 250px;
    transform: translate(-50%, -50%);
    padding: 5px 10px 6px;
    text-align: center;
    border-radius: var(--border-radius-small);
    background-color:#fff;
    box-shadow: var(--shadow2);
    opacity: 0;
    transition: all .3s ease;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.33;
    pointer-events: none;
  `

  if (position === 'right') {
    container.style.transform = 'translate(0%, -50%)'
  } else if (position === 'left') {
    container.style.transform = 'translate(-100%, -50%)'
  }

  document.body.appendChild(container)

  forceLayout()

  timeOut = setTimeout(() => {
    requestAnimationFrame(() => {
      container.style.opacity = 1
      container.style.boxShadow = 'var(--shadow2)'

      if (position === 'top') {
        container.style.transform = 'translate(-50%, -120%)'
      } else if (position === 'right') {
        container.style.transform = 'translate(20%, -50%)'
      } else if (position === 'left') {
        container.style.transform = 'translate(-120%, -50%)'
      }

      timeOut = setTimeout(() => {
        requestAnimationFrame(() => {
          container.style.opacity = 0

          if (position === 'top') {
            container.style.transform = 'translate(-50%, -175%)'
          } else if (position === 'right') {
            container.style.transform = 'translate(40%, -50%)'
          } else if (position === 'left') {
            container.style.transform = 'translate(-140%, -50%)'
          }

          timeOut = setTimeout(() => {
            container.remove()
          }, 300)
        })
      }, delay)
    })
  })

  const noteObj = {
    cancel: function () {
      clearTimeout(timeOut)
      container.remove()
    },
    el: container,
  }

  notes.push(noteObj)

  return noteObj
}

/*
 *   Cancel all
 */
export function cancelAllNotes() {
  notes.forEach((n) => n.cancel())
  notes = []
}

export default clickNote
