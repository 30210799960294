import PropTypes from 'prop-types'
import { useState } from 'react'
import classNames from 'classnames'
import {
  isPopulated,
  splitIntoColumns,
  getSortedPostsJSON,
} from 'Helpers/utils'
import { v1 as v2ToV1 } from 'Helpers/feedVersionTransformers'
import useWindowSize from 'Hooks/useWindowSize'
import LazyImage from 'Components/LazyImage'
import Video from 'Components/Video'
import Lit from 'Components/Lit'
import TransitionWrapper from 'Components/TransitionWrapper'
import Eyecon from 'Images/icons/eye-outline.svg'
import CodeIcon from 'Images/icons/json.svg'
import styles from './FeedApiPreview.module.scss'

export default function FeedApiPreview({
  feed,
  posts,
  feedMetadata,
  localFeedSettings,
}) {
  const windowSize = useWindowSize()
  const [currentPreviewTab, setCurrentPreviewTab] = useState('JSON')
  const displayPosts = structuredClone(posts)

  const imageEls = isPopulated(posts)
    ? splitIntoColumns(
        posts.map(
          ({
            id,
            mediaUrl,
            mediaUrlIsVideo,
            mediaType,
            thumbnailUrl,
            colorPalette,
            sizes,
          }) => {
            return (mediaType === 'VIDEO' && !thumbnailUrl) ||
              mediaUrlIsVideo ? (
              <Video
                key={id}
                className={styles.video}
                src={mediaUrl}
                currentTime={1}
              />
            ) : (
              <LazyImage
                key={id}
                className={styles.photo}
                src={
                  mediaType === 'VIDEO'
                    ? sizes?.large?.mediaUrl ?? thumbnailUrl
                    : sizes?.large?.mediaUrl ?? mediaUrl
                }
                width={sizes?.large?.width}
                height={sizes?.large?.height}
                backgroundColor={
                  colorPalette?.dominant
                    ? `rgb(${colorPalette?.dominant})`
                    : 'var(--color-green-grey-dark)'
                }
              />
            )
          },
        ),
        3,
      )
    : null

  let jsonPreviewObject = {
    username: feedMetadata.username,
    biography: feedMetadata.biography,
    profilePictureUrl: feedMetadata.profilePictureUrl,
    website: feedMetadata.website,
    followersCount: feedMetadata.followersCount,
    followsCount: feedMetadata.followsCount,
    posts: displayPosts,
  }

  // Apply version transformers

  switch (parseInt(localFeedSettings.version ?? 1)) {
    case 1:
      jsonPreviewObject = v2ToV1(jsonPreviewObject, feed)
      break
    case 2:
      break
  }

  const codeString = getSortedPostsJSON(jsonPreviewObject, 2)

  return (
    <div className={classNames(styles.preview)}>
      {windowSize.width > 700 && (
        <div className={styles.preview__tabs}>
          <button
            aria-label="JSON preview"
            onClick={() => setCurrentPreviewTab('JSON')}
            className={classNames(styles.preview__tab, {
              [styles.current]: currentPreviewTab === 'JSON',
            })}
          >
            <CodeIcon /> JSON
          </button>
          <button
            aria-label="Image preview"
            onClick={() => setCurrentPreviewTab('IMAGES')}
            className={classNames(styles.preview__tab, {
              [styles.current]: currentPreviewTab === 'IMAGES',
            })}
          >
            <Eyecon /> Images
          </button>
        </div>
      )}
      <div
        className={classNames(styles.preview__photos, {
          [styles.active]:
            currentPreviewTab === 'IMAGES' && windowSize.width > 700,
        })}
      >
        {isPopulated(posts) && (
          <>
            <div className={styles.preview__photos_column}>{imageEls[0]}</div>
            <div className={styles.preview__photos_column}>{imageEls[1]}</div>
            <div className={styles.preview__photos_column}>{imageEls[2]}</div>
          </>
        )}
      </div>
      <TransitionWrapper
        show={isPopulated(posts)}
        className={classNames(styles.preview__json, {
          [styles.active]:
            currentPreviewTab === 'JSON' || windowSize.width < 701,
        })}
      >
        <Lit copy highlight={windowSize.width > 700}>
          {codeString}
        </Lit>
      </TransitionWrapper>
    </div>
  )
}

FeedApiPreview.propTypes = {
  posts: PropTypes.array,
  fullFeed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  api: PropTypes.string,
}
