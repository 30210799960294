import { useEffect, useState, useCallback, useRef } from 'react'

const STORAGE_KEY = 'behold_local_app_storage_'

function getInitialValue(type) {
  const storageObj = type === 'session' ? sessionStorage : localStorage
  const rawStoredValue = storageObj.getItem(STORAGE_KEY)
  if (rawStoredValue) {
    return JSON.parse(rawStoredValue)
  } else {
    return JSON.stringify({})
  }
}

export default function useStorage(type = 'local') {
  const [value, setValue] = useState(getInitialValue(type))
  const valueRef = useRef(getInitialValue(type))
  const storageObj = type === 'session' ? sessionStorage : localStorage

  const setVal = useCallback((key, val) => {
    const updated = {
      ...valueRef.current,
      [key]: val,
    }
    valueRef.current = updated
    storageObj.setItem(STORAGE_KEY, JSON.stringify(updated))
    window.dispatchEvent(new Event('storage'))
    setValue(updated)
  }, [])

  const handleStorageEvent = useCallback(() => {
    const rawStoredValue = storageObj.getItem(STORAGE_KEY)
    valueRef.current = JSON.parse(rawStoredValue)
    setValue(JSON.parse(rawStoredValue))
  }, [])

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [handleStorageEvent])

  return [value || {}, setVal]
}
