import PropTypes from 'prop-types'
import { useState } from 'preact/hooks'
import FeedPanel from 'Components/feed/FeedPanel'
import Lit from 'Components/Lit'
import Toggle from 'Components/Toggle'
import CodeIcon from 'Images/icons/code.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelEmbedCode({
  feedId,
  panelId,
  demoMode = false,
}) {
  const [embedVersion, setEmbedVersion] = useState('STANDARD')

  let code = `<behold-widget feed-id="${feedId}"></behold-widget>
<script>
  (() => {
    const d=document,s=d.createElement("script");s.type="module";
    s.src="https://w.behold.so/widget.js";d.head.append(s);
  })();
</script>`

  if (embedVersion === 'COMPATIBILITY') {
    code = `<div data-behold-id="${feedId}"></div>
<script>
  (() => {
    const d=document,s=d.createElement("script");s.type="module";
    s.src="https://w.behold.so/widget.js";d.head.append(s);
  })();
</script>`
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={615}
      icon={<CodeIcon />}
      title="Embed code"
    >
      <div className={styles.setting}>
        {!demoMode && (
          <div className={styles.embed_toggle}>
            <Toggle
              values={[
                ['STANDARD', <>Standard</>],
                ['COMPATIBILITY', <>Compatibility</>],
              ]}
              align="center"
              value={embedVersion}
              onChange={setEmbedVersion}
            />
          </div>
        )}
        <Lit language="html" copy>
          {code}
        </Lit>
        {demoMode && (
          <div className={styles.demo_embed_overlay}>
            <div>
              <a href="https://app.behold.so/register">
                Sign up for a free account
              </a>{' '}
              to create your own widgets.
            </div>
          </div>
        )}
      </div>
      <div className={styles.description}>
        <p>
          Paste this embed code wherever you are able to put HTML script tags
          and your feed will appear! Help using Behold with specific platforms
          can be found in{' '}
          <a
            href="https://behold.so/docs#platforms"
            target="_blank"
            rel="noreferrer"
          >
            our docs.
          </a>
        </p>
        <p>
          Some systems will strip out the{' '}
          <small>
            &quot;<code>{`<behold-widget>`}</code>&quot;
          </small>{' '}
          component used in our standard embed, which breaks our widget. In
          those cases, using the compatibility embed code can fix the issue.
        </p>
      </div>
    </FeedPanel>
  )
}

FeedPanelEmbedCode.propTypes = {
  feedId: PropTypes.string,
  panelId: PropTypes.string,
}
