import { useState, useEffect, useRef, useMemo } from 'react'
import { v1 as uuidv1 } from 'uuid'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import XIcon from 'Images/icons/x.svg'
import styles from './TagInput.module.scss'

/*
 *   Normalize
 */
function normalize(string) {
  return string.toLowerCase().replace(/[,\s]/g, '')
}

export default function TagInput({
  onUpdate,
  className,
  tags,
  ariaLabel,
  size = 'medium',
  allowSpaces = false,
  normalizer = (tag) => tag,
  ...rest
}) {
  const [currentInputVal, setCurrentInputVal] = useState('')
  const [isfocused, setIsFocused] = useState(false)
  const tagsRef = useRef(tags)
  const inputValRef = useRef('')

  /*
   *   Submit updated tag list
   */
  function submit() {
    const newTag = allowSpaces
      ? normalizer(inputValRef.current)
      : normalizer(normalize(inputValRef.current))
    if (newTag) {
      onUpdate(Array.from(new Set([...tagsRef.current, newTag])), newTag)
      setCurrentInputVal('')
      inputValRef.current = ''
    }
  }

  /*
   *   Handle keydown
   */
  const handleKeydownRef = useRef((event) => {
    if (!allowSpaces && (event.code === 'Space' || event.code === 'Comma')) {
      event.preventDefault()
      submit()
    }
  })

  /*
   *   Handle form submit
   */
  function handleSubmit(event) {
    event.preventDefault()
    submit()
  }

  /*
   *   Remove tag
   */
  function removeTag(tag) {
    onUpdate(tags.filter((t) => t !== tag))
  }

  /*
   *   Handle input focus
   */
  function handleInputFocus() {
    setIsFocused(true)
    document.addEventListener('keydown', handleKeydownRef.current)
  }

  /*
   *   Handle input blur
   */
  function handleInputBlur() {
    setIsFocused(false)
    document.removeEventListener('keydown', handleKeydownRef.current)
    submit()
  }

  /*
   *   Handle input change
   */
  function handleInputChange(event) {
    setCurrentInputVal(event.target.value)
    inputValRef.current = event.target.value
  }

  /*
   *   Update tags ref
   */
  useEffect(() => {
    tagsRef.current = tags
  }, [tags])

  /*
   *   Cleanup
   */
  useEffect(() => {
    return () =>
      document.removeEventListener('keydown', handleKeydownRef.current)
  }, []) // eslint-disable-line

  /*
   *   Tags
   */
  const tagEls = tags.map((tag) => (
    <div key={tag} className={styles.tag}>
      {tag}
      <button
        className={styles.tag__remove}
        onClick={() => removeTag(tag)}
        type="button"
      >
        <XIcon />
      </button>
    </div>
  ))

  const inputId = useMemo(uuidv1, [])

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor={inputId}
        className={classnames(styles.container, {
          [styles.focused]: isfocused,
          [styles.large]: tagEls.length > 3,
          [styles.small]: size === 'small',
        })}
      >
        {tagEls}
        <input
          id={inputId}
          className={classnames(styles.input, className)}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={currentInputVal}
          aria-label={ariaLabel}
          type="text"
          size={5}
          {...rest}
        />
      </label>
    </form>
  )
}

TagInput.defaultProps = {
  className: '',
  tags: [],
  ariaLabel: null,
  normalizer: (str) => str,
}

TagInput.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  ariaLabel: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
  size: PropTypes.string,
  allowSpaces: PropTypes.bool,
}
