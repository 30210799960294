import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { widget } from '@behold/widget'
widget.register()

export default function Widget({
  posts,
  settings,
  metadata,
  previewLoadingColors,
}) {
  const widgetRef = useRef(null)

  useEffect(() => {
    widgetRef.current.posts = posts
    widgetRef.current.widgetSettings = settings
    widgetRef.current.feedMetadata = metadata
    widgetRef.current.previewLoadingColors = previewLoadingColors
  }, [posts, settings, metadata])

  return <behold-widget ref={widgetRef}></behold-widget>
}

Widget.propTypes = {
  posts: PropTypes.array,
  settings: PropTypes.object,
  metadata: PropTypes.object,
  previewLoadingColors: PropTypes.string,
}
