import PropTypes from 'prop-types'
import { auth } from 'Fire/firebaseInit'
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { validateEmail, validatePassword } from 'Helpers/utils'
import useStorage from 'Hooks/useStorage'
import Metas from 'Components/Metas'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import Logo from 'Images/logo.svg'
import GitHubLogo from 'Images/github.svg'
import GoogleLogo from 'Images/google.svg'
import styles from './AuthSignUp.module.scss'

const googleProvider = new GoogleAuthProvider()
const gitHubProvider = new GithubAuthProvider()

export default function AuthSignUp({ email, setEmail }) {
  const [, setLocalStorage] = useStorage()
  const { planAndPeriod } = useParams()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  /*
   *   Sign up with oAuth
   */
  function oAuthLogin(authProvider) {
    setIsLoading(true)

    signInWithPopup(auth, authProvider)
      .then(() => {})
      .catch(function (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
          setIsLoading(false)
          setErrorMessage('That email uses a different sign-in method.')
        }
        setIsLoading(false)

        console.log(error)
      })
  }

  /*
   *   Sign up with email and password
   */
  async function passwordSignUp() {
    if (!validateEmail(email)) {
      emailRef.current.focus()
      if (email.length < 1) {
        setErrorMessage('Please enter an email address')
      } else {
        setErrorMessage('Invalid email address')
      }
      return false
    } else if (!validatePassword(password)) {
      passwordRef.current.focus()
      setErrorMessage('Please enter a password')
      return false
    }

    setIsLoading(true)

    try {
      await createUserWithEmailAndPassword(auth, email, password)
    } catch (error) {
      setIsLoading(false)

      switch (error.code) {
        case 'auth/weak-password':
          setErrorMessage('Password must be at least 6 characters')
          break
        case 'auth/email-already-in-use':
          setErrorMessage(
            'There is already an account registered with this email address',
          )
          break
      }

      console.log(error)
    }
  }

  /*
   *    on load
   */
  useEffect(() => {
    if (planAndPeriod) {
      const [plan, interval] = planAndPeriod.split('-')
      setLocalStorage('SELECTED_PLAN', `${plan}-${interval}`)
    }

    if (emailRef.current && window.innerWidth > 600) emailRef.current.focus()
  }, [planAndPeriod, setLocalStorage])

  return (
    <main
      className={classnames(styles.container, { [styles.loading]: isLoading })}
    >
      <Metas title="Create an account | Behold" />
      <div className={styles.right}>
        <div className={styles.form}>
          <Form
            onSubmit={passwordSignUp}
            isLoading={isLoading}
            errorMessage={errorMessage}
            submitText="Submit"
            className={styles.email}
          >
            <h2 className={styles.section_title}>Create an account:</h2>

            <FormItem label="Email" inputId="email">
              <ControlledInput
                id="email"
                ref={emailRef}
                className={styles.input}
                type="email"
                onChange={setEmail}
                value={email}
              />
            </FormItem>

            <FormItem label="Password" note="6+ characters" inputId="password">
              <ControlledInput
                id="password"
                ref={passwordRef}
                className={styles.input}
                type="password"
                onChange={setPassword}
                value={password}
              />
            </FormItem>
          </Form>

          <div className={styles.oauth}>
            <h2 className={styles.oauth_title}>Or sign up with oAuth:</h2>
            <div className={styles.oauth__buttons}>
              <button
                className={styles.oauth_button}
                onClick={() => oAuthLogin(gitHubProvider, 'GitHub')}
              >
                <GitHubLogo />
                GitHub
              </button>
              <button
                className={styles.oauth_button}
                onClick={() => oAuthLogin(googleProvider, 'Google')}
              >
                <GoogleLogo /> Google
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.left}>
        <Link to="/" className={styles.logo}>
          <Logo />
        </Link>
        <footer className={styles.left__footer}>
          Already have an account? <Link to="/sign-in">Sign in</Link>
          <div className={styles.left__footer_links}>
            <a href="https://behold.so/terms" target="_blank" rel="noreferrer">
              Terms
            </a>{' '}
            <a
              href="https://behold.so/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </footer>
      </div>
    </main>
  )
}

AuthSignUp.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
}
