import { useSlice } from 'State'
import ErrorBoundary from 'Components/ErrorBoundary'
import BannerIcon from 'Images/icons/announcement.svg'
import styles from './Announcements.module.scss'

function Announcements() {
  const { announcement } = useSlice('announcement')

  return announcement?.isActive &&
    (announcement?.title || announcement?.text) ? (
    <aside className={styles.container}>
      <BannerIcon />
      <div className={styles.text}>
        <h1 className={styles.title}>{announcement.title}</h1>{' '}
        {announcement.text}
      </div>
    </aside>
  ) : null
}

export default function SafeAnnouncements(props) {
  return (
    <ErrorBoundary>
      <Announcements {...props} />
    </ErrorBoundary>
  )
}
