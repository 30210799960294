import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import Layout from 'Components/Layout'
import PageHeader from 'Components/PageHeader'
import SmartButton from 'Components/SmartButton'
import Switch from 'Components/Switch'
import PlanDetails from 'Components/PlanDetails'
import LiveChatButton from 'Components/LiveChatButton'
import TransitionWrapper from 'Components/TransitionWrapper'

import styles from './ChoosePlan.module.scss'

const displayPlans = ['Free', 'Personal', 'Business', 'Enterprise']

export default function ChoosePlan() {
  const windowSize = useWindowSize()
  const appState = useSlice('user', 'plans')
  const { planId } = useParams()
  const plans = appState.plans
  const { plan, stripeSubscriptionInterval } = appState.user
  const [selectedPlan, setSelectedPlan] = useState(planId)
  const [period, setPeriod] = useState('year')
  const selectedPlanObj = plans?.[selectedPlan]
  const navigate = useNavigate()

  /*
   *   Handle plan select
   */
  function handlePlanSelect(evt) {
    setSelectedPlan(evt.target.value)
  }

  useEffect(() => {
    if (planId && displayPlans.includes(planId)) return
    const currentIndex = displayPlans.indexOf(plan)
    if (currentIndex === -1) setSelectedPlan('Business')
    const nextIndex =
      currentIndex + 1 === displayPlans.length
        ? displayPlans.length - 1
        : currentIndex + 1
    setSelectedPlan(displayPlans[nextIndex])
  }, [plan, planId])

  /*
   *   Period switch
   */
  const periodSwitch = (
    <Switch
      toggle
      className={styles.period_switch}
      checked={period === 'month'}
      onChange={(status) => setPeriod(status ? 'month' : 'year')}
      before={
        <div
          className={
            period === 'year'
              ? styles.switch_label_current
              : styles.switch_label
          }
        >
          Annual
        </div>
      }
      after={
        <div
          className={
            period === 'month'
              ? styles.switch_label_current
              : styles.switch_label
          }
        >
          Monthly
        </div>
      }
      values={['annual', 'monthly']}
      ariaLabel="Billing frequency"
    />
  )

  /*
   *   Plans
   */
  const planEls =
    plans && selectedPlan
      ? displayPlans.map((id) => {
          const { title } = plans[id]
          const monthlyPrice =
            period === 'year'
              ? plans[id].price.year.amount / 12
              : plans[id].price.month.amount

          const annualPrice =
            period === 'year'
              ? plans[id].price.year.amount
              : plans[id].price.month.amount * 12

          const periodDisplayText = period === 'year' ? 'Annual' : 'Monthly'

          return (
            <div
              key={id}
              className={classnames(styles.plan, {
                [styles.small]: id === 'Free',
              })}
            >
              <input
                className={styles.plan__radio}
                id={id}
                type="radio"
                name="plan"
                value={title}
                onChange={handlePlanSelect}
                checked={selectedPlan === id}
              />
              <label className={styles.plan__inner} htmlFor={id}>
                <div className={styles.plan__title}>
                  {title}{' '}
                  {id !== 'Free' && (
                    <span className={styles.plan__title_period}>
                      {periodDisplayText}
                    </span>
                  )}
                </div>
                {((id === plan && period === stripeSubscriptionInterval) ||
                  (id === 'Free' && plan === 'Free')) && (
                  <TransitionWrapper scale className={styles.plan__current}>
                    Current
                  </TransitionWrapper>
                )}
                {id !== 'Free' && (
                  <div className={styles.plan__price}>
                    ${monthlyPrice}
                    <span className={styles.plan__price_period}> / mo</span>
                    <div className={styles.plan__annual_price}>
                      ${annualPrice}
                      <span className={styles.plan__price_period}> / year</span>
                    </div>
                  </div>
                )}
                {id === 'Free' && <div className={styles.plan__price}>-</div>}
              </label>
            </div>
          )
        })
      : [1, 2, 3, 4].map((i) => (
          <div key={i} className={styles.plan_loader}></div>
        ))

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Pick a plan | Behold">
      <main className={styles.container}>
        <PageHeader
          title="Pick a plan"
          stacked={windowSize.width < 701}
          breadCrumbs={[
            { text: 'Account', link: '/account' },
            { text: 'Pick a plan' },
          ]}
        >
          <TransitionWrapper
            show={period === 'year'}
            scale
            className={styles.save_note}
          >
            Saving 20%
          </TransitionWrapper>
          {periodSwitch}
        </PageHeader>

        <div className={styles.inner}>
          <div className={styles.sidebar}>
            <PlanDetails planId={selectedPlan} planObj={selectedPlanObj} />
            <LiveChatButton size="small" />
          </div>

          <div className={styles.plans}>
            <TransitionWrapper>
              {planEls}
              <div className={styles.plans__footer}>
                {plans && selectedPlan && (
                  <SmartButton
                    onClick={() =>
                      navigate(`/choose-plan/${selectedPlan}-${period}/confirm`)
                    }
                    color="dark_green"
                    size="large"
                    disabled={
                      (selectedPlan === plan &&
                        period === stripeSubscriptionInterval) ||
                      (selectedPlan === plan && selectedPlan === 'Free')
                    }
                  >
                    Checkout
                  </SmartButton>
                )}
              </div>
            </TransitionWrapper>
          </div>
        </div>
      </main>
    </Layout>
  )
}
