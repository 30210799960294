import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useSlice } from 'State'
import { clearFeedCache } from 'Helpers/instaApi'
import useIsLoaded from 'Hooks/useIsLoaded'
import HashtagSearch from 'Components/HashtagSearch'
import styles from 'Pages/AddFeed.module.scss'

export default function AddFeedHashtags() {
  const appState = useSlice('addFeedIsLoading', 'setAddFeedIsLoading', 'feeds')
  const { ids } = useParams()
  const navigate = useNavigate()
  const isLoaded = useIsLoaded()
  const [feedId, instagramUserId, parentSourceId] = ids.split('_')
  const feed = appState.feeds[feedId] || {}

  useEffect(() => {
    appState.setAddFeedIsLoading(false)
  }, [])

  return appState.addFeedIsLoading ? null : (
    <div className={classNames(styles.hashtags, { [styles.loaded]: isLoaded })}>
      <h1 className={classNames(styles.title, { [styles.loaded]: isLoaded })}>
        Add hashtags to your feed
      </h1>
      <div className={styles.hashtags__form}>
        <HashtagSearch
          instagramUserId={instagramUserId}
          parentSourceId={parentSourceId}
          feedId={feedId}
        />
        <button
          className={styles.hashtags_submit_button}
          disabled={!feed?.hashtags?.length}
          onClick={() => {
            clearFeedCache(feedId)
            navigate(`/feeds/${feedId}`)
          }}
        >
          Done
        </button>
      </div>
    </div>
  )
}
