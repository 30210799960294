import {
  useState,
  useEffect,
  useRef,
  createRef,
  forwardRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useTimers from 'Hooks/useTimers'
import useOnResize from 'Hooks/useOnResize'
import useDragScroll from 'Hooks/useDragScroll'
import styles from './Toggle.module.scss'

const ToggleButton = forwardRef(function ToggleButton(
  { value, label, isSelected, onClick },
  ref,
) {
  return (
    <button
      tabIndex="-1"
      ref={ref}
      key={value}
      onClick={() => onClick(value)}
      className={classnames(styles.button, {
        [styles.current]: isSelected,
      })}
    >
      {label}
    </button>
  )
})

ToggleButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

function removeDups(arr) {
  const curr = []
  return arr.filter((arr) => {
    if (curr.includes(arr[0])) {
      return false
    } else {
      curr.push(arr[0])
      return true
    }
  })
}

export default function Toggle({
  values,
  onChange,
  className,
  value,
  align = 'left',
}) {
  const { st } = useTimers()
  const containerRef = useRef()
  const buttonRefs = useRef({})
  const backgroundRef = useRef()
  const valueRef = useRef(value)
  const [xAdjust, setXAdjust] = useState(0)
  const [backgroundBounds, setBackgroundBounds] = useState({
    width: 0,
    left: 0,
  })
  const containerLeftAdust = useRef(0)
  const backgroundLeftOffset = useRef(0)
  const [isLoaded, setIsLoaded] = useState(false)

  values = removeDups(values)

  buttonRefs.current = values.reduce(
    (acc, curr) => ({ ...acc, [curr[0]]: createRef() }),
    {},
  )

  const updatePosition = useCallback(() => {
    const buttonEl = buttonRefs?.current?.[valueRef.current]?.current
    const containerEl = containerRef.current
    const width = containerEl?.offsetWidth
    const scrollWidth = containerEl?.scrollWidth
    const overflow = scrollWidth - width
    const midPoint = width / 2
    const buttonLeft = buttonEl?.offsetLeft
    const buttonRight = buttonEl?.offsetLeft + buttonEl?.offsetWidth
    const buttonWidth = buttonEl?.offsetWidth
    const shouldOffset =
      overflow > 0 &&
      (buttonLeft + buttonWidth / 2 > width / 2 || buttonRight + 20 >= width)
    const xOffset = shouldOffset ? midPoint - buttonLeft - buttonWidth / 2 : 0
    const maxOffset = width - scrollWidth
    const translation = Math.max(xOffset, maxOffset)

    setXAdjust(translation)

    setBackgroundBounds({
      width: buttonWidth,
      left: buttonLeft,
    })
  }, [valueRef])

  const handleWheel = useCallback(
    (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      const containerEl = containerRef.current
      const width = containerEl?.offsetWidth
      const scrollWidth = containerEl?.scrollWidth
      const maxOffset = width - scrollWidth
      const translation = Math.min(Math.max(xAdjust - evt.deltaX, maxOffset), 0)

      setXAdjust(translation)
    },
    [xAdjust],
  )

  const handleKeyDown = useCallback(
    (evt) => {
      const valueIds = values.map((value) => value[0])
      const currentIndex = valueIds.indexOf(valueRef.current)

      if (evt.key === 'ArrowRight') {
        evt.preventDefault()
        onChange(valueIds[Math.min(currentIndex + 1, valueIds.length - 1)])
      }

      if (evt.key === 'ArrowLeft') {
        evt.preventDefault()
        onChange(valueIds[Math.max(currentIndex - 1, 0)])
      }

      if ('1234567890'.includes(evt.key)) {
        evt.preventDefault()
        let keyIndex = Math.min(valueIds.length - 1, evt.key - 1).toString()
        if (evt.key === 0) {
          keyIndex = Math.min(valueIds.length - 1, 9).toString()
        }
        onChange(valueIds[keyIndex])
      }
    },
    [onChange, values],
  )

  const didDrag = useDragScroll(
    containerRef.current,
    () => {
      containerLeftAdust.current = xAdjust
      backgroundLeftOffset.current = backgroundRef.current.offsetLeft - xAdjust
    },
    (delta) => {
      const width = containerRef.current?.offsetWidth
      const scrollWidth = containerRef.current?.scrollWidth
      const overflow = scrollWidth - width
      const innerDelta = Math.max(
        Math.min(containerLeftAdust.current + delta, 0),
        -overflow,
      )
      setXAdjust(innerDelta)
    },
  )

  useOnResize(updatePosition, 100, containerRef.current)

  const onButtonClick = useCallback(
    (val) => {
      if (didDrag) return
      onChange(val)
    },
    [onChange, didDrag],
  )

  useEffect(() => {
    st(() => setIsLoaded(true), 100)
  }, [st])

  useEffect(() => {
    valueRef.current = value
    if (buttonRefs.current[value]) {
      updatePosition()
    }
  }, [value, updatePosition])

  const backgroundStyles = {
    left: `${backgroundBounds.left}px`,
    width: `${backgroundBounds.width}px`,
  }

  const buttonEls = values.map(([buttonValue, label]) => (
    <ToggleButton
      ref={buttonRefs.current[buttonValue]}
      key={buttonValue}
      value={buttonValue}
      label={label}
      isSelected={buttonValue === value}
      onClick={onButtonClick}
    />
  ))

  return (
    <div
      tabIndex={0}
      onWheelCapture={handleWheel}
      onKeyDown={handleKeyDown}
      ref={containerRef}
      className={classnames(styles.container, className, styles[align], {
        [styles.animate]: isLoaded,
      })}
    >
      <div
        className={styles.inner}
        style={{ transform: `translateX(${xAdjust}px)` }}
      >
        {buttonEls}
        <div
          ref={backgroundRef}
          className={classnames(styles.background)}
          style={backgroundStyles}
        ></div>
      </div>
    </div>
  )
}

Toggle.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  align: PropTypes.string,
}
