import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { reauthenticate, updatePassword } from 'Fire/firebaseActions'
import Popup from 'Components/Popup'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import KeyIcon from 'Images/icons/key.svg'
import styles from './ChangePasswordPopup.module.scss'

export default function ChangePasswordPopup({
  onRequestClose,
  onSuccess,
  closeFocusRef,
}) {
  const [currentPassword, setCurrentPassword] = useState('')
  const currentPasswordRef = useRef(null)
  const currentPasswordEl = useRef(null)
  const newPasswordEl = useRef(null)
  const [newPassword, setNewPassword] = useState('')
  const [forceClose, setForceClose] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  /*
   *   Submit
   */
  async function submit() {
    setIsLoading(true)

    try {
      await reauthenticate(currentPassword)
      await updatePassword(newPassword)
      setIsLoading(false)
      onSuccess()
    } catch (error) {
      setIsLoading(false)
      switch (error.code) {
        case 'auth/wrong-password':
          setErrorMessage('Wrong password')
          currentPasswordEl.current.focus()
          break
        case 'auth/weak-password':
          setErrorMessage('Password must be at least 6 characters long')
          currentPasswordEl.current.focus()
          break
        default:
          if (error.message) setErrorMessage(error.message)
      }
    }
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    currentPasswordEl.current.focus()
  }, [])

  useEffect(() => {
    currentPasswordRef.current = currentPassword
  }, [currentPassword])

  return (
    <Popup
      onRequestClose={onRequestClose}
      forceClose={forceClose}
      showCloseButton={false}
      closeFocusRef={closeFocusRef}
    >
      <div className={classnames(styles.container)}>
        <header className={styles.header}>
          <h1 className={styles.title}>
            <KeyIcon /> Update password
          </h1>
        </header>
        <Form
          className={styles.form}
          submitText="Submit"
          showArrow={false}
          onSubmit={submit}
          submitIsDisabled={
            !currentPassword ||
            !currentPassword.length ||
            !newPassword ||
            !newPassword.length
          }
          buttonAlignment="right"
          showCancelButton
          onCancel={() => setForceClose(true)}
          isLoading={isLoading}
          errorMessage={errorMessage}
        >
          <FormItem label="Current password" inputId="current-password">
            <ControlledInput
              id="current-password"
              ref={currentPasswordEl}
              value={currentPassword}
              type="password"
              onChange={setCurrentPassword}
              disabled={isLoading}
            />
          </FormItem>
          <FormItem
            label="New password"
            note="6+ characters"
            inputId="new-password"
          >
            <ControlledInput
              id="new-password"
              ref={newPasswordEl}
              value={newPassword}
              type="password"
              onChange={setNewPassword}
              disabled={isLoading}
              aria-description="must be 6 or more characters"
            />
          </FormItem>
        </Form>
      </div>
    </Popup>
  )
}

ChangePasswordPopup.propTypes = {
  onRequestClose: PropTypes.func,
  onSuccess: PropTypes.func,
  closeFocusRef: PropTypes.object,
}
