import { useEffect } from 'react'
import { trapFocus, releaseFocus } from 'Helpers/trapFocus'

export default function useTrapFocus(el, active = true) {
  useEffect(() => {
    if (active && el) {
      trapFocus({ el, exclude: [], initialFocusIndex: 0 })
    } else {
      releaseFocus()
    }

    return () => {
      releaseFocus()
    }
  }, [el, active])
}
