export default function resizeImage(source, maxWidth, maxHeight, quality = 1) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const image = document.createElement('img')
    image.src = source

    const originalWidth = image.width
    const originalHeight = image.height

    const resizingFactor = Math.min(
      maxWidth / originalWidth,
      maxHeight / originalHeight,
    )

    const canvasWidth = originalWidth * resizingFactor
    const canvasHeight = originalHeight * resizingFactor

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    context.drawImage(
      image,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor,
    )

    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      'image/png',
      quality,
    )
  })
}
