import { useState, useRef, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { updateInstagramFeed } from 'Fire/firebaseActions'
import { clearFeedCache, updateFeedInRedis } from 'Helpers/instaApi'
import {
  isPopulated,
  isEqual,
  pruneObject,
  shouldFeedRefresh,
} from 'Helpers/utils'
import { useSlice } from 'State'
import defaultWidgetSettings from 'Constants/defaultWidgetSettings'
import useWindowSize from 'Hooks/useWindowSize'
import useTimers from 'Hooks/useTimers'
import ReauthPopup from 'Components/ReauthPopup'
import Metas from 'Components/Metas'
import CopyText from 'Components/CopyText'
import TransitionWrapper from 'Components/TransitionWrapper'
import FeedMenu from 'Components/FeedMenu'
import PopupMenu from 'Components/PopupMenu'
import ControlledInput from 'Components/ControlledInput'
import Switch from 'Components/Switch'
import SmartButton from 'Components/SmartButton'
import { Panels, usePanels } from 'Components/Panels'
import FeedPanelEmbedCode from 'Components/feed/FeedPanelEmbedCode'
import FeedPanelKlaviyoEmbedCode from 'Components/feed/klaviyo/FeedPanelKlaviyoEmbedCode'
import FeedPanelWidgetType from 'Components/feed/FeedPanelWidgetType'
import FeedPanelHashtags from 'Components/feed/FeedPanelHashtags'
import FeedPanelWidgetSettingsGrid from 'Components/feed/grid/FeedPanelWidgetSettingsGrid'
import FeedPanelWidgetSettingsElasticCarousel from 'Components/feed/elasticCarousel/FeedPanelWidgetSettingsElasticCarousel'
import FeedPanelWidgetSettingsGalleryWall from 'Components/feed/galleryWall/FeedPanelWidgetSettingsGalleryWall'
import FeedPanelResponsiveGrid from 'Components/feed/grid/FeedPanelResponsiveGrid'
import FeedPanelResponsiveElasticCarousel from 'Components/feed/elasticCarousel/FeedPanelResponsiveElasticCarousel'
import FeedPanelWidgetSettingsAdvanced from 'Components/feed/FeedPanelWidgetSettingsAdvanced'
import FeedPanelApiSettings from 'Components/feed/json/FeedPanelApiSettings'
import FeedPanelKlaviyoFilterSettings from 'Components/feed/klaviyo/FeedPanelKlaviyoFilterSettings'
import FeedPanelKlaviyoSettings from 'Components/feed/klaviyo/FeedPanelKlaviyoSettings'
import FeedWidgetPreview from 'Components/feed/FeedWidgetPreview'
import FeedApiPreview from 'Components/feed/json/FeedApiPreview'
import FeedKlaviyoPreview from 'Components/feed/klaviyo/FeedKlaviyoPreview'
import Toast from 'Components/Toast'

import LeftArrow from 'Images/icons/caret-left.svg'
import RightCaret from 'Images/icons/caret-right.svg'
import DownCaret from 'Images/icons/caret-down.svg'
import UpCaret from 'Images/icons/caret-up.svg'
import CodeIcon from 'Images/icons/code.svg'
import LinkIcon from 'Images/icons/link.svg'
import HashIcon from 'Images/icons/hash.svg'
import WidgetIcon from 'Images/icons/feed-outline.svg'
import JSONIcon from 'Images/icons/json.svg'
import SettingsIcon from 'Images/icons/settings.svg'
import PaletteIcon from 'Images/icons/palette.svg'
import FilterIcon from 'Images/icons/filter.svg'
import ResponsiveIcon from 'Images/icons/responsive.svg'
import KeyIcon from 'Images/icons/key-outline.svg'
import Eyecon from 'Images/icons/eye-outline.svg'
import KlaviyoIcon from 'Images/klaviyo-outline.svg'
import ConnectIcon from 'Images/icons/connect.svg'
import UpgradeIcon from 'Images/icons/premium-solid.svg'
import SourceIcon from 'Images/icons/source-outline.svg'
import AdvancedSourceIcon from 'Images/icons/advanced.svg'
import styles from './Feed.module.scss'
import useFeatures from 'Hooks/useFeatures'

export default function Feed() {
  const location = useLocation()
  const navigate = useNavigate()
  const { st } = useTimers()
  const isLoadingTimer = useRef()
  const windowSize = useWindowSize()
  const [mobileView, setMobileView] = useState('SETTINGS')
  const [previewWidth, setPreviewWidth] = useState(null)
  const [showReauthPopup, setShowReauthPopup] = useState(false)
  const params = useParams()
  const appState = useSlice(
    'feeds',
    'initialFeedsLoaded',
    'setFeedIsUpdating',
    'user',
    'plans',
    'addNotification',
    'authorizedAccounts',
  )
  const features = useFeatures()
  const [panelIsOpen, setPanelIsOpen] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const [upgradeNoticeIsOpen, setUpgradeNoticeIsOpen] = useState(true)
  const titleInputRef = useRef()
  const [forceCloseMenu, setForceCloseMenu] = useState(false)
  const feedDefaults = {
    maxPosts: null,
    domainWhitelist: null,
    hashtags: null,
    hashtagFeedType: null,
    hashtagFeedSort: null,
    widgetSettings: {},
    filter: {
      allowedTypes: ['IMAGE', 'VIDEO', 'REEL', 'CAROUSEL_ALBUM'],
    },
  }

  const feed = useMemo(() => {
    return appState?.feeds?.[params.feedId]
      ? { ...feedDefaults, ...appState.feeds[params.feedId] }
      : feedDefaults
  }, [appState?.feeds])

  const feedTitleRef = useRef(feed.title)
  let { pausedByOverage, widgetSettings, settings, hasTransientError } = feed
  widgetSettings = {
    ...defaultWidgetSettings[widgetSettings.type || 'grid'],
    ...widgetSettings,
  }
  const {
    maxPosts,
    domainWhitelist,
    hashtagFeedType,
    hashtagFeedSort,
    filter,
  } = settings || {}
  const [localWidgetSettings, setLocalWidgetSettings] = useState(widgetSettings)
  const [localFeedSettings, setLocalFeedSettings] = useState({
    maxPosts,
    domainWhitelist,
    hashtags: feed.hashtags,
    hashtagFeedType,
    hashtagFeedSort,
    filter,
    version: feed.version,
  })
  const [previewLoadingColors, setPreviewLoadingColors] = useState(null)
  const [currentPosts, setCurrentPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showingMobileKlaviyoStyles, setShowingMobileKlaviyoStyles] =
    useState(false)
  const breakpointsRef = useRef()
  const feedUpdateToRef = useRef()
  const plan = appState.plans?.[appState.user?.plan] || {}
  const sourceId = feed.sourceId
  const source = appState?.authorizedAccounts?.[sourceId]

  const feedMetadata = {
    username: feed.username,
    biography: feed.biography,
    profilePictureUrl: feed.profilePictureUrl,
    website: feed.website,
    followersCount: feed.followersCount,
    followsCount: feed.followsCount,
    hashtags: feed.hashtags?.map((ht) => ht.name),
    isAdvanced: source?.type?.includes('instagramAdvanced'),
  }

  let feedError = null

  if (feed.source === 'HASHTAG' && !feed.hashtags?.length) {
    feedError = 'NO_HASHTAGS'
  }

  if (feed.throttledStartTime) {
    feedError = 'THROTTLED'
  }

  breakpointsRef.current = Object.entries(
    pruneObject(localWidgetSettings?.breakpoints, ['default']),
  )

  function updateLocalFeedSetting(setting, value) {
    setLocalFeedSettings({ ...localFeedSettings, [setting]: value })
  }

  function updateLocalWidgetSetting(setting, value) {
    setLocalWidgetSettings({ ...localWidgetSettings, [setting]: value })
  }

  function setPosts(posts) {
    posts = posts ?? []
    let filtered = posts.length
      ? posts.filter((p, i) => i < localFeedSettings.maxPosts)
      : posts

    filtered.sort((a, b) => {
      return b.timestamp.localeCompare(a.timestamp)
    })

    setCurrentPosts(filtered)
  }

  function resetChanges() {
    setLocalFeedSettings({
      maxPosts,
      domainWhitelist,
      hashtags: feed.hashtags,
      hashtagFeedType,
      hashtagFeedSort,
      filter,
      version: feed.version,
    })

    setLocalWidgetSettings(widgetSettings)
  }

  async function saveChanges() {
    const updatedWidgetSettings = {
      ...widgetSettings,
      ...localWidgetSettings,
    }

    if (feed.type === 'WIDGET') {
      const breakpointNamedLayouts = updatedWidgetSettings.breakpoints
        ? Object.values(updatedWidgetSettings.breakpoints).reduce(
            (acc, curr) => {
              return [...acc, curr.galleryWallNamedLayout]
            },
            [],
          )
        : []

      let widgetType = localWidgetSettings.type
      if (feed.subtype === 'KLAVIYO') {
        widgetType = 'klaviyo'
      }

      if (features.lacks(`widgetType:${widgetType}`)) {
        appState.addNotification(
          {
            title: 'Paid plan required',
            text: (
              <>
                <Link to="/choose-plan">Upgrade </Link> to any paid plan for
                access to all widget types
              </>
            ),
            duration: 20,
          },
          'premium_widget',
        )
        return false
      }

      if (
        plan?.maxImagesPerFeed < 14 &&
        updatedWidgetSettings.type === 'galleryWall' &&
        breakpointNamedLayouts.includes('tribeca')
      ) {
        appState.addNotification(
          {
            title: 'Paid plan required',
            text: (
              <>
                <Link to="/choose-plan">Upgrade </Link> to any paid plan for
                full access to this gallery wall design
              </>
            ),
            duration: 20,
          },
          'premium_widget_style',
        )
      }
    }

    try {
      const shouldRefresh = shouldFeedRefresh(localFeedSettings, {
        hashtags: feed.hashtags,
        ...settings,
      })

      await updateInstagramFeed(feed.id, {
        ...(localFeedSettings.maxPosts <= plan.maxImagesPerFeed && {
          'settings.maxPosts': localFeedSettings.maxPosts,
        }),
        'settings.domainWhitelist': localFeedSettings.domainWhitelist || [],
        'settings.hashtagFeedType': localFeedSettings.hashtagFeedType || 'TOP',
        'settings.hashtagFeedSort': localFeedSettings.hashtagFeedSort || 'MIX',
        'settings.filter': localFeedSettings.filter || {
          allowedTypes: ['IMAGE', 'VIDEO', 'REEL', 'CAROUSEL_ALBUM'],
        },
        hashtags: localFeedSettings.hashtags || null,
        ...(updatedWidgetSettings && { widgetSettings: updatedWidgetSettings }),
      })

      if (shouldRefresh) {
        clearFeedCache(feed.id)
        setIsLoading(true)
      } else {
        updateFeedInRedis(feed.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function changeTitle(val) {
    setEditingTitle(false)
    if (val === feedTitleRef.current) return
    feedTitleRef.current = val

    try {
      await updateInstagramFeed(feed.id, { title: val })
    } catch (error) {
      console.log(error)
    }
  }

  async function upgradeToV2() {
    try {
      await updateInstagramFeed(feed.id, { version: 2 })
      updateFeedInRedis(feed.id)
      appState.addNotification(
        {
          title: 'Feed successfully upgraded to v2',
          duration: 10,
        },
        'feed_upgrade',
      )
    } catch (error) {
      console.log(error)
    }
  }

  function editTitle() {
    setEditingTitle(true)
    setForceCloseMenu(true)
    st(() => titleInputRef.current?.focus(), 50)
  }

  useEffect(() => {
    if (!feed.settings) {
      navigate(`/feeds`)
    }
  }, [feed])

  useEffect(() => {
    clearTimeout(isLoadingTimer.current)
    const isLoading =
      !appState.initialFeedsLoaded ||
      (feed.isUpdating &&
        !pausedByOverage &&
        (feed.source === 'HASHTAG' ? !!feed.hashtags.length : true))

    if (isLoading) {
      setIsLoading(true)
    } else {
      isLoadingTimer.current = st(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [feed, pausedByOverage])

  useEffect(() => {
    clearTimeout(feedUpdateToRef.current)
    if (isEqual(feed.posts, currentPosts)) return

    if (!feed?.posts?.length && currentPosts?.length) {
      feedUpdateToRef.current = st(() => {
        setPosts(feed.posts)
      }, 500)
    } else {
      setPosts(feed.posts)
    }
  }, [feed])

  useEffect(() => {
    setMobileView('SETTINGS')
  }, [location])

  useEffect(() => {
    const breakpointNamedLayouts = localWidgetSettings.breakpoints
      ? Object.values(localWidgetSettings.breakpoints).reduce((acc, curr) => {
          return [...acc, curr.galleryWallNamedLayout]
        }, [])
      : []

    if (
      plan?.maxImagesPerFeed < 14 &&
      localWidgetSettings.type === 'galleryWall' &&
      breakpointNamedLayouts.includes('tribeca')
    ) {
      appState.addNotification(
        {
          title: 'Paid plan required',
          text: (
            <>
              The current gallery wall design requires a paid plan to function
              properly. <Link to="/choose-plan">Upgrade now</Link>
            </>
          ),
          duration: 20,
        },
        'premium_widget_style',
      )
    }
  }, [])

  const hasChanges =
    !isEqual(
      {
        maxPosts: settings?.maxPosts,
        domainWhitelist: settings?.domainWhitelist,
        hashtags: feed?.hashtags,
        hashtagFeedType: settings?.hashtagFeedType,
        hashtagFeedSort: settings?.hashtagFeedSort,
        filter: settings?.filter,
      },
      localFeedSettings,
      true,
      true,
      ['version'],
    ) || !isEqual(widgetSettings, localWidgetSettings, true)

  const ErrorMessage = () => {
    let message = <>Something went wrong. Try again in a few minutes.</>

    switch (feedError) {
      case 'THROTTLED':
        message = (
          <>
            <p>
              This feed is being rate-limited by Instagram. <br />
              Sorry! It&apos;s out of our hands. Limiting usually only lasts an
              hour. In the meantime we&apos;ll serve cached posts.
            </p>
            <p>
              If you have any questions or need help with this, please send us
              an email: <a href="mailto:support@behold.so">support@behold.so</a>
            </p>
          </>
        )
        break
      case 'NO_HASHTAGS':
        message = <>Add a hashtag to load posts</>
        break
    }

    return message
  }

  let panelInfo = [
    { id: 'embed_code', title: 'Embed code', icon: <CodeIcon /> },
    {
      id: 'hashtags',
      title: 'Hashtags',
      icon: <HashIcon />,
      hidden: feed.source !== 'HASHTAG',
    },
    { id: 'widget_type', title: 'Widget type', icon: <WidgetIcon /> },
    {
      id: 'customize',
      title: 'Customize',
      icon: <PaletteIcon />,
    },
    {
      id: 'breakpoints',
      title: 'Breakpoints',
      icon: <ResponsiveIcon />,
      hidden: !'grid,elasticCarousel'.includes(widgetSettings.type),
    },
    { id: 'advanced', title: 'Advanced', icon: <KeyIcon /> },
  ]

  if (feed.type === 'API') {
    panelInfo = [
      {
        id: 'settings',
        title: 'Feed settings',
        icon: <SettingsIcon />,
        arrow: true,
      },
      {
        id: 'hashtags',
        title: 'Hashtags',
        icon: <HashIcon />,
        hidden: feed.source !== 'HASHTAG',
      },
    ]
  }

  if (feed.subtype === 'KLAVIYO') {
    panelInfo = [
      { id: 'embed_code', title: 'Add to Klaviyo', icon: <CodeIcon /> },
      {
        id: 'hashtags',
        title: 'Hashtags',
        icon: <HashIcon />,
        hidden: feed.source !== 'HASHTAG',
      },
      {
        id: 'filter',
        title: 'Filter',
        icon: <FilterIcon />,
        arrow: true,
      },
      {
        id: 'customize',
        title: 'Customize',
        icon: <PaletteIcon />,
        arrow: true,
      },
    ]
  }

  const panelNavEls = panelInfo
    .filter((panel) => !panel.hidden)
    .map((panel) => (
      <PanelNavButton
        key={panel.id}
        panelIsOpen={panelIsOpen}
        arrow={panel.arrow}
        {...panel}
      />
    ))

  const sidebarType = feed.subtype === 'KLAVIYO' ? 'KLAVIYO' : feed.type

  /*
   *   WIDGET
   */
  let panelEls = (
    <>
      <FeedPanelEmbedCode panelId="embed_code" feedId={feed.id} />

      {feed.source === 'HASHTAG' && (
        <FeedPanelHashtags
          panelId="hashtags"
          feed={feed}
          localFeedSettings={localFeedSettings}
          updateLocalFeedSetting={updateLocalFeedSetting}
          plan={plan}
          hasChanges={hasChanges}
          saveChanges={saveChanges}
          resetChanges={resetChanges}
        />
      )}

      <FeedPanelWidgetType
        panelId="widget_type"
        user={appState.user}
        feed={feed}
        plan={plan}
        updateLocalFeedSetting={updateLocalFeedSetting}
        localWidgetSettings={localWidgetSettings}
        widgetSettings={widgetSettings}
        setLocalWidgetSettings={setLocalWidgetSettings}
        hasChanges={hasChanges}
        saveChanges={saveChanges}
        resetChanges={resetChanges}
      />

      {widgetSettings.type === 'grid' && (
        <>
          <FeedPanelWidgetSettingsGrid
            feedId={feed.id}
            panelId="customize"
            feed={feed}
            plan={plan}
            localFeedSettings={localFeedSettings}
            updateLocalFeedSetting={updateLocalFeedSetting}
            localWidgetSettings={localWidgetSettings}
            updateLocalWidgetSetting={updateLocalWidgetSetting}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
            setPreviewLoadingColors={setPreviewLoadingColors}
            isLoading={isLoading}
          />
          <FeedPanelResponsiveGrid
            panelId="breakpoints"
            breakpoints={breakpointsRef.current}
            localFeedSettings={localFeedSettings}
            localWidgetSettings={localWidgetSettings}
            updateLocalWidgetSetting={updateLocalWidgetSetting}
            setPreviewWidth={setPreviewWidth}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
          />
        </>
      )}

      {widgetSettings.type === 'elasticCarousel' && (
        <>
          <FeedPanelWidgetSettingsElasticCarousel
            feedId={feed.id}
            panelId="customize"
            feed={feed}
            plan={plan}
            localFeedSettings={localFeedSettings}
            updateLocalFeedSetting={updateLocalFeedSetting}
            localWidgetSettings={localWidgetSettings}
            updateLocalWidgetSetting={updateLocalWidgetSetting}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
            setPreviewLoadingColors={setPreviewLoadingColors}
            isLoading={isLoading}
          />
          <FeedPanelResponsiveElasticCarousel
            panelId="breakpoints"
            breakpoints={breakpointsRef.current}
            localFeedSettings={localFeedSettings}
            localWidgetSettings={localWidgetSettings}
            updateLocalWidgetSetting={updateLocalWidgetSetting}
            setPreviewWidth={setPreviewWidth}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
          />
        </>
      )}

      {widgetSettings.type === 'galleryWall' && (
        <FeedPanelWidgetSettingsGalleryWall
          feedId={feed.id}
          panelId="customize"
          feed={feed}
          plan={plan}
          localFeedSettings={localFeedSettings}
          updateLocalFeedSetting={updateLocalFeedSetting}
          localWidgetSettings={localWidgetSettings}
          setLocalWidgetSettings={setLocalWidgetSettings}
          updateLocalWidgetSetting={updateLocalWidgetSetting}
          hasChanges={hasChanges}
          saveChanges={saveChanges}
          resetChanges={resetChanges}
          setPreviewLoadingColors={setPreviewLoadingColors}
          isLoading={isLoading}
        />
      )}

      <FeedPanelWidgetSettingsAdvanced
        panelId="advanced"
        localFeedSettings={localFeedSettings}
        localWidgetSettings={localWidgetSettings}
        updateLocalFeedSetting={updateLocalFeedSetting}
        updateLocalWidgetSetting={updateLocalWidgetSetting}
        hasChanges={hasChanges}
        saveChanges={saveChanges}
        resetChanges={resetChanges}
      />
    </>
  )

  /*
   *   API
   */
  if (sidebarType === 'API') {
    panelEls = (
      <>
        <FeedPanelApiSettings
          panelId="settings"
          localFeedSettings={localFeedSettings}
          updateLocalFeedSetting={updateLocalFeedSetting}
          plan={plan}
          hasChanges={hasChanges}
          saveChanges={saveChanges}
          resetChanges={resetChanges}
          isLoading={isLoading}
        />
        {feed.source === 'HASHTAG' && (
          <FeedPanelHashtags
            panelId="hashtags"
            feed={feed}
            localFeedSettings={localFeedSettings}
            updateLocalFeedSetting={updateLocalFeedSetting}
            plan={plan}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
          />
        )}
      </>
    )
  }

  /*
   *   KLAVIYO
   */
  if (sidebarType === 'KLAVIYO') {
    panelEls = (
      <>
        <FeedPanelKlaviyoEmbedCode
          panelId="embed_code"
          feed={feed}
          plan={plan}
          posts={currentPosts}
        />
        {feed.source === 'HASHTAG' && (
          <FeedPanelHashtags
            panelId="hashtags"
            feed={feed}
            localFeedSettings={localFeedSettings}
            updateLocalFeedSetting={updateLocalFeedSetting}
            plan={plan}
            hasChanges={hasChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
          />
        )}
        <FeedPanelKlaviyoFilterSettings
          panelId="filter"
          feed={feed}
          localFeedSettings={localFeedSettings}
          updateLocalFeedSetting={updateLocalFeedSetting}
          localWidgetSettings={localWidgetSettings}
          updateLocalWidgetSetting={updateLocalWidgetSetting}
          hasChanges={hasChanges}
          saveChanges={saveChanges}
          resetChanges={resetChanges}
          isLoading={isLoading}
        />
        <FeedPanelKlaviyoSettings
          panelId="customize"
          feed={feed}
          plan={plan}
          localFeedSettings={localFeedSettings}
          updateLocalFeedSetting={updateLocalFeedSetting}
          localWidgetSettings={localWidgetSettings}
          updateLocalWidgetSetting={updateLocalWidgetSetting}
          hasChanges={hasChanges}
          saveChanges={saveChanges}
          resetChanges={resetChanges}
          setPreviewLoadingColors={setPreviewLoadingColors}
          isLoading={isLoading}
          showingMobileStyles={showingMobileKlaviyoStyles}
          setShowingMobileStyles={setShowingMobileKlaviyoStyles}
        />
      </>
    )
  }

  const emptyMessageEl = (
    <div className={styles.empty_message}>
      <h1 className={styles.empty_message__title}>
        This feed doesn&apos;t exist
      </h1>{' '}
      <Link className={styles.empty_message__link} to="/feeds">
        Back to Feeds
      </Link>
    </div>
  )

  let panelWidth = feed.type === 'API' ? 460 : 350
  if (feed.subtype === 'KLAVIYO') {
    panelWidth = 350
  }
  if (windowSize.width < 1000 && feed.type === 'API') {
    panelWidth = 350
  }
  if (windowSize.width < 701) panelWidth = 'FULL'

  let feedStatus = 'LOADING'

  if (
    !(isLoading && !isPopulated(feed.posts)) &&
    !hasTransientError &&
    !pausedByOverage &&
    isPopulated(currentPosts)
  ) {
    feedStatus = 'HEALTHY'
  }

  if (!isLoading && !pausedByOverage && hasTransientError) {
    feedStatus = 'TRANSIENT_ERROR'
  }

  if (
    !isLoading &&
    !hasTransientError &&
    !pausedByOverage &&
    !isPopulated(currentPosts)
  ) {
    feedStatus = 'NO_POSTS'
  }

  if (!isLoading && feedError) {
    feedStatus = 'ERROR'
  }

  if (!isLoading && source?.reauthorize) {
    feedStatus = 'REAUTHORIZE'
  }

  if (pausedByOverage) {
    feedStatus = 'OVERAGE'
  }

  let reauthMessage = (
    <>
      <p>The source for this feed is no longer connected.</p>
      <p>
        <button
          className={styles.no_images__reauth_button}
          onClick={() => setShowReauthPopup(true)}
        >
          Reauthorize
        </button>
      </p>
    </>
  )

  switch (source?.reasonForReauth) {
    case 'PASSWORD_CHANGE':
      reauthMessage = (
        <>
          <p>
            The password for this feed&apos;s source was changed, or the session
            was cleared for security reasons.
          </p>
          <p>
            <button
              className={styles.no_images__reauth_button}
              onClick={() => setShowReauthPopup(true)}
            >
              Reauthorize
            </button>
          </p>
        </>
      )
      break

    case 'PRIVATE':
      reauthMessage = (
        <p>
          This Instagram account was set to private.{' '}
          <button
            className={styles.no_images__reauth_button}
            onClick={() => setShowReauthPopup(true)}
          >
            Reauthorize
          </button>
        </p>
      )
      break
  }

  let showToast =
    !feedError &&
    'HEALTHY,LOADING'.includes(feedStatus) &&
    ((isLoading && appState.initialFeedsLoaded) ||
      (feed.newImagesAreProcessing &&
        localWidgetSettings.loadingColor !== 'tone'))

  let toastText = null

  if (isLoading) {
    toastText = 'Fetching from Instagram'
  }

  if (
    !isLoading &&
    feed.newImagesAreProcessing &&
    localWidgetSettings.loadingColor !== 'tone'
  ) {
    toastText = 'Extracting colors...'
  }

  return (
    <>
      <Metas title={`${feed.title} | Behold`} />
      {feed.settings ? (
        <main className={styles.container} id="skip-target" tabIndex="0">
          <TransitionWrapper
            tag="nav"
            transform="translateX(-10px)"
            className={styles.sidebar}
          >
            <Panels
              baseUrl={`/feeds/${feed.id}`}
              onPanelsToggle={setPanelIsOpen}
              panels={panelEls}
              width={panelWidth}
            >
              <Link
                className={styles.sidebar__back}
                to="/feeds"
                aria-label="back to feeds"
                tabIndex={panelIsOpen ? -1 : 0}
              >
                <div className={styles.back_button__back}>
                  <LeftArrow className={styles.back_button__arrow} />
                </div>
                <div className={styles.sidebar__back_text}>Back</div>
              </Link>
              <div className={styles.sidebar__meta}>
                <div className={styles.sidebar__meta_header}>
                  <h1 className={styles.sidebar__title}>
                    {editingTitle ? (
                      <ControlledInput
                        ref={titleInputRef}
                        className={styles.sidebar__title_input}
                        value={feed.title}
                        onChange={changeTitle}
                        updateOnEnter={true}
                        updateOn="blur"
                        unstyled
                      />
                    ) : (
                      <div className={styles.sidebar__title_inner}>
                        {feed.title}
                      </div>
                    )}
                  </h1>
                  <div className={styles.sidebar__menu}>
                    <PopupMenu
                      yAlign="bottom"
                      offset={5}
                      width={170}
                      untabbable={panelIsOpen}
                      forceClose={forceCloseMenu}
                      onClose={() => setForceCloseMenu(false)}
                      fixed
                    >
                      <FeedMenu
                        showSettings={false}
                        feedId={feed.id}
                        editTitleHandler={editTitle}
                      />
                    </PopupMenu>
                  </div>
                </div>
                <div className={styles.sidebar__type}>
                  {feed?.type === 'API' && feed?.subtype !== 'KLAVIYO' && (
                    <>
                      <JSONIcon /> JSON Feed
                    </>
                  )}
                  {feed?.type === 'WIDGET' && feed?.subtype !== 'KLAVIYO' && (
                    <>
                      <WidgetIcon /> Widget
                    </>
                  )}
                  {feed?.subtype === 'KLAVIYO' && (
                    <>
                      <KlaviyoIcon /> Klaviyo Widget
                    </>
                  )}
                </div>
                <div className={styles.sidebar__views}>
                  <Eyecon /> {feed?.usage?.toLocaleString() || 'N/A'} views this
                  month
                </div>
                <div className={styles.sidebar__source}>
                  {feedMetadata.isAdvanced ? (
                    <AdvancedSourceIcon />
                  ) : (
                    <SourceIcon />
                  )}
                  {feedMetadata.username}
                </div>
              </div>
              {windowSize.width < 701 && (
                <div className={styles.mobile_view__tabs}>
                  <button
                    onClick={() => setMobileView('SETTINGS')}
                    className={classNames(styles.mobile_view__tab, {
                      [styles.current]: mobileView === 'SETTINGS',
                    })}
                  >
                    <SettingsIcon /> Settings
                  </button>
                  <button
                    onClick={() => setMobileView('PREVIEW')}
                    className={classNames(styles.mobile_view__tab, {
                      [styles.current]: mobileView === 'PREVIEW',
                    })}
                  >
                    <Eyecon /> Preview
                  </button>
                </div>
              )}
              {features.lacks(`widgetType:${localWidgetSettings.type}`) &&
                feed.type === 'WIDGET' &&
                feed.subtype !== 'KLAVIYO' && (
                  <div className={styles.upgrade_notice}>
                    <p>
                      This widget type requires a paid plan. Upgrade now to
                      reactivate.
                    </p>
                    <p>
                      <a href="/choose-plan/Personal">
                        <UpgradeIcon /> Upgrade Now
                      </a>
                    </p>
                  </div>
                )}
              {feed.type === 'API' && feed.version !== 2 && (
                <>
                  <div
                    className={classNames(styles.api_version, {
                      [styles.is_open]: upgradeNoticeIsOpen,
                    })}
                  >
                    <button
                      className={styles.api_version__title}
                      aria-label={
                        upgradeNoticeIsOpen
                          ? 'Update available, collapse notice'
                          : 'Update available, expand notice'
                      }
                      onClick={() =>
                        setUpgradeNoticeIsOpen(!upgradeNoticeIsOpen)
                      }
                    >
                      <UpgradeIcon />
                      Update Available
                      <div className={styles.api_verion__caret}>
                        {upgradeNoticeIsOpen ? <UpCaret /> : <DownCaret />}
                      </div>
                    </button>
                    {upgradeNoticeIsOpen && (
                      <div className={styles.api_version__text}>
                        <p>
                          Upgrade this feed to v2 for multiple image sizes, a
                          faster CDN and more.
                        </p>
                        <p>
                          <b>There are breaking changes.</b> Read our short{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://behold.so/docs/v2-feeds-migration-guide"
                          >
                            migration guide
                          </a>{' '}
                          to learn more.
                        </p>
                        <p>
                          Permanently upgrade this feed by previewing the new
                          output and then clicking &quot;Upgrade&quot; below.
                        </p>
                        <div className={styles.api_version__footer}>
                          <label className={styles.api_version_toggle}>
                            <Switch
                              ariaLabel="Feed version"
                              className={styles.api_version_toggle__switch}
                              checked={localFeedSettings.version === 2}
                              onChange={(val) => {
                                updateLocalFeedSetting('version', val ? 2 : 1)
                              }}
                              before={
                                <div
                                  className={styles.api_version_toggle__label}
                                >
                                  Preview v2 output
                                </div>
                              }
                            />
                          </label>
                          <SmartButton
                            color="dark"
                            disabled={
                              feed.version === localFeedSettings.version
                            }
                            className={styles.api_version__button}
                            confirm={true}
                            confirmColor="var(--color-green)"
                            onClick={upgradeToV2}
                          >
                            Upgrade
                          </SmartButton>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {feed.type === 'API' &&
                feed.subtype !== 'KLAVIYO' &&
                (windowSize.width > 700 || mobileView === 'SETTINGS') && (
                  <div className={styles.api_url}>
                    <div className={styles.api_url__section}>
                      <div className={styles.api_url__title}>
                        <LinkIcon />
                        Feed URL
                      </div>
                      <CopyText value={`${process.env.FEEDS_URL}/${feed.id}`} />
                    </div>
                  </div>
                )}
              {features.lacks('widgetType:klaviyo') &&
                feed.subtype === 'KLAVIYO' &&
                (windowSize.width > 700 || mobileView === 'SETTINGS') && (
                  <div className={styles.klaviyo_plan_notice}>
                    <p>
                      The Klaviyo widget builder requires a <b>Business</b> plan
                      or greater.
                    </p>
                    <p>
                      You can customize and preview your widget, but you&apos;ll
                      have to upgrade to access the output.
                    </p>
                    <p>
                      <a href="/choose-plan/Business">
                        <UpgradeIcon /> Upgrade Now
                      </a>
                    </p>
                  </div>
                )}
              {(windowSize.width > 700 || mobileView === 'SETTINGS') && (
                <div className={styles.panel_nav}>{panelNavEls}</div>
              )}
            </Panels>
          </TransitionWrapper>

          {(windowSize.width > 700 || mobileView === 'PREVIEW') && (
            <TransitionWrapper
              transform="translateY(20px)"
              opacity={false}
              className={styles.preview}
            >
              {isLoading &&
                feedStatus !== 'OVERAGE' &&
                !isPopulated(currentPosts) && (
                  <div className={styles.preview_loader}>
                    <l-squircle
                      speed={0.75}
                      size={35}
                      stroke={4}
                      color="var(--color-text-light)"
                    ></l-squircle>
                    {feed.hashtags &&
                      maxPosts > 20 &&
                      appState.initialFeedsLoaded && (
                        <div className={styles.hashtag_load_warning}>
                          Large hashtag feeds can take a minute or two to
                          preload.
                        </div>
                      )}
                    {feed.hashtags &&
                      maxPosts < 21 &&
                      feed.settings.filter?.captionIncludeText?.length > 0 && (
                        <div className={styles.hashtag_load_warning}>
                          Hashtag feeds with the &quot;Caption must
                          include&quot; filter can take a few minutes to preload
                        </div>
                      )}
                  </div>
                )}
              {feedStatus === 'TRANSIENT_ERROR' && (
                <div className={styles.no_images}>
                  <p>
                    <b>
                      The Instagram API has returned an unspecified{' '}
                      <code>transient error</code>
                    </b>
                  </p>
                  <p>
                    This means that Instagram is having internal issues, and
                    usually lasts no more than 30 minutes.
                  </p>
                  <p>In the meantime, your feed will serve cached posts.</p>
                </div>
              )}
              {feedStatus === 'NO_POSTS' && (
                <div className={styles.no_images}>
                  {feedError ? (
                    <ErrorMessage />
                  ) : (
                    <>
                      No posts found.{' '}
                      <button
                        className={styles.no_images__button}
                        onClick={() => {
                          appState.setFeedIsUpdating(feed.id, true)
                          clearFeedCache(feed.id)
                        }}
                      >
                        Clear cache
                      </button>
                    </>
                  )}
                </div>
              )}
              {feedStatus === 'REAUTHORIZE' && (
                <div className={styles.no_images}>
                  <ConnectIcon className={styles.no_images__icon} />
                  <h2 className={styles.no_images__title}>Disconnected</h2>
                  {reauthMessage}
                </div>
              )}
              {feedStatus === 'OVERAGE' && (
                <div className={styles.no_images}>
                  <p>You have reached your limit of monthly views.</p>
                  <p>
                    <Link to="/choose-plan">Upgrade now</Link> to reactivate.
                  </p>
                </div>
              )}
              {feedStatus === 'ERROR' && (
                <div className={styles.no_images}>
                  <ErrorMessage />
                </div>
              )}
              {feedStatus === 'HEALTHY' &&
                feed.type === 'WIDGET' &&
                feed.subtype !== 'KLAVIYO' && (
                  <FeedWidgetPreview
                    feedId={feed.id}
                    posts={currentPosts}
                    feedMetadata={feedMetadata}
                    localWidgetSettings={localWidgetSettings}
                    breakpointsRef={breakpointsRef}
                    previewLoadingColors={previewLoadingColors}
                    previewWidth={previewWidth}
                    onRequestWidthChange={setPreviewWidth}
                    newImagesAreProcessing={feed.newImagesAreProcessing}
                  />
                )}
              {feedStatus === 'HEALTHY' &&
                feed.type === 'API' &&
                feed.subtype !== 'KLAVIYO' && (
                  <FeedApiPreview
                    feed={feed}
                    posts={currentPosts}
                    feedMetadata={feedMetadata}
                    localFeedSettings={localFeedSettings}
                  />
                )}
              {feedStatus === 'HEALTHY' && feed.subtype === 'KLAVIYO' && (
                <FeedKlaviyoPreview
                  feed={feed}
                  posts={currentPosts}
                  feedMetadata={feedMetadata}
                  localFeedSettings={localFeedSettings}
                  localWidgetSettings={localWidgetSettings}
                  showingMobileStyles={showingMobileKlaviyoStyles}
                  setShowingMobileStyles={setShowingMobileKlaviyoStyles}
                  previewLoadingColors={previewLoadingColors}
                />
              )}
              <Toast isActive={showToast} minTimeVisible={2000}>
                {toastText}
              </Toast>
            </TransitionWrapper>
          )}
        </main>
      ) : (
        emptyMessageEl
      )}
      {showReauthPopup && (
        <ReauthPopup
          sourceId={feed.sourceId}
          onRequestClose={() => setShowReauthPopup(false)}
          redirectTo={`feeds/${params.feedId}`}
        />
      )}
    </>
  )
}

function PanelNavButton({ id, title, icon, panelIsOpen, arrow = true }) {
  const { openPanel } = usePanels()

  return (
    <button
      tabIndex={panelIsOpen ? -1 : 0}
      onClick={() => openPanel(id)}
      className={styles.panel_nav__button}
    >
      {icon}
      <div className={styles.panel_nav__button_title}>{title}</div>
      {arrow && <RightCaret className={styles.panel_nav__button_arrow} />}
    </button>
  )
}

PanelNavButton.propTypes = {
  panelId: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.element,
}
