export async function clearFeedCache(feedId, flush = false) {
  try {
    await fetch(
      `${process.env.FEEDS_URL}/removeFeedFromCache/${feedId}?flush=${flush}`,
      {
        mode: 'cors',
      },
    )
  } catch (error) {
    console.log(error)
  }
}

export async function updateFeedInRedis(feedId) {
  try {
    await fetch(`${process.env.FEEDS_URL}/updateFeedInRedis/${feedId}`, {
      mode: 'cors',
    })
  } catch (error) {
    console.log(error)
  }
}
