import { useState, useEffect } from 'react'
import useTimers from 'Hooks/useTimers'

export default function useIsLoaded(allowSet = false, delay = 0) {
  const { raf, st } = useTimers()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    st(() => {
      raf(() => {
        setIsLoaded(true)
      })
    }, delay)
  }, [])

  return allowSet ? [isLoaded, setIsLoaded] : isLoaded
}
