import { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { copyText } from 'Helpers/utils'
import CopyIcon from 'Images/icons/copy.svg'
import styles from './CopyText.module.scss'

export default function CopyText({ value, className }) {
  const inputRef = useRef(null)

  function copy(evt) {
    const bbox = inputRef.current.getBoundingClientRect()
    requestAnimationFrame(() => {
      inputRef.current.select()
      copyText(evt, value, {
        x: bbox.x + bbox.width / 2,
        y: bbox.y - 5,
        duration: 1000,
        forcePosition: 'top',
      })
    })
  }

  return (
    <div className={classnames(styles.container, className)}>
      <button onClick={copy} aria-label="click to copy">
        <CopyIcon />
      </button>
      <input
        ref={inputRef}
        type="text"
        autoComplete="off"
        value={value}
        readOnly
        onClick={copy}
        data-lpignore={true}
      />
    </div>
  )
}

CopyText.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
}
