import { useSlice } from 'State'

export default function useAuthUrl({ afterAuthRedirect = null, isReauth }) {
  const appState = useSlice('user')

  /*
   *   Instagram login
   */
  const basicParams = new URLSearchParams({
    enable_fb_login: 1,
    force_authentication: 0,
    client_id: process.env.IG_APP_ID,
    response_type: 'code',
    redirect_uri: process.env.SERVICES_URL + '/connect',
    scope: 'instagram_business_basic',
    state: JSON.stringify({
      uid: appState?.user?.id,
      type: 'instagramBasic',
      isReauth,
      afterAuthRedirect: afterAuthRedirect ?? '',
    }),
  })

  const params = new URLSearchParams({
    ...(isReauth ? { reauth: isReauth } : {}),
    ...(afterAuthRedirect ? { next: afterAuthRedirect } : {}),
  })

  const basicAuthUrl = appState?.user?.shortId
    ? `${process.env.SHORTLINK_URL}/${appState.user.shortId}${params.size ? '?' + params : ''}`
    : `https://api.instagram.com/oauth/authorize?${basicParams}`

  /*
   *   Business portfolio login
   */
  const advancedParams = new URLSearchParams({
    client_id: process.env.FB_APP_ID,
    redirect_uri: process.env.SERVICES_URL + '/connect',
    response_type: 'code',
    config_id: process.env.FB_OAUTH_CONFIG_ID,
    state: JSON.stringify({
      uid: appState?.user?.id,
      type: 'instagramAdvanced',
      isReauth,
      afterAuthRedirect: afterAuthRedirect ?? '',
    }),
  })

  const advancedAuthUrlBusinessPortfolio = appState?.user?.shortId
    ? `${process.env.SHORTLINK_URL}/${appState.user.shortId}?type=bp${params.size ? '&' + params : ''}`
    : `https://www.facebook.com/v20.0/dialog/oauth?${advancedParams}`

  /*
   *   Facebook user login
   */
  const advancedUserTokenParams = new URLSearchParams({
    client_id: process.env.FB_APP_ID,
    redirect_uri: process.env.SERVICES_URL + '/connect',
    response_type: 'code',
    scope:
      'public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,business_management',
    state: JSON.stringify({
      uid: appState?.user?.id,
      type: 'instagramAdvancedUserToken',
      isReauth,
      afterAuthRedirect: afterAuthRedirect ?? '',
    }),
  })

  const advancedAuthUrlFacebookUser = appState?.user?.shortId
    ? `${process.env.SHORTLINK_URL}/${appState.user.shortId}?type=fb${params.size ? '&' + params : ''}`
    : `https://www.facebook.com/v20.0/dialog/oauth?${advancedUserTokenParams}`

  return {
    basicAuthUrl,
    advancedAuthUrlBusinessPortfolio,
    advancedAuthUrlFacebookUser,
  }
}
