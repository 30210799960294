import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useMatch } from 'react-router-dom'

export default function NavLink({
  loose,
  to,
  className,
  currentClass = 'current',
  include = 'none',
  children,
  tabIndex,
}) {
  const matchPattern = loose ? to + '/*' : to
  const match = useMatch(matchPattern)
  const includeMatch = useMatch(include)

  return (
    <Link
      to={to}
      className={classNames(className, {
        [currentClass]: !!match || !!includeMatch,
      })}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  )
}

NavLink.propTypes = {
  loose: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
  currentClass: PropTypes.string,
  include: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  tabIndex: PropTypes.number,
}
