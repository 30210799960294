import { useState, useRef, useEffect, useCallback } from 'react'
import { createPortal } from 'preact/compat'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { measureUniverse } from 'Helpers/utils'
import useHotkey from 'Hooks/useHotkey'
import useOnResize from 'Hooks/useOnResize'
import useOnScroll from 'Hooks/useOnScroll'
import useTimers from 'Hooks/useTimers'
import useTransition from 'Hooks/useTransition'
import useTrapFocus from 'Hooks/useTrapFocus'
import ErrorBoundary from 'Components/ErrorBoundary'
import styles from './Popover.module.scss'

function calculateTopOverlap(y) {
  return y < 0 ? Math.abs(y) + 10 : 0
}

function calculateBottomOverlap(y, container, u) {
  let bottomOverlap = y + container.height - u.docHeight

  return bottomOverlap > 0 ? bottomOverlap + 10 : 0
}

/*
 *  Popover
 */
function Popover({
  children,
  onRequestClose,
  onOpen,
  anchorRef,
  openFocusRef,
  closeFocusRef,
  width = 200,
  yAlign = 'bottom',
  xAlign = 'right',
  offset = 5,
  fixed = false,
  mask = false,
  forceClose,
  containerId = 'root',
  trapFocus = true,
  focusOnInit = true,
  id = '',
  role = 'dialog',
}) {
  const containerRef = useRef(null)
  const [shouldTransition, setShouldTransition] = useState(false)
  const { st } = useTimers()
  const [popoverStyle, setPopoverStyle] = useState({ width })

  if (xAlign === 'center') offset = 0

  /*
   *   On open callback
   */
  const afterOpen = useCallback(() => {
    if (onOpen) onOpen()
    if (openFocusRef?.current) {
      openFocusRef.current.focus()
    }
  }, [onOpen, openFocusRef])

  /*
   *   On close callback
   */
  const onClose = useCallback(() => {
    if (onRequestClose) onRequestClose()
  }, [onRequestClose])

  const onBeforeClose = useCallback(() => {
    if (closeFocusRef) {
      closeFocusRef?.current?.focus()
    } else {
      anchorRef?.current?.focus()
    }
  }, [anchorRef, closeFocusRef])

  const { show, hide, isVisible, isHidden } = useTransition(
    mask ? 150 : 0,
    afterOpen,
    onClose,
    onBeforeClose,
  )

  if (trapFocus) {
    useTrapFocus(containerRef.current, !isHidden, focusOnInit)
  }

  /*
   *   Calculate position
   */
  const setPosition = useCallback(() => {
    const u = measureUniverse(
      'root,body'.includes(containerId)
        ? 'window'
        : document.getElementById(containerId),
    )
    const anchor = anchorRef.current?.getBoundingClientRect() || {}
    const container = containerRef.current?.getBoundingClientRect() || {}
    const right = anchor.left + anchor.width + offset
    const leftOverlap = anchor.left - container.width
    const rightOverlap = u.widthMinusScrollbars - right - container.width
    let flipX =
      (xAlign === 'left' || rightOverlap < offset) && xAlign !== 'center'
        ? true
        : false
    let x = flipX
      ? u.scrollX + anchor.left - container.width - offset
      : u.scrollX + anchor.left + anchor.width + offset
    if (fixed) {
      x = flipX
        ? anchor.left - container.width - offset
        : anchor.left + anchor.width + offset
    }

    if (xAlign === 'center') {
      x = Math.max(10, x - anchor.width / 2 - container.width / 2)
    }

    let y =
      yAlign === 'bottom'
        ? u.scrollY + anchor.top
        : u.scrollY + anchor.top + anchor.height - container.height

    if (fixed) {
      y =
        yAlign === 'bottom'
          ? anchor.top
          : anchor.top + anchor.height - container.height
    }

    if (xAlign === 'center' && yAlign === 'top') {
      y = y - anchor.height - 10
    }

    if (xAlign === 'center' && yAlign === 'bottom') {
      y = y + anchor.height + 10
    }

    const topOverlap = calculateTopOverlap(y)
    const bottomOverlap = calculateBottomOverlap(y, container, u)

    y = y + topOverlap - bottomOverlap

    let xAdjust = 0
    let yAdjust = 0

    if (flipX && leftOverlap < offset) {
      xAdjust = Math.abs(leftOverlap) + offset * 2
      yAdjust = anchor.height + offset
    }

    const xTranslate = `${xAdjust}px`

    setPopoverStyle({
      width: `${width}px`,
      top: `${y + yAdjust}px`,
      left: `${x}px`,
      transform: `translateX(${xTranslate})`,
    })
  }, [anchorRef, fixed, width, xAlign, yAlign])

  /*
   *   Recalc on resize
   */
  useOnResize(setPosition, 10, window)
  useOnScroll(setPosition, 100)

  useHotkey('Escape', hide)

  /*
   *   Handle keydown
   */
  const handleKeydown = useCallback(
    (evt) => {
      switch (evt.key) {
        case 'Tab':
          if (!trapFocus) {
            hide()
          }
      }
    },
    [hide],
  )

  /*
   *   Handle window click
   */
  const handleWindowClick = useCallback(
    (evt) => {
      if (
        containerRef.current?.contains(evt.target) ||
        anchorRef.current?.contains(evt.target)
      ) {
        return false
      }
      hide()
    },
    [hide],
  )

  /*
   *   UseEffect
   */
  useEffect(() => {
    st(() => setShouldTransition(true), 50)
  }, [st])

  useEffect(() => {
    setPosition()
    show()
  }, [setPosition, show, children])

  useEffect(() => {
    if (forceClose) hide()
  }, [forceClose, hide])

  useEffect(() => {
    window.addEventListener('mousedown', handleWindowClick)

    return () => {
      window.removeEventListener('mousedown', handleWindowClick)
    }
  }, [handleWindowClick])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown])

  const popoverClasses = classnames(styles.popover, {
    [styles.visible]: isVisible,
    [styles.hidden]: isHidden,
    [styles.fixed]: fixed,
    [styles.transition]: shouldTransition,
  })

  /*
   *   Render
   */
  if (!anchorRef.current) return null
  return createPortal(
    <>
      <div
        id={id}
        ref={containerRef}
        className={popoverClasses}
        style={popoverStyle}
        role={role}
      >
        <div
          className={classnames(styles.popover__inner, {
            [styles.x_center]: xAlign === 'center',
            [styles.x_left]: xAlign === 'left',
          })}
        >
          {children}
        </div>
      </div>
      {mask && (
        <div
          className={classnames(styles.mask, {
            [styles.visible]: isVisible,
            [styles.hidden]: isHidden,
          })}
        />
      )}
    </>,
    containerId === 'body'
      ? document.body
      : document.getElementById(containerId),
  )
}

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onRequestClose: PropTypes.func,
  onOpen: PropTypes.func,
  anchorRef: PropTypes.object,
  openFocusRef: PropTypes.object,
  closeFocusRef: PropTypes.object,
  width: PropTypes.number,
  yAlign: PropTypes.string,
  xAlign: PropTypes.string,
  fixed: PropTypes.bool,
  mask: PropTypes.bool,
  forceClose: PropTypes.any,
  trapFocus: PropTypes.bool,
  focusOnInit: PropTypes.bool,
  id: PropTypes.string,
  ariaLive: PropTypes.bool,
}

export default function SafePopover(props) {
  return (
    <ErrorBoundary>
      <Popover {...props} />
    </ErrorBoundary>
  )
}
