import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { addPaymentMethodToUser, updateUser } from 'Fire/firebaseActions'
import { initStripe } from 'Helpers/stripe.js'
import { createSetupIntent, setDefaultPaymentMethod } from 'Helpers/services'
import useTrapFocus from 'Hooks/useTrapFocus'
import Popup from 'Components/Popup'
import ControlledInput from 'Components/ControlledInput'
import SmartButton from 'Components/SmartButton'
import { Form, FormItem } from 'Components/Form'
import AddCardIcon from 'Images/icons/add-card.svg'
import StripeLogo from 'Images/stripe.svg'
import CheckIcon from 'Images/icons/check.svg'
import BgPattern from 'Images/pattern2.svg'
import styles from './AddNewCardPopup.module.scss'

const stripePromise = initStripe()

function AddNewCardPopup({ onRequestClose, onOpen, stripeId, closeFocusRef }) {
  const [name, setName] = useState('')
  const [cardFocused, setCardFocused] = useState(false)
  const [forceClose, setForceClose] = useState(false)
  const [showCardEl, setShowCardEl] = useState(false)
  const [setupIntent, setSetupIntent] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessfull, setIsSuccessfull] = useState(false)
  const successRef = useRef()
  const paymentMethodRef = useRef(null)
  const nameInputEl = useRef(null)
  const stripe = useStripe()
  const elements = useElements()
  const cardElementElRef = useRef()
  const cardElement = elements?.getElement(CardElement)

  useTrapFocus(successRef.current, isSuccessfull)

  /*
   *   Confirm
   */
  async function confirm() {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
      },
    })

    if (error) {
      setErrorMessage(error.message)
      return { error: error }
    }

    const {
      id: pmId,
      type,
      card: { brand, exp_month, exp_year, last4 },
    } = paymentMethod

    paymentMethodRef.current = {
      id: pmId,
      type,
      card: { brand, exp_month, exp_year, last4 },
    }

    return stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: pmId,
    })
  }

  /*
   *   Submit
   */
  async function submit() {
    if (!setupIntent || !setupIntent.client_secret) return

    if (name.length < 3) {
      setIsLoading(false)
      setErrorMessage('Please enter your full name')
      nameInputEl.current.focus()
      return
    }

    setIsLoading(true)
    setErrorMessage(null)
    const { error, setupIntent: confirmedSetupIntent } = await confirm()

    if (error) {
      setIsLoading(false)
      setErrorMessage(error.message)
      cardElementElRef.current.focus()
    } else {
      if (confirmedSetupIntent?.status === 'succeeded') {
        const {
          id,
          type,
          card: { brand, exp_month, exp_year, last4 },
        } = paymentMethodRef.current

        try {
          await setDefaultPaymentMethod(setupIntent.customer, id)

          await addPaymentMethodToUser({
            id,
            customerId: setupIntent.customer,
            name,
            type,
            brand,
            expYear: exp_year,
            expMonth: exp_month,
            lastFour: last4,
          })

          await updateUser({
            defaultPaymentMethod: id,
          })

          setIsLoading(false)
          requestAnimationFrame(() => setIsSuccessfull(true))
        } catch (error) {
          console.log('Error adding card in Firebase', error)
        }
      }
    }
  }

  useEffect(() => {
    if (name?.length > 2) setErrorMessage(null)
  }, [name])

  useEffect(() => {
    const controller = new AbortController()

    if (stripeId) {
      if (!setupIntent) {
        createSetupIntent(stripeId, controller.signal)
          .then((intent) => setSetupIntent(intent))
          .catch((err) => console.log(err))
      }
    } else {
      console.log('No stripe customer ID')
    }

    nameInputEl.current.focus()
    setShowCardEl(true)

    if (onOpen) onOpen()

    return () => controller.abort()
  }, [onOpen, stripeId])

  const cardElementOptions = {
    disabled: isLoading,
    style: {
      base: {
        color: '#081b10',
        fontFamily: 'proxima-nova, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: window.innerWidth > 400 ? '16px' : '14px',
        iconColor: '#081b10',
        '::placeholder': {
          color: '#355844',
        },
      },
      invalid: {
        color: '#8f3c31',
        iconColor: '#8f3c31',
      },
    },
  }

  return (
    <Popup
      onRequestClose={onRequestClose}
      closeOnMaskClick={!isLoading}
      forceClose={forceClose}
      showCloseButton={false}
      closeFocusRef={closeFocusRef}
      trapFocus={false}
    >
      <div
        className={classnames(styles.container, {
          [styles.successful]: isSuccessfull,
        })}
      >
        <header className={styles.header}>
          <h1 className={styles.title}>
            <AddCardIcon aria-hidden /> Add a card
          </h1>
        </header>
        <div className={styles.note}>
          <div>
            <h2 className={styles.note__title}>
              Secured by <StripeLogo />
            </h2>
            <div className={styles.note__text}>
              Your credit card information is passed directly from your browser
              to Stripe over a secure connection.
            </div>
          </div>
          <BgPattern aria-hidden className={styles.note__background} />
        </div>
        <div className={styles.inner} aria-hidden={isSuccessfull}>
          <Form
            isLoading={isLoading}
            errorMessage={errorMessage}
            onSubmit={submit}
            submitIsDisabled={!name || !name.length || isSuccessfull}
            onCancel={() => setForceClose(true)}
            showCancelButton
            buttonAlignment="right"
            submitButtonSize="medium"
            submitButtonContent={<>Save Card</>}
          >
            <FormItem
              label="Full Name"
              note="As it appears on your card"
              inputId="full-name"
            >
              <ControlledInput
                id="full-name"
                ref={nameInputEl}
                value={name}
                onChange={setName}
                disabled={isLoading}
              />
            </FormItem>
            <FormItem label="Credit Card">
              <div
                className={classnames(styles.card_wrapper, {
                  [styles.focused]: cardFocused,
                })}
              >
                {showCardEl && (
                  <CardElement
                    options={cardElementOptions}
                    onFocus={() => setCardFocused(true)}
                    onBlur={() => setCardFocused(false)}
                    onChange={() => setErrorMessage(null)}
                    onReady={(el) => (cardElementElRef.current = el)}
                  />
                )}
              </div>
            </FormItem>
          </Form>
        </div>

        {/* Success Message */}

        <div
          ref={successRef}
          className={styles.success_message}
          aria-hidden={!isSuccessfull}
        >
          <div className={styles.success_icon}>
            <CheckIcon aria-hidden />
          </div>
          <h2 className={styles.success_title} aria-live="assertive">
            {isSuccessfull && 'Successfully added card'}
          </h2>
          {paymentMethodRef.current && (
            <div className={styles.success_text}>
              <span className={styles.success_text__brand}>
                {paymentMethodRef.current.card.brand}
              </span>{' '}
              &ensp; XXXX-
              {paymentMethodRef.current.card.last4} &ensp; Exp.{' '}
              {paymentMethodRef.current.card.exp_month}/
              {paymentMethodRef.current.card.exp_year}
            </div>
          )}
          <div className={styles.success_button_container}>
            {isSuccessfull && (
              <SmartButton
                className={styles.success_button}
                color="algae"
                onClick={() => setForceClose(true)}
              >
                Done
              </SmartButton>
            )}
          </div>
        </div>

        {/* Loading Graphic */}
        {(!stripePromise || !stripeId) && (
          <div className={styles.loader}>
            <l-squircle
              stroke="4"
              size={35}
              color="var(--color-text-light)"
            ></l-squircle>
          </div>
        )}
      </div>
    </Popup>
  )
}

AddNewCardPopup.propTypes = {
  onRequestClose: PropTypes.func,
  onOpen: PropTypes.func,
  stripeId: PropTypes.string,
  closeFocusRef: PropTypes.object,
}

/*
 *  Export with Stripe Elements provider
 */
export default ({ onRequestClose, stripeId, onOpen, closeFocusRef }) => (
  <Elements
    stripe={stripePromise}
    options={{ fonts: [{ cssSrc: 'https://use.typekit.net/ivv6kun.css' }] }}
  >
    <AddNewCardPopup
      onRequestClose={onRequestClose}
      stripeId={stripeId}
      onOpen={onOpen}
      closeFocusRef={closeFocusRef}
    />
  </Elements>
)
