import { useEffect, useCallback, useMemo } from 'react'
import throttle from 'lodash.throttle'

export default function useOnScroll(func, delay = 50, el = document) {
  const throttledCallback = useMemo(() => throttle(func, delay), [func, delay])

  const handleScroll = useCallback(throttledCallback, [throttledCallback])

  useEffect(() => {
    el.addEventListener('scroll', handleScroll)

    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, el])

  return () => {
    el.removeEventListener('scroll', handleScroll)
  }
}
