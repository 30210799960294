import classnames from 'classnames'
import { getISOString } from 'Helpers/utils'
import { useSlice } from 'State'
import SmartLink from 'Components/SmartLink'
import ErrorBoundary from 'Components/ErrorBoundary'
import styles from './Usage.module.scss'

function Usage() {
  const appState = useSlice('user', 'plans', 'authorizedAccounts', 'feeds')

  const user = appState?.user

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? appState?.plans?.[planId] : null

  /*
   *   Max views
   */
  let maxViewsText = activePlan?.maxViews

  if (maxViewsText >= 1000000) {
    maxViewsText = maxViewsText / 1000000 + 'M'
  } else {
    maxViewsText = maxViewsText / 1000 + 'k'
  }

  /*
   *   Total usage
   */
  const usageByMonth = user?.usageByMonth
  const totalUsage =
    usageByMonth?.[getISOString(new Date(), 'month')]?.hits || 0

  /*
   *   Render
   */
  return (
    <div className={styles.container}>
      {/* Plan */}
      <div
        className={classnames(
          styles.stat,
          styles.stat_plan,
          styles[activePlan?.title],
          {
            [styles.stat_loaded]: !!activePlan?.title,
          },
        )}
      >
        <div className={styles.stat__inner}>
          <h3 className={styles.stat__title}>Plan</h3>
          <div className={styles.stat__text}>
            <SmartLink
              to="/account#plan"
              aria-label={`current plan ${activePlan?.title}`}
            >
              {activePlan?.title}
            </SmartLink>{' '}
          </div>
        </div>
        <div className={styles.stat__loader}>
          <l-dot-pulse
            color="var(--color-text-light)"
            speed={1}
            size={30}
          ></l-dot-pulse>
        </div>
      </div>
      {/* Views */}
      <div
        className={classnames(styles.stat, styles.stat_views, {
          [styles.stat_views_large]: totalUsage >= 100000,
          [styles.stat_loaded]:
            Number.isInteger(totalUsage) &&
            maxViewsText &&
            !isNaN(activePlan?.maxViews),
        })}
      >
        <div className={styles.stat__inner}>
          <h3 className={styles.stat__title}>
            {new Date().toLocaleString('default', {
              month: 'long',
            })}{' '}
            Views
          </h3>
          <div className={styles.stat__text}>
            {new Intl.NumberFormat().format(totalUsage) || 0} / {maxViewsText}
          </div>
        </div>
        <div className={styles.stat__loader}>
          <l-dot-pulse
            color="var(--color-text-light)"
            speed={1}
            size={30}
          ></l-dot-pulse>
        </div>
      </div>
    </div>
  )
}

export default function SafeUsage(props) {
  return (
    <ErrorBoundary>
      <Usage {...props} />
    </ErrorBoundary>
  )
}
