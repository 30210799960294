import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SmartButton from './SmartButton'
import styles from './PageHeader.module.scss'

export default function PageHeader({
  title,
  buttonText,
  buttonAction,
  buttonTo,
  children,
  breadCrumbs,
  stacked = false,
  bottomMargin = true,
}) {
  const breadcrumbEls = breadCrumbs
    ? breadCrumbs.map(({ text, link }, i) => {
        const content =
          i < breadCrumbs.length - 1 ? (
            <Link to={link} className={styles.breadcrumb}>
              {text}
            </Link>
          ) : (
            <div className={styles.breadcrumb_current}>{text}</div>
          )

        return (
          <Fragment key={text}>
            {i > 0 && <span className={styles.breadcrumbs__divider}>/</span>}
            {content}
          </Fragment>
        )
      })
    : null

  return (
    <header
      className={classNames(styles.header, {
        [styles.stacked]: stacked,
        [styles.bottom_margin]: bottomMargin,
      })}
    >
      <div className={styles.inner}>
        <h1 className={styles.title} id="skip-target" tabIndex={-1}>
          {title}
        </h1>
        {buttonText && (buttonAction || buttonTo) ? (
          <SmartButton
            color="grey"
            size="large"
            className={styles.button}
            onClick={buttonAction}
            to={buttonTo}
          >
            {buttonText}
          </SmartButton>
        ) : (
          buttonText
        )}
        {children}
      </div>
      {breadCrumbs && <div className={styles.breadcrumbs}>{breadcrumbEls}</div>}
    </header>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.node,
  buttonAction: PropTypes.func,
  buttonTo: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  stacked: PropTypes.bool,
  breadCrumbs: PropTypes.array,
}
