import { useRef } from 'react'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'
import classNames from 'classnames'
import styles from './BlockSelect.module.scss'

export default function BlockSelect({
  options = [],
  value,
  onChange,
  name,
  numColumns = 1,
  size = 'medium',
}) {
  const idRef = useRef(uuidv1())

  function handleChange(evt) {
    onChange(evt.target.value)
  }

  const blockEls = options.map((option) => {
    return (
      <label
        key={`${idRef.current}_${option.value}`}
        className={classNames(styles.block, {
          [styles.selected]: value === option.value,
          [styles.disabled]: option.disabled,
        })}
      >
        <div className={styles.block__inner}>
          <input
            type="radio"
            id={`${idRef.current}_${option.value}`}
            name={name}
            value={option.value}
            onChange={option.disabled ? () => {} : handleChange}
            checked={value === option.value}
            className={styles.input}
          />
          {option.title}
        </div>
        {option.badge && <div className={styles.badge}>{option.badge}</div>}
      </label>
    )
  })

  return (
    <div
      className={classNames(styles.container, styles[size])}
      style={{ '--num-columns': `${numColumns}` }}
    >
      {blockEls}
    </div>
  )
}

BlockSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  numColumns: PropTypes.number,
  size: PropTypes.string,
}
