import { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSlice } from 'State'
import useTimers from 'Hooks/useTimers'
import defaultWidgetSettings from 'Constants/defaultWidgetSettings'
import FeedPanel from 'Components/feed/FeedPanel'
import TransitionWrapper from 'Components/TransitionWrapper'
import HelpTooltip from 'Components/HelpTooltip'
import ControlledInput from 'Components/ControlledInput'
import ControlledSelect from 'Components/ControlledSelect'
import ControlledRange from 'Components/ControlledRange'
import PanelIcon from 'Images/icons/palette.svg'
import MobileIcon from 'Images/icons/mobile.svg'
import DesktopIcon from 'Images/icons/desktop.svg'
import LinkIcon from 'Images/icons/link.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelKlaviyoSettings({
  panelId,
  localFeedSettings,
  updateLocalFeedSetting,
  localWidgetSettings,
  updateLocalWidgetSetting,
  hasChanges,
  saveChanges,
  resetChanges,
  plan,
  demoMode = false,
  isLoading,
  showingMobileStyles,
  setShowingMobileStyles,
}) {
  localWidgetSettings = {
    ...defaultWidgetSettings.klaviyo,
    ...localWidgetSettings,
  }
  const { addNotification } = useSlice('addNotification')
  const { st } = useTimers()
  const loadingColorToneRef = useRef()
  const loadingColorRef = useRef(localWidgetSettings.loadingColor)
  const maxPostsRef = useRef(localFeedSettings.maxPosts)

  let maxPostsPerFeedText = (
    <p>
      On the <b>{plan.title}</b> plan, you can set this up to{' '}
      <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.{' '}
      <b>
        <Link to="/choose-plan">Upgrade now</Link>
      </b>{' '}
      to increase your limit.
    </p>
  )

  if (plan?.topTier) {
    maxPostsPerFeedText = (
      <p>
        On the <b>{plan.title}</b> plan, you can set this up to{' '}
        <b>{plan?.maxImagesPerFeed?.toLocaleString()}</b> posts.
      </p>
    )
  }

  const currentBreakpoint =
    showingMobileStyles && !localWidgetSettings.mobileStylesLinked
      ? 'MOBILE'
      : 'DESKTOP'

  const settingsBase = showingMobileStyles
    ? localWidgetSettings.breakpoints.MOBILE
    : localWidgetSettings.breakpoints.DESKTOP

  function save() {
    maxPostsRef.current = localFeedSettings.maxPosts
    saveChanges()
  }

  function maxPostsWarning() {
    addNotification(
      {
        title: 'Post limit reached',
        text: maxPostsPerFeedText,
        duration: 20,
      },
      'MAX_POSTS_EXCEEDED',
    )
  }

  function updateSetting(setting, value) {
    updateLocalWidgetSetting('breakpoints', {
      ...localWidgetSettings.breakpoints,
      [currentBreakpoint]: {
        ...settingsBase,
        [setting]: value,
      },
    })
  }

  function updateNumPosts(number) {
    updateLocalFeedSetting(
      'maxPosts',
      Math.max(
        localWidgetSettings.breakpoints[
          showingMobileStyles ? 'DESKTOP' : 'MOBILE'
        ].numPosts,
        number,
      ),
    )

    updateSetting('numPosts', number)
  }

  useEffect(() => {
    if (
      loadingColorRef.current !== 'tone' &&
      localWidgetSettings.loadingColor === 'tone'
    ) {
      st(() => {
        if (loadingColorToneRef.current) {
          loadingColorToneRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        }
      }, 10)
    }
    loadingColorRef.current = localWidgetSettings.loadingColor
  }, [localWidgetSettings])

  /*
   *   Click behavior options
   */
  const clickBehavior = [
    { label: 'Link to post', value: `linkToPost` },
    { label: 'Link to profile', value: `linkToProfile` },
    { label: 'Custom link', value: `customLink` },
    { label: 'No link', value: `doNothing` },
  ]

  return (
    <FeedPanel
      panelId={panelId}
      width={350}
      icon={<PanelIcon />}
      title="Customize"
      hasChanges={hasChanges}
      saveChanges={save}
      resetChanges={resetChanges}
    >
      <div className={classNames(styles.fieldset)}>
        <div className={styles.setting}>
          {/* <div className={styles.label}>Desktop / Mobile</div> */}
          <div className={styles.size_toggle}>
            <button
              className={classNames(styles.size_toggle__button, {
                [styles.is_current]: !showingMobileStyles,
              })}
              aria-label="Desktop styles"
              disabled={localWidgetSettings.mobileStylesLinked}
              onClick={() => setShowingMobileStyles(false)}
            >
              <HelpTooltip
                showIcon={false}
                delay={0}
                openOnClick={false}
                xAlign="center"
                width={120}
                tabIndex={-1}
                className={styles.size_toggle__button_inner}
                triggerText={<DesktopIcon />}
              >
                <div className={styles.size_toggle__tooltip}>
                  Desktop styles
                </div>
              </HelpTooltip>
            </button>

            <HelpTooltip
              showIcon={false}
              delay={0}
              openOnClick={false}
              xAlign="center"
              width={170}
              tabIndex={-1}
              className={
                localWidgetSettings.mobileStylesLinked
                  ? styles.is_linked
                  : styles.is_unlinked
              }
              triggerText={
                <button
                  className={styles.size_toggle__link}
                  aria-label={
                    localWidgetSettings.mobileStylesLinked
                      ? 'Using same styles for desktop and mobile'
                      : 'Using separate desktop & mobile styles'
                  }
                  onClick={() => {
                    updateLocalWidgetSetting(
                      'mobileStylesLinked',
                      !localWidgetSettings.mobileStylesLinked,
                    )
                    setShowingMobileStyles(false)
                  }}
                >
                  <LinkIcon />
                </button>
              }
            >
              <div className={styles.size_toggle__tooltip}>
                {localWidgetSettings.mobileStylesLinked
                  ? 'Using same styles for desktop and mobile'
                  : 'Using separate desktop & mobile styles'}
              </div>
            </HelpTooltip>
            <button
              className={classNames(styles.size_toggle__button, {
                [styles.is_current]: showingMobileStyles,
              })}
              aria-label="Mobile styles"
              disabled={localWidgetSettings.mobileStylesLinked}
              onClick={() => setShowingMobileStyles(true)}
            >
              <HelpTooltip
                showIcon={false}
                delay={0}
                openOnClick={false}
                xAlign="center"
                width={120}
                tabIndex={-1}
                className={styles.size_toggle__button_inner}
                triggerText={<MobileIcon />}
              >
                <div className={styles.size_toggle__tooltip}>Mobile styles</div>
              </HelpTooltip>
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>Number of posts</div>
          <ControlledInput
            type="number"
            min={1}
            max={plan.maxImagesPerFeed}
            value={settingsBase.numPosts}
            onChange={updateNumPosts}
            onMaxExceeded={maxPostsWarning}
            ariaLabel="Number of posts"
            disabled={isLoading}
          />
          <TransitionWrapper
            show={localFeedSettings.maxPosts > maxPostsRef.current && !demoMode}
            scale
            height
          >
            <div className={styles.load_posts_notice}>
              <div
                className={classNames(
                  styles.description,
                  styles.description_darker,
                  styles.description_narrower,
                )}
              >
                <button onClick={save}>Save</button> to load additional posts.
              </div>
            </div>
          </TransitionWrapper>
        </div>
      </div>

      <h3 className={styles.section_title}>Layout</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Number of columns */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}># of Columns</div>
          <ControlledInput
            ariaLabel="Number of columns"
            type="number"
            min={1}
            value={settingsBase.numColumns}
            onChange={(val) => updateSetting('numColumns', val)}
          />
        </div>

        {/* Horizontal spacing */}
        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Column Gap</div>
          <ControlledInput
            ariaLabel="Column Gap"
            type="number"
            min={0}
            value={settingsBase.gap.x}
            onChange={(val) =>
              updateSetting('gap', {
                ...settingsBase.gap,
                x: val,
              })
            }
          />
        </div>

        {/* Vertical spacing */}
        <div className={classNames(styles.setting, styles.setting_half)}>
          <div className={styles.label}>Row Gap</div>
          <ControlledInput
            ariaLabel="Row Gap"
            type="number"
            min={0}
            value={settingsBase.gap.y}
            onChange={(val) =>
              updateSetting('gap', {
                ...settingsBase.gap,
                y: val,
              })
            }
          />
        </div>
      </div>

      <h3 className={styles.section_title}>Appearance</h3>
      <div className={classNames(styles.fieldset, styles.fieldset_invisible)}>
        <div className={styles.fieldset}>
          {/* Border radius */}
          <div className={classNames(styles.setting)}>
            <div className={styles.label}>Rounded corners</div>
            <ControlledRange
              ariaLabel="Rounded corners"
              type="range"
              min="0"
              max="100"
              suffix={`${settingsBase.borderRadius * 2}%`}
              value={settingsBase.borderRadius * 2}
              onChange={(val) => updateSetting('borderRadius', val / 2)}
            />
          </div>
        </div>
      </div>

      <h3 className={styles.section_title}>Link</h3>
      <div className={classNames(styles.fieldset)}>
        {/* Click behavior */}
        <div className={classNames(styles.setting)}>
          <div className={styles.label}>On click</div>
          <ControlledSelect
            ariaLabel="On Click"
            options={clickBehavior}
            value={settingsBase.onPostClick}
            onChange={(val) => updateSetting('onPostClick', val)}
          />
        </div>

        {/* Custom link URL */}
        <TransitionWrapper
          show={settingsBase?.onPostClick === 'customLink'}
          scale
          height
          className={classNames(styles.setting)}
        >
          <div className={styles.label}>Custom link URL</div>
          <ControlledInput
            ariaLabel="Custom link URL"
            value={settingsBase.customLinkURL}
            updateOn="blur"
            updateOnEnter={true}
            onChange={(val) => updateSetting('customLinkURL', val)}
          />
        </TransitionWrapper>
      </div>
    </FeedPanel>
  )
}

FeedPanelKlaviyoSettings.propTypes = {
  panelId: PropTypes.string,
  feed: PropTypes.object,
  localWidgetSettings: PropTypes.object,
  updateLocalWidgetSetting: PropTypes.func,
  updateLocalFeedSetting: PropTypes.func,
  setPreviewLoadingColors: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
  plan: PropTypes.object,
  demoMode: PropTypes.bool,
}
