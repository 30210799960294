import classNames from 'classnames'
import { useState, useCallback } from 'preact/hooks'
import { auth } from 'Fire/firebaseInit'
import { getISOString } from 'Helpers/utils'
import { useSlice } from 'State'
import { sendVerificationEmail } from 'Helpers/services'
import useInterval from 'Hooks/useInterval'
import useTimers from 'Hooks/useTimers'
import SmartButton from 'Components/SmartButton'
import Notice from 'Components/Notice'
import SmartLink from 'Components/SmartLink'
import UpgradeIcon from 'Images/icons/premium-solid.svg'
import RetryIcon from 'Images/icons/refresh.svg'
import EmailIcon from 'Images/icons/email-solid.svg'
import SentIcon from 'Images/icons/check.svg'
import styles from './Notices.module.scss'

const minResendWait = 60

function getNow() {
  return Date.now()
}

export default function Notices({ show }) {
  const { st } = useTimers()
  let lastSend = sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL')
  const [secondsSinceLastSend, setSecondsSinceLastSend] = useState(
    (getNow() -
      parseInt(sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL'))) /
      1000,
  )
  const { user, plans } = useSlice('user', 'plans')
  const [canResendVerification, setCanResendVerification] = useState(
    !lastSend || secondsSinceLastSend > minResendWait,
  )
  const [resendButtonIsLoading, setResendButtonIsLoading] = useState(false)

  const checkResendTimer = useCallback(() => {
    lastSend = sessionStorage.getItem('BEHOLD_LAST_VERIFICATION_EMAIL')
    if (!lastSend) {
      setCanResendVerification(true)
    } else {
      const delta = (getNow() - parseInt(lastSend)) / 1000
      setSecondsSinceLastSend(delta)
      setCanResendVerification(delta > minResendWait)
    }
  }, [])

  useInterval(checkResendTimer, 1000)

  function resendVerificationLink() {
    if (!canResendVerification) return false
    setCanResendVerification(false)
    setResendButtonIsLoading(true)
    const authUser = auth.currentUser
    if (authUser?.providerData) {
      authUser.providerData.forEach(async (provider) => {
        if (provider.providerId === 'password' && !authUser?.emailVerified) {
          try {
            await sendVerificationEmail(provider.email)
            st(() => setResendButtonIsLoading(false), 1000)
          } catch (error) {
            console.log(error)
            setCanResendVerification(true)
            setResendButtonIsLoading(false)
          }
        }
      })
    }
  }

  /*
   *   Active Plan
   */
  const planId = user?.plan
  const activePlan = planId ? plans?.[planId] : null
  const planIsUpdating = user?.planIsUpdating
  const activeNotices = []

  if (!auth.currentUser?.emailVerified) {
    activeNotices.push('verifyEmail')
  }

  if (
    !planIsUpdating &&
    user?.pausedByOverage &&
    activePlan?.overageNotice?.text
  ) {
    activeNotices.push('overage')
  }

  if (
    !planIsUpdating &&
    !user?.pausedByOverage &&
    user?.lastTrafficSpike === getISOString(new Date(), 'month') &&
    activePlan?.overageProtection
  ) {
    activeNotices.push('spike')
  }

  if (!planIsUpdating && user?.failedInvoice) {
    activeNotices.push('failedPayment')
  }

  /*
   *   Need to verify email
   */
  const verifyEmailNotice = activeNotices.includes('verifyEmail') ? (
    <Notice
      title="Verify your email"
      animateIn={true}
      compact={activeNotices.length > 1}
      button={
        <SmartButton
          color="green"
          className={styles.notice_button}
          onClick={resendVerificationLink}
          isLoading={resendButtonIsLoading}
        >
          {canResendVerification || resendButtonIsLoading ? (
            <>
              <EmailIcon />
              Resend
            </>
          ) : (
            <>
              <SentIcon />
              Sent
              <div className={styles.countdown}>
                {Math.max(Math.round(minResendWait - secondsSinceLastSend), 0)}
              </div>
            </>
          )}
        </SmartButton>
      }
    >
      Click the link we sent to <b>{auth.currentUser.email}</b> to gain full
      access to your account. Remember to check your spam folder. Need help?{' '}
      <a href="mailto:support@behold.so">support@behold.so</a>
    </Notice>
  ) : null

  /*
   *   Paused by overage notice - Free plan
   */
  const overageNotice = activeNotices.includes('overage') ? (
    <Notice
      title={activePlan?.overageNotice?.title}
      animateIn={true}
      compact={activeNotices.length > 1}
      button={
        <SmartLink
          className={classNames(styles.notice_button)}
          to={activePlan?.overageNotice?.buttonLink || '/'}
        >
          <UpgradeIcon />
          {activePlan?.overageNotice?.buttonText}
        </SmartLink>
      }
    >
      {activePlan?.overageNotice?.text}
    </Notice>
  ) : null

  /*
   *   Traffic Spike notice
   */
  const spikeNotice = activeNotices.includes('spike') ? (
    <Notice
      title={activePlan?.trafficSpikeNotice?.title}
      animateIn={true}
      compact={activeNotices.length > 1}
      button={
        <SmartLink
          className={classNames(styles.notice_button)}
          to={activePlan?.trafficSpikeNotice?.buttonLink || '/'}
        >
          <UpgradeIcon />
          {activePlan?.trafficSpikeNotice?.buttonText}
        </SmartLink>
      }
    >
      {activePlan?.trafficSpikeNotice?.text}
    </Notice>
  ) : null

  /*
   *   Paused by overage notice - Free plan
   */
  const failedPaymentNotice = activeNotices.includes('failedPayment') ? (
    <Notice
      title="Failed payment"
      animateIn={true}
      compact={activeNotices.length > 1}
      button={
        <SmartLink
          className={classNames(styles.notice_button)}
          to={'/retry-payment'}
        >
          <RetryIcon /> Retry payment
        </SmartLink>
      }
    >
      Your last payment failed to process. Please retry, with a new card if
      necessary, to ensure your account stays current.
    </Notice>
  ) : null

  /*
   *   Render
   */
  return (
    <div
      className={classNames(styles.container, {
        [styles.is_active]: show && activeNotices.length,
      })}
    >
      {verifyEmailNotice}
      {overageNotice}
      {spikeNotice}
      {failedPaymentNotice}
    </div>
  )
}
