import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore, serverTimestamp } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.FIRE_API_KEY,
  authDomain: process.env.FIRE_AUTH_DOMAIN,
  databaseURL: process.env.FIRE_DATABASE_URL,
  projectId: process.env.FIRE_PROJECT_ID,
  storageBucket: process.env.FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIRE_MESSAGING_SENDER_ID,
  appId: process.env.FIRE_APP_ID,
  measurementId: process.env.FIRE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
})
const func = getFunctions(firebaseApp)
const auth = getAuth(firebaseApp)

if (process.env.ENVIRONMENT === 'LOCAL') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APPCHECK_DEBUG_TOKEN
}

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LddIq4eAAAAAEZvkJXIVIP6wLc6HTkG08J0fiTR'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})

if (process.env.ENVIRONMENT === 'LOCAL') {
  connectFunctionsEmulator(func, 'localhost', 5001)
}

export { db, func, auth, firebaseApp, serverTimestamp }
