import PropTypes from 'prop-types'
import { auth } from 'Fire/firebaseInit'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { validateEmail } from 'Helpers/utils'
import Metas from 'Components/Metas'
import ControlledInput from 'Components/ControlledInput'
import { Form, FormItem } from 'Components/Form'
import Logo from 'Images/logo.svg'
import LeftArrow from 'Images/icons/caret-left.svg'
import styles from './AuthResetPassword.module.scss'

export default function AuthResetPassword({ email, setEmail }) {
  const [currentForm, setCurrentForm] = useState('RESET')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const emailRef = useRef(null)

  /*
   *   Send password reset email
   */
  async function passwordReset() {
    if (!validateEmail(email)) {
      emailRef.current.focus()
      if (email.length < 1) {
        setErrorMessage('Please enter an email address')
      } else {
        setErrorMessage('Invalid email address')
      }
      return false
    }

    setIsLoading(true)

    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      console.log(error)
    }

    setCurrentForm('RESET_SENT')
    setIsLoading(false)
  }

  /*
   *   Focus input on load
   */
  useEffect(() => {
    if (emailRef.current && window.innerWidth > 600) emailRef.current.focus()
  }, [])

  const passwordResetForm = (
    <div className={styles.form}>
      <Form
        className={styles.email}
        onSubmit={passwordReset}
        isLoading={isLoading}
        errorMessage={errorMessage}
        submitButtonContent={<>Submit</>}
        buttonAlignment="center"
      >
        <h2 className={styles.section_title}>Reset your password:</h2>

        <FormItem label="Account Email" inputId="reset-email">
          <ControlledInput
            id="reset-email"
            tabIndex={1}
            ref={emailRef}
            className={styles.input}
            type="email"
            onChange={setEmail}
            value={email}
          />
        </FormItem>
      </Form>
    </div>
  )

  const passwordResetSuccess = (
    <div className={styles.form}>
      <div className={styles.success}>
        <h2 className={styles.password_reset_message}>
          A password reset link has been sent to <b>{email}</b>
        </h2>
      </div>
    </div>
  )

  return (
    <main className={styles.container}>
      <Metas title="Behold login | Sign in to your account" />
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {currentForm === 'RESET' && passwordResetForm}
        {currentForm === 'RESET_SENT' && passwordResetSuccess}
        <footer className={styles.footer}>
          <Link to="/sign-in">
            <LeftArrow />
            Back to login
          </Link>
        </footer>
      </div>
    </main>
  )
}

AuthResetPassword.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
}
