import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSlice } from 'State'
import FeedPanel from 'Components/feed/FeedPanel'
import HelpTooltip from 'Components/HelpTooltip'
import ToggleButton from 'Components/ToggleButton'
import TagInput from 'Components/TagInput'
import PanelIcon from 'Images/icons/filter.svg'
import ReelsIcon from 'Images/icons/reels.svg'
import InstagramIcon from 'Images/icons/instagram.svg'
import GalleryIcon from 'Images/icons/gallery-outline.svg'
import VideoIcon from 'Images/icons/play-outline.svg'
import styles from 'Components/feed/FeedPanel.module.scss'

export default function FeedPanelKlaviyoSettings({
  panelId,
  localFeedSettings,
  updateLocalFeedSetting,
  hasChanges,
  saveChanges,
  resetChanges,
  isLoading,
}) {
  const { addNotification } = useSlice('addNotification')

  function save() {
    saveChanges()
  }

  function updatePostTypeFilter(postType, val) {
    const filterSet = new Set(localFeedSettings.filter.allowedTypes)
    if (val) {
      filterSet.add(postType)
      updateLocalFeedSetting('filter', { allowedTypes: Array.from(filterSet) })
    } else {
      if (filterSet.size > 1) {
        filterSet.delete(postType)
        updateLocalFeedSetting('filter', {
          allowedTypes: Array.from(filterSet),
        })
      } else {
        addNotification(
          {
            text: 'You must allow at least one type of post',
            duration: 20,
          },
          'MIN_ONE_POST_TYPE',
        )
      }
    }
  }

  function updateCaptionIncludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionIncludeText: Array.from(new Set(text)),
    })
  }

  function updateCaptionExcludeText(text) {
    updateLocalFeedSetting('filter', {
      ...localFeedSettings.filter,
      captionExcludeText: Array.from(new Set(text)),
    })
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={350}
      icon={<PanelIcon />}
      title="Filter"
      hasChanges={hasChanges}
      saveChanges={save}
      resetChanges={resetChanges}
    >
      <div
        className={classNames(styles.fieldset, {
          [styles.disabled]: isLoading,
        })}
      >
        <div className={styles.setting}>
          <div className={styles.label}>Allowed post types</div>
          <div className={styles.toggle_buttons}>
            <ToggleButton
              ariaLabel="Allowed post types: Images"
              popupLabel={`Images: ${
                localFeedSettings.filter.allowedTypes.includes('IMAGE')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('IMAGE')}
              onChange={(val) => updatePostTypeFilter('IMAGE', val)}
              iconOnly
              disabled={isLoading}
            >
              <InstagramIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Videos"
              popupLabel={`Videos: ${
                localFeedSettings.filter.allowedTypes.includes('VIDEO')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('VIDEO')}
              onChange={(val) => updatePostTypeFilter('VIDEO', val)}
              iconOnly
              disabled={isLoading}
            >
              <VideoIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Reels"
              popupLabel={`Reels: ${
                localFeedSettings.filter.allowedTypes.includes('REEL')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes('REEL')}
              onChange={(val) => updatePostTypeFilter('REEL', val)}
              iconOnly
              disabled={isLoading}
            >
              <ReelsIcon />
            </ToggleButton>

            <ToggleButton
              ariaLabel="Allowed post types: Albums"
              popupLabel={`Albums: ${
                localFeedSettings.filter.allowedTypes.includes('CAROUSEL_ALBUM')
                  ? 'On'
                  : 'Off'
              }`}
              className={styles.toggle_button}
              checked={localFeedSettings.filter.allowedTypes.includes(
                'CAROUSEL_ALBUM',
              )}
              onChange={(val) => updatePostTypeFilter('CAROUSEL_ALBUM', val)}
              iconOnly
              disabled={isLoading}
            >
              <GalleryIcon />
            </ToggleButton>
          </div>
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption must include">
              <div className={styles.tooltip}>
                <h1>Caption must include</h1>
                <p>
                  Only posts that contain at least one of the text snippets you
                  add here will be included in your feed. This is case-sensitive
                  and can include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionIncludeText}
            onUpdate={updateCaptionIncludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
        <div className={styles.setting}>
          <div className={styles.label}>
            <HelpTooltip width={275} triggerText="Caption may not include">
              <div className={styles.tooltip}>
                <h1>Caption may not include</h1>
                <p>
                  Posts that contain any of the text you add here will be
                  excluded from your feed. This is case-sensitive and can
                  include spaces.
                </p>
              </div>
            </HelpTooltip>
          </div>
          <TagInput
            tags={localFeedSettings.filter.captionExcludeText}
            onUpdate={updateCaptionExcludeText}
            size="small"
            allowSpaces
            disabled={isLoading}
          />
        </div>
      </div>
    </FeedPanel>
  )
}

FeedPanelKlaviyoSettings.propTypes = {
  panelId: PropTypes.string,
  feed: PropTypes.object,
  updateLocalWidgetSetting: PropTypes.func,
  updateLocalFeedSetting: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
}
